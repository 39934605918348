import * as types from "../store/actionTypes";

export const setDataForm = (data, object) => ({
  type: types.SET_DATA_FORM_CASCO,
  payload: {data, object}
});

export const clearIsRequestState = () => ({
    type: types.CLEAR_IS_REQUEST_STATE
})

export const uploadPassportFile = (payload) => ({
    type: types.UPLOAD_PASSPORT_FILE,
    payload: payload,
})

export const removePassportFile = (payload) => ({
    type: types.REMOVE_PASSPORT_FILE,
    payload: payload,
})

export const uploadPtsFile = (payload) => ({
    type: types.UPLOAD_PTS_FILE,
    payload: payload,
})

export const removePtsFile = (payload) => ({
    type: types.REMOVE_PTS_FILE,
    payload: payload,
})

export const uploadStsFile = (payload) => ({
    type: types.UPLOAD_STS_FILE,
    payload: payload,
})

export const removeStsFile = (payload) => ({
    type: types.REMOVE_STS_FILE,
    payload: payload,
})

export const uploadCurrentPolicyFile = (payload) => ({
    type: types.UPLOAD_CURRENT_POLICY_FILE,
    payload: payload,
})

export const removeCurrentPolicyFile = (payload) => ({
    type: types.REMOVE_CURRENT_POLICY_FILE,
    payload: payload,
})

export const uploadJuridicalCardFile = (payload) => ({
    type: types.UPLOAD_JURIDICAL_CARD_FILE,
    payload: payload,
})

export const removeJuridicalCardFile = (payload) => ({
    type: types.REMOVE_JURIDICAL_CARD_FILE,
    payload: payload,
})

export const uploadDrivingLicenseFile = (payload) => ({
    type: types.UPLOAD_DRIVING_LICENSE_FILE,
    payload: payload,
})

export const removeDrivingLicenseFile = (payload) => ({
    type: types.REMOVE_DRIVING_LICENSE_FILE,
    payload: payload,
})

export const sendDataForm = () => ({
  type: types.SEND_DATA_FORM_CASCO
})

export const toggleCascoKind = (kind) => ({
  type: types.TOGGLE_CASCO_KIND,
  payload: kind,
})

export const clearForm = (type) => ({
  type: types.CLEAR_FORM,
  payload: type,
})

export const toggleModalCASCO = (value) => ({
  type: types.TOGGLE_CASCO_MODAL,
  payload: value,
})

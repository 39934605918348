import {takeLatest} from 'redux-saga/effects';
import * as api from './api';
import * as types from '../store/actionTypes';

export function* signInSaga({payload}) {
  yield api.post('login/', null, payload, types.SIGN_IN);
}

export function* refreshTokenSaga({payload}) {
  yield api.post('refresh/', null, payload, types.REFRESH_TOKEN, true);
}

export function* checkAuthenticationSaga() {
  yield api.get(`check_authentication/`, null, types.CHECK_AUTHENTICATION);
}

export function* getUserInfoSaga({payload}) {
  yield api.get(`get_user_info/`, payload, types.GET_USER_INFO);
}

export default function* () {
  yield takeLatest(types.SIGN_IN, signInSaga);
  yield takeLatest(types.REFRESH_TOKEN, refreshTokenSaga);
  yield takeLatest(types.CHECK_AUTHENTICATION, checkAuthenticationSaga);
  yield takeLatest(types.NEXT_STEP, checkAuthenticationSaga);
  yield takeLatest(types.PREV_STEP, checkAuthenticationSaga);
  yield takeLatest(types.GET_USER_INFO, getUserInfoSaga);
}
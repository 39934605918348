import {fork, all} from "redux-saga/effects";
import osagoSaga from "./osagoSaga";
import cascoSaga from "./cascoSaga";
import agentsSaga from "./agentsSaga";
import loginSaga from "./loginSaga";
import registrationSaga from "./registrationSaga";
import accidentsSportSaga from "./accidentsSportSaga";
import contractsSaga from "./contractsSaga";
import profileSaga from "./profileSaga";
import mainSaga from "./mainSaga";
import mortgageSaga from "./mortgageSaga";
import crossSaga from "./crossSaga";
import renewalSaga from "./renewalSaga";

export default function* rootSaga() {
  yield all([
    fork(osagoSaga),
    fork(cascoSaga),
    fork(agentsSaga),
    fork(loginSaga),
    fork(registrationSaga),
    fork(accidentsSportSaga),
    fork(contractsSaga),
    fork(profileSaga),
    fork(mainSaga),
    fork(mortgageSaga),
    fork(crossSaga),
    fork(renewalSaga),
  ]);
}

import React from "react";
import {Button, Dropdown, Menu} from "antd";
import * as keys from "../../routers/keys";
import {ReactComponent as DropdownIcon} from '../../assets/Icons/Dropdown.svg';
import {ReactComponent as BellIcon} from '../../assets/Icons/Bell.svg';
import {ReactComponent as SmallRedCircleIcon} from '../../assets/Icons/SmallRedCircle.svg';
import "./styles/index.scss";


export const insuranceDropdownMenu = (history) => (
  <Menu className="hyd-dropdown-menu">
    <Menu.Item
      key="1"
      onClick={() => {
        history.replace(keys.OSAGO)
      }}
    >
      ОСАГО
    </Menu.Item>
    <Menu.Item key="2" onClick={() => {
      history.replace(keys.CASCO)
    }}>
      КАСКО
    </Menu.Item>
    <Menu.Item key="3" disabled>
      ИФЛ
    </Menu.Item>
    <Menu.Item key="4" disabled>
      НС Авто
    </Menu.Item>
    <Menu.Item
      key="5"
      onClick={() => {
        history.replace(keys.ACCIDENTS_SPORT)
      }}
    >
      НС
    </Menu.Item>
    <Menu.Item
      key="7"
      onClick={() => {
        history.replace(keys.CROSS)
      }}
    >
      КРОСС
    </Menu.Item>
    <Menu.Item
      key="8"
      onClick={() => {
        history.replace(keys.MORTGAGE)
      }}
    >
      Ипотека
    </Menu.Item>
    <Menu.Item key="9" disabled>
      Телемедицина
    </Menu.Item>
  </Menu>
)

export const servicesDropdownMenu = (history) => (
  <Menu className="hyd-dropdown-menu">
    <Menu.Item
      key="10"
      onClick={() => {
        history.replace(keys.KBM)
      }}
    >
      Проверить КБМ
    </Menu.Item>
    <Menu.Item
      key="11"
      onClick={() => {
        history.replace(keys.PROLONGATION)
      }}
    >
      Пролонгация ОСАГО
    </Menu.Item>
    <Menu.Item
      key="12"
      onClick={() => {
        history.replace(keys.ADDENDUM)
      }}
    >
      Аддендум
    </Menu.Item>
    <Menu.Item key="13" disabled>
      Комиссия
    </Menu.Item>
  </Menu>
)

export const reportsDropdownMenu = (history) => (
  <Menu className="hyd-dropdown-menu">
    <Menu.Item
      key="14"
      onClick={() => {
        history.replace(keys.CONTRACTS)
      }}
    >
      Журнал договоров
    </Menu.Item>
    <Menu.Item key="15" disabled>
          <span>
            Журнал отчётов
          </span>
    </Menu.Item>
  </Menu>
)

export const mainDropdownMenu = (history) => (
  <>
    <Dropdown overlay={insuranceDropdownMenu(history)} trigger={['click']}>
      <span className="ant-dropdown-link hyd-dropdown" onClick={e => e.preventDefault()}>
        Страхование <DropdownIcon className="hyd-dropdown-ico"/>
      </span>
    </Dropdown>
    <Dropdown overlay={servicesDropdownMenu(history)} trigger={['click']}>
      <span className="ant-dropdown-link hyd-dropdown" onClick={e => e.preventDefault()}>
        Сервисы <DropdownIcon className="hyd-dropdown-ico"/>
      </span>
    </Dropdown>
    <Dropdown overlay={reportsDropdownMenu(history)} trigger={['click']}>
      <span className="ant-dropdown-link hyd-dropdown" onClick={e => e.preventDefault()}>
        Отчёты <DropdownIcon className="hyd-dropdown-ico"/>
      </span>
    </Dropdown>
  </>
)

export const getMoneyButton = (earnedMoney) => (
  <Button className="hyd-btn-small hyd-medium-btn-give-me-my-money" disabled={earnedMoney <= 0}>
    Получить деньги
  </Button>
)

const showCountIcon = (countSentNotifications) => {
  if (countSentNotifications) {
    return (
      <>
        <SmallRedCircleIcon className="hyd-small-num-ico"/>
        <span className={countSentNotifications < 10 ? "hyd-small-num-ico-num" : "hyd-small-num-ico-num-2"}>
            {countSentNotifications}
          </span>
      </>
    )
  }
}

export const showNotifications = (notifications, toggleNotificationsModal) => {
  let countSentNotifications = notifications.filter(item => item.status === 'sent').length

  return (
    <>
      <span onClick={() => {
        toggleNotificationsModal(true)
      }}>
        <BellIcon className="hyd-bell-ico"/>
        {showCountIcon(countSentNotifications)}
      </span>
    </>
  )
}

export const isRequestSelector = state => state.cascoReducer.isRequest;
export const errorsCascoSelector = state => state.cascoReducer.errors;
export const visibleErrorModalCascoSelector = state => state.cascoReducer.visibleErrorModal;
export const cascoKindSelector = state => state.cascoReducer.cascoKind;
export const ptsFileListSelector = state => state.cascoReducer.ptsFileList;
export const stsFileListSelector = state => state.cascoReducer.stsFileList;
export const currentPolicyFileListSelector = state => state.cascoReducer.currentPolicyFileList;
export const passportFileListSelector = state => state.cascoReducer.passportFileList;
export const juridicalCardFileListSelector = state => state.cascoReducer.juridicalCardFileList;
export const drivingLicenseFileListSelector = state => state.cascoReducer.drivingLicenseFileList;
export const isFormSuccessfullySentSelector = state => state.cascoReducer.isFormSuccessfullySent;
export const cascoDataFormSelector = state => state.cascoReducer.cascoDataForm;
export const visibleModalCASCOApplicationSelector = state => state.cascoReducer.visibleModalCASCOApplication;

import React, {Component} from "react";
import {Row, Form, Input, Button, Col, Tooltip,} from "antd";
import Cleave from "cleave.js/react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {setDataAccidentsSportForm} from "../../actions/accidentsSportActions";
import {accidentsSportDataFormSelector} from "../../selectors/accidentsSportSelectors";
import {transform} from "../../utils";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import {ReactComponent as DeleteIcon} from '../../assets/Icons/Delete.svg';


class AdditionalInsurers extends Component {
  state = {
    CleaveKey: 0,
    oldCleaveKey: 0
  }

  render() {
    const {
      k,
      key,
      index,
      remove,
      add,
      checkValueDate,
      isLast
    } = this.props;
    const {
      getFieldDecorator,
    } = this.props.form;

    return (
      <>
        <div className={"hyd-insurers-number-accidents"}>
          <span>Застрахованный №{index}</span>
          <span className={"hyd-osago-driver-delete"} onClick={() => remove(k)}>
            <Tooltip title="Удалить">
              <DeleteIcon className={"hyd-accidents-insurer-delete-ico"}/>
            </Tooltip>
          </span>
        </div>
        <Row key={key} data-key={key}>

          {/* Фамилия */}
          <Form.Item
            required={false}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"accidents-sport-step-1_" + `additional_insurer_lastname_${k}`}
              labelText={"Фамилия"}
              divId={"float-label-small"}
              child={getFieldDecorator(`additional_insurer_lastname_${k}`, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: " ",
                  },
                  {
                    // только лат или только кириллица (допускается двойная фамилия с пробелом по центру или тире)
                    pattern: /^(([А-Яа-яЁё])+(-|\s)?([А-Яа-яЁё]+))$|^(([A-za-z])+(-|\s)?([A-za-z]+))$/g,
                    message: " "
                  }
                ],
              })(<Input className={"ant-input hyd-input-small hyd-osago-8-input"}/>)}
            />
          </Form.Item>

          {/* Имя */}
          <Form.Item
            required={false}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"accidents-sport-step-1_" + `additional_insurer_name_${k}`}
              labelText={"Имя"}
              divId={"float-label-small"}
              child={getFieldDecorator(`additional_insurer_name_${k}`, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: " ",
                  },
                  {
                    // только лат или только кириллица (допускается двойное имя с пробелом по центру или тире)
                    pattern: /^(([А-Яа-яЁё])+(-|\s)?([А-Яа-яЁё]+))$|^(([A-za-z])+(-|\s)?([A-za-z]+))$/g,
                    message: " "
                  }
                ],
              })(<Input className={"ant-input hyd-input-small hyd-osago-8-input"}/>)}
            />
          </Form.Item>

          {/* Отчество */}
          <Form.Item
            required={false}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input hyd-osago-no-margin"
              htmlFor={"accidents-sport-step-1_" + `additional_insurer_middlename_${k}`}
              labelText={"Отчество"}
              divId={"float-label-small"}
              child={getFieldDecorator(`additional_insurer_middlename_${k}`, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    // только лат или только кириллица (допускается двойное отчество с пробелом по центру или тире)
                    pattern: /^(([А-Яа-яЁё])+(-|\s)?([А-Яа-яЁё]+))$|^(([A-za-z])+(-|\s)?([A-za-z]+))$/g,
                    message: " "
                  },
                ],
              })(<Input className={"ant-input hyd-input-small hyd-osago-8-input"}/>)}
            />
          </Form.Item>


          {/* Дата рождения */}
          <Form.Item
            required={false}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"accidents-sport-step-1_" + `additional_insurer_birth_${k}`}
              labelText={"Дата рождения"}
              divId={"float-label-small"}
              child={getFieldDecorator(`additional_insurer_birth_${k}`, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: " ",
                  },
                  {
                    validator: checkValueDate,
                  },
                ],
              })(
                <Cleave
                  className="ant-input hyd-input-small hyd-osago-8-input"
                  key={`additional_insurer_birth_${k}`}
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  }}
                />
              )}
            />
          </Form.Item>

          <Row>
            {isLast && (
              <div className={"hyd-emphasise-block-small"} id={"hyd-add-insurer"}>
                <Form.Item>
                  <Button
                    className={"hyd-btn-small hyd-osago-btn"}
                    onClick={add}
                    style={{width: "100%", marginRight: "auto"}}
                  >
                    Добавить
                  </Button>
                </Form.Item>
              </div>
            )}
          </Row>
        </Row>
      </>
    )
  }
}

const WrappedAdditionalInsurersForm = Form.create({
  name: "accidents-sport-step-1",
  mapPropsToFields(props) {
    const {accidentsSportDataForm} = props;
    const transformed = transform({accidentsSportDataForm});

    return transformed.accidentsSportDataForm;
  },
  onFieldsChange(props, changedFields) {
    props.setDataAccidentsSportForm(changedFields);
  }
})(AdditionalInsurers);

const mapStateToProps = state => ({
  accidentsSportDataForm: accidentsSportDataFormSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setDataAccidentsSportForm,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedAdditionalInsurersForm);

import React, {Component} from "react";
import {
  Row,
  Form,
  Input,
  Button,
  Tabs,
  Select,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Cleave from "cleave.js/react";

import {
  setDataForm,
  getCarOwnerInfoByINN,
} from "../../../actions/osagoActions";
import {
  dataFormSelector,
} from "../../../selectors/osagoSelectors";

import {
  juristicDocumentsTypes,
  transform
} from "../../../utils";
import FloatingLabelInput from "../FloatingLabeledInputs/FloatingLabelInput";
import ModalError from "../../ModalError";

const {Option} = Select;
const {TabPane} = Tabs;


class InfoJuristicOwnerCar extends Component {

  state = {
    cleaveKey: 0,
  }

  onCustomChange = (event) => {
    if (event.target.value !== "") {
      this.setState({isActive: true});
    } else {
      this.setState({isActive: false});
    }
    let resultValueDate = event.target.value.split(" ")
    if (resultValueDate.length === 2) {
      let series = resultValueDate[0]
      let number = resultValueDate[1]
      this.props.form.setFieldsValue({
        [this.props.numberJuristicDocument]: number,
        [this.props.seriesJuristicDocument]: series
      });
    } else {
      this.props.form.setFieldsValue({
        [this.props.numberJuristicDocument]: event.target.rawValue,
        [this.props.seriesJuristicDocument]: ""
      });
    }
  }

  render() {
    const {
      identifyNumberJuristicError = "",
      identifyNumberJuristic = "",
      nameJuristic = "",
      nameJuristicError = "",
      seriesJuristicDocument = "",
      numberJuristicDocumentError = "",
      numberJuristicDocument = "",
      dateOfJuristicDocError = "",
      dateOfJuristicDoc = "",
      getCarOwnerInfoByINN,
      carOwnerJuristicKpp = "",
      carOwnerJuristicKppError = "",
      carOwnerCladrJuristic = "",
      carOwnerCladrJuristicError = "",
      carOwnerJuristicDocumentType = "",

    } = this.props;

    const {
      getFieldDecorator,
      getFieldValue,
      getFieldError,
      setFieldsValue,
    } = this.props.form;

    const documentSeriesNumber = `united_${seriesJuristicDocument}_${numberJuristicDocument}`;
    const documentSeriesNumberError = getFieldError(documentSeriesNumber);

    return (
      <>
        <Row>
          {/* ИНН */}
          <Form.Item
            validateStatus={identifyNumberJuristicError ? "error" : ""}
            help={identifyNumberJuristicError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"step-2_" + identifyNumberJuristic}
              labelText={"ИНН"}
              divId={"float-label-small"}
              child={getFieldDecorator(identifyNumberJuristic, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    pattern: /^([0-9])+$/g,
                    message: " "
                  }
                ]
              })(<Input className="hyd-input-small hyd-osago-8-input"/>)}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="hyd-btn-small hyd-osago-btn"
              type="primary"
              disabled={identifyNumberJuristicError || !getFieldValue(identifyNumberJuristic)}
              onClick={() => {
                getCarOwnerInfoByINN({inn: getFieldValue(identifyNumberJuristic)});
              }}
            >
              Проверить
            </Button>
          </Form.Item>
        </Row>

        <Row>
          {/* Название */}
          <Form.Item
            validateStatus={nameJuristicError ? "error" : ""}
            help={nameJuristicError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"step-2_" + nameJuristic}
              labelText={"Название собственника как ЮЛ"}
              divId={"float-label-small"}
              child={getFieldDecorator(nameJuristic, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [{required: true, message: " "}]
              })(<Input className="hyd-input-small hyd-osago-8-input"/>)}
            />
          </Form.Item>

          {/* КПП */}
          <Form.Item
            validateStatus={carOwnerJuristicKppError ? "error" : ""}
            help={carOwnerJuristicKppError || ""}

          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"step-2_" + carOwnerJuristicKpp}
              labelText={"КПП организации"}
              divId={"float-label-small"}
              child={getFieldDecorator(carOwnerJuristicKpp, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [{required: true, message: " "}]
              })(<Input className="hyd-input-small hyd-osago-8-input"/>)}
            />
          </Form.Item>

          {/* КЛАДР */}
          <Form.Item
            validateStatus={carOwnerCladrJuristicError ? "error" : ""}
            help={carOwnerCladrJuristicError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input hyd-osago-no-margin"
              htmlFor={"step-2_" + carOwnerCladrJuristic}
              labelText={"КЛАДР"}
              divId={"float-label-small"}
              child={getFieldDecorator(carOwnerCladrJuristic, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [{required: true, message: " "}]
              })(<Input className="hyd-input-small hyd-osago-8-input"/>)}
            />
          </Form.Item>
        </Row>

        <div className={"hyd-bold-text hyd-subtitle"}>Документ о регистрации ЮЛ</div>

        {/* Документ о регистрации ЮЛ */}
        <Form.Item style={{display: "none"}}>
          {getFieldDecorator(carOwnerJuristicDocumentType, {
            initialValue: 1,
          })(
            <Select onChange={() => {
              this.setState({cleaveKey: ++this.state.cleaveKey})
            }}>
              {juristicDocumentsTypes.map(({key, text}) =>
                <Option value={key}>{text}</Option>
              )}
            </Select>
          )}
        </Form.Item>

        <Tabs
          className="hyd-tab-osago"
          defaultActiveKey={getFieldValue(carOwnerJuristicDocumentType).toString()}
          onChange={(e) => {
            e = parseInt(e)
            setFieldsValue({
              [carOwnerJuristicDocumentType]: e,
              [seriesJuristicDocument]: undefined,
              [numberJuristicDocument]: undefined,
              [documentSeriesNumber]: undefined,
              [dateOfJuristicDoc]: undefined,
            })
            this.setState({cleaveKey: ++this.state.cleaveKey})
          }}
        >
          {juristicDocumentsTypes.map(({key, text}) =>
            <TabPane tab={<span className="hyd-tab-pane">{text}</span>} key={key.toString()}></TabPane>
          )}
        </Tabs>

        {/* Серия */}
        <Form.Item style={{display: "none"}}>
          <FloatingLabelInput
            htmlFor={"step-2_" + seriesJuristicDocument}
            child={getFieldDecorator(seriesJuristicDocument, {})(<Input/>)}
          />
        </Form.Item>

        {/* Номер */}
        <Form.Item
          style={([1].includes(this.props.dataForm[carOwnerJuristicDocumentType].value)) ? {display: "none"} : {}}
          validateStatus={numberJuristicDocumentError ? "error" : ""}
          help={numberJuristicDocumentError || ""}
        >
          <FloatingLabelInput
            divClassName="hyd-osago-8-input"
            htmlFor={"step-2_" + numberJuristicDocument}
            labelText={"Номер"}
            divId={"float-label-small"}
            child={getFieldDecorator(numberJuristicDocument, {
              validateTrigger: ["onBlur", "onChange"],
              rules: [
                {required: true, message: " "},
                {
                  pattern: /^.{5,50}$/g,
                  message: " "
                }
              ]
            })(<Input maxLength={50} className="hyd-input-small hyd-osago-8-input"/>)}
          />
        </Form.Item>

        {/* Серия и номер */}
        {[1].includes(getFieldValue(carOwnerJuristicDocumentType)) &&
          <Form.Item
            validateStatus={documentSeriesNumberError ? "error" : ""}
            help={documentSeriesNumberError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"step-2_" + documentSeriesNumber}
              labelText={"Серия и номер"}
              divId={"float-label-small"}
              child={getFieldDecorator(documentSeriesNumber, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " ",
                  },
                  {
                    pattern: /^[0-9]{2} [0-9]{9}$/g,
                    message: " ",
                  },
                ],
              })(
                <Cleave
                  key={this.state.cleaveKey}
                  className="ant-input hyd-input-small hyd-osago-8-input"
                  options={{
                    blocks: [2, 9],
                    delimiter: " ",
                    numericOnly: false
                  }}
                  onChange={this.onCustomChange}
                />
              )}
            />
          </Form.Item>}

        {/* Дата выдачи */}
        <Form.Item
          validateStatus={dateOfJuristicDocError ? "error" : ""}
          help={dateOfJuristicDocError || ""}
        >
          <FloatingLabelInput
            divClassName="hyd-osago-8-input hyd-osago-no-margin"
            htmlFor={"step-2_" + dateOfJuristicDoc}
            labelText={"Дата выдачи"}
            divId={"float-label-small"}
            child={getFieldDecorator(dateOfJuristicDoc, {
              validateTrigger: ["onBlur", "onChange"],
              rules: [{required: true, message: " "}]
            })(
              <Cleave
                className="ant-input hyd-input-small hyd-osago-8-input"
                key={JSON.stringify({
                  date: true,
                  delimiter: ".",
                  datePattern: ["d", "m", "Y"]
                })}
                options={{
                  date: true,
                  delimiter: ".",
                  datePattern: ["d", "m", "Y"]
                }}
              />
            )}
          />
        </Form.Item>
        <ModalError/>
      </>
    );
  }
}


const WrappedInfoJuristicOwnerCarForm = Form.create({
  name: "step-2",
  mapPropsToFields(props) {
    const {dataForm} = props;
    const transformed = transform({dataForm});
    return transformed.dataForm;
  },
  onFieldsChange(props, changedFields) {

    props.setDataForm(changedFields, "carOwner");
  }
})(InfoJuristicOwnerCar);


const mapStateToProps = state => ({
  dataForm: dataFormSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setDataForm,
      getCarOwnerInfoByINN,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WrappedInfoJuristicOwnerCarForm);

import React from "react";
import {Row} from "antd";

const gender_options = [
  {
    value: "M",
    text: "Мужской"
  },
  {
    value: "F",
    text: "Женский"
  },
  {
    value: "",
    text: ""
  },
]
const family_state_options = [
  {
    value: 1,
    text: "Не женат/ Не замужем"
  },
  {
    value: 2,
    text: "Женат/ Замужем"
  },
  {
    value: "",
    text: ""
  },
]
const document_type_options = [
  {
    value: 1,
    text: "ВУ РФ"
  },
  {
    value: 2,
    text: "Иностранное ВУ"
  },
  {
    value: "",
    text: ""
  },
]

export default class DriverDisabled extends React.Component {
  render() {
    return (
      <>
        <div className={"hyd-driver-number-osago-disabled"}>
          {`Водитель №${this.props.driverNumber}`}
        </div>
        <Row>
          {this.props.lastName &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Фамилия</div>
            <div className={"hyd-prev-step-value"}>{this.props.lastName}</div>
          </div>}
          {this.props.firstName &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Имя</div>
            <div className={"hyd-prev-step-value"}>{this.props.firstName}</div>
          </div>}
          {this.props.middle_name &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Отчество</div>
            <div className={"hyd-prev-step-value"}>{this.props.middle_name}</div>
          </div>}
          {this.props.gender &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Пол</div>
            <div className={"hyd-prev-step-value"}>{gender_options.find(e => this.props.gender === e.value).text}</div>
          </div>}
          {this.props.birth_date &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата рождения</div>
            <div className={"hyd-prev-step-value"}>{this.props.birth_date}</div>
          </div>}
          {this.props.family_state &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Семейное положение</div>
              <div className={"hyd-prev-step-value"}>{family_state_options.find(e => this.props.family_state === e.value).text}</div>
            </div>
          }
        </Row>
        <Row>
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Водительское удостоверение</div>
            <div className={"hyd-prev-step-value"}>{document_type_options.find(e => this.props.document_type === e.value).text}</div>
          </div>
          {this.props.series &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Серия документа</div>
            <div className={"hyd-prev-step-value"}>{this.props.series}</div>
          </div>}
          {this.props.number &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Номер документа</div>
            <div className={"hyd-prev-step-value"}>{this.props.number}</div>
          </div>}
          {this.props.document_date &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата выдачи</div>
            <div className={"hyd-prev-step-value"}>{this.props.document_date}</div>
          </div>}
          {this.props.experience_date &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата начала стажа</div>
            <div className={"hyd-prev-step-value"}>{this.props.experience_date}</div>
          </div>}
        </Row>
        {this.props.is_old &&
        <Row>
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Серия старого ВУ</div>
            <div className={"hyd-prev-step-value"}>{this.props.old_series}</div>
          </div>
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Номер старого ВУ</div>
            <div className={"hyd-prev-step-value"}>{this.props.old_number}</div>
          </div>
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата выдачи старого ВУ</div>
            <div className={"hyd-prev-step-value"}>{this.props.old_document_date}</div>
          </div>
        </Row>
        }
      </>
    )
  }
}
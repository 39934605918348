import React, {Component} from "react";
import {
  Form,
  Spin,
  notification,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
  goToNextStep,
  goToPrevStep,
  setDataForm,
  createPolicy,
} from "../../actions/mortgageActions";
import {
  banksSelector,
  isRequestSelector,
  currentStepSelector,
  errorsMortgageSelector,
  mortgageDataFormSelector,
  calcResultSelector,
  calculationInProgressSelector,
  creatingPolicySelector
} from "../../selectors/mortgageSelectors";
import {userInfoSelector} from "../../selectors/loginSelectors";
import ModalError from "../../components/ModalError";
import {transform} from "../../utils";
import "./styles/index.scss";
import "../../fonts/open-sans.css"
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import {ReactComponent as NumberInactiveIcon} from "../../assets/Icons/NumberInactive.svg";
import FirstStepDisabled from "../../components/MortgageDisabledSteps/FirstStepDisabled";
import SecondStepDisabled from "../../components/MortgageDisabledSteps/SecondStepDisabled";
import ThirdStepDisabled from "../../components/MortgageDisabledSteps/ThirdStepDisabled";
import CustomCollapse from "../../components/Form/CustomCollapse";
import rulesLifeVSK from "../../assets/files/mortgage/rulesLifeVSK.pdf";
import rulesPropertyVSK from "../../assets/files/mortgage/rulesPropertyVSK.pdf";
import rulesAlfa from "../../assets/files/mortgage/rulesAlfa.pdf";
import rulesRenins from "../../assets/files/mortgage/rulesRenins.pdf";
import {checkSelectedBankKey, VTB_KEY} from "./mortgageUtils";

class FourthStepMortgage extends Component {
  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  componentDidMount() {
    this.headerRef.current.scrollIntoView()
  }

  componentDidUpdate(prevProps, prevState, snapshot?) {
    const {
      isRequest,
      currentStep,
      goToNextStep,
      errors,
      creatingPolicy,
    } = this.props;

    if (
      isRequest !== prevProps.isRequest &&
      creatingPolicy !== prevProps.creatingPolicy &&
      !isRequest &&
      !errors
    ) {
      goToNextStep(currentStep + 1);
    }

    if (errors !== prevProps.errors && errors) {
      //alert(errors)
    }
  }

  handleSubmit = (e) => {
    const {createPolicy} = this.props;

    this.props.form.validateFields((err,) => {
      if (!err) {
        createPolicy(e)
      } else {
        notification["error"]({
          message: err
        })
      }
    })
  }

  render() {
    const {
      form,
      banks,
      currentStep,
      goToPrevStep,
      calcResult,
      userInfo,
      setDataForm,
      calculationInProgress,
      mortgageDataForm
    } = this.props

    const {
      getFieldDecorator,
      getFieldValue,
    } = form

    const ALFA_KEY = "alfastrah"
    const RENAISSANCE_KEY = "renesans"

    return (
      <>
        <FirstStepDisabled
          goToPrevStep={() => goToPrevStep(currentStep - 3)}
        />
        <SecondStepDisabled
          goToPrevStep={() => goToPrevStep(currentStep - 2)}
        />
        <ThirdStepDisabled
          goToPrevStep={() => goToPrevStep(currentStep - 1)}
        />

        <div className="step-title-active" ref={this.headerRef}>
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">4</span>
          </div>

          <span className="step-title-active-header">Финальные Условия</span>
        </div>

        <Form
          className="calc-table"
          style={{paddingRight: "35px"}}
          id="mortgage-step-4"
        >

          {calcResult &&
            Array.isArray(calcResult) &&
            calcResult.length !== 0 &&
            calcResult
              .filter((value) =>
                checkSelectedBankKey(banks, getFieldValue("mortgage_bank"), VTB_KEY) ? value.key === ALFA_KEY : true)
              .map((value, index) => (
                <CustomCollapse
                  index={index}
                  value={value}
                  setDataForm={setDataForm}
                  userInfo={userInfo}
                  handleSubmit={this.handleSubmit}
                  key={index}
                  insuranceRules={
                    value.key === ALFA_KEY ? rulesAlfa :
                      value.key === RENAISSANCE_KEY ? rulesRenins :
                        mortgageDataForm.is_life_risk.value === true ? rulesLifeVSK :
                          mortgageDataForm.is_property_risk.value === true ? rulesPropertyVSK : ""
                  }
                  getFieldDecorator={getFieldDecorator}
                  getFieldValue={getFieldValue}
                />
              ))
          }
          {calculationInProgress && <Spin size="large"/>}

        </Form>
        {[
          {number: 5, text: "Оплата"},
        ].map(({number, text}) =>
          <div className="step-title-disabled" key={number}>
            <div className="step-icon-block">
              <NumberInactiveIcon className="hyd-large-num-ico"/>
              <span className="hyd-large-num-ico-num">{number}</span>
            </div>
            <span className="step-title-disabled-header">{text}</span>
          </div>
        )}
        <ModalError type={7}/>
      </>
    );
  }
}

const WrappedFourthStepMortgageForm = Form.create({
    name: "mortgage-step-4",
    mapPropsToFields(props) {
      const {mortgageDataForm} = props;
      const transformed = transform({mortgageDataForm});

      return transformed.mortgageDataForm;
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields);
    },
  }
)(FourthStepMortgage);

const mapStateToProps = (state) => ({
  banks: banksSelector(state),
  isRequest: isRequestSelector(state),
  currentStep: currentStepSelector(state),
  calcResult: calcResultSelector(state),
  creatingPolicy: creatingPolicySelector(state),
  userInfo: userInfoSelector(state),
  calculationInProgress: calculationInProgressSelector(state),
  errors: errorsMortgageSelector(state),
  mortgageDataForm: mortgageDataFormSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPrevStep,
      goToNextStep,
      setDataForm,
      createPolicy
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WrappedFourthStepMortgageForm);

import React from "react";
import {Button, notification, Upload} from "antd";
import {ReactComponent as UploadIcon} from "../../assets/Icons/CASCO/Upload.svg";


const handleUpload = actionUpload => file => {
  let fileName = file.name
  let fileUID = file.uid

  const reader = new FileReader()
  let base64
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    base64 = reader.result
    actionUpload({uid: fileUID, name: fileName, file: base64.split(",")[1]})
  }
  reader.onerror = () => {
    notification["error"]({
      message: `Ошибка загрузки файла: ${reader.error.message}.`,
    })
  }

  return false; // return false so file won't auto upload
}

const handleRemoveFile = actionRemove => file => {
  actionRemove(file)
}

const uploadButton = (docName, multiple, fileList, actionUpload, actionRemove) => {
  const props = {
    key: docName,
    multiple: multiple,
    fileList: fileList,
    beforeUpload: handleUpload(actionUpload),
    onRemove: handleRemoveFile(actionRemove),
  }

  return (
    <Upload className={"hyd-upload"} {...props}>
      <Button className={"upload-btn"}>
        <span>{docName}</span>
        <span className={"casco-upload-ico"}><UploadIcon/></span>
      </Button>
    </Upload>
  )
}

export default uploadButton;

import React, {Component} from "react";
import {Form, Modal, Input, Button} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {transform, transliterateSeries,} from "../../../utils";
import {toggleOsagoModal, goToNextStep, createPolicy, setDataForm,} from "../../../actions/osagoActions";
import {
  dataFormSelector, dataFormResultSelector, isRequestSelector, visibleOsagoModalSelector,
  creatingPolicySelector,
  currentStepSelector, errorsSelector, visibleKbmModalSelector, visibleResultKbmModalSelector,
  keyNameInsuranceSelector,
} from "../../../selectors/osagoSelectors";
import FloatingLabelInput from "../FloatingLabeledInputs/FloatingLabelInput";
import "./styles/index.scss";
import "../../../fonts/open-sans.css"

class ModalOsago extends Component {
  componentDidMount() {
    // this.props.form.validateFields();
  }

  componentDidUpdate(prevProps) {
    const {
      isRequest,
      currentStep,
      goToNextStep,
      errors,
      visibleKbmModal,
      visibleResultKbmModal,
      creatingPolicy,
    } = this.props;

    if (
      isRequest !== prevProps.isRequest &&
      creatingPolicy !== prevProps.creatingPolicy &&
      !isRequest &&
      !errors &&
      !visibleKbmModal &&
      !visibleResultKbmModal
    ) {
      goToNextStep(currentStep + 1);
    }

    if (errors !== prevProps.errors && errors) {
      //alert(errors)
    }
  }


  showModal = () => {
    // const { toggleKbmModal, clearModalDriverKbm, } = this.props;
    // this.props.toggleKbmModal(true);
    // clearModalDriverKbm();
    // this.props.form.setFieldsValue({ 'driver_modal_kbm': '' });
  };

  handleOk = () => {
    const {
      getFieldValue,
    } = this.props.form;


    const {toggleOsagoModal, createPolicy, keyNameInsurance,} = this.props;
    toggleOsagoModal(false, keyNameInsurance);
    createPolicy(keyNameInsurance, getFieldValue('series_policy'), getFieldValue('number_policy'));
  };
  handleCancel = () => {
    const {toggleOsagoModal, keyNameInsurance} = this.props;
    this.props.form.setFieldsValue({'series_policy': '', 'number_policy': ''});
    toggleOsagoModal(false, keyNameInsurance);
  }

  render() {
    const {
      visibleOsagoModal,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldError,
      getFieldValue,
    } = this.props.form;

    const seriesPolicy = "series_policy";
    const seriesPolicyError = getFieldError(seriesPolicy);

    const numberPolicy = "number_policy";
    const numberPolicyError = getFieldError(numberPolicy);


    return (
      <>
        <Modal
          visible={visibleOsagoModal}
          cancelButtonProps={{style: {display: 'none'}}}
          okButtonProps={{style: {display: 'none'}}}
          footer={false}
          closable={false}
          width='678px'
          height='242px'
        >

          <Form>

            <div className="hyd-modal-head">Введите данные полиса</div>

            <div className="hyd-row-profile">
              {/* Серия полиса */}
              <Form.Item
                validateStatus={seriesPolicyError ? "error" : ""}
                help={seriesPolicyError || ""}
              >
                <FloatingLabelInput
                  divClassName="hyd-input-modal"
                  htmlFor={"modal_osago_" + seriesPolicy}
                  labelText={"Серия полиса"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(seriesPolicy, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: " "
                      },
                      {
                        pattern: /((^[ААС]{3})|(^[AAC]{3})|(^[ААВ]{3})|(^[AAB]{3})|(^[ААМ]{3})|(^[AАM]{3}))$/g,
                        message: " "
                      },
                    ],
                  })(<Input className="hyd-input-small hyd-input-modal"
                            maxLength={3}
                            onChange={(e) => {
                              e.target.value = transliterateSeries(e.target.value.toUpperCase())
                            }}/>)}
                />
              </Form.Item>

              {/* Номер полиса */}
              <Form.Item
                validateStatus={numberPolicyError ? "error" : ""}
                help={numberPolicyError || ""}
              >
                <FloatingLabelInput
                  divClassName="hyd-input-modal-2"
                  htmlFor={"modal_osago_" + numberPolicy}
                  labelText={"Номер полиса"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(numberPolicy, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: " "
                      },
                      {
                        pattern: /^[0-9]{10}$/g,
                        message: " "
                      },
                    ],
                  })(<Input className="hyd-input-small hyd-input-modal-2" maxLength={10}/>)}
                />
              </Form.Item>
            </div>
          </Form>

          <div className="hyd-row-profile">
            <Button
              className="hyd-btn-small-3 hyd-btn-modal"
              onClick={() => {
                this.handleOk()
              }}
              disabled={
                !getFieldValue(seriesPolicy) || !getFieldValue(numberPolicy) ||
                (getFieldError(seriesPolicy) || getFieldError(numberPolicy))
              }
            >
              Оформить
            </Button>

            <Button
              className="hyd-btn-small-grey"
              onClick={() => {
                this.handleCancel()
              }}
            >
              Отмена
            </Button>
          </div>

        </Modal>
      </>
    )
  }
}


const WrappedModalOsagoForm = Form.create({
  name: "modal_osago",
  mapPropsToFields(props) {
    const {dataForm,} = props;
    const transformed = transform({dataForm});

    return transformed.dataForm;
  },
  onFieldsChange(props, changedFields) {
    props.setDataForm(changedFields);
  }
})(ModalOsago);

const mapStateToProps = state => ({
  dataForm: dataFormSelector(state),
  visibleOsagoModal: visibleOsagoModalSelector(state),
  isRequest: isRequestSelector(state),
  currentStep: currentStepSelector(state),
  creatingPolicy: creatingPolicySelector(state),
  errors: errorsSelector(state),
  dataFormResult: dataFormResultSelector(state),
  visibleKbmModal: visibleKbmModalSelector(state),
  visibleResultKbmModal: visibleResultKbmModalSelector(state),
  keyNameInsurance: keyNameInsuranceSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleOsagoModal,
      createPolicy,
      goToNextStep,
      setDataForm,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(WrappedModalOsagoForm);

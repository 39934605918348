import React from "react";
import {ReactComponent as NumberInactiveIcon} from "../../../assets/Icons/NumberInactive.svg";
import {Button, Row} from "antd";
import {connect} from "react-redux";
import {mortgageDataFormSelector, isRequestSelector, banksSelector} from "../../../selectors/mortgageSelectors";


class FirstStepDisabled extends React.Component {
  render() {
    const {
      banks,
      dataForm,
      goToPrevStep,
      isDisabled
    } = this.props

    return (
      <>
        <div className="step-title-disabled">
          <div className="step-icon-block">
            <NumberInactiveIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">1</span>
          </div>
          <span className="step-title-disabled-header">Данные об Ипотеке</span>
          {goToPrevStep && !isDisabled &&
            <Button
              className="hyd-change-button-small"
              onClick={goToPrevStep}
            >
              Изменить
            </Button>}
        </div>

        <Row>
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Банк</div>
            <div className={"hyd-prev-step-value"}>
              {banks && banks.find(e => dataForm.mortgage_bank.value === e.id).name}
            </div>
          </div>

          {dataForm.loan_amount &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Остаток задолженности</div>
              <div className={"hyd-prev-step-value"}>{dataForm.loan_amount.value} ₽</div>
            </div>}

          {dataForm.rate &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Ставка</div>
              <div className={"hyd-prev-step-value"}>{dataForm.rate.value} %</div>
            </div>}

          {dataForm.contract_number &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Номер договора</div>
              <div className={"hyd-prev-step-value"}>{dataForm.contract_number.value}</div>
            </div>}

          {dataForm.mortgage_contract_start_date &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Дата выдачи</div>
              <div className={"hyd-prev-step-value"}>{dataForm.mortgage_contract_start_date.value}</div>
            </div>}

        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => (
  {
    dataForm: mortgageDataFormSelector(state),
    isRequest: isRequestSelector(state),
    banks: banksSelector(state),
  });

export default connect(mapStateToProps)(FirstStepDisabled);
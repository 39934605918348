import React, {Component} from "react";
import {
  Row,
  Form,
  Input,
  Select,
  Tabs
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Cleave from "cleave.js/react";
import petrovich from 'petrovich';

import {
  setDataForm,
} from "../../../actions/osagoActions";
import {
  dataFormSelector,
} from "../../../selectors/osagoSelectors";

import {checkValueBirthDate, frontDateFormat, getDateFromString, nameRules, transform} from "../../../utils";
import FloatingLabelInput from "../FloatingLabeledInputs/FloatingLabelInput";
import moment from "moment";


const {Option} = Select;
const {TabPane} = Tabs;


class InfoOwnerCar extends Component {
  state = {
    cleaveKey: 0,
  }

  checkValueCarOwnerDate = (rule, value, callback) => {
    if (value) {
      let resultCurrentDate = new Date() //
      let resultPastDate = new Date(1920, 0, 1);


      let resultValueDate = value.split('.'); //в Date месяц с нуля
      if (moment(value, frontDateFormat, true).isValid()) {
        let result = getDateFromString(resultValueDate)
        if (result && (resultCurrentDate < result) || (result < resultPastDate)) {
          callback(" ")
        } else {
          callback()
        }
      } else {
        callback(" ")
      }
    } else {
      callback()
    }
  }

  checkValueBirthCertificateDate = (rule, value, callback) => {
    const {dataForm} = this.props
    if (value) {
      let resultCurrentDate = new Date()
      let birthDate = getDateFromString(dataForm["car_owner_birth_date"].value.split('.'))
      let resultValueDate = value.split('.')

      if (moment(value, frontDateFormat, true).isValid()) {
        let result = getDateFromString(resultValueDate)
        if (result && (resultCurrentDate < result) || (result < birthDate)) {
          callback(" ")
        } else {
          callback()
        }
      } else {
        callback(" ")
      }
    } else {
      callback()
    }
  }

  checkValueExperienceDate = (rule, value, callback) => {
    const {dataForm} = this.props;
    if (value) {
      let resultCurrentDate = new Date()
      let resultPastDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100))
      let birthDate
      if (rule.field === "policy_owner_experience_date") {
        birthDate = getDateFromString(dataForm["policy_owner_birth_date"].value.split('.'))
      } else if (rule.field === "car_owner_experience_date") {
        birthDate = getDateFromString(dataForm["car_owner_birth_date"].value.split('.'))
      }
      let resultValueDate = value.split('.')
      if (moment(value, frontDateFormat, true).isValid()) {
        let result = getDateFromString(resultValueDate)
        if (result && (resultCurrentDate < result) || (result < resultPastDate)) {
          callback(" ")
        } else if (moment(result).diff(moment(birthDate), 'years', true) < 14) { // 14 yo valid
          callback(" ")
        } else {
          callback()
        }
      } else {
        callback(" ")
      }
    } else {
      callback()
    }
  }

  onCustomChange = (event) => {
    if (event.target.value !== '') {
      this.setState({isActive: true});
    } else {
      this.setState({isActive: false});
    }
    let resultValueDate = event.target.value.split(" ")
    if (resultValueDate.length === 2) {
      let series = resultValueDate[0]
      let number = resultValueDate[1]
      this.props.form.setFieldsValue({[this.props.number]: number, [this.props.serial]: series});
    } else {
      this.props.form.setFieldsValue({[this.props.number]: event.target.rawValue, [this.props.serial]: ''});
    }
  }


  defineGender = (rule, value, callback) => {

    if (rule.field === 'policy_owner_middle_name') {
      if (petrovich.detect_gender(value) === 'male') {
        this.props.form.setFieldsValue({"policy_owner_gender": 'M'});
        callback();
      } else if (petrovich.detect_gender(value) === 'female') {
        this.props.form.setFieldsValue({"policy_owner_gender": 'F'});
        callback();
      } else {
        this.props.form.setFieldsValue({"policy_owner_gender": 'M'});
        // callback(" ");
        callback();
      }

    } else if (rule.field === 'car_owner_middle_name') {
      if (petrovich.detect_gender(value) === 'male') {
        this.props.form.setFieldsValue({"car_owner_gender": 'M'});
        callback();
      } else if (petrovich.detect_gender(value) === 'female') {
        this.props.form.setFieldsValue({"car_owner_gender": 'F'});
        callback();
      } else {
        this.props.form.setFieldsValue({"car_owner_gender": 'M'});
        callback();
        // callback(" ");
      }

    }

  };


  render() {

    const {
      isOwner = false,
      nameError = "",
      name = "",
      lastNameError = "",
      lastName = "",
      middleNameError = "",
      middleName = "",
      genderError = "",
      gender = "",
      birthDateError = "",
      birthDate = "",
      identityDocumentError = "",
      identityDocument = "",
      serialError = "",
      serial = "",
      numberError = "",
      number = "",
      experienceDateError = "",
      experienceDate = "",
    } = this.props;

    const {
      getFieldDecorator,
      getFieldValue,
      getFieldError,
      setFieldsValue
    } = this.props.form;

    const documentSeriesNumber = `document_series_number_${serial}_${number}`;
    const documentSeriesNumberError = getFieldError(documentSeriesNumber);


    return (
      <>
        <Row>
          <Form.Item
            validateStatus={lastNameError ? "error" : ""}
            help={lastNameError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input hyd-input-autocomplete-small"
              htmlFor={"step-2_" + lastName}
              labelText={"Фамилия"}
              divId={"float-label-small"}
              child={getFieldDecorator(lastName, {
                validateTrigger: ["onBlur", "onChange"],
                rules: nameRules,
              })(<Input className={"ant-input hyd-input-small hyd-osago-8-input"}/>)}
            />
          </Form.Item>
          <Form.Item
            validateStatus={nameError ? "error" : ""}
            help={nameError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input hyd-input-autocomplete-small"
              htmlFor={"step-2_" + name}
              labelText={"Имя"}
              divId={"float-label-small"}
              child={getFieldDecorator(name, {
                validateTrigger: ["onBlur", "onChange"],
                rules: nameRules,
              })(<Input className={"ant-input hyd-input-small hyd-osago-8-input"}/>)}
            />
          </Form.Item>
          <Form.Item
            validateStatus={middleNameError ? "error" : ""}
            help={middleNameError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input hyd-osago-autocomplete-small hyd-osago-no-margin"
              htmlFor={"step-2_" + middleName}
              labelText={"Отчество"}
              divId={"float-label-small"}
              child={getFieldDecorator(middleName, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: false,
                    message: " "
                  },
                  {
                    // только лат или только кириллица (допускается двойное имя с пробелом по центру или тире)
                    pattern: /^[А-Яа-яЁёa-zA-Z() -]+$/g,
                    message: " ",
                  },
                  {
                    validator: this.defineGender,
                  },
                ],
              })(<Input className={"ant-input hyd-input-small hyd-osago-8-input"}/>)}
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            validateStatus={genderError ? "error" : ""}
            help={genderError || ""}
          >
            <div className="hyd-select-small hyd-osago-8-input hyd-osago-select">
              {getFieldDecorator(gender, {
                initialValue: "M",
                validateTrigger: ["onBlur", "onChange"],
                rules: [{required: true, message: " "}]
              })(
                <Select
                  placeholder={"Пол"}
                  className="hyd-input-select-small"
                  style={{width: "100%"}}
                  name={gender}>

                  <Option value="M">
                    <div className={"hyd-select-tooltip"}>Пол</div>
                    <div className={"hyd-select-value"}>Мужской</div>
                  </Option>
                  <Option value="F">
                    <div className={"hyd-select-tooltip"}>Пол</div>
                    <div className={"hyd-select-value"}>Женский</div>
                  </Option>
                </Select>
              )}
            </div>
          </Form.Item>

          <Form.Item
            validateStatus={birthDateError ? "error" : ""}
            help={birthDateError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"step-2_" + birthDate}
              labelText={"Дата рождения"}
              divId={"float-label-small"}
              child={getFieldDecorator(birthDate, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: !getFieldValue(birthDate),
                    message: " "
                  },
                  {
                    validator: isOwner ? this.checkValueCarOwnerDate : checkValueBirthDate,
                  },
                ]
              })(
                <Cleave
                  className="ant-input hyd-input-small hyd-osago-8-input"
                  placeholder=""
                  key={JSON.stringify({
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  })}
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  }}

                />
              )}
            />
          </Form.Item>
        </Row>
        <div className={"hyd-bold-text hyd-subtitle"}>Документ удостоверяющий личность</div>
        <Tabs
          className="hyd-tab-osago"
          defaultActiveKey={getFieldValue(identityDocument).toString()}
          onChange={(e) => {
            e = parseInt(e);
            setFieldsValue({
              [identityDocument]: e,
              [serial]: "",
              [number]: "",
              [documentSeriesNumber]: "",
              [experienceDate]: ""
            });
            this.setState({cleaveKey: ++this.state.cleaveKey});
          }}>
          <TabPane tab={<span className="hyd-tab-pane">Паспорт гражданина РФ</span>} key="1">

          </TabPane>
          <TabPane tab={<span className="hyd-tab-pane">Паспорт иностранный</span>} key="3">

          </TabPane>
          {isOwner && <TabPane tab={<span className="hyd-tab-pane">Свидетельство о рождении</span>} key="9">

          </TabPane>}
        </Tabs>
        <Row>
          <Form.Item
            style={([1].includes(this.props.dataForm[identityDocument].value)) ? {display: 'none'} : {}}
            validateStatus={serialError ? "error" : ""}
            help={serialError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"step-2_" + serial}
              labelText={"Серия"}
              divId={"float-label-small"}
              child={getFieldDecorator(serial, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " ",
                  }
                ]
              })(<Input className="hyd-input-small hyd-osago-8-input"/>)}
            />
          </Form.Item>

          {/* Номер */}
          <Form.Item
            style={([1].includes(this.props.dataForm[identityDocument].value)) ? {display: 'none'} : {}}
            validateStatus={numberError ? "error" : ""}
            help={numberError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"step-2_" + number}
              labelText={"Номер"}
              divId={"float-label-small"}
              child={getFieldDecorator(number, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " ",
                  }
                ]
              })(<Input className="hyd-input-small hyd-osago-8-input" maxLength={10}/>)}
            />
          </Form.Item>

          {[1].includes(getFieldValue(identityDocument)) &&
            <Form.Item
              validateStatus={documentSeriesNumberError ? "error" : ""}
              help={documentSeriesNumberError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input"
                htmlFor={"step-2_" + documentSeriesNumber}
                labelText={"Серия и номер паспорта"}
                divId={"float-label-small"}
                child={getFieldDecorator(documentSeriesNumber, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " ",
                    },
                    {
                      pattern: /^[0-9]{4} [0-9]{6}$/g,
                      message: " ",
                    },
                  ],
                })(
                  <Cleave
                    key={this.state.cleaveKey}
                    className="ant-input hyd-input-small hyd-osago-8-input"
                    options={{
                      blocks: [4, 6],
                      delimiter: ' ',
                      numericOnly: false
                    }}
                    onChange={this.onCustomChange}
                  />
                )}
              />
            </Form.Item>}

          {/* Дата выдачи */}
          <Form.Item
            validateStatus={experienceDateError ? "error" : ""}
            help={experienceDateError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input hyd-osago-no-margin"
              htmlFor={"step-2_" + experienceDate}
              labelText={"Дата выдачи"}
              divId={"float-label-small"}
              child={getFieldDecorator(experienceDate, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: !getFieldValue(experienceDate),
                    message: " "
                  },
                  {
                    validator: [9].includes(getFieldValue(identityDocument)) ?
                      this.checkValueBirthCertificateDate : this.checkValueExperienceDate,
                  },
                ]
              })(
                <Cleave
                  className="ant-input hyd-input-small hyd-osago-8-input"
                  key={JSON.stringify({
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  })}
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  }}
                />
              )}
            />
          </Form.Item>
        </Row>
        {/* Документ, удостоверяющий личность */}
        <Form.Item
          style={{display: 'none'}}
          label="Документ, удостоверяющий личность:"
          validateStatus={identityDocumentError ? "error" : ""}
          help={identityDocumentError || ""}
        >
          {getFieldDecorator(identityDocument, {
            initialValue: 1,
            validateTrigger: ["onBlur", "onChange"],
            rules: [{required: true, message: "Обязательное поле!"}]

          })(
            <Select
              style={{width: "100%"}}
              name={identityDocument}
              onChange={() => {
                this.setState({cleaveKey: ++this.state.cleaveKey});
              }}
            >
              <Option value={1}>Паспорт гражданина РФ</Option>
              {/* <Option value={2}>Заграничный паспорт</Option> */}
              <Option value={3}>Паспорт иностранный</Option>
              {/* <Option value={4}>Паспорт дипломатический</Option>
                                    <Option value={5}>Паспорт моряка</Option>
                                    <Option value={6}>Вид на жительство</Option>
                                    <Option value={7}>Военный билет солдата (матроса, сержанта, старшины) </Option>
                                    <Option value={8}>Военный билет офицера запаса</Option> */}
              {isOwner && <Option value={9}>Свидетельство о рождении</Option>}
              {/* <Option value={10}>Удостоверение беженца</Option>
                                    <Option value={11}>Справка об освобождении</Option>
                                    <Option value={12}>Временное удостоверение личности </Option>
                                    <Option value={13}>Иные документы, выдаваемые органами мвд </Option> */}
            </Select>
          )}

        </Form.Item>

      </>
    );
  }
}


const WrappedInfoOwnerCarForm = Form.create({
  name: "step-2",
  mapPropsToFields(props) {
    const {dataForm} = props;
    const transformed = transform({dataForm});
    return transformed.dataForm;
  },
  onFieldsChange(props, changedFields) {

    props.setDataForm(changedFields, 'carOwner');
  }
})(InfoOwnerCar);


const mapStateToProps = state => ({
  dataForm: dataFormSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setDataForm,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WrappedInfoOwnerCarForm);

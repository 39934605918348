import React from "react";
import {ReactComponent as NumberInactiveIcon} from "../../../assets/Icons/NumberInactive.svg";
import {Button} from "antd";
import DriverDisabled from "../DriverDisabled";

export default class ThirdStepDisabled extends React.Component {
  render() {
    const {
      dataForm,
      goToPrevStep,
      isDisabled
    } = this.props

    const formItems = () => dataForm.keys.value.map((k, index) => {
      return (
        <DriverDisabled key={k}
          driverNumber={index + 1}
          lastName={dataForm[`additional_drivers_lastname_${k}`]
            ? dataForm[`additional_drivers_lastname_${k}`].value : ""}
          firstName={dataForm[`additional_drivers_name_${k}`] ? dataForm[`additional_drivers_name_${k}`].value : ""}
          middle_name={dataForm[`additional_drivers_middlename_${k}`]
            ? dataForm[`additional_drivers_middlename_${k}`].value : ""}
          birth_date={dataForm[`additional_drivers_birth_${k}`] ? dataForm[`additional_drivers_birth_${k}`].value : ""}
          series={dataForm[`additional_drivers_document_serial_${k}`]
            ? dataForm[`additional_drivers_document_serial_${k}`].value : ""}
          is_old={dataForm[`is_additional_drivers_old_${k}`] ? dataForm[`is_additional_drivers_old_${k}`].value : false}
          old_series={dataForm[`is_additional_drivers_old_${k}`] && dataForm[`is_additional_drivers_old_${k}`].value
            ? dataForm[`additional_drivers_old_document_serial_${k}`].value : ""}
          old_number={dataForm[`is_additional_drivers_old_${k}`] && dataForm[`is_additional_drivers_old_${k}`].value
            ? dataForm[`additional_drivers_old_document_number_${k}`].value : ""}
          old_document_date={
            dataForm[`is_additional_drivers_old_${k}`] && dataForm[`is_additional_drivers_old_${k}`].value
              ? dataForm[`additional_drivers_old_document_date_${k}`].value : ""}
          number={dataForm[`additional_drivers_document_number_${k}`]
            ? dataForm[`additional_drivers_document_number_${k}`].value : ""}
          document_type={dataForm[`additional_drivers_document_type_${k}`]
            ? dataForm[`additional_drivers_document_type_${k}`].value : ""}
          gender={dataForm[`additional_drivers_gender_${k}`] ? dataForm[`additional_drivers_gender_${k}`].value : ""}
          document_date={dataForm[`additional_drivers_document_date_${k}`]
            ? dataForm[`additional_drivers_document_date_${k}`].value : ""}
          experience_date={dataForm[`additional_drivers_experience_date_${k}`]
            ? dataForm[`additional_drivers_experience_date_${k}`].value : ""}
          family_state={dataForm[`additional_drivers_family_state_${k}`]
            ? dataForm[`additional_drivers_family_state_${k}`].value : ""}
        />
      )
    })
    const getUnlimitedDrivers = () =>
      <div className={"hyd-prev-step-value"}>
        Без ограничений
      </div>
    return (
      <>
        <div className="step-title-disabled">
          <div className="step-icon-block">
            <NumberInactiveIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">3</span>
          </div>
          <span className="step-title-disabled-header">Список водителей</span>
          {goToPrevStep && !isDisabled &&
          <Button
            className="hyd-change-button-small"
            onClick={goToPrevStep}
          >
            Изменить
          </Button>}
        </div>
        {(dataForm.number_drivers.value === 2) && getUnlimitedDrivers()}
        {dataForm.number_drivers.value === 1 && dataForm.keys && formItems()}
      </>
    )

  }
}
import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
  toggleToolsModal,
  addToolToTempList,
  clearToolsTempList,
  updateFavoritesTools,
  getTools,
} from "../../../actions/mainActions";
import {
  visibleToolsModalSelector,
  toolsListSelector,
  tempToolsListSelector,
  favoritesToolsSelector,
} from "../../../selectors/mainSelectors";

import {Tabs, Button, Form, Modal,} from "antd";
import {ReactComponent as YESIcon} from "../../../assets/Icons/YES.svg";

import "./styles/index.scss";

const {TabPane} = Tabs;

class ModalTools extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      visibleToolsModal,
    } = this.props;

    if (!prevProps.visibleToolsModal && visibleToolsModal) {
      this.props.form.resetFields();
    }
  }

  showModal = () => {
    this.props.toggleToolsModal(true)
  };

  handleOk = () => {
    this.props.toggleToolsModal(false)
    this.props.updateFavoritesTools()
    this.props.getTools()
    this.props.clearToolsTempList()
  };

  handleCancel = () => {
    this.props.toggleToolsModal(false)
    this.props.clearToolsTempList()
  };

  selectTool = (tool) => {
    this.props.addToolToTempList(tool)
  }

  render() {
    const {
      visibleToolsModal,
      toolsList,
      tempToolsList,
      favoritesTools,
    } = this.props;

    const toolsTabs = [
      {tabKey: "1", toolCategory: "Страхование"},
      {tabKey: "2", toolCategory: "Сервисы"},
      {tabKey: "3", toolCategory: "Профиль"},
    ]

    const generateToolsTabs = () => {
      return (
        <>
          <Tabs className="hyd-tab hyd-tab-tools" defaultActiveKey="1">
            {Object.keys(toolsTabs).map((it, i) => (
              <TabPane
                tab={<span className="hyd-tab-pane hyd-tab-pane-tools">{toolsTabs[i].toolCategory}</span>}
                key={toolsTabs[i].tabKey}
              >
                {toolsList &&
                Array.isArray(toolsList) &&
                toolsList
                  .filter(item => item.category === toolsTabs[i].toolCategory)
                  .map(item => {
                    return (
                      <div
                        className={
                          "hyd-tab-tool " +
                          (!item.available ? "hyd-tab-tool-disabled"
                            : (favoritesTools.includes(item.id) || tempToolsList.includes(item.id)
                              ? "hyd-tab-tool-selected" : ""))
                        }
                        onClick={
                          item.available && !favoritesTools.includes(item.id)
                            ? () => this.selectTool(item.id) : null
                        }
                      >
                        {item.name}
                        {
                          favoritesTools.includes(item.id) &&
                          <YESIcon className="hyd-tool-yes-icon"/>
                        }
                      </div>
                    )
                  })}
              </TabPane>
            ))}
          </Tabs>
        </>
      )
    }


    return (
      <>
        <Modal
          centered
          visible={visibleToolsModal}
          width={545}
          closable={false}
          footer={null}
          onCancel={this.handleCancel}
        >
          <div className="hyd-bold-text hyd-tools-header">Добавление инструмента</div>

          {generateToolsTabs()}

          <div className="hyd-tools-footer">
            <Button
              className="hyd-btn-small-2 hyd-tools-ok-btn"
              type="primary"
              key="submit"
              // loading={this.state.loadingEffect}
              onClick={this.handleOk}
            >
              Добавить
            </Button>

            <Button
              className="hyd-text-btn hyd-tools-cancel-btn"
              key="back"
              onClick={this.handleCancel}
            >
              Отмена
            </Button>
          </div>

        </Modal>
      </>
    );
  }

}


const
  mapStateToProps = state => ({
    visibleToolsModal: visibleToolsModalSelector(state),
    toolsList: toolsListSelector(state),
    tempToolsList: tempToolsListSelector(state),
    favoritesTools: favoritesToolsSelector(state),
  });

const
  mapDispatchToProps = dispatch =>
    bindActionCreators({
        toggleToolsModal,
        addToolToTempList,
        clearToolsTempList,
        updateFavoritesTools,
        getTools,
      },
      dispatch
    );

const WrappedModalToolsForm = Form.create({name: "tools"})(ModalTools);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedModalToolsForm);

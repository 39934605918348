import React from "react";
import {ReactComponent as NumberInactiveIcon} from "../../../assets/Icons/NumberInactive.svg";
import {Button, Checkbox, Row} from "antd";
import {connect} from "react-redux";
import {mortgageIdentityDocs, mortgageMaritalStatuses} from "../../../utils";
import {
  isRequestSelector,
  professionsSelector,
  mortgageDataFormSelector,
  employmentStatusesSelector,
  healthAbnormalitiesSelector,
} from "../../../selectors/mortgageSelectors";


class SecondStepDisabled extends React.Component {
  render() {
    const {
      dataForm,
      goToPrevStep,
      professions,
      isDisabled,
      employmentStatusesList,
      healthAbnormalitiesList,
    } = this.props

    return (
      <>
        <div className="step-title-disabled">
          <div className="step-icon-block">
            <NumberInactiveIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">2</span>
          </div>
          <span className="step-title-disabled-header">Данные о Заёмщике</span>
          {goToPrevStep && !isDisabled &&
            <Button
              className="hyd-change-button-small"
              onClick={goToPrevStep}
            >
              Изменить
            </Button>}
        </div>

        <Row>
          {dataForm.last_name &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Фамилия</div>
              <div className={"hyd-prev-step-value"}>{dataForm.last_name.value}</div>
            </div>}

          {dataForm.first_name &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Имя</div>
              <div className={"hyd-prev-step-value"}>{dataForm.first_name.value}</div>
            </div>}

          {dataForm.middle_name &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Отчество</div>
              <div className={"hyd-prev-step-value"}>{dataForm.middle_name.value}</div>
            </div>}

          {dataForm.birth_date &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Дата рождения</div>
              <div className={"hyd-prev-step-value"}>{dataForm.birth_date.value}</div>
            </div>}

          {dataForm.gender &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Пол</div>
              <div className={"hyd-prev-step-value"}>{dataForm.gender.value}</div>
            </div>}

          {dataForm.phone &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Телефон</div>
              <div className={"hyd-prev-step-value"}>{dataForm.phone.value}</div>
            </div>}

          {dataForm.email &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>email</div>
              <div className={"hyd-prev-step-value"}>{dataForm.email.value}</div>
            </div>}
        </Row>

        <Row>
          {dataForm.identity_doc &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Документы</div>
              <div className={"hyd-prev-step-value"}>
                {mortgageIdentityDocs.find(e => dataForm.identity_doc.value === e.value).text}
              </div>
            </div>}

          {dataForm.document_series &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Серия</div>
              <div className={"hyd-prev-step-value"}>{dataForm.document_series.value}</div>
            </div>}

          {dataForm.document_number &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Номер</div>
              <div className={"hyd-prev-step-value"}>{dataForm.document_number.value}</div>
            </div>}

          {dataForm.document_issue_date &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Дата выдачи</div>
              <div className={"hyd-prev-step-value"}>{dataForm.document_issue_date.value}</div>
            </div>}

          {dataForm.division_code &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Код подразделения</div>
              <div className={"hyd-prev-step-value"}>{dataForm.division_code.value}</div>
            </div>}
        </Row>

        <Row>
          {dataForm.document_issuer &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Кем выдан</div>
              <div className={"hyd-prev-step-value"}>{dataForm.document_issuer.value}</div>
            </div>}

          {dataForm.birth_place &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Место рождения</div>
              <div className={"hyd-prev-step-value"}>{dataForm.birth_place.value}</div>
            </div>}

          {dataForm.registration_address &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Адрес регистрации</div>
              <div className={"hyd-prev-step-value"}>{dataForm.registration_address.value}</div>
            </div>}
        </Row>

        <Row>
          {dataForm.marital_status &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Семейное положение</div>
              <div className={"hyd-prev-step-value"}>
                {mortgageMaritalStatuses.find(e => dataForm.marital_status.value === e.value).text}
              </div>
            </div>}

          {dataForm.employment_status &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Место работы</div>
              <div
                className={"hyd-prev-step-value"}>{employmentStatusesList.find(
                  item => item.id === dataForm.employment_status.value).name}
              </div>
            </div>}

          {dataForm.health_abnormalities &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Отклонения по здоровью</div>
              <div
                className={"hyd-prev-step-value"}>{healthAbnormalitiesList.find(
                  item => item.id === dataForm.health_abnormalities.value).name}
              </div>
            </div>}

          {professions && dataForm.profession &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Профессия</div>
              <div
                className={"hyd-prev-step-value"}>{professions.find(
                  item => item.id === dataForm.profession.value).name}
              </div>
            </div>}
        </Row>

        <Row>
          {dataForm.not_public_official &&
            <Checkbox
              style={{marginLeft: 16}}
              checked={dataForm.not_public_official.value}
              className={"hyd-norm-text"}
              disabled
            >
              Не является публичным должностным лицом, его супругом, близким родственником
            </Checkbox>}
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => (
  {
    isRequest: isRequestSelector(state),
    professions: professionsSelector(state),
    dataForm: mortgageDataFormSelector(state),
    employmentStatusesList: employmentStatusesSelector(state),
    healthAbnormalitiesList: healthAbnormalitiesSelector(state),
  });

export default connect(mapStateToProps)(SecondStepDisabled);
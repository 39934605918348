import React, {Component} from "react";
import {Form, notification, Spin,} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
  goToNextStep,
  clearIsRequestState,
  setDataForm,
  goToPrevStep,
  createPolicy,
} from "../../actions/crossActions";
import {
  isRequestSelector,
  currentStepSelector,
  crossDataFormSelector,
  errorsCrossSelector,
  calcResultSelector,
  calculationInProgressSelector,
  creatingPolicySelector,
} from "../../selectors/crossSelectors";
import {userInfoSelector} from "../../selectors/loginSelectors";
import ModalError from "../../components/ModalError";
import {transform,} from "../../utils";
import "./styles/index.scss";
import "../../fonts/open-sans.css"
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import {ReactComponent as NumberInactiveIcon} from "../../assets/Icons/NumberInactive.svg";
import FirstStepDisabled from "../../components/CrossDisabledSteps/FirstStepDisabled";
import Preloader from "../../components/Preloader";
import CustomCollapse from "../../components/Form/CustomCollapse";


class SecondStepCross extends Component {

  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  componentDidMount() {
    this.props.clearIsRequestState()
  }

  componentDidUpdate(prevProps, prevState, snapshot?) {
    const {
      isRequest,
      currentStep,
      goToNextStep,
      errors,
      creatingPolicy,
    } = this.props;

    if (
      isRequest !== prevProps.isRequest &&
      creatingPolicy !== prevProps.creatingPolicy &&
      !isRequest &&
      !errors
    ) {
      goToNextStep(currentStep + 1);
    }

    if (errors !== prevProps.errors && errors) {
      //alert(errors)
    }
  }

  handleSubmit = (e) => {
    const {createPolicy} = this.props;

    this.props.form.validateFields((err,) => {
      if (!err) {
        createPolicy(e)
      } else {
        notification["error"]({
          message: err
        })
      }
    })
  }

  render() {
    const {
      isRequest,
      currentStep,
      goToPrevStep,
      calcResult,
      userInfo,
      calculationInProgress,
    } = this.props;

    return (
      <>
        <FirstStepDisabled
          goToPrevStep={() => goToPrevStep(currentStep - 1)}
        />

        <Preloader loading={isRequest}/>

        <div className="step-title-active" ref={this.headerRef}>
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">2</span>
          </div>
          <span className="step-title-active-header">Расчёт</span>
        </div>

        <Form
          className="calc-table"
          style={{paddingRight: "35px"}}
          id="mortgage-step-4"
        >

          {calcResult &&
            Array.isArray(calcResult) &&
            calcResult.length !== 0 &&
            calcResult.map((value, index) => (
              <CustomCollapse
                index={index}
                value={value}
                setDataForm={setDataForm}
                userInfo={userInfo}
                handleSubmit={this.handleSubmit}
                key={index}
              />
            ))
          }
          {calculationInProgress && <Spin size="large"/>}
        </Form>

        {[
          {number: 3, text: "Оплата"},
        ].map(({number, text}) =>
          <div className="step-title-disabled-small" key={number}>
            <div className="step-icon-block">
              <NumberInactiveIcon className="hyd-large-num-ico"/>
              <span className="hyd-large-num-ico-num">{number}</span>
            </div>
            <span className="step-title-disabled-header">{text}</span>
          </div>
        )}

        <ModalError type={8}/>
      </>
    )
  }

}

const WrappedSecondStepCrossForm = Form.create({
    name: "cross-step-2",
    mapPropsToFields(props) {
      const {crossDataForm} = props;
      const transformed = transform({crossDataForm});

      return transformed.crossDataForm;
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields);
    },
  }
)(SecondStepCross);

const mapStateToProps = (state) => ({
  isRequest: isRequestSelector(state),
  currentStep: currentStepSelector(state),
  errors: errorsCrossSelector(state),
  crossDataForm: crossDataFormSelector(state),
  calcResult: calcResultSelector(state),
  userInfo: userInfoSelector(state),
  calculationInProgress: calculationInProgressSelector(state),
  creatingPolicy: creatingPolicySelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToNextStep,
      clearIsRequestState,
      setDataForm,
      goToPrevStep,
      createPolicy,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WrappedSecondStepCrossForm);

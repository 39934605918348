import React, {Component} from "react";
import {Affix, Button, Form, Spin} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
  goToPrevStep,
  goToNextStep,
  createPolicy,
  setDataForm,
  toggleOsagoModal,
  toggleKPModal,
} from "../../actions/osagoActions";
import {
  currentStepSelector,
  calcResultSelector,
  isRequestSelector,
  creatingPolicySelector,
  errorsSelector,
  dataFormSelector,
  calculationInProgressSelector,
  vehicleDurationSelector,
} from "../../selectors/osagoSelectors";
import {userInfoSelector} from "../../selectors/loginSelectors";

import ModalError from "../../components/ModalError";
import ModalOsago from "../../components/Form/ModalOsago";

import {transform} from "../../utils";
import FirstStepDisabled from "../../components/OsagoDisabledSteps/FirstStepDisabled";
import SecondStepDisabled from "../../components/OsagoDisabledSteps/SecondStedDisabled";
import ThirdStepDisabled from "../../components/OsagoDisabledSteps/ThirdStepDisabled";
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import {ReactComponent as NumberInactiveIcon} from "../../assets/Icons/NumberInactive.svg";
import FourthStepDisabled from "../../components/OsagoDisabledSteps/FourthStepDisabled";
import CustomCollapse from "../../components/Form/CustomCollapse";
import ModalKP from "../../components/Form/ModalKP";

const ins_days_variants = {
  absolut: 3,
  makc: 4,
  renesans: 4,
}

const getPlusDays = (startDate, insuranceKey) => {
  let today = new Date();
  let cur_date = startDate.split(".");
  cur_date = new Date(+cur_date[2], cur_date[1] - 1, +cur_date[0]);
  let date_difference = Math.floor((cur_date - today) / (1000 * 60 * 60 * 24))
  if (date_difference < ins_days_variants[insuranceKey]) {
    return ins_days_variants[insuranceKey]
  } else return undefined
}

class FifthStep extends Component {

  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }


  componentDidMount() {
    this.headerRef.current.scrollIntoView()
  }

  componentDidUpdate(prevProps) {
    const {
      isRequest,
      currentStep,
      goToNextStep,
      errors,
      creatingPolicy,
    } = this.props;

    if (
      isRequest !== prevProps.isRequest &&
      creatingPolicy !== prevProps.creatingPolicy &&
      !isRequest &&
      !errors
    ) {
      goToNextStep(currentStep + 1);
    }

    if (errors !== prevProps.errors && errors) {
      //alert(errors)
    }
  }

  handleSubmit = (e) => {
    const {createPolicy} = this.props;

    this.props.form.validateFields((err) => {
      if (!err) {
        createPolicy(e);
      }
    });
  };

  render() {
    const {
      currentStep,
      goToPrevStep,
      calcResult,
      toggleOsagoModal,
      userInfo,
      setDataForm,
      calculationInProgress,
      vehicleDuration,
      dataForm,
      toggleKPModal
    } = this.props;

    return (
      <>
        <FirstStepDisabled
          goToPrevStep={() => goToPrevStep(currentStep - 4)}
          isDisabled={calculationInProgress}
        />
        <SecondStepDisabled
          dataForm={dataForm}
          goToPrevStep={() => goToPrevStep(currentStep - 3)}
          isDisabled={calculationInProgress}
        />
        <ThirdStepDisabled
          dataForm={dataForm}
          goToPrevStep={() => goToPrevStep(currentStep - 2)}
          isDisabled={calculationInProgress}
        />
        <FourthStepDisabled
          dataForm={dataForm}
          goToPrevStep={() => goToPrevStep(currentStep - 1)}
          isDisabled={calculationInProgress}
        />
        <div className="step-title-active" ref={this.headerRef}>
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">5</span>
          </div>
          <span className="step-title-active-header">Оформление полиса ОСАГО</span>
          <Button className="hyd-link-btn hyd-reset-filters"
            style={{
              "display": "inline-flex",
              "marginLeft": "auto"
            }}
            onClick={event => {
              event.preventDefault();
              toggleKPModal(true);
            }}
          >
            Отправить КП
          </Button>
        </div>

        {/* Верстка результатов по страховым:  */}
        {calculationInProgress &&
          <Affix>
            <div className="preloader-simple-wrapper">
              <Spin size="large"/>
            </div>
          </Affix>
        }
        <Form
          className="calc-table"
          style={{paddingRight: "35px"}}
        >
          {
            calcResult &&
            Array.isArray(calcResult) &&
            calcResult.length !== 0 &&
            calcResult.map((value, index) => (
              <CustomCollapse
                plusDays={getPlusDays(dataForm.start_policy_date.value, value.key)}
                index={index}
                value={value}
                setDataForm={setDataForm}
                toggleOsagoModal={toggleOsagoModal}
                userInfo={userInfo}
                handleSubmit={this.handleSubmit}
                key={index}
                vehicleDuration={vehicleDuration}
              />

            ))
          }
        </Form>
        {[
          {number: 6, text: "Предварительный просмотр документов"},
        ].map(({number, text}) =>
          <div className="step-title-disabled" key={number}>
            <div className="step-icon-block">
              <NumberInactiveIcon className="hyd-large-num-ico"/>
              <span className="hyd-large-num-ico-num">{number}</span>
            </div>
            <span className="step-title-disabled-header">{text}</span>
          </div>
        )}
        <ModalError type={1}/>
        <ModalOsago/>
        <ModalKP/>
      </>
    );
  }
}

const WrappedFifthStepForm = Form.create({
  name: "step-5",
  mapPropsToFields(props) {
    const {dataForm} = props;
    const transformed = transform({dataForm});

    return transformed.dataForm;
  },
  onFieldsChange(props, changedFields) {
    props.setDataForm(changedFields);
  },
})(FifthStep);

const mapStateToProps = (state) => ({
  currentStep: currentStepSelector(state),
  calcResult: calcResultSelector(state),
  isRequest: isRequestSelector(state),
  creatingPolicy: creatingPolicySelector(state),
  errors: errorsSelector(state),
  dataForm: dataFormSelector(state),
  userInfo: userInfoSelector(state),
  calculationInProgress: calculationInProgressSelector(state),
  vehicleDuration: vehicleDurationSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPrevStep,
      goToNextStep,
      createPolicy,
      setDataForm,
      toggleOsagoModal,
      toggleKPModal
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedFifthStepForm);

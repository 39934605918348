export const isRequestSelector = state => state.crossReducer.isRequest;
export const currentStepSelector = state => state.crossReducer.currentStep;
export const errorsCrossSelector = state => state.crossReducer.errors;
export const visibleErrorModalCrossSelector = state => state.crossReducer.visibleErrorModalCross;
export const crossDataFormSelector = state => state.crossReducer.crossDataForm;
export const limitSumsSelector = state => state.crossReducer.limitSums;
export const marksSelector = state => state.crossReducer.marks;
export const calcResultSelector = state => state.crossReducer.calcResult;
export const calculationInProgressSelector = state => state.crossReducer.calculationInProgress;
export const creatingPolicySelector = state => state.crossReducer.creatingPolicy;
export const documentsPolicySelector = state => state.crossReducer.documentsPolicy;
export const paymentStatusSelector = state => state.crossReducer.paymentStatus;
export const documentsSelector = state => state.crossReducer.documents;
export const paymentDocsSelector = state => state.crossReducer.paymentDocs;
export const crossKindSelector = state => state.crossReducer.crossKind;

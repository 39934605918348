import React, {Component} from "react";
import {
  Row,
  Form,
  Input,
  Button,
  Radio,
  Tabs
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import petrovich from "petrovich";
import ModalError from "../../components/ModalError";
import AdditionalDrivers from "../../components/Form/Step3Components/AdditionalDrivers";
import moment from "moment";

import {
  goToPrevStep,
  goToNextStep,
  setDataForm,
  toggleNumberDriversStepThree,
  removeDriverFromListDrivers,
  clearListDrivers,
  changeQuantityAdditionalDrivers,
  getDriverKbm,
  getKbmPolicyCarOwnerJuristic,
  getOwnerDriverKbm,
  getCarOwnerDriverKbm,
  clearKBM,
  getKbmDriverNoLimit,
  savePolicyOwnerCountryData,
  getCountries
} from "../../actions/osagoActions";
import {
  currentStepSelector,
  dataFormSelector,
  numberDriversStepThreeSelector,
  driverKBMSelector,
  quantityAdditionalDriversSelector,
  typeFormPolicyOwnerStepTwoSelector,
  errorsSelector,
  countriesSelector,
} from "../../selectors/osagoSelectors";

import {
  hasErrors,
  transform,
  getDigits,
  getDateFromString,
  frontDateFormat,
} from "../../utils";
import FirstStepDisabled from "../../components/OsagoDisabledSteps/FirstStepDisabled";
import SecondStepDisabled from "../../components/OsagoDisabledSteps/SecondStedDisabled";
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import {ReactComponent as NumberInactiveIcon} from "../../assets/Icons/NumberInactive.svg";

const {TabPane} = Tabs;

class ThirdStep extends Component {
  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  state = {
    policyOwnerCleaveKey: 0,
    carOwnerCleaveKey: 0,
    mainDriverCleaveKey: 0,
    policyOwnerOldCleaveKey: 0,
    carOwnerOldCleaveKey: 0,
    mainDriverOldCleaveKey: 0,
    doAddPolicyOwnerDriver: false,
    doAddCarOwnerDriver: false,
  }

  componentDidMount() {
    const {form, getCountries} = this.props

    if (form.getFieldValue("keys").length === 0 && form.getFieldValue("number_drivers") === 1) {
      this.add()
    }

    this.headerRef.current.scrollIntoView()

    getCountries()
    let drivers = form.getFieldValue("keys")
    drivers && drivers.map(k => {
      return form.validateFields([`additional_drivers_document_series_number_${k}`], {force: true})
    })
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if (this.state.doAddPolicyOwnerDriver) {
      this.copyPolicyOwnerDriver()
      this.setState({doAddPolicyOwnerDriver: false})
    }
    if (this.state.doAddCarOwnerDriver) {
      this.copyCarOwnerDriver()
      this.setState({doAddCarOwnerDriver: false})
    }
  }

  remove = (k) => {
    const {
      form,
      removeDriverFromListDrivers,
      changeQuantityAdditionalDrivers,
    } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");

    // We need at least one passenger
    // if (keys.length === 1) {
    //   return;
    // }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter((key) => key !== k),
    });
    removeDriverFromListDrivers(k);
    changeQuantityAdditionalDrivers(keys.length - 1);
  };

  add = () => {
    const {form, changeQuantityAdditionalDrivers} = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    const new_key = keys.length ? keys[keys.length - 1] + 1 : 0
    const nextKeys = keys.concat(new_key);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
    changeQuantityAdditionalDrivers(nextKeys.length);
  };

  handleSubmit = (e) => {
    const {
      form,
      clearKBM,
      currentStep,
      goToNextStep,
      clearListDrivers,
      numberDriversStepThree,
      changeQuantityAdditionalDrivers,
    } = this.props
    const {setFieldsValue, validateFields,} = form

    e.preventDefault()

    validateFields((err, force = true) => {
      if (!err) {
        if (numberDriversStepThree === 2) {
          clearKBM()
          clearListDrivers() //при переключении на кнопку "Без ограничено" очищаем dataForm от дополнит. водителей
          changeQuantityAdditionalDrivers(0)  // обнуляем список доп.водителей
          setFieldsValue({
            keys: [], //удаление массива инпутов доп. водителей при переключении на кнопку "Ограничено"
          })
        }
        goToNextStep(currentStep + 1)
      }
    })
  }

  checkValueDate = (rule, value, callback) => {
    if (value) {
      const {validateFields} = this.props.form
      let resultCurrentDate = new Date()
      let resultPastDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100))

      if (moment(value, frontDateFormat, true).isValid()) {
        let result = getDateFromString(value.split("."))
        if ((result && resultCurrentDate && resultCurrentDate < result) || result < resultPastDate) {
          callback(" ")
        } else if (result && resultCurrentDate && moment(resultCurrentDate).diff(moment(result), 'years', true) < 18) { // 18 yo valid
          callback(" ")
        } else {
          if (rule.field.indexOf("additional_drivers") !== -1) {
            let key = getDigits(rule.field)  // индекс доп. водителя
            if (rule.field === `additional_drivers_birth_${key}`) {
              validateFields([`additional_drivers_experience_date_${key}`], {force: true})
            }
          }
          callback()
        }
      } else {
        callback(" ")
      }
    }
  }

  checkValueExperienceDate = (rule, value, callback) => {
    if (value) {
      const {validateFields, getFieldValue} = this.props.form
      // let driverLicenseValidity = 10  // in years
      if (rule.field.indexOf("additional_drivers") !== -1) {
        let key = getDigits(rule.field)  // индекс доп. водителя
        if (rule.field === `additional_drivers_document_date_${key}` &&
          getFieldValue(`additional_drivers_document_type_${key}`) === 2) {
          // driverLicenseValidity = Infinity
        }
      }
      let resultCurrentDate = new Date()
      // let resultPastDate = new Date(new Date().setFullYear(new Date().getFullYear() - driverLicenseValidity))
      if (moment(value, frontDateFormat, true).isValid()) {
        let result = getDateFromString(value.split("."))
        // if ((result && resultCurrentDate < result) || result < resultPastDate) {
        if ((result && resultCurrentDate < result)) {
          callback(" ")
        } else {
          if (rule.field.indexOf("additional_drivers") !== -1) {
            let key = getDigits(rule.field)  // индекс доп. водителя
            if (rule.field === `additional_drivers_document_date_${key}`) {
              validateFields([
                `additional_drivers_experience_date_${key}`,
                `additional_drivers_old_document_date_${key}`,
              ], {force: true})
            }
          }
          callback()
        }
      } else {
        callback(" ")
      }
    }
  }

  checkValueOldExperienceDate = (rule, value, callback) => {
    if (value) {
      const {validateFields, getFieldValue} = this.props.form
      let resultCurrentDate = new Date()
      let resultPastDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100))

      let driverLicenseIssueDate
      if (rule.field.indexOf("additional_drivers") !== -1) {
        let key = getDigits(rule.field)  // индекс доп. водителя
        if (rule.field === `additional_drivers_old_document_date_${key}`) {
          driverLicenseIssueDate = getFieldValue(`additional_drivers_document_date_${key}`)
          validateFields([`additional_drivers_experience_date_${key}`], {force: true})
        }
      }

      if (moment(value, frontDateFormat, true).isValid()) {
        let result = getDateFromString(value.split("."))
        if (
          (result && resultCurrentDate < result) ||
          result < resultPastDate ||
          result > getDateFromString(driverLicenseIssueDate.split("."))
        ) {
          callback(" ")
        }
        callback()
      } else {
        callback(" ")
      }
    }
  }

  checkValueStartExperienceDate = (rule, value, callback) => {
    if (value) {
      const {getFieldValue} = this.props.form
      let birthDate
      let driverLicenseIssueDate
      let driverOldLicenseIssueDate

      if (rule.field.indexOf("additional_drivers") !== -1) {
        let key = getDigits(rule.field)  // индекс доп. водителя
        birthDate = getFieldValue(`additional_drivers_birth_${key}`)
        driverLicenseIssueDate = getFieldValue(`additional_drivers_document_date_${key}`)
        driverOldLicenseIssueDate = getFieldValue(`additional_drivers_old_document_date_${key}`)
      }

      if (birthDate && driverLicenseIssueDate) {
        birthDate = getDateFromString(birthDate.split("."))
        driverLicenseIssueDate = getDateFromString(driverLicenseIssueDate.split("."))
      }
      if (driverOldLicenseIssueDate) {
        driverOldLicenseIssueDate = getDateFromString(driverOldLicenseIssueDate.split("."))
      }

      if (moment(value, frontDateFormat, true).isValid()) {
        let result = getDateFromString(value.split("."))
        if (result) {
          if (birthDate && moment(result).diff(moment(birthDate), 'years', true) < 18) { // 18 yo valid
            callback(" ")
          } else if (driverLicenseIssueDate && result > driverLicenseIssueDate) {
            callback(" ")
          } else if (driverOldLicenseIssueDate && result > driverOldLicenseIssueDate) {
            callback(" ")
          } else {
            callback()
          }
        }
      } else {
        callback(" ")
      }
    }
  }

  defineGender = (rule, value, callback) => {
    if (rule.field.indexOf("additional_drivers") !== -1) {
      let key = getDigits(rule.field); //получаем индекс дополнительного водителя
      // let gender = `additional_drivers_gender_${key}`; //формируем название поля пола

      if (petrovich.detect_gender(value) === "male") {
        this.props.form.setFieldsValue({
          [`additional_drivers_gender_${key}`]: "M",
        });
        callback();
      } else if (petrovich.detect_gender(value) === "female") {
        this.props.form.setFieldsValue({
          [`additional_drivers_gender_${key}`]: "F",
        });
        callback();
      } else {
        this.props.form.setFieldsValue({[`additional_drivers_gender_${key}`]: "M"});
        // callback(" ");
        callback();
      }
    }
  };

  isDriverEmpty = (k) => {
    const {getFieldValue} = this.props.form
    if (getFieldValue(`additional_drivers_lastname_${k}`)) {
      return false
    }
    if (getFieldValue(`additional_drivers_name_${k}`)) {
      return false
    }
    if (getFieldValue(`additional_drivers_middlename_${k}`)) {
      return false
    }
    if (getFieldValue(`additional_drivers_birth_${k}`)) {
      return false
    }
    return true
  }

  get_empty_driver = () => {
    const {getFieldValue} = this.props.form
    const keys = getFieldValue("keys")
    for (let k of keys) {
      if (this.isDriverEmpty(k)) {
        return k
      }
    }
    return null
  }

  copyOwnerDrivers = (owner_type) => {
    const {getFieldValue, setFieldsValue} = this.props.form
    let k = this.get_empty_driver()
    if (k === null) {
      this.add();
      return false
    } else {
      setFieldsValue({
        [`additional_drivers_lastname_${k}`]: getFieldValue(`${owner_type}_owner_last_name`),
        [`additional_drivers_name_${k}`]: getFieldValue(`${owner_type}_owner_name`),
        [`additional_drivers_middlename_${k}`]: getFieldValue(`${owner_type}_owner_middle_name`),
        [`additional_drivers_birth_${k}`]: getFieldValue(`${owner_type}_owner_birth_date`),
      });
      return true
    }
  }

  copyPolicyOwnerDriver = () => {
    let result = this.copyOwnerDrivers("policy")
    if (!result) {
      this.setState({doAddPolicyOwnerDriver: true})
    }
  }

  copyCarOwnerDriver = () => {
    const {getFieldValue} = this.props.form
    if (getFieldValue("is_owner")) {
      let result = this.copyOwnerDrivers("policy")
      if (!result) {
        this.setState({doAddCarOwnerDriver: true})
      }
    } else {
      let result = this.copyOwnerDrivers("car")
      if (!result) {
        this.setState({doAddCarOwnerDriver: true})
      }
    }
  }

  render() {
    const {
      currentStep,
      goToPrevStep,
      toggleNumberDriversStepThree,
      numberDriversStepThree,
      quantityAdditionalDrivers,
      dataForm,
      getKbmPolicyCarOwnerJuristic,
      getOwnerDriverKbm,
      form,
    } = this.props

    const {
      getFieldDecorator,
      getFieldValue,
      getFieldsError,
      getFieldError,
      setFieldsValue,
    } = form

    const numberDrivers = "number_drivers";
    const numberDriversError = getFieldError(numberDrivers);
    const add = this.add

    function toggleRadioButtons(e) {
      if (e === 1 && getFieldValue("keys").length === 0) {
        add()
      }
      toggleNumberDriversStepThree(e)
    }

    getFieldDecorator("keys", {initialValue: []});
    const keys = getFieldValue("keys");

    const formItems = keys.map((k, index) => {
      const newIndex = index + 1
      return (
        <AdditionalDrivers
          isLast={quantityAdditionalDrivers === index + 1}
          isAlone={keys.length === 1}
          form={form}
          k={k}
          key={k + index}
          index={newIndex}
          keys={keys}
          remove={this.remove}
          add={this.add}
          defineGender={this.defineGender}
          checkValueDate={this.checkValueDate}
          checkValueExperienceDate={this.checkValueExperienceDate}
          checkValueOldExperienceDate={this.checkValueOldExperienceDate}
          checkValueStartExperienceDate={this.checkValueStartExperienceDate}
        />
      );
    });

    const getUnlimitedDrivers = () =>
      <>
        {/* страхователь - юридическое лицо - не собственник */}
        {//getFieldValue(numberDrivers) === 2 &&
          dataForm.is_owner &&
          dataForm.is_owner.value === true &&
          dataForm.policy_owner_inshur &&
          dataForm.policy_owner_inshur.value === 2 && (
            <>
              <Row>
                <Form.Item
                >
                  <FloatingLabelInput
                    divClassName="hyd-osago-8-input"
                    htmlFor={"step-3_" + "vin"}
                    labelText={"VIN"}
                    divId={"float-label-small"}
                    child={getFieldDecorator("vin", {initialValue: ""})(
                      <Input
                        className="hyd-input-small hyd-osago-8-input"
                        disabled={true}
                      />
                    )}
                  />
                </Form.Item>
                <Form.Item
                >
                  <FloatingLabelInput
                    divClassName="hyd-osago-8-input"
                    htmlFor={"step-3_" + "policy_owner_name_juristic"}
                    labelText={"Название"}
                    divId={"float-label-small"}
                    child={getFieldDecorator("policy_owner_name_juristic", {
                      initialValue: "",
                    })(<Input className="hyd-input-small hyd-osago-8-input" disabled={true}/>)}
                  />
                </Form.Item>

                <Form.Item
                >
                  <FloatingLabelInput
                    divClassName="hyd-osago-8-input"
                    htmlFor={"step-3_" + "policy_owner_identify_number_juristic"}
                    labelText={"ИНН"}
                    divId={"float-label-small"}
                    child={getFieldDecorator("policy_owner_identify_number_juristic",
                      {initialValue: ""}
                    )(<Input className="hyd-input-small hyd-osago-8-input" disabled={true}/>)}
                  />
                </Form.Item>
              </Row>
              <div id={"hyd-osago-kbm-block"} className={"hyd-emphasise-block-small"}>
                <Form.Item>
                  <Button
                    className="hyd-btn-small hyd-osago-btn"
                    type="primary"
                    disabled={
                      !dataForm.policy_owner_name_juristic.value ||
                      !dataForm.policy_owner_identify_number_juristic.value
                    }
                    onClick={() => {
                      getKbmPolicyCarOwnerJuristic({
                        // vin: dataForm.vin.value,
                        name: dataForm.policy_owner_name_juristic.value,
                        inn: dataForm.policy_owner_identify_number_juristic.value,
                      });
                    }}
                    style={{width: "100%", marginRight: "auto"}}
                  >
                    Получить КБМ
                  </Button>
                </Form.Item>
                <Form.Item>
                  <div className="hyd-osago-kbm-value">
                    {getFieldDecorator("kbm_coeff", {initialValue: ""})(
                      <Input
                        className="hyd-input-small-emphasise"
                        placeholder="КБМ"
                        disabled={true}
                      />
                    )}
                  </div>
                </Form.Item>
              </div>
            </>
          )}
        {
          /* если страховщик Не явл собственников и собственник физ лицо  */
          ((dataForm.is_owner &&
            !dataForm.is_owner.value) || !dataForm.is_owner) &&
          dataForm.inshur &&
          dataForm.inshur.value === 1 && (
            <>
              <Row>
                <Form.Item
                >
                  <FloatingLabelInput
                    divClassName="hyd-osago-8-input"
                    htmlFor={"step-3_" + "vin"}
                    labelText={"VIN"}
                    divId={"float-label-small"}
                    child={getFieldDecorator("vin", {initialValue: ""})(
                      <Input className="hyd-input-small hyd-osago-8-input" disabled={true}/>
                    )}
                  />
                </Form.Item>
                <Form.Item
                >
                  <FloatingLabelInput
                    divClassName="hyd-osago-8-input"
                    htmlFor={"step-3_" + "car_owner_serial"}
                    labelText={"Серия"}
                    divId={"float-label-small"}
                    child={getFieldDecorator("car_owner_serial", {
                      initialValue: "",
                    })(<Input className="hyd-input-small hyd-osago-8-input" disabled={true}/>)}
                  />
                </Form.Item>
                <Form.Item
                  style={{width: "30%", marginRight: 0, marginLeft: 4}}
                >
                  <FloatingLabelInput
                    divClassName="hyd-osago-8-input"
                    htmlFor={"step-3_" + "car_owner_number"}
                    labelText={"Номер"}
                    divId={"float-label-small"}
                    child={getFieldDecorator("car_owner_number", {
                      initialValue: "",
                    })(<Input className="hyd-input-small hyd-osago-8-input" disabled={true}/>)}
                  />
                </Form.Item>
              </Row>

              <div id={"hyd-osago-kbm-block"} className={"hyd-emphasise-block-small"}>
                <Form.Item>
                  <Button
                    className="hyd-btn-small hyd-osago-btn"
                    type="primary"
                    onClick={() => {
                      //если не ограничено
                      getOwnerDriverKbm({
                        last_name: dataForm.car_owner_last_name.value,
                        first_name: dataForm.car_owner_name.value,
                        // middle_name: dataForm.car_owner_middle_name.value,
                        birth_date: dataForm.car_owner_birth_date.value,
                        number: dataForm.car_owner_number.value,
                        series: dataForm.car_owner_serial.value,
                        // vin: dataForm.vin.value,
                      });
                    }}
                    style={{width: "100%", marginRight: "auto"}}
                  >
                    Получить КБМ
                  </Button>
                </Form.Item>

                <Form.Item>
                  <div className="hyd-osago-kbm-value">
                    {getFieldDecorator("kbm_coeff", {initialValue: ""})(
                      <Input
                        className="hyd-input-small-emphasise"
                        placeholder="КБМ"
                        disabled={true}
                      />
                    )}
                  </div>
                </Form.Item>
              </div>
            </>
          )
        }

        {
          /* если без ограничено - страховщик Не явл собственников и собственник юр ицо  */
          ((dataForm.is_owner &&
            !dataForm.is_owner.value) || !dataForm.is_owner) &&
          dataForm.inshur &&
          dataForm.inshur.value === 2 && (
            <>
              <Row>
                <Form.Item
                >
                  <FloatingLabelInput
                    divClassName="hyd-osago-8-input"
                    htmlFor={"step-3_" + "vin"}
                    labelText={"VIN"}
                    divId={"float-label-small"}
                    child={getFieldDecorator("vin", {initialValue: ""})(
                      <Input className="hyd-input-small hyd-osago-8-input" disabled={true}/>
                    )}
                  />
                </Form.Item>

                <Form.Item
                >
                  <FloatingLabelInput
                    divClassName="hyd-osago-8-input"
                    htmlFor={"step-3_" + "car_owner_name_juristic"}
                    labelText={"Название"}
                    divId={"float-label-small"}
                    child={getFieldDecorator("car_owner_name_juristic", {initialValue: "",})(
                      <Input className="hyd-input-small hyd-osago-8-input" disabled={true}/>)}
                  />
                </Form.Item>

                <Form.Item
                >
                  <FloatingLabelInput
                    divClassName="hyd-osago-8-input"
                    htmlFor={"step-3_" + "car_owner_identify_number_juristic"}
                    labelText={"ИНН"}
                    divId={"float-label-small"}
                    child={getFieldDecorator("car_owner_identify_number_juristic",
                      {initialValue: ""}
                    )(<Input className="hyd-input-small hyd-osago-8-input" disabled={true}/>)}
                  />
                </Form.Item>
              </Row>
              <div id={"hyd-osago-kbm-block"} className={"hyd-emphasise-block-small"}>
                <Form.Item>
                  <Button
                    className="hyd-btn-small hyd-osago-btn"
                    type="primary"
                    disabled={
                      !dataForm.car_owner_name_juristic.value ||
                      !dataForm.car_owner_identify_number_juristic.value
                    }
                    onClick={() => {
                      getKbmPolicyCarOwnerJuristic({
                        // vin: dataForm.vin.value,
                        name: dataForm.car_owner_name_juristic.value,
                        inn: dataForm.car_owner_identify_number_juristic.value,
                      });
                    }}
                    style={{width: "100%", marginRight: "auto"}}
                  >
                    Получить КБМ
                  </Button>
                </Form.Item>
                <Form.Item>
                  <div className="hyd-osago-kbm-value">
                    {getFieldDecorator("kbm_coeff", {initialValue: ""})(
                      <Input
                        className="hyd-input-small-emphasise"
                        placeholder="КБМ"
                        disabled={true}
                      />
                    )}
                  </div>
                </Form.Item>
              </div>
            </>
          )
        }

        {
          // (getFieldValue(numberDrivers) === 1 && dataForm.policy_owner_inshur && (dataForm.policy_owner_inshur.value === 1 || dataForm.policy_owner_inshur.value === 2)) &&
          /* Если выбран режим не ограничено - страхов явл собсвенником и страхователь физ лицо  */

          dataForm.is_owner &&
          dataForm.is_owner.value &&
          dataForm.policy_owner_inshur &&
          dataForm.policy_owner_inshur.value === 1 && (
            <>
              <Row>
                <Form.Item
                >
                  <FloatingLabelInput
                    divClassName="hyd-osago-8-input"
                    htmlFor={"step-3_" + "vin"}
                    labelText={"VIN"}
                    divId={"float-label-small"}
                    child={getFieldDecorator("vin", {initialValue: ""})(
                      <Input className="hyd-input-small hyd-osago-8-input" disabled={true}/>
                    )}
                  />
                </Form.Item>
                {/* страховател,если он физ лицо и выбран режим Без Ограничений  */}

                <Form.Item
                >
                  <FloatingLabelInput
                    divClassName="hyd-osago-8-input"
                    htmlFor={"step-3_" + "policy_owner_serial"}
                    labelText={"Серия ДУЛ"}
                    divId={"float-label-small"}
                    child={getFieldDecorator("policy_owner_serial", {
                      initialValue: "",
                    })(
                      <Input className="hyd-input-small hyd-osago-8-input" disabled={true}/>
                    )}
                  />
                </Form.Item>

                <Form.Item
                >
                  <FloatingLabelInput
                    divClassName="hyd-osago-8-input"
                    htmlFor={"step-3_" + "policy_owner_number"}
                    labelText={"Номер ДУЛ"}
                    divId={"float-label-small"}
                    child={getFieldDecorator("policy_owner_number", {
                      initialValue: "",
                    })(
                      <Input className="hyd-input-small hyd-osago-8-input" disabled={true}/>
                    )}
                  />
                </Form.Item>
              </Row>
              <div id={"hyd-osago-kbm-block"} className={"hyd-emphasise-block-small"}>
                <Form.Item>
                  <Button
                    className="hyd-btn-small hyd-osago-btn"
                    type="primary"
                    onClick={() => {
                      // получаем КБМ для страхователя,если он физ лицо и выбран режим Не Ограничено
                      getOwnerDriverKbm({
                        last_name: dataForm.policy_owner_last_name.value,
                        first_name: dataForm.policy_owner_name.value,
                        // middle_name: dataForm.policy_owner_middle_name.value,
                        birth_date: dataForm.policy_owner_birth_date.value,
                        //    number: dataForm.policy_owner_driving_licence_number.value,
                        number: dataForm.policy_owner_number.value,
                        // series: dataForm.policy_owner_driving_licence_serial.value,
                        series: dataForm.policy_owner_serial.value,
                        // vin: dataForm.vin.value,
                      });
                    }}
                    style={{width: "100%", marginRight: "auto"}}
                  >
                    Получить КБМ
                  </Button>
                </Form.Item>
                <Form.Item>
                  <div className="hyd-osago-kbm-value">
                    {getFieldDecorator("kbm_coeff", {initialValue: ""})(
                      <Input
                        className="hyd-input-small-emphasise"
                        placeholder="КБМ"
                        disabled={true}
                      />
                    )}
                  </div>
                </Form.Item>
              </div>
            </>
          )
        }
      </>

    const isEmptyDates = () => {
      let drivers = getFieldValue("keys")

      return !drivers.map(k => {
        return !!getFieldValue(`additional_drivers_document_date_${k}`)
      }).every(v => v === true)
    }

    return (
      <>
        <FirstStepDisabled
          goToPrevStep={() => goToPrevStep(currentStep - 2)}
        />
        <SecondStepDisabled
          dataForm={dataForm}
          goToPrevStep={() => goToPrevStep(currentStep - 1)}
        />
        <div className="step-title-active" ref={this.headerRef}>
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">3</span>
          </div>
          <span className="step-title-active-header">Список водителей</span>
        </div>
        <Form layout="inline" onSubmit={this.handleSubmit}>
          {/* Количество водителей */}
          <div className={"hyd-bold-text"}>Количество водителей</div>
          <Form.Item
            style={{display: 'none'}}
            validateStatus={numberDriversError ? "error" : ""}
            help={numberDriversError || ""}
          >
            {getFieldDecorator(numberDrivers, {
              initialValue: numberDrivers,
              rules: [{required: true, message: " "}],
            })(
              <Radio.Group name={numberDrivers} onChange={toggleRadioButtons} className={"hyd-radio-group"}>
                <Radio value={1}>Ограничено</Radio>
                <Radio value={2}>Без ограничений</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Tabs
            className="hyd-tab-osago"
            defaultActiveKey={getFieldValue(numberDrivers).toString()}
            onChange={(e) => {
              e = parseInt(e);
              setFieldsValue({
                [numberDrivers]: e,
              });
              toggleRadioButtons(e);
            }}
          >
            <TabPane tab={<span className="hyd-tab-pane">Ограничено</span>} key="1">
              <Form.Item>
                <Button
                  className="hyd-btn-small hyd-osago-btn"
                  type="primary"
                  onClick={() => {
                    this.copyPolicyOwnerDriver();
                  }}
                  style={{width: "auto"}}
                >
                  Страхователь
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  className="hyd-btn-small hyd-osago-btn"
                  type="primary"
                  onClick={() => {
                    this.copyCarOwnerDriver();
                  }}
                  style={{width: "auto"}}
                >
                  Собственник
                </Button>
              </Form.Item>
            </TabPane>
            <TabPane tab={<span className="hyd-tab-pane">Без ограничений</span>} key="2">
              {getFieldValue(numberDrivers) === 2 && getUnlimitedDrivers()}
            </TabPane>
          </Tabs>

          {numberDriversStepThree === 1 && formItems}

          {/* Назад/Далее */}
          <Row>
            <Form.Item>
              <Button
                style={{marginTop: "48px"}}
                type="primary"
                htmlType="submit"
                className="hyd-btn-small hyd-osago-btn-proceed"
                disabled={hasErrors(getFieldsError()) || numberDriversStepThree === 1 && isEmptyDates()}
              >
                Продолжить
              </Button>
            </Form.Item>
          </Row>
        </Form>
        {[
          {number: 4, text: "Условия использования"},
          {number: 5, text: "Оформление полиса ОСАГО"},
          {number: 6, text: "Предварительный просмотр документов"},
        ].map(({number, text}) =>
          <div className="step-title-disabled" key={number}>
            <div className="step-icon-block">
              <NumberInactiveIcon className="hyd-large-num-ico"/>
              <span className="hyd-large-num-ico-num">{number}</span>
            </div>
            <span className="step-title-disabled-header">{text}</span>
          </div>
        )}
        <ModalError type={1}/>
      </>
    );
  }
}

const WrappedThirdStepForm = Form.create({
  name: "step-3",
  mapPropsToFields(props) {
    const {dataForm} = props;
    const transformed = transform({dataForm});

    return transformed.dataForm;
  },
  onFieldsChange(props, changedFields) {
    props.setDataForm(changedFields);
  },
})(ThirdStep);

const mapStateToProps = (state) => ({
  currentStep: currentStepSelector(state),
  dataForm: dataFormSelector(state),
  numberDriversStepThree: numberDriversStepThreeSelector(state),
  driverKBM: driverKBMSelector(state),
  quantityAdditionalDrivers: quantityAdditionalDriversSelector(state),
  typeFormPolicyOwnerStepTwo: typeFormPolicyOwnerStepTwoSelector(state),
  errors: errorsSelector(state),
  countries: countriesSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPrevStep,
      goToNextStep,
      setDataForm,
      toggleNumberDriversStepThree,
      removeDriverFromListDrivers,
      clearListDrivers,
      changeQuantityAdditionalDrivers,
      getDriverKbm,
      getKbmPolicyCarOwnerJuristic,
      getOwnerDriverKbm,
      getCarOwnerDriverKbm,
      clearKBM,
      getKbmDriverNoLimit,
      savePolicyOwnerCountryData,
      getCountries
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedThirdStepForm);

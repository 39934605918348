export const isRequestSelector = state => state.mortgageReducer.isRequest;
export const currentStepSelector = state => state.mortgageReducer.currentStep;
export const errorsMortgageSelector = state => state.mortgageReducer.errors;
export const visibleErrorModalMortgageSelector = state => state.mortgageReducer.visibleErrorModalMortgage;
export const mortgageDataFormSelector = state => state.mortgageReducer.mortgageDataForm;
export const lastNameArrSelector = state => state.mortgageReducer.lastNameArr;
export const firstNameArrSelector = state => state.mortgageReducer.firstNameArr;
export const genGenderSelector = state => state.mortgageReducer.genGender;
export const regAddressArrSelector = state => state.mortgageReducer.regAddressArr;
export const factAddressArrSelector = state => state.mortgageReducer.factAddressArr;
export const objectAddressArrSelector = state => state.mortgageReducer.objectAddressArr;
export const precalculateResultSelector = state => state.mortgageReducer.precalculateResult;
export const calcResultSelector = state => state.mortgageReducer.calcResult;
export const calculationInProgressSelector = state => state.mortgageReducer.calculationInProgress;
export const creatingPolicySelector = state => state.mortgageReducer.creatingPolicy;
export const documentsPolicySelector = state => state.mortgageReducer.documentsPolicy;
export const paymentStatusSelector = state => state.mortgageReducer.paymentStatus;
export const documentsSelector = state => state.mortgageReducer.documents;
export const paymentDocsSelector = state => state.mortgageReducer.paymentDocs;
export const professionsSelector = state => state.mortgageReducer.professions;
export const paymentDocTypeSelector = state => state.mortgageReducer.paymentDocType;
export const banksSelector = state => state.mortgageReducer.banks;
export const employmentStatusesSelector = state => state.mortgageReducer.employment_statuses_list;
export const healthAbnormalitiesSelector = state => state.mortgageReducer.health_abnormalities_list;
export const coBorrowersQuantitySelector = state => state.mortgageReducer.coBorrowersQuantity;
export const coBorrowersQuantityMaxSelector = state => state.mortgageReducer.coBorrowersQuantityMax;
export const coBorrowersKeysSelector = state => state.mortgageReducer.coBorrowersKeys;
export const fiasIdSelector = state => state.mortgageReducer.fiasId;
export const isLifeRiskSelector = state => state.mortgageReducer.mortgageDataForm.is_life_risk.value;
export const isPropertyRiskSelector = state => state.mortgageReducer.mortgageDataForm.is_property_risk.value;
export const propertyShareSumSelector = state => state.mortgageReducer.propertyShareSum;
export const passportFileListSelector = state => state.mortgageReducer.passportFileList;
export const loanAgreementFileListSelector = state => state.mortgageReducer.loanAgreementFileList;
export const evaluationReportFileListSelector = state => state.mortgageReducer.evaluationReportFileList;
export const EGRNFileListSelector = state => state.mortgageReducer.EGRNFileList;
export const isFormSuccessfullySentSelector = state => state.mortgageReducer.isFormSuccessfullySent;
export const visibleModalMortgageApplicationSelector = state => state.mortgageReducer.visibleModalMortgageApplication;

import {takeLatest} from 'redux-saga/effects';
import * as api from './api';
import * as types from '../store/actionTypes';

export function* signUpSaga({payload}) {
  yield api.post('registration/', null, payload, types.SIGN_UP);
}

export default function* () {
  yield takeLatest(types.SIGN_UP, signUpSaga);
}

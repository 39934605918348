import React, {Component} from "react";
import {Row, Form, Tooltip} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {ReactComponent as DeleteIcon} from '../../assets/Icons/mortgage/Del.svg';
import {transform} from "../../utils";
import {
  setDataForm,
  removeCoBorrower,
  calcPropertyShareSum,
  saveCoBorrowerAddressData,
  clearCoBorrowerAddressData,
  saveCoBorrowerFactAddressData,
  clearCoBorrowerFactAddressData,
} from "../../actions/mortgageActions";
import {coBorrowersQuantityMaxSelector, mortgageDataFormSelector} from "../../selectors/mortgageSelectors";
import MortgagePerson from "./MortgagePerson";


class CoBorrowers extends Component {


  render() {
    const {
      k,
      key,
      index,
      removeCoBorrower,
      form,
      saveCoBorrowerAddressData,
      clearCoBorrowerAddressData,
      saveCoBorrowerFactAddressData,
      clearCoBorrowerFactAddressData,
      coBorrowersKeys,
    } = this.props;

    return (
      <>
        <div className={"hyd-driver-number-osago"}>
          <span>Созаёмщик №{index + 1}</span>
          <span
            className={"hyd-osago-driver-delete"}
            onClick={() => {
              removeCoBorrower(k)
              calcPropertyShareSum()
              this.props.form.validateFields([`property_share`], {force: true})
            }}
          >
            <Tooltip title="Удалить созаёмщика">
              <DeleteIcon className={"hyd-osago-driver-delete-ico"}/>
            </Tooltip>
          </span>
        </div>
        <Row key={key} data-key={key}>

          <MortgagePerson
            form={form}
            k={`_${k}`}
            clearAddressData={clearCoBorrowerAddressData}
            saveAddressData={saveCoBorrowerAddressData}
            clearFactAddressData={clearCoBorrowerFactAddressData}
            saveFactAddressData={saveCoBorrowerFactAddressData}
            coBorrowersKeys={coBorrowersKeys}
          />

        </Row>
      </>
    )
  }
}

const WrappedCoBorrowersForm = Form.create({
  name: "mortgage-step-2",
  mapPropsToFields(props) {
    const {mortgageDataForm} = props;
    const transformed = transform({mortgageDataForm});

    return transformed.mortgageDataForm;
  },
  onFieldsChange(props, changedFields) {
    props.setDataForm(changedFields);
  }
})(CoBorrowers);

const mapStateToProps = state => ({
  mortgageDataForm: mortgageDataFormSelector(state),
  coBorrowersQuantityMax: coBorrowersQuantityMaxSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setDataForm,
      removeCoBorrower,
      calcPropertyShareSum,
      saveCoBorrowerAddressData,
      clearCoBorrowerAddressData,
      saveCoBorrowerFactAddressData,
      clearCoBorrowerFactAddressData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WrappedCoBorrowersForm);

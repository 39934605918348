import React, {Component} from "react";
import {
  Row, Form, Button, Input, Select, Radio, Checkbox, Spin, Tooltip
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
  findInsuranceID,
  transliterateSeries,
  checkValueBirthDate,
  hasErrors,
  nameRules,
  transform,
} from "../../utils";
import {
  setDataForm,
  toggleOsagoModal,
  getKladrPolicyCarOwner,
  toggleSelectedAddress,
  toggleTypeTransportInsurer,
  getAlfaProlongation,
  getAlfaAgreementData,
  getVskProlongation,
  getVskAgreementData,
  getRenessansProlongation,
  getRenessansAgreementData,
  getIngosAgreementData,
  getIngosProlongation,
  clearDataProlongation,
  goToNextStep,
  toggleIngosVIN,
  toggleIngosTypeCarIdentity,
  createPolicy,
  doPaymentNSS,
  getConfig,
  getPaymentStatus,
  getInsurances,
  getSevaProlongation,
  getProlongationRGS,
  getAgreementDataRGS,
} from "../../actions/osagoActions";
import {
  dataFormSelector,
  dataFormResultSelector,
  calcResultSelector,
  isProlongDisabledButtonsSelector,
  isProlongDisabledFieldsSelector,
  typeTransportInsurerSelector,
  policyAdressArrSelector,
  isRequestSelector,
  errorsSelector,
  ingosVinSelector,
  typeCarIdentityIngosSelector,
  calculationInProgressSelector,
  alfaDocsSelector,
  ingosDocsSelector,
  insuranceDocumentsSelector,
  configAdminSelector,
  documentsPolicySelector,
  paymentStatusSelector,
  policyCreatedSelector,
  paymentDocsSelector,
  documentsNSSSelector,
  isProlongCalcSuccessSelector,
  renessansDocsSelector,
  insurancesSelector,
  draftIdSelector,
} from "../../selectors/osagoSelectors";
import {userInfoSelector} from "../../selectors/loginSelectors";
import Cleave from "cleave.js/react";
import "./styles/index.scss";
import "../../fonts/open-sans.css"
import ModalError from "../../components/ModalError";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import ProlongationCalcDisabled from "../../components/Prolongation/DisabledComponents/ProlongationCalcDisabled";
import ProlongationIssueDisabled from "../../components/Prolongation/DisabledComponents/ProlongationIssueDisabled";
import IssuingProlongationPolicy from "../../components/Prolongation/IssuingProlongationPolicy";
import CustomCollapse from "../../components/Form/CustomCollapse";
import {ReactComponent as ExclamationIcon} from "../../assets/Icons/ExclamationYellowSmall.svg";
import ModalOsago from "../../components/Form/ModalOsago";
import {checkEarnedMoneyAmount, getNotifications} from "../../actions/mainActions";

const {Option} = Select;


class Prolongation extends Component {

  componentWillUnmount() {
    this.props.clearDataProlongation(2)
  }

  componentDidMount() {
    document.title = "Пролонгация"
    const {form, getInsurances, getNotifications, checkEarnedMoneyAmount} = this.props
    getNotifications()
    getInsurances()
    getConfig()
    form.validateFields()
    checkEarnedMoneyAmount()
  }

  clearAllFields = () => {
    const {setFieldsValue} = this.props.form;

    setFieldsValue({
      prolongation_previous_policy_serial: '',
      prolongation_previous_policy_number: '',
      prolongation_vin: '',
      prolongation_chassis_number: '',
      prolongation_body_number: '',
      prolongation_policy_owner_identify_number_juristic: '',
      prolongation_policy_owner_last_name: '',
      prolongation_policy_owner_name: '',
      prolongation_policy_owner_middle_name: '',
      prolongation_policy_owner_birth_date: '',
      prolongation_policy_owner_inshur: 1,
      is_prolongation_seva: false,
      is_all_companies_calculation: false,
    })
  }

  onChangeCalcTypeCheckBox = (e) => {
    const {setFieldsValue, getFieldValue} = this.props.form;
    let isAllCompaniesCalculation = getFieldValue("is_all_companies_calculation")
    let isProlongationSEVA = getFieldValue("is_prolongation_seva")

    if (e.target.checked && isAllCompaniesCalculation) {
      setFieldsValue({is_all_companies_calculation: false})
    } else if (e.target.checked && isProlongationSEVA) {
      setFieldsValue({is_prolongation_seva: false})
    }
  }

  handleSubmit = (e) => {
    const {createPolicy} = this.props;

    this.props.form.validateFields((err) => {
      if (!err) {
        createPolicy(e)
      }
    })
  }

  render() {
    const {
      insurances,
      toggleTypeTransportInsurer,
      typeTransportInsurer,
      getAlfaProlongation,
      getAlfaAgreementData,
      getVskProlongation,
      getVskAgreementData,
      getProlongationRGS,
      getAgreementDataRGS,
      getRenessansProlongation,
      getRenessansAgreementData,
      getIngosAgreementData,
      getIngosProlongation,
      clearDataProlongation,
      toggleIngosVIN,
      ingosWithoutVIN,
      toggleIngosTypeCarIdentity,
      typeCarIdentityIngos,
      dataFormResult,
      calcResult,
      setDataForm,
      toggleOsagoModal,
      userInfo,
      calculationInProgress,
      isProlongDisabledButtons,
      isProlongDisabledFields,
      isProlongCalcSuccess,
      documentsNSS,
      getPaymentStatus,
      configAdmin,
      documentsPolicy,
      doPaymentNSS,
      payment_status,
      alfa_docs,
      ingostrah_docs,
      renessans_docs,
      insuranceDocuments,
      policyCreated,
      getSevaProlongation,
      draftId,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldError,
      isFieldTouched,
      getFieldValue,
      setFieldsValue,
      getFieldsError,
    } = this.props.form;

    const ALFA = "alfastrah"
    const RENINS = "renesans"
    const INGOS = "ingostrah"
    const VSK = "vsk"
    const RGS = "rosgosstrah"
    const SEVA = "seva"

    const activeProlongationInsurances = insurances.filter(
      insurance => insurance["prolongation_active"]).map(insurance => insurance["ins_key"])

    const vin = "prolongation_vin";
    const vinError = isFieldTouched(vin) && getFieldError(vin);

    const chassisNumber = "prolongation_chassis_number";
    const chassisNumberError = isFieldTouched(chassisNumber) && getFieldError(chassisNumber);

    const bodyNumber = "prolongation_body_number";
    const bodyNumberError = isFieldTouched(bodyNumber) && getFieldError(bodyNumber);

    const carIdentity = "prolongation_car_identity";
    const carIdentityError = isFieldTouched(carIdentity) && getFieldError(carIdentity);

    const transportInsurer = "prolongation_policy_owner_inshur";
    const transportInsurerError = isFieldTouched(transportInsurer) && getFieldError(transportInsurer);

    const insuranceCompany = "prolongation_previous_policy_insurance_company";

    const isAllCompaniesCalculation = "is_all_companies_calculation";
    const isProlongationSEVA = "is_prolongation_seva";

    const prevSeries = "prolongation_previous_policy_serial";
    const prevSeriesError = isFieldTouched(prevSeries) && getFieldError(prevSeries);

    const prevNumber = "prolongation_previous_policy_number";
    const prevNumberError = isFieldTouched(prevNumber) && getFieldError(prevNumber);

    const vehicleDuration = "vehicle_duration_prolong_ingos";

    const prolongationBirthDate = "prolongation_policy_owner_birth_date";
    const prolongationBirthDateError = isFieldTouched(prolongationBirthDate) && getFieldError(prolongationBirthDate);

    const prolongationINN = "prolongation_policy_owner_identify_number_juristic";
    const prolongationINNError = isFieldTouched(prolongationINN) && getFieldError(prolongationINN);

    const prolongationDocSeries = "policy_owner_doc_series";
    const prolongationDocSeriesError = isFieldTouched(prolongationDocSeries) && getFieldError(prolongationDocSeries);

    const prolongationDocNumber = "policy_owner_doc_number";
    const prolongationDocNumberError = isFieldTouched(prolongationDocNumber) && getFieldError(prolongationDocNumber);

    const prolongationLastName = "prolongation_policy_owner_last_name";
    const prolongationLastNameError = isFieldTouched(prolongationLastName) && getFieldError(prolongationLastName);

    const prolongationFirstName = "prolongation_policy_owner_name";
    const prolongationFirstNameError = isFieldTouched(prolongationFirstName) && getFieldError(prolongationFirstName);

    const prolongationMiddleName = "prolongation_policy_owner_middle_name";
    const prolongationMiddleNameError = isFieldTouched(prolongationMiddleName) && getFieldError(prolongationMiddleName);

    const onChangeRadioTransportInsurer = (e) => {
      toggleTypeTransportInsurer(e.target.value);
    }

    const radioInsurerType = () => {
      return (
        <>

          {/* Страхователь */}
          <Form.Item
            validateStatus={transportInsurerError ? "error" : ""}
            help={transportInsurerError || ""}
          >
            {getFieldDecorator(transportInsurer, {
              rules: [{required: true, message: " "}],
              valuePropName: "checked"
            })(
              <Radio.Group
                className={"hyd-radio-group"}
                name={transportInsurer}
                defaultValue={1}
                value={getFieldValue(transportInsurer)}
                disabled={
                  isProlongDisabledFields ||
                  findInsuranceID(insurances, [VSK, RGS], getFieldValue(insuranceCompany))
                }
                onChange={
                  (e) => {
                    onChangeRadioTransportInsurer(e);
                    if (e.target.value === 2) {
                      setFieldsValue({
                        policy_owner_birth_date: "",
                        policy_owner_last_name: "",
                        policy_owner_name: "",
                        policy_owner_middle_name: "",
                      })
                    } else {
                      setFieldsValue({policy_owner_identify_number_juristic: ""})
                    }
                  }
                }
              >
                <Radio value={1}>Физ. лицо</Radio>
                <Radio value={2}>Юр. лицо</Radio>
              </Radio.Group>
            )}
          </Form.Item>

        </>
      )
    }

    const getFormSeva = () => {
      return getFormOldPolicyData()
    }

    const getFormAlfa = () => {
      return (
        <>
          {getFormOldPolicyData()}

          {radioInsurerType()}

          {typeTransportInsurer === 2
            ? getFormOrganisationData()  // юрик
            : getFormBirthDate()        // физик
          }

        </>
      )
    }

    const getIngosFormPersonData = () => {
      return (
        <>

          <div className={"hyd-row-prolongation"}>
            {/* Фамилия */}
            <Form.Item
              validateStatus={prolongationLastNameError ? "error" : ""}
              help={prolongationLastNameError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input hyd-input-autocomplete-small"
                htmlFor={"prolongation_" + prolongationLastName}
                labelText={"Фамилия"}
                divId={"float-label-small"}
                child={getFieldDecorator(prolongationLastName, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: nameRules,
                })(
                  <Input
                    className={"ant-input hyd-input-small hyd-osago-8-input"}
                    disabled={isProlongDisabledFields}
                  />
                )}
              />
            </Form.Item>

            {/* Имя */}
            <Form.Item
              validateStatus={prolongationFirstNameError ? "error" : ""}
              help={prolongationFirstNameError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input hyd-input-autocomplete-small"
                htmlFor={"prolongation_" + prolongationFirstName}
                labelText={"Имя"}
                divId={"float-label-small"}
                child={getFieldDecorator(prolongationFirstName, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: nameRules,
                })(
                  <Input
                    className={"ant-input hyd-input-small hyd-osago-8-input"}
                    disabled={isProlongDisabledFields}
                  />
                )}
              />
            </Form.Item>

            {/* Отчество */}
            <Form.Item
              validateStatus={prolongationMiddleNameError ? "error" : ""}
              help={prolongationMiddleNameError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input hyd-input-autocomplete-small hyd-osago-no-margin"
                htmlFor={"prolongation_" + prolongationMiddleName}
                labelText={"Отчество"}
                divId={"float-label-small"}
                child={getFieldDecorator(prolongationMiddleName, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: false,
                      message: " "
                    },
                    {
                      // только лат или только кириллица (допускается двойное имя с пробелом по центру или тире)
                      pattern: /^[А-Яа-яЁёa-zA-Z() -]+$/g,
                      message: " ",
                    },
                    {
                      validator: this.defineGender,
                    },
                  ],
                })(
                  <Input
                    className={"ant-input hyd-input-small hyd-osago-8-input"}
                    disabled={isProlongDisabledFields}
                  />
                )}
              />
            </Form.Item>
          </div>

        </>
      )
    }

    const getVIN = () => {
      return (
        <div className={"hyd-row-prolongation"}>
          <Form.Item
            validateStatus={vinError ? "error" : ""}
            help={vinError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-input-prolongation"
              htmlFor={"prolongation_" + vin}
              labelText={"VIN"}
              divId={"float-label-small"}
              child={getFieldDecorator(vin, {
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    pattern: /^[a-zA-Z0-9]{17}$/g,
                    message: " "
                  },],
              })(<Input className="hyd-input-small hyd-input-prolongation" maxLength={17}
                        disabled={isProlongDisabledFields}/>)}
            />
          </Form.Item>
        </div>
      )
    }

    const onChangeRadioCarIdentity = (e) => {
      toggleIngosTypeCarIdentity(e.target.value);
    };

    const getFormBodyNumber = () => {
      return (
        <Form.Item
          validateStatus={bodyNumberError ? "error" : ""}
          help={bodyNumberError || ""}
        >
          <FloatingLabelInput
            divClassName="hyd-input-prolongation"
            htmlFor={"prolongation_" + bodyNumber}
            labelText={"Кузов"}
            divId={"float-label-small"}
            child={getFieldDecorator(bodyNumber, {
              rules: [{required: true, message: " "}],
            })(<Input className="hyd-input-small hyd-input-prolongation" maxLength={17} minLength={3}
                      disabled={isProlongDisabledFields}/>)}
          />
        </Form.Item>
      )
    }

    const getFormChassisNumber = () => {
      return (
        <Form.Item
          validateStatus={chassisNumberError ? "error" : ""}
          help={chassisNumberError || ""}
        >
          <FloatingLabelInput
            divClassName="hyd-input-prolongation"
            htmlFor={"prolongation_" + chassisNumber}
            labelText={"Шасси"}
            divId={"float-label-small"}
            child={getFieldDecorator(chassisNumber, {
              rules: [{required: true, message: " "}],
            })(<Input className="hyd-input-small hyd-input-prolongation" maxLength={17} minLength={3}
                      disabled={isProlongDisabledFields}/>)}
          />
        </Form.Item>
      )
    }

    const getIdentity = () => {
      return (
        <>
          <Form.Item
            validateStatus={carIdentityError ? "error" : ""}
            help={carIdentityError || ""}
          >
            {getFieldDecorator(carIdentity, {
              initialValue: carIdentity,
              rules: [{required: true, message: " "}]
            })(
              <Radio.Group
                className={"hyd-radio-group"}
                name={carIdentity}
                disabled={isProlongDisabledFields}
                onChange={(e) => {
                  onChangeRadioCarIdentity(e);
                  if (e.target.value === 2) {
                    // clearDataBodyNumber();
                    setFieldsValue({
                      body_number: '',
                    })
                  } else {
                    // clearDataChassisNumber();
                    setFieldsValue({
                      chassis_number: '',
                    })
                  }
                }}
              >
                <Radio value={1}>Кузов</Radio>
                <Radio value={2}>Шасси</Radio>
              </Radio.Group>
            )}
          </Form.Item>

          {typeCarIdentityIngos === 1
            ? getFormBodyNumber()      // Кузов
            : getFormChassisNumber()   // Шасси
          }
        </>
      )
    }

    const checkBoxVIN = (e) => {
      toggleIngosVIN(e.target.checked);
      if (e.target.checked) {
        setFieldsValue({
          vin: '',
        })
      } else { // чистим в форме "Кузов" и "Шасси"
        setFieldsValue({
          body_number: '',
          chassis_number: '',
        })
      }
    }

    const getFormIngos = () => {
      return (
        <>
          {getFormOldPolicyData()}

          {/* VIN */}
          {!getFieldValue(isProlongationSEVA) &&
            < Form.Item>
              < Checkbox onChange={checkBoxVIN} checked={ingosWithoutVIN} disabled={isProlongDisabledFields}>
                VIN отсутствует
              </Checkbox>
            </Form.Item>
          }

          {!ingosWithoutVIN && !getFieldValue(isProlongationSEVA) && getVIN()}
          {ingosWithoutVIN && !getFieldValue(isProlongationSEVA) && getIdentity()}

          {!getFieldValue(isProlongationSEVA) && radioInsurerType()}

          {typeTransportInsurer === 2
            ? !getFieldValue(isProlongationSEVA) && getFormOrganisationData()   // юрик
            : !getFieldValue(isProlongationSEVA) && getIngosFormPersonData()    // физик
          }

        </>
      )
    }

    const getFormVSK = () => {
      return (
        <>
          {getFormOldPolicyData()}

          {getFormPassportData()}

          {!getFieldValue(isProlongationSEVA) && radioInsurerType()}

          {typeTransportInsurer === 2
            ? !getFieldValue(isProlongationSEVA) && getFormOrganisationData()   // юрик
            : !getFieldValue(isProlongationSEVA) && getIngosFormPersonData()    // физик
          }
        </>
      )
    }

    const prolongInfo = (data) => {
      return (
        <>
          <ProlongationCalcDisabled
            lastName={data.last_name}
            inn={data.inn}
            vin={data.vin}
            bodyNum={data.body_num}
            chassisNum={data.chassis_num}
            oldPolicySerial={data.previous_policy_serial}
            oldPolicyNumber={data.previous_policy_number}
            newPolicyStart={data.start_policy_date}
            newPolicyEnd={data.finish_policy_date}
          />
        </>
      )
    }

    const calcResults = () => {
      return (
        <>
          {dataFormResult &&
            <>
              <div className="step-title-active" ref={this.headerRef}>
                <span>Оформление полиса ОСАГО</span>
              </div>

              <Form
                className="calc-table"
                style={{paddingRight: "35px"}}
              >
                {calcResult &&
                  Array.isArray(calcResult) &&
                  calcResult.length !== 0 &&
                  calcResult.map((value, index) => (
                    <CustomCollapse
                      index={index}
                      value={value}
                      setDataForm={setDataForm}
                      toggleOsagoModal={toggleOsagoModal}
                      userInfo={userInfo}
                      handleSubmit={this.handleSubmit}
                      key={index}
                      vehicleDuration={vehicleDuration}
                    />

                  ))
                }
                {calculationInProgress && <Spin size="large"/>}
              </Form>
            </>
          }
        </>
      )
    }

    const getFormRenins = () => {
      return (
        <>

          <div className={"hyd-row-prolongation"}>
            {/* Серия полиса */}
            <Form.Item
              validateStatus={prevSeriesError ? "error" : ""}
              help={prevSeriesError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-input-prolongation"
                htmlFor={"prolongation_" + prevSeries}
                labelText={"Серия полиса"}
                divId={"float-label-small"}
                child={getFieldDecorator(prevSeries, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                    // {
                    //   pattern: /((^[АВЕКМНОРСТУХавекмнорстух]{3})|(^[ABEKMHOPCTYXabekmhopctyx]{3}))$/g,
                    //   message: " "
                    // },
                  ],
                })(<Input className="hyd-input-small hyd-input-prolongation"
                          maxLength={3}
                          disabled={isProlongDisabledFields}
                          onChange={(e) => {
                            e.target.value = transliterateSeries(e.target.value.toUpperCase())
                          }}/>)}
              />
            </Form.Item>

            {/* Номер полиса */}
            <Form.Item
              validateStatus={prevNumberError ? "error" : ""}
              help={prevNumberError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-input-prolongation"
                htmlFor={"prolongation_" + prevNumber}
                labelText={"Номер полиса"}
                divId={"float-label-small"}
                child={getFieldDecorator(prevNumber, {
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                    {
                      pattern: /^[0-9]{10}$/g,
                      message: " "
                    },
                  ],
                })(<Input className="hyd-input-small hyd-input-prolongation" maxLength={10}
                          disabled={isProlongDisabledFields}/>)}
              />
            </Form.Item>
          </div>

        </>
      )
    }

    const getFormRGS = () => {
      return (
        <>
          {getFormOldPolicyData()}

          {getFormPassportData()}

          {!getFieldValue(isProlongationSEVA) && radioInsurerType()}

          {typeTransportInsurer === 1 && getFormBirthDate()}

          {typeTransportInsurer === 2
            ? !getFieldValue(isProlongationSEVA) && getFormOrganisationData()   // юрик
            : !getFieldValue(isProlongationSEVA) && getIngosFormPersonData()    // физик
          }
        </>
      )
    }

    const getFormBirthDate = () => {
      return (
        <div className={"hyd-row-prolongation"}>
          {/* Дата рождения */}
          <Form.Item
            validateStatus={prolongationBirthDateError ? "error" : ""}
            help={prolongationBirthDateError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-input-profile"
              htmlFor={"prolongation_" + prolongationBirthDate}
              labelText={"Дата рождения"}
              divId={"float-label-small"}
              child={getFieldDecorator(prolongationBirthDate, {
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    validator: checkValueBirthDate,
                  },
                ],
              })(
                <Cleave
                  className="ant-input hyd-input-small"
                  key={prolongationBirthDate}
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"],
                  }}
                  disabled={isProlongDisabledFields}
                />)}
            />
          </Form.Item>
        </div>
      )
    }

    const getFormOrganisationData = () => {
      return (
        <div className={"hyd-row-prolongation"}>
          {/* ИНН */}
          <Form.Item
            validateStatus={prolongationINNError ? "error" : ""}
            help={prolongationINNError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-input-prolongation"
              htmlFor={"prolongation_" + prolongationINN}
              labelText={"ИНН"}
              divId={"float-label-small"}
              child={getFieldDecorator(prolongationINN, {
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    pattern: /^[0-9]{10}$/g,
                    message: " "
                  }
                ],
              })(<Input className="hyd-input-small hyd-input-prolongation" maxLength={10}
                        disabled={isProlongDisabledFields}/>)}
            />
          </Form.Item>
        </div>
      )
    }

    const getFormOldPolicyData = () => {
      return (
        <div className={"hyd-row-prolongation"}>
          {/* Серия полиса */}
          <Form.Item
            validateStatus={prevSeriesError ? "error" : ""}
            help={prevSeriesError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-input-prolongation"
              htmlFor={"prolongation_" + prevSeries}
              labelText={"Серия полиса"}
              divId={"float-label-small"}
              child={getFieldDecorator(prevSeries, {
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    pattern: /((^[АВЕКМНОРСТУХавекмнорстух]{3})|(^[ABEKMHOPCTYXabekmhopctyx]{3}))$/g,
                    message: " "
                  },
                ],
              })(<Input className="hyd-input-small hyd-input-prolongation"
                        maxLength={3}
                        disabled={isProlongDisabledFields}
                        onChange={(e) => {
                          e.target.value = transliterateSeries(e.target.value.toUpperCase())
                        }}/>)}
            />
          </Form.Item>

          {/* Номер полиса */}
          <Form.Item
            validateStatus={prevNumberError ? "error" : ""}
            help={prevNumberError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-input-prolongation"
              htmlFor={"prolongation_" + prevNumber}
              labelText={"Номер полиса"}
              divId={"float-label-small"}
              child={getFieldDecorator(prevNumber, {
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    pattern: /^[0-9]{10}$/g,
                    message: " "
                  },
                ],
              })(<Input className="hyd-input-small hyd-input-prolongation" maxLength={10}
                        disabled={isProlongDisabledFields}/>)}
            />
          </Form.Item>
        </div>
      )
    }

    const getFormPassportData = () => {
      return (
        <div className={"hyd-row-prolongation"}>
          {/* Серия документа */}
          <Form.Item
            validateStatus={prolongationDocSeriesError ? "error" : ""}
            help={prolongationDocSeriesError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-input-prolongation"
              htmlFor={"prolongation_" + prolongationDocSeries}
              labelText={"Серия паспорта"}
              divId={"float-label-small"}
              child={getFieldDecorator(prolongationDocSeries, {
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  // {
                  // pattern: /((^[АВЕКМНОРСТУХавекмнорстух]{3})|(^[ABEKMHOPCTYXabekmhopctyx]{3}))$/g,
                  // message: " "
                  // },
                ],
              })(<Input className="hyd-input-small hyd-input-prolongation"
                        maxLength={4}
                        disabled={isProlongDisabledFields}
                        onChange={(e) => {
                          e.target.value = transliterateSeries(e.target.value.toUpperCase())
                        }}/>)}
            />
          </Form.Item>

          {/* Номер документа */}
          <Form.Item
            validateStatus={prolongationDocNumberError ? "error" : ""}
            help={prolongationDocNumberError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-input-prolongation"
              htmlFor={"prolongation_" + prolongationDocNumber}
              labelText={"Номер паспорта"}
              divId={"float-label-small"}
              child={getFieldDecorator(prolongationDocNumber, {
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  // {
                  //   pattern: /^[0-9]{10}$/g,
                  //   message: " "
                  // },
                ],
              })(<Input className="hyd-input-small hyd-input-prolongation" maxLength={10}
                        disabled={isProlongDisabledFields}/>)}
            />
          </Form.Item>
        </div>
      )
    }

    const isAlfaBtnDisabled = () => {
      return (
        !getFieldValue(transportInsurer) ||
        !getFieldValue(prevSeries) || !getFieldValue(prevNumber) ||
        (getFieldValue(transportInsurer) === 1 && !getFieldValue(prolongationBirthDate)) ||
        (getFieldValue(transportInsurer) === 2 && !getFieldValue(prolongationINN))
      )
    }

    const isBtnDisabledRGS = () => {
      return (
        !getFieldValue(transportInsurer) ||
        !getFieldValue(prevSeries) || !getFieldValue(prevNumber) ||
        !getFieldValue(prolongationDocSeries) || !getFieldValue(prolongationDocNumber) ||
        (getFieldValue(transportInsurer) === 1 && !getFieldValue(prolongationBirthDate)) ||
        (getFieldValue(transportInsurer) === 1 &&
          (!getFieldValue(prolongationLastName) || !getFieldValue(prolongationFirstName)))
      )
    }

    const isIngosBtnDisabled = () => {
      return (
        !getFieldValue(transportInsurer) ||
        !getFieldValue(prevSeries) || !getFieldValue(prevNumber) ||
        (ingosWithoutVIN && getFieldValue(carIdentity) === 1 && !getFieldValue(bodyNumber)) ||
        (ingosWithoutVIN && getFieldValue(carIdentity) === 2 && !getFieldValue(chassisNumber)) ||
        (!ingosWithoutVIN && !getFieldValue(vin)) ||
        (getFieldValue(transportInsurer) === 1 &&
          (!getFieldValue(prolongationLastName) || !getFieldValue(prolongationFirstName))) ||
        (getFieldValue(transportInsurer) === 2 && !getFieldValue(prolongationINN))
      )
    }

    const isVskBtnDisabled = () => {
      return (
        !getFieldValue(transportInsurer) ||
        !getFieldValue(prevSeries) || !getFieldValue(prevNumber) ||
        (getFieldValue(transportInsurer) === 1 &&
          (!getFieldValue(prolongationLastName) || !getFieldValue(prolongationFirstName)))
      )
    }


    const isIngosSEVABtnDisabled = () => {
      return (
        !getFieldValue(prevSeries) || !getFieldValue(prevNumber)
      )
    }


    return (
      <>
        <div className="prolongation-container">
          <span className="hyd-very-big-bold-text-small">Пролонгация ОСАГО</span>
          <br/><br/>

          {!policyCreated && <Form>

            <div className="hyd-row-prolongation">
              {/* Страховая компания */}
              <Form.Item
                required={false}
              >
                <div className="hyd-select-small hyd-select-prolongation">
                  {getFieldDecorator(insuranceCompany, {
                    rules: [{required: true, message: " "}]
                  })(
                    <Select
                      className="hyd-input-select-small"
                      style={{width: "100%"}}
                      placeholder="Страховая компания"
                      onChange={(e) => {
                        console.log(e)
                        toggleTypeTransportInsurer(1)
                        clearDataProlongation(2)
                        if (findInsuranceID(insurances, [RENINS, VSK], e)) {
                          setFieldsValue({
                            is_prolongation_seva: false,
                            is_all_companies_calculation: false,
                          })
                        }
                        if (e === SEVA) {
                          setFieldsValue({
                            is_prolongation_seva: false,
                            is_all_companies_calculation: true,
                          })
                        }
                      }}
                      disabled={isProlongDisabledFields}
                    >
                      <Option value={SEVA} key={SEVA}>
                        <div className={"hyd-select-tooltip"}>Страховая компания</div>
                        <div className={"hyd-select-value"}><b>{SEVA}</b></div>
                        <Tooltip
                          title={"Для расчета пролонгации укажите серию и номер полиса ОСАГО, оформленного в SEVA"}
                          placement={"bottom"}
                          overlayClassName={"hyd-tooltip"}
                        >
                          <span
                            style={{height: "10px", marginLeft: "10px"}}
                          >
                            <ExclamationIcon/>
                          </span>
                        </Tooltip>
                      </Option>
                      {insurances && insurances
                        .filter(i => activeProlongationInsurances.includes(i.ins_key))
                        .map(({id, short_name}) =>
                          <Option value={id} key={id}>
                            <div className={"hyd-select-tooltip"}>Страховая компания</div>
                            <div className={"hyd-select-value"}>{short_name}</div>
                          </Option>
                        )}
                    </Select>
                  )}
                </div>
              </Form.Item>

              <Form.Item>
                <Row>
                  {getFieldDecorator(isAllCompaniesCalculation, {
                    valuePropName: "checked",
                    initialValue: true,
                  })(
                    <Checkbox
                      className={"hyd-prolong-checkbox"}
                      onChange={this.onChangeCalcTypeCheckBox}
                      disabled={
                        findInsuranceID(insurances, [RENINS], getFieldValue(insuranceCompany))
                        || isProlongDisabledFields || getFieldValue(insuranceCompany) === SEVA
                      }
                    >
                      Расчёт по всем компаниям
                    </Checkbox>
                  )}
                </Row>
              </Form.Item>
            </div>

            {findInsuranceID(insurances, [ALFA], getFieldValue(insuranceCompany)) ? getFormAlfa()
              : findInsuranceID(insurances, [RENINS], getFieldValue(insuranceCompany)) ? getFormRenins()
                : findInsuranceID(insurances, [INGOS], getFieldValue(insuranceCompany)) ? getFormIngos()
                  : findInsuranceID(insurances, [VSK], getFieldValue(insuranceCompany)) ? getFormVSK()
                    : findInsuranceID(insurances, [RGS], getFieldValue(insuranceCompany)) ? getFormRGS()
                      : getFieldValue(insuranceCompany) === SEVA ? getFormSeva()
                        : ""}

            {/* Поиск/Очистить */}
            <Form.Item>
              <Button
                className="hyd-btn-small-3"
                style={{marginRight: 16}}
                type="primary"
                disabled={
                  isProlongDisabledButtons || hasErrors(getFieldsError()) ? true :
                    documentsPolicy && activeProlongationInsurances.includes(documentsPolicy.key) ? true :
                      findInsuranceID(insurances, [ALFA], getFieldValue(insuranceCompany)) ? isAlfaBtnDisabled() :
                        findInsuranceID(insurances, [INGOS], getFieldValue(insuranceCompany)) && !getFieldValue(isProlongationSEVA) ? isIngosBtnDisabled() :
                          findInsuranceID(insurances, [VSK], getFieldValue(insuranceCompany)) && !getFieldValue(isProlongationSEVA) ? isVskBtnDisabled() :
                            findInsuranceID(insurances, [RGS], getFieldValue(insuranceCompany)) ? isBtnDisabledRGS() :
                              findInsuranceID(insurances, [SEVA], getFieldValue(insuranceCompany)) ? isIngosSEVABtnDisabled() :
                                getFieldValue(isProlongationSEVA) ? isIngosSEVABtnDisabled() : false
                }

                onClick={() => {
                  if (findInsuranceID(insurances, [ALFA], getFieldValue(insuranceCompany))) {
                    if (getFieldValue(isAllCompaniesCalculation)) {
                      getAlfaAgreementData({
                        transport_insurer: getFieldValue(transportInsurer),
                        policy_series: getFieldValue(prevSeries),
                        policy_number: getFieldValue(prevNumber),
                        ...(getFieldValue(prolongationBirthDate) && {policy_owner_birth_date: getFieldValue(prolongationBirthDate)}),
                        ...(getFieldValue(prolongationINN) && {inn: getFieldValue(prolongationINN)}),
                      })
                    } else {
                      getAlfaProlongation({
                        transport_insurer: getFieldValue(transportInsurer),
                        policy_series: getFieldValue(prevSeries),
                        policy_number: getFieldValue(prevNumber),
                        ...(getFieldValue(prolongationBirthDate) && {policy_owner_birth_date: getFieldValue(prolongationBirthDate)}),
                        ...(getFieldValue(prolongationINN) && {inn: getFieldValue(prolongationINN)}),
                        previous_policy_insurance_company: getFieldValue(insuranceCompany),
                        draft_id: draftId,
                      })
                    }
                  } else if (findInsuranceID(insurances, [INGOS], getFieldValue(insuranceCompany))) {
                    if (getFieldValue(isAllCompaniesCalculation)) {
                      getIngosAgreementData({
                        is_prolongation_seva: false,
                        transport_insurer: getFieldValue(transportInsurer),
                        policy_series: getFieldValue(prevSeries),
                        policy_number: getFieldValue(prevNumber),
                        vin: getFieldValue(vin),
                        body_number: getFieldValue(bodyNumber),
                        chassis_number: getFieldValue(chassisNumber),
                        last_name: getFieldValue(prolongationLastName),
                        first_name: getFieldValue(prolongationFirstName),
                        middle_name: getFieldValue(prolongationMiddleName),
                        inn: getFieldValue(prolongationINN),
                      })
                    } else {
                      getIngosProlongation({
                        is_prolongation_seva: !!getFieldValue(isProlongationSEVA),
                        transport_insurer: getFieldValue(transportInsurer),
                        policy_series: getFieldValue(prevSeries),
                        policy_number: getFieldValue(prevNumber),
                        vin: getFieldValue(vin),
                        body_number: getFieldValue(bodyNumber),
                        chassis_number: getFieldValue(chassisNumber),
                        last_name: getFieldValue(prolongationLastName),
                        first_name: getFieldValue(prolongationFirstName),
                        middle_name: getFieldValue(prolongationMiddleName),
                        inn: getFieldValue(prolongationINN),
                        previous_policy_insurance_company: getFieldValue(insuranceCompany),
                        draft_id: draftId,
                      })
                    }
                  } else if (findInsuranceID(insurances, [RENINS], getFieldValue(insuranceCompany))) {
                    if (getFieldValue(isAllCompaniesCalculation)) {
                      getRenessansAgreementData({
                        is_prolongation_seva: false,
                        policy_series: getFieldValue(prevSeries),
                        policy_number: getFieldValue(prevNumber),
                      })
                    } else {
                      getRenessansProlongation({
                        is_prolongation_seva: !!getFieldValue(isProlongationSEVA),
                        policy_series: getFieldValue(prevSeries),
                        policy_number: getFieldValue(prevNumber),
                        previous_policy_insurance_company: getFieldValue(insuranceCompany),
                        draft_id: draftId,
                      })
                    }
                  } else if (findInsuranceID(insurances, [VSK], getFieldValue(insuranceCompany))) {
                    if (getFieldValue(isAllCompaniesCalculation)) {
                      getVskAgreementData({
                        transport_insurer: getFieldValue(transportInsurer),
                        policy_series: getFieldValue(prevSeries),
                        policy_number: getFieldValue(prevNumber),
                        doc_series: getFieldValue(prolongationDocSeries),
                        doc_number: getFieldValue(prolongationDocNumber),
                        last_name: getFieldValue(prolongationLastName),
                        first_name: getFieldValue(prolongationFirstName),
                        middle_name: getFieldValue(prolongationMiddleName),
                      })
                    } else {
                      getVskProlongation({
                        is_prolongation_seva: !!getFieldValue(isProlongationSEVA),
                        policy_series: getFieldValue(prevSeries),
                        policy_number: getFieldValue(prevNumber),
                        doc_series: getFieldValue(prolongationDocSeries),
                        doc_number: getFieldValue(prolongationDocNumber),
                        last_name: getFieldValue(prolongationLastName),
                        first_name: getFieldValue(prolongationFirstName),
                        middle_name: getFieldValue(prolongationMiddleName),
                        draft_id: draftId,
                      })
                    }
                  } else if (findInsuranceID(insurances, [RGS], getFieldValue(insuranceCompany))) {
                    if (getFieldValue(isAllCompaniesCalculation)) {
                      getAgreementDataRGS({
                        transport_insurer: getFieldValue(transportInsurer),
                        policy_series: getFieldValue(prevSeries),
                        policy_number: getFieldValue(prevNumber),
                        doc_series: getFieldValue(prolongationDocSeries),
                        doc_number: getFieldValue(prolongationDocNumber),
                        ...(getFieldValue(prolongationBirthDate) && {policy_owner_birth_date: getFieldValue(prolongationBirthDate)}),
                        last_name: getFieldValue(prolongationLastName),
                        first_name: getFieldValue(prolongationFirstName),
                        middle_name: getFieldValue(prolongationMiddleName),
                      })
                    } else {
                      getProlongationRGS({
                        is_prolongation_seva: !!getFieldValue(isProlongationSEVA),
                        policy_series: getFieldValue(prevSeries),
                        policy_number: getFieldValue(prevNumber),
                        doc_series: getFieldValue(prolongationDocSeries),
                        doc_number: getFieldValue(prolongationDocNumber),
                        ...(getFieldValue(prolongationBirthDate) && {policy_owner_birth_date: getFieldValue(prolongationBirthDate)}),
                        last_name: getFieldValue(prolongationLastName),
                        first_name: getFieldValue(prolongationFirstName),
                        middle_name: getFieldValue(prolongationMiddleName),
                        draft_id: draftId,
                      })
                    }
                  } else if (getFieldValue(insuranceCompany) === SEVA) {
                    getSevaProlongation({
                      policy_series: getFieldValue(prevSeries),
                      policy_number: getFieldValue(prevNumber),
                      draft_id: draftId,
                    })
                  }
                }
                }
              >
                Продлить
              </Button>

              <Button
                className="hyd-btn-small-grey"
                type="primary"
                htmlType="submit"
                disabled={isProlongDisabledButtons
                  || (documentsPolicy && activeProlongationInsurances.includes(documentsPolicy.key))
                }
                onClick={() => {
                  toggleTypeTransportInsurer(1)
                  clearDataProlongation(2)
                  this.clearAllFields()
                }}
              >
                Очистить форму
              </Button>
            </Form.Item>

          </Form>}

          {isProlongCalcSuccess && dataFormResult && prolongInfo(dataFormResult)}

          {policyCreated ?
            <>
              <ProlongationIssueDisabled
                value={calcResult.find(i => ((i.key === documentsPolicy.key) && (i.type === documentsPolicy.product_type)))}
                userInfo={userInfo}
              />
              <IssuingProlongationPolicy
                userInfo={userInfo}
                documentsNSS={documentsNSS}
                getPaymentStatus={getPaymentStatus}
                configAdmin={configAdmin}
                documentsPolicy={documentsPolicy}
                doPaymentNSS={doPaymentNSS}
                payment_status={payment_status}
                alfa_docs={alfa_docs}
                ingostrah_docs={ingostrah_docs}
                renessans_docs={renessans_docs}
                insuranceDocuments={insuranceDocuments}
              />
            </> : calcResults()}

        </div>
        <ModalError type={1}/>
        <ModalOsago/>
      </>
    );
  }
}

const mapStateToProps = state => ({
  dataForm: dataFormSelector(state),
  userInfo: userInfoSelector(state),
  isProlongDisabledButtons: isProlongDisabledButtonsSelector(state),
  isProlongDisabledFields: isProlongDisabledFieldsSelector(state),
  isProlongCalcSuccess: isProlongCalcSuccessSelector(state),
  calculationInProgress: calculationInProgressSelector(state),
  calcResult: calcResultSelector(state),
  dataFormResult: dataFormResultSelector(state),
  typeTransportInsurer: typeTransportInsurerSelector(state),
  policyAdressArr: policyAdressArrSelector(state),
  isRequest: isRequestSelector(state),
  errors: errorsSelector(state),
  ingosWithoutVIN: ingosVinSelector(state),
  typeCarIdentityIngos: typeCarIdentityIngosSelector(state),
  alfa_docs: alfaDocsSelector(state),
  renessans_docs: renessansDocsSelector(state),
  ingostrah_docs: ingosDocsSelector(state),
  insuranceDocuments: insuranceDocumentsSelector(state),
  configAdmin: configAdminSelector(state),
  documentsPolicy: documentsPolicySelector(state),
  payment_status: paymentStatusSelector(state),
  policyCreated: policyCreatedSelector(state),
  paymentDocs: paymentDocsSelector(state),
  documentsNSS: documentsNSSSelector(state),
  insurances: insurancesSelector(state),
  draftId: draftIdSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setDataForm,
      toggleOsagoModal,
      toggleTypeTransportInsurer,
      toggleSelectedAddress,
      getKladrPolicyCarOwner,
      getAlfaProlongation,
      getAlfaAgreementData,
      getVskProlongation,
      getVskAgreementData,
      getProlongationRGS,
      getAgreementDataRGS,
      getIngosAgreementData,
      getIngosProlongation,
      getRenessansProlongation,
      getRenessansAgreementData,
      getSevaProlongation,
      clearDataProlongation,
      goToNextStep,
      toggleIngosVIN,
      toggleIngosTypeCarIdentity,
      createPolicy,
      doPaymentNSS,
      getConfig,
      getPaymentStatus,
      getInsurances,
      getNotifications,
      checkEarnedMoneyAmount,
    },
    dispatch
  );


const WrappedProlongationForm = Form.create({
  name: 'prolongation',
  mapPropsToFields(props) {
    const {dataForm} = props;
    const transformed = transform({dataForm});
    return transformed.dataForm;
  },
  onFieldsChange(props, changedFields) {

    props.setDataForm(changedFields, 'prolongationOSAGO');
  }
})(Prolongation);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedProlongationForm);

import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
  Row,
  Form,
  Button,
  Popover,
} from "antd";
import ModalError from "../../components/ModalError";
import {
  getPaymentStatusAccidentSport,
  createPolicyAccidentSport,
  sendDataAccidentSportForm,
  setDataAccidentsSportForm,
} from "../../actions/accidentsSportActions";
import {
  isRequestSelector,
  currentStepSelector,
  documentsSelector,
  paymentDocsSelector,
  paymentStatusSelector,
  documentsPolicySelector,
  errorsAccidentsSportSelector,
  calculationInProgressSelector,
  accidentsSportDataFormSelector,
  calcResultSelector,
} from "../../selectors/accidentsSportSelectors";
import {userInfoSelector} from "../../selectors/loginSelectors";
import {downloadDocsButton, get_doc, transform} from "../../utils";
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import {ReactComponent as ErrorMarkIcon} from "../../assets/Icons/ErrorMark.svg";
import FirstStepDisabled from "../../components/AccidentsSportDisabledSteps/FirstStepDisabled";
import SecondStepDisabled from "../../components/AccidentsSportDisabledSteps/SecondStedDisabled";


class ThirdStepAccidentsSport extends Component {
  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  state = {}

  componentDidMount() {
    this.headerRef.current.scrollIntoView()
    // this.props.getConfig();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {isRequest, paymentDocs} = this.props;

    if (paymentDocs !== prevProps.paymentDocs && !isRequest && paymentDocs) { //открытие Документов заявление, копия полиса
      let file = new Blob([paymentDocs], {type: 'application/pdf'});
      let fileURL = URL.createObjectURL(file);
      fileURL && window.open(fileURL);
    }
  }

  render() {
    const {
      paymentStatus,
      documentsPolicy,
      getPaymentStatusAccidentSport,
      userInfo,
      calcResult,
    } = this.props;

    return (
      <div style={{width: 911}}>
        <FirstStepDisabled/>
        <SecondStepDisabled
          value={calcResult && calcResult.find(i => (i.key === documentsPolicy.key))}
          docs={documentsPolicy}
          userInfo={userInfo}
        />
        <div className="step-title-active-small" ref={this.headerRef}>
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">3</span>
          </div>

          <span className="step-title-active-header">Оплата</span>
        </div>

        {this.props.documents &&
          <Row>
            {this.props.documents.map((item, index) => {
              return (item.name && (downloadDocsButton(get_doc, item, index)))
            })}
          </Row>}

        <Row>
          <div className={"hyd-osago-step6-payment"}>
            <div className={"hyd-osago-ins-result-head"}>
              Стоимость полиса
            </div>
            <div>
              {documentsPolicy && (
                <span className={"hyd-osago-ins-result-value hyd-osago-step6-value"}>
                {Number(documentsPolicy.price).toLocaleString("ru-RU")} ₽
              </span>
              )}
            </div>
          </div>

          {documentsPolicy && (
            <>
              {documentsPolicy.product_type === "accident" && (
                <>
                  {paymentStatus === "paid" ?
                    <Button className={"hyd-btn-small hyd-policy-paid"}>Полис оплачен</Button> :
                    <>
                      <div className={"hyd-accident-payment"}>
                        <Button
                          className={"hyd-btn-small"}
                          onClick={() => {
                            window.open(documentsPolicy["payment_url"])
                          }}
                          type="primary"
                        >
                          Оплата картой
                        </Button>
                      </div>
                      <div className={"hyd-accident-payment"}>
                        <Button
                          className={"hyd-btn-small"}
                          type="primary"
                          onClick={() => {
                            getPaymentStatusAccidentSport({
                              key: documentsPolicy.key,
                              policy_id: documentsPolicy.policyObjId,
                            })
                          }}
                        >
                          Проверить статус оплаты
                        </Button>
                      </div>
                    </>
                  }
                  {paymentStatus && paymentStatus !== "paid" &&
                      <div className={"hyd-not-paid hyd-not-paid-acc"}>
                        <span className={"hyd-not-paid-ico"}><ErrorMarkIcon/></span>
                        <span className={"hyd-not-paid-txt"}>Не оплачен</span>
                      </div>
                    }
                </>
              )}

              {documentsPolicy &&
                documentsPolicy.payment_url &&
                documentsPolicy.payment_url.errors &&
                documentsPolicy.payment_url.errors.error && (
                  <div className={"hyd-accident-payment"}>
                    <Popover
                      content={documentsPolicy.payment_url.errors.error}
                      title="Ошибка"
                      trigger="hover"
                    >
                      <Button
                        className={"hyd-btn-small"}
                        type="primary"
                        disabled={true}
                      >
                        Оплата картой
                      </Button>
                    </Popover>
                  </div>
                )}
            </>
          )}
        </Row>

        <ModalError type={4}/>
      </div>
    );
  }
}

const WrappedThirdStepAccidentsSportForm = Form.create({
  name: "step-3",
  mapPropsToFields(props) {
    const {accidentsSportDataForm} = props;
    const transformed = transform({accidentsSportDataForm});

    return transformed.accidentsSportDataForm;
  },
  onFieldsChange(props, changedFields) {
    props.setDataAccidentsSportForm(changedFields);
  },
})(ThirdStepAccidentsSport);

const mapStateToProps = (state) => ({
  userInfo: userInfoSelector(state),
  isRequest: isRequestSelector(state),
  documents: documentsSelector(state),
  currentStep: currentStepSelector(state),
  paymentDocs: paymentDocsSelector(state),
  errors: errorsAccidentsSportSelector(state),
  paymentStatus: paymentStatusSelector(state),
  documentsPolicy: documentsPolicySelector(state),
  calculationInProgress: calculationInProgressSelector(state),
  accidentsSportDataForm: accidentsSportDataFormSelector(state),
  calcResult: calcResultSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPaymentStatusAccidentSport,
      setDataAccidentsSportForm,
      sendDataAccidentSportForm,
      createPolicyAccidentSport,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedThirdStepAccidentsSportForm);

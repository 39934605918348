import React, {Component} from "react";
import {Form, Input, Button, Tabs} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {checkValueBirthDate, formItemLayout, hasErrors, nameRules} from "../../utils";

import "./styles/index.scss";
import "../../fonts/open-sans.css"
import Cleave from "cleave.js/react";
import ModalError from "../../components/ModalError";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import {driverKbmSelector} from "../../selectors/osagoSelectors";
import {getKbm, getKbmJuristic, clearModalDriverKbm,} from "../../actions/osagoActions";
import {clearForm} from "../../actions/accidentsSportActions";
import {checkEarnedMoneyAmount, getNotifications} from "../../actions/mainActions";

const {TabPane} = Tabs;

class Kbm extends Component {
  state = {
    index: 1,
    indexJur: 10,
  };

  componentDidMount() {
    document.title = "КБМ"

    const {getNotifications, checkEarnedMoneyAmount} = this.props
    getNotifications()
    checkEarnedMoneyAmount()
  }

  componentWillUnmount() {
    this.props.clearForm(1)
  }

  onDriverCustomChange = (event) => {
    let resultValue = event.target.value.split(" ")
    if (resultValue.length === 1) {
      resultValue = event.target.value.split(" ")
    }
    if (resultValue.length === 2) {
      let series = resultValue[0]
      let number = resultValue[1]
      this.props.form.setFieldsValue({
        driver_doc_serial: series,
        driver_doc_number: number
      })
    } else {
      this.props.form.setFieldsValue({
        driver_doc_serial: "",
        driver_doc_number: event.target.rawValue
      })
    }
  }

  render() {
    const {
      getKbm,
      getKbmJuristic,
      driverKbm,
      clearModalDriverKbm,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      getFieldValue,
    } = this.props.form;

    const driverLastName = "driver_last_name";
    const driverLastNameError = getFieldError(driverLastName);

    const driverFirstName = "driver_first_name";
    const driverFirstNameError = getFieldError(driverFirstName);

    const driverMiddleName = "driver_middle_name";
    const driverMiddleNameError = getFieldError(driverMiddleName);

    const driverBirth = "driver_birth";
    const driverBirthError = getFieldError(driverBirth);

    const driverDocSerial = "driver_doc_serial";
    const driverDocSerialError = getFieldError(driverDocSerial);

    const driverDocNumber = "driver_doc_number";
    const driverDocNumberError = getFieldError(driverDocNumber);

    const driverDocSeriesNumber = "driver_doc_series_number";
    const driverDocSeriesNumberError = getFieldError(driverDocSeriesNumber);

    const juristicInn = "juristic_inn";
    const juristicInnError = getFieldError(juristicInn);


    return (
      <>
        <div className="kbm-container">
          <span className="hyd-very-big-bold-text-small">Проверить КБМ</span>

          <Tabs
            className="hyd-tab"
            onChange={(k) => {
              clearModalDriverKbm()
              if (k % 2 === 0) {
                this.setState({
                  index: this.state.index + 2,
                })
              } else {
                this.setState({
                  indexJur: this.state.indexJur + 2,
                })
              }
            }}>
            <TabPane tab={<span className="hyd-tab-pane">Физическое лицо</span>} key={`${this.state.index}`}>

              <Form {...formItemLayout}>

                <div className="hyd-kbm-driver-name">
                  {/* Фамилия */}
                  <Form.Item
                    validateStatus={driverLastNameError ? "error" : ""}
                    help={driverLastNameError || ""}
                  >
                    <FloatingLabelInput
                      divClassName="hyd-kbm-input"
                      htmlFor={"kbm_" + driverLastName}
                      labelText={"Фамилия"}
                      divId={"float-label-small"}
                      child={getFieldDecorator(driverLastName, {
                        validateTrigger: ["onBlur", "onChange"],
                        rules: nameRules,
                      })(<div className="hyd-kbm-input">
                        <Input className="hyd-input-small hyd-input"/>
                      </div>)}
                    />
                  </Form.Item>

                  {/* Имя */}
                  <Form.Item
                    validateStatus={driverFirstNameError ? "error" : ""}
                    help={driverFirstNameError || ""}
                  >
                    <FloatingLabelInput
                      divClassName="hyd-kbm-input"
                      htmlFor={"kbm_" + driverFirstName}
                      labelText={"Имя"}
                      divId={"float-label-small"}
                      child={getFieldDecorator(driverFirstName, {
                        validateTrigger: ["onBlur", "onChange"],
                        rules: nameRules,
                      })(<div className="hyd-kbm-input">
                        <Input className="hyd-input-small hyd-input"/>
                      </div>)}
                    />
                  </Form.Item>

                  {/* Отчество */}
                  <Form.Item
                    validateStatus={driverMiddleNameError ? "error" : ""}
                    help={driverMiddleNameError || ""}
                  >
                    <FloatingLabelInput
                      divClassName="hyd-kbm-input"
                      htmlFor={"kbm_" + driverMiddleName}
                      labelText={"Отчество"}
                      divId={"float-label-small"}
                      child={getFieldDecorator(driverMiddleName, {
                        validateTrigger: ["onBlur", "onChange"],
                        rules: [
                          {
                            // только лат или только кириллица (допускается двойное имя с пробелом по центру или тире)
                            pattern: /^[А-Яа-яЁёa-zA-Z() -]+$/g,
                            message: " ",
                          },
                        ],
                      })(<div className="hyd-kbm-input">
                        <Input className="hyd-input-small hyd-input"/>
                      </div>)}
                    />
                  </Form.Item>
                </div>

                <div className="hyd-kbm-birthdate-docs">
                  {/* Дата рождения */}
                  <Form.Item
                    validateStatus={driverBirthError ? "error" : ""}
                    help={driverBirthError || ""}
                  >
                    <FloatingLabelInput
                      divClassName="hyd-kbm-input"
                      htmlFor={"kbm_" + driverBirth}
                      labelText={"Дата рождения"}
                      divId={"float-label-small"}
                      child={getFieldDecorator(driverBirth, {
                        validateTrigger: ["onBlur", "onChange"],
                        rules: [
                          {
                            required: !getFieldValue(driverBirth),
                            message: " "
                          },
                          {
                            validator: checkValueBirthDate,
                          },
                        ]
                      })(
                        <div className="hyd-kbm-input">
                          <Cleave
                            className="ant-input hyd-input-small"
                            key={JSON.stringify({
                              date: true,
                              delimiter: ".",
                              datePattern: ["d", "m", "Y"]
                            })}
                            options={{
                              date: true,
                              delimiter: ".",
                              datePattern: ["d", "m", "Y"]
                            }}
                          />
                        </div>
                      )}
                    />
                  </Form.Item>

                  {/* Серия */}
                  <Form.Item
                    validateStatus={driverDocSerialError ? "error" : ""}
                    help={driverDocSerialError || ""}
                    style={{display: "none"}}
                  >
                    <FloatingLabelInput
                      divClassName="hyd-kbm-input"
                      htmlFor={"kbm_" + driverDocSerial}
                      labelText={"Серия ВУ"}
                      child={getFieldDecorator(driverDocSerial, {
                        rules: [
                          {
                            required: true,
                            message: " "
                          },
                        ]
                      })(<div className="hyd-kbm-input">
                        <Input className="hyd-input-small"/>
                      </div>)}
                    />
                  </Form.Item>

                  {/* Номер */}
                  <Form.Item
                    validateStatus={driverDocNumberError ? "error" : ""}
                    help={driverDocNumberError || ""}
                    style={{display: "none"}}
                  >
                    <FloatingLabelInput
                      divClassName="hyd-kbm-input"
                      htmlFor={"kbm_" + driverDocNumber}
                      labelText={"Номер ВУ"}
                      child={getFieldDecorator(driverDocNumber, {
                        rules: [
                          {
                            required: true,
                            message: " "
                          },
                        ]
                      })(<div className="hyd-kbm-input">
                        <Input className="hyd-input-small"/>
                      </div>)}
                    />
                  </Form.Item>

                  {/* Серия и номер */}
                  <Form.Item
                    validateStatus={driverDocSeriesNumberError ? "error" : ""}
                    help={driverDocSeriesNumberError || ""}
                  >
                    <FloatingLabelInput
                      divClassName="hyd-kbm-input"
                      htmlFor={"kbm_" + driverDocSeriesNumber}
                      labelText={"Серия и номер ВУ"}
                      divId={"float-label-small"}
                      child={getFieldDecorator(driverDocSeriesNumber, {
                        validateTrigger: ["onBlur", "onChange"],
                        rules: [
                          {
                            required: true,
                            message: " "
                          },
                          {
                            pattern: /((^[0-9]{2}[АВЕКМНОРСТУХавекмнорстух]{2})|(^[0-9]{2}[ABEKMHOPCTYXabekmhopctyx]{2})|(^[0-9]{4})) [0-9]{6}$/g,
                            message: " ",
                          },
                        ],
                      })(
                        <Cleave
                          className="ant-input hyd-input-small hyd-kbm-input"
                          style={{width: "100%"}}
                          options={{
                            blocks: [4, 6],
                            delimiter: ' ',
                            numericOnly: false
                          }}
                          onChange={this.onDriverCustomChange}
                        />
                      )}
                    />
                  </Form.Item>
                </div>

                <div id="hyd-osago-kbm-block" className="hyd-emphasise-block-small hyd-kbm-result">
                  <Button
                    className="hyd-btn-small hyd-kbm-btn"
                    type="primary"
                    disabled={hasErrors(getFieldsError())}
                    onClick={() => {
                      getKbm({
                        last_name: getFieldValue(driverLastName),
                        first_name: getFieldValue(driverFirstName),
                        middle_name: getFieldValue(driverMiddleName),
                        birth_date: getFieldValue(driverBirth),
                        number: getFieldValue(driverDocNumber),
                        series: getFieldValue(driverDocSerial)
                      });
                    }}
                  >
                    Получить КБМ
                  </Button>
                  <div className="hyd-kbm-value-result">
                    <Input
                      className="hyd-input-small-emphasise"
                      placeholder={driverKbm ? driverKbm.driver_kbm : "КБМ"}
                      disabled
                    />
                  </div>
                </div>

              </Form>
            </TabPane>

            <TabPane tab={<span className="hyd-tab-pane">Юридическое лицо</span>} key={`${this.state.indexJur}`}>
              <Form {...formItemLayout}>
                <Form.Item
                  validateStatus={juristicInnError ? "error" : ""}
                  help={juristicInnError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-kbm-input"
                    htmlFor={"kbm_" + juristicInn}
                    labelText={"ИНН"}
                    divId={"float-label-small"}
                    child={getFieldDecorator(juristicInn, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        {
                          required: true,
                          message: " "
                        },
                        {
                          pattern: /^([0-9])+$/g,
                          message: " "
                        }
                      ]
                    })(<div className="hyd-kbm-input">
                      <Input className="hyd-input hyd-input-small" maxLength={10}/>
                    </div>)}
                  />
                </Form.Item>

                <div id="hyd-osago-kbm-block" className="hyd-emphasise-block-small hyd-kbm-result">
                  <Button
                    className="hyd-btn-small hyd-kbm-btn"
                    type="primary"
                    // disabled={hasErrors(getFieldsError())}
                    disabled
                    onClick={() => {
                      getKbmJuristic({
                        inn: getFieldValue(juristicInn),
                      });
                    }}
                  >
                    Получить КБМ
                  </Button>
                  <div className="hyd-kbm-value-result">
                    <Input
                      className="hyd-input-small-emphasise"
                      placeholder={driverKbm ? driverKbm.driver_kbm : ""}
                      disabled
                    />
                  </div>
                </div>
              </Form>
            </TabPane>

          </Tabs>
        </div>
        <ModalError type={1}/>
      </>
    );
  }
}

const mapStateToProps = state => ({
  driverKbm: driverKbmSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getKbm,
      clearForm,
      getKbmJuristic,
      getNotifications,
      clearModalDriverKbm,
      checkEarnedMoneyAmount,
    },
    dispatch
  );


const WrappedKbmForm = Form.create({name: 'kbm'})(Kbm);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedKbmForm);

import React, {Component} from "react";
import {Form, Typography, Modal, Icon, Button} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {toggleErrorModal} from "../../actions/osagoActions";
import {signOut, refreshToken} from "../../actions/loginActions";
import {visibleErrorModalSelector, errorsSelector, calcResultSelector} from "../../selectors/osagoSelectors";
import {refreshSelector, errorsSelectorLogin, visibleErrorModalSelectorLogin} from "../../selectors/loginSelectors";
import {errorsProfileSelector, visibleErrorModalProfileSelector} from "../../selectors/profileSelectors";
import {
  errorsAccidentsSportSelector,
  visibleErrorModalAccidentsSportSelector
} from "../../selectors/accidentsSportSelectors";
import {errorsRegistrationSelector, visibleErrorModalRegistrationSelector} from "../../selectors/registrationSelectors";
import {ReactComponent as ExclamationBigIcon} from "../../assets/Icons/ExclamationBig.svg";
import "./styles/index.scss";
import {transform} from "../../utils";
import {errorsMortgageSelector, visibleErrorModalMortgageSelector} from "../../selectors/mortgageSelectors";
import {errorsCrossSelector, visibleErrorModalCrossSelector} from "../../selectors/crossSelectors";
import {errorsContractsSelector, visibleErrorModalContractsSelector} from "../../selectors/contractsSelectors";
import {errorsAgentsSelector, visibleErrorModalAgentsSelector} from "../../selectors/agentsSelectors";
import {errorsCascoSelector, visibleErrorModalCascoSelector} from "../../selectors/cascoSelectors";
import {
  errorsRenewalPoliciesSelector, visibleErrorModalRenewalPoliciesSelector,
} from "../../selectors/renewalSelectors";

const {Paragraph, Text} = Typography;


class ModalError extends Component {

  showErrorModal = () => {
    this.props.toggleErrorModal(true);
  };

  handleErrorModalOk = () => {
    this.props.toggleErrorModal(false);
  };

  render() {
    const {
      type,
      errorsLogin,
      errorsOsago,
      errorsCross,
      errorsProfile,
      errorsCasco,
      errorsAgents,
      errorsMortgage,
      errorsContracts,
      visibleErrorModal,
      errorsRegistration,
      errorsAccidentsSport,
      errorsRenewalPolicies,
      visibleErrorModalLogin,
      visibleErrorModalCross,
      visibleErrorModalProfile,
      visibleErrorModalCasco,
      visibleErrorModalAgents,
      visibleErrorModalMortgage,
      visibleErrorModalContracts,
      visibleErrorModalRegistration,
      visibleErrorModalAccidentsSport,
      visibleErrorModalRenewalPolicies,
    } = this.props;

    let errors = null;

    if (errorsOsago && type === 1) {
      errors = errorsOsago;
    } else if (errorsLogin && type === 0) {
      errors = errorsLogin;
    } else if (errorsAgents && type === 2) {
      errors = errorsAgents;
    } else if (errorsCasco && type === 3) {
      errors = errorsCasco;
    } else if (errorsAccidentsSport && type === 4) {
      errors = errorsAccidentsSport;
    } else if (errorsProfile && type === 5) {
      errors = errorsProfile;
    } else if (errorsRegistration && type === 6) {
      errors = errorsRegistration;
    } else if (errorsMortgage && type === 7) {
      errors = errorsMortgage;
    } else if (errorsCross && type === 8) {
      errors = errorsCross;
    } else if (errorsContracts && type === 9) {
      errors = errorsContracts;
    } else if (errorsRenewalPolicies && type === 10) {
      errors = errorsRenewalPolicies;
    }
    return (

      <>
        <Modal
          visible={
            visibleErrorModal ||
            visibleErrorModalLogin ||
            visibleErrorModalAccidentsSport ||
            visibleErrorModalProfile ||
            visibleErrorModalRegistration ||
            visibleErrorModalMortgage ||
            visibleErrorModalCross ||
            visibleErrorModalCasco ||
            visibleErrorModalAgents ||
            visibleErrorModalRenewalPolicies ||
            visibleErrorModalContracts
          }
          cancelButtonProps={{style: {display: 'none'}}}
          okButtonProps={{style: {display: 'none'}}}
          footer={false}
          closable={false}
          width={415}
        >
          <div className="hyd-err-cont">

            <div style={{marginTop: 20}}>
              <ExclamationBigIcon/>
            </div>

            <Paragraph style={{marginTop: 16}}>
              <span className="hyd-err-head">Внимание</span>
            </Paragraph>

            {errors && errors.errors && typeof errors.errors === 'object' && !errors.errors.error && Object.entries(errors.errors).map(([key, value]) => (
              <Paragraph key={key}>
                <Icon style={{color: 'red', marginRight: '5px'}} type="close-circle"/>
                <Text className="hyd-txt-clr">{key + ': ' + JSON.stringify(value).replace(/[{\[\]}"]/g, '')}</Text>
              </Paragraph>
            ))}
            {errors && errors.error && Array.isArray(errors.error) && errors.error.map((value, index) => (
              <Paragraph key={value + index}>
                <Icon style={{color: 'red', marginRight: '5px'}} type="close-circle"/>
                <Text className="hyd-txt-clr">{value}</Text>
              </Paragraph>
            ))}
            {errors && errors.errors && typeof errors.errors === 'object' && errors.errors.error && Array.isArray(errors.errors.error) && errors.errors.error.map((value, index) => (
              <Paragraph key={value + index}>
                <Icon style={{color: 'red', marginRight: '5px'}} type="close-circle"/>
                <Text className="hyd-txt-clr">{value}</Text>
              </Paragraph>
            ))}
            {errors && errors.errors && typeof errors.errors === 'string' &&
            <Paragraph>
              <Icon style={{color: 'red', marginRight: '5px'}} type="close-circle"/>
              <Text className="hyd-txt-clr">{errors.errors}</Text>
            </Paragraph>}
            {errors && errors.detail && typeof errors.detail === 'string' &&
            <Paragraph>
              <Icon style={{color: 'red', marginRight: '5px'}} type="close-circle"/>
              <Text className="hyd-txt-clr">{errors.detail}</Text>
            </Paragraph>}

            <Button
              className="hyd-btn-small"
              style={{marginTop: 8, marginBottom: 16}}
              onClick={() => {
                this.handleErrorModalOk()
              }}
            >
              ОК
            </Button>

          </div>
        </Modal>
      </>
    )
  }
}

const WrappedModalErrorForm = Form.create({
  name: "modal-error",
  mapPropsToFields(props) {
    const {dataForm} = props;
    const transformed = transform({dataForm});

    return transformed.dataForm;
  },
  onFieldsChange(props, changedFields) {
    props.setDataForm(changedFields, "");
  },
})(ModalError);

const mapStateToProps = state => ({
  refresh: refreshSelector(state),
  //  access: accessSelector(state),
  errorsOsago: errorsSelector(state),
  calcResult: calcResultSelector(state),
  errorsLogin: errorsSelectorLogin(state),
  errorsCross: errorsCrossSelector(state),
  errorsCasco: errorsCascoSelector(state),
  errorsAgents: errorsAgentsSelector(state),
  errorsProfile: errorsProfileSelector(state),
  errorsMortgage: errorsMortgageSelector(state),
  errorsContracts: errorsContractsSelector(state),
  visibleErrorModal: visibleErrorModalSelector(state),
  errorsRegistration: errorsRegistrationSelector(state),
  errorsAccidentsSport: errorsAccidentsSportSelector(state),
  errorsRenewalPolicies: errorsRenewalPoliciesSelector(state),
  visibleErrorModalLogin: visibleErrorModalSelectorLogin(state),
  visibleErrorModalCross: visibleErrorModalCrossSelector(state),
  visibleErrorModalProfile: visibleErrorModalProfileSelector(state),
  visibleErrorModalCasco: visibleErrorModalCascoSelector(state),
  visibleErrorModalAgents: visibleErrorModalAgentsSelector(state),
  visibleErrorModalMortgage: visibleErrorModalMortgageSelector(state),
  visibleErrorModalContracts: visibleErrorModalContractsSelector(state),
  visibleErrorModalRegistration: visibleErrorModalRegistrationSelector(state),
  visibleErrorModalAccidentsSport: visibleErrorModalAccidentsSportSelector(state),
  visibleErrorModalRenewalPolicies: visibleErrorModalRenewalPoliciesSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signOut,
      refreshToken,
      toggleErrorModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedModalErrorForm);

import {takeLatest, select} from 'redux-saga/effects';
import * as api from './api';
import * as types from '../store/actionTypes';
import history from "../modules/history.js";
import * as keys from "../routers/keys";

function* getMortgageDirectories() {
  yield api.get("get_professions/", null, types.GET_PROFESSIONS_MORTGAGE)
  yield api.get("get_banks_list/", null, types.GET_BANKS)
  yield api.get("get_employment_statuses_list/", null, types.GET_EMPLOYMENT_STATUSES)
  yield api.get("get_health_abnormalities_list/", null, types.GET_HEALTH_ABNORMALITIES)
}

export function* getExportDocumentSaga({payload}) {
  yield api.get(`/api/v1/export_to_xlsx/`, payload, types.GET_EXPORT_DOCUMENT, true);
}

export function* getContractsListSaga({payload}) {
  yield api.get(`export_to_json/`, payload, types.GET_CONTRACTS_LIST);
}

export function* resumeDraftSaga({payload}) {
  yield api.get(`get_draft/`, {draft_id: payload}, types.RESUME_DRAFT);
  history.push(keys.OSAGO);
}

export function* resumeDraftMortgageSaga({payload}) {
  yield api.get(`get_mortgage_obj/`, {policy_obj_id: payload, is_resume: true}, types.RESUME_DRAFT_MORTGAGE)
  yield getMortgageDirectories()
  history.push(keys.MORTGAGE)
}

export function* resumeDraftCrossSaga({payload}) {
  yield api.get(`get_cross_obj/`, {policy_obj_id: payload, is_resume: true}, types.RESUME_DRAFT_CROSS);
  history.push(keys.CROSS);
}

export function* copyPolicySaga({payload}) {
  yield api.get(`get_draft/`, {draft_id: payload}, types.COPY_POLICY);
  history.push(keys.OSAGO);
}

export function* resumePolicySaga({payload}) {
  yield api.get(`get_policy/`, {policy_id: payload}, types.RESUME_POLICY);
  let errors = yield select((state) => state.osagoReducer.errors)
  if (!errors) {
    history.push(keys.OSAGO);
  }
}

export function* resumePolicyProlongationSaga({payload}) {
  yield api.get(`get_policy/`, {policy_id: payload}, types.RESUME_POLICY_PROLONGATION);
  history.push(keys.PROLONGATION);
}

export function* resumeDraftProlongationSaga({payload}) {
  yield api.get(`get_draft/`, {draft_id: payload}, types.RESUME_DRAFT_PROLONGATION);
  history.push(keys.PROLONGATION);
}

export function* resumePolicyMortgageSaga({payload}) {
  yield api.get(`get_mortgage_obj/`, {policy_obj_id: payload, is_resume: true}, types.RESUME_POLICY_MORTGAGE)
  yield getMortgageDirectories()
  history.push(keys.MORTGAGE)
}

export function* resumePolicyCrossSaga({payload}) {
  yield api.get(`get_cross_obj/`, {policy_obj_id: payload, is_resume: true}, types.RESUME_POLICY_CROSS);
  history.push(keys.CROSS);
}

export function* resumeAccidentsSportSaga({payload}) {
  yield api.get(`get_accidents_sport_obj/`, {policy_obj_id: payload}, types.RESUME_POLICY_ACCIDENTS_SPORT);
  history.push(keys.ACCIDENTS_SPORT);
}

export function* copyPolicyAccidentsSportSaga({payload}) {
  yield api.get(`get_accidents_sport_obj/`, {policy_obj_id: payload}, types.COPY_POLICY_ACCIDENTS_SPORT);
  history.push(keys.ACCIDENTS_SPORT);
}

export function* payPolicyAccidentsSportSaga({payload}) {
  yield api.get(`get_accidents_sport_obj/`, {policy_obj_id: payload}, types.PAY_POLICY_ACCIDENTS_SPORT);
  yield api.get("get_sport_kind/", null, types.GET_KINDS_OF_SPORTS)
  history.push(keys.ACCIDENTS_SPORT);
}

export function* copyPolicyMortgageSaga({payload}) {
  yield api.get(`get_mortgage_obj/`, {policy_obj_id: payload}, types.COPY_POLICY_MORTGAGE)
  yield getMortgageDirectories()
  history.push(keys.MORTGAGE)
}

export function* copyPolicyCrossSaga({payload}) {
  yield api.get(`get_cross_obj/`, {policy_obj_id: payload}, types.COPY_POLICY_CROSS);
  history.push(keys.CROSS);
}

export function* getPolicyDocsSaga({payload}) {
  yield api.get(`get_policy_docs/`, payload, types.GET_POLICY_DOCS);
}

export function* checkPaymentStatusCommonSaga({payload}) {
  yield api.post(`payment_status/`,null, payload, types.GET_PAYMENT_STATUS_COMMON);
}

export default function* () {
  yield takeLatest(types.GET_EXPORT_DOCUMENT, getExportDocumentSaga);
  yield takeLatest(types.GET_CONTRACTS_LIST, getContractsListSaga);
  yield takeLatest(types.GET_POLICY_DOCS, getPolicyDocsSaga);
  yield takeLatest(types.GET_PAYMENT_STATUS_COMMON, checkPaymentStatusCommonSaga);
  yield takeLatest(types.RESUME_DRAFT, resumeDraftSaga);
  yield takeLatest(types.RESUME_POLICY, resumePolicySaga);
  yield takeLatest(types.RESUME_POLICY_PROLONGATION, resumePolicyProlongationSaga);
  yield takeLatest(types.RESUME_DRAFT_PROLONGATION, resumeDraftProlongationSaga);
  yield takeLatest(types.COPY_POLICY, copyPolicySaga);
  yield takeLatest(types.RESUME_POLICY_ACCIDENTS_SPORT, resumeAccidentsSportSaga);
  yield takeLatest(types.COPY_POLICY_ACCIDENTS_SPORT, copyPolicyAccidentsSportSaga);
  yield takeLatest(types.PAY_POLICY_ACCIDENTS_SPORT, payPolicyAccidentsSportSaga);
  yield takeLatest(types.COPY_POLICY_MORTGAGE, copyPolicyMortgageSaga);
  yield takeLatest(types.RESUME_POLICY_MORTGAGE, resumePolicyMortgageSaga);
  yield takeLatest(types.RESUME_DRAFT_MORTGAGE, resumeDraftMortgageSaga);
  yield takeLatest(types.COPY_POLICY_CROSS, copyPolicyCrossSaga);
  yield takeLatest(types.RESUME_POLICY_CROSS, resumePolicyCrossSaga);
  yield takeLatest(types.RESUME_DRAFT_CROSS, resumeDraftCrossSaga);
}

import React, {Component} from "react"
import {Form, Button} from "antd"
import {connect} from "react-redux"
import {bindActionCreators} from "redux"

import "./styles/index.scss"
import ModalError from "../../components/ModalError"

import {ReactComponent as DeleteIcon} from '../../assets/Icons/Delete.svg'
import {ReactComponent as PlusSignIcon} from '../../assets/Icons/PlusSign.svg'
import {ReactComponent as PlusSignSmallIcon} from '../../assets/Icons/PlusSignSmall.svg'

import ModalTools from "../../components/Form/ModalTools"
import {
  toggleToolsModal,
  dellToolFromFavorites,
  getTools,
  getActiveBanners,
  getNotifications,
  checkEarnedMoneyAmount
} from "../../actions/mainActions"
import {favoritesToolsSelector, toolsListSelector, activeBannersSelector} from "../../selectors/mainSelectors"
import ModalNotifications from "../../components/Form/ModalNotifications";


class Main extends Component {
  componentDidMount() {
    document.title = "SEVA"

    const {getActiveBanners, getNotifications, checkEarnedMoneyAmount} = this.props
    getActiveBanners()
    getNotifications()
    checkEarnedMoneyAmount()
  }

  deleteTool = (tool) => {
    const {
      dellToolFromFavorites,
      getTools,
    } = this.props
    dellToolFromFavorites(tool)
    getTools()
  }

  getBannerByOrder = (banners: Array, bannerOrder: BigInteger) => {
    return banners.find(banner => banner[`banner_order_${bannerOrder}`] === true)
  }

  showBanners = (activeBanners) => {
    let firstBanner = this.getBannerByOrder(activeBanners, 1)
    let secondBanner = this.getBannerByOrder(activeBanners, 2)

    return (
      <div className="hyd-news-container">
        {firstBanner && <img
          src={firstBanner["banner_file"]}
          alt={firstBanner["name"]}
          style={{marginRight: 28}}
        />}
        {secondBanner && <img
          src={secondBanner["banner_file"]}
          alt={secondBanner["name"]}
        />}
      </div>
    )
  }

  render() {
    const {
      toggleToolsModal,
      favoritesTools,
      toolsList,
      activeBanners,
    } = this.props

    return (
      <>
        <div className="main-container">
          <span className="hyd-very-big-bold-text-small">Инструменты</span>

          <div className="hyd-tools-container">

            <div className="hyd-tools-buttons-container">
              {favoritesTools &&
              Array.isArray(favoritesTools) &&
              toolsList.filter(tool => favoritesTools.includes(tool.id))
                .map(item => {
                  return (
                    item.available &&
                    <>
                      <div className="hyd-tool-btn-m">
                        <Button
                          className="hyd-tool-btn"
                          onClick={() => {
                            const {history} = this.props
                            history.replace(item.url)
                          }}
                        >
                          <div className="hyd-tool-btn-data">
                            <div className="hyd-tool-category-txt">{item.category}</div>
                            <div className="hyd-tool-name-txt">{item.name}</div>
                            {!item.mandatory &&
                            <div className="hyd-del-ico-div"
                                 onClick={(e) => {
                                   e.stopPropagation()
                                   this.deleteTool(item.id)
                                 }}
                            >
                              <DeleteIcon className="hyd-del-ico"/>
                            </div>
                            }
                          </div>
                        </Button>
                      </div>
                    </>
                  )
                })}

              {favoritesTools &&
              (toolsList.filter(item => favoritesTools.includes(item.id)).filter(item => item.available).length) % 4 !== 0 &&
              <div>
                <Button
                  className="hyd-add-tool-btn"
                  onClick={() => {
                    toggleToolsModal(true)
                  }}
                >
                  <PlusSignIcon className="hyd-plus-ico"/>
                </Button>
              </div>
              }
            </div>

            {favoritesTools &&
            (toolsList.filter(item => favoritesTools.includes(item.id)).filter(item => item.available).length) % 4 === 0 &&
            <div>
              <Button
                className="hyd-add-tool-btn hyd-add-tool-btn-wide"
                onClick={() => {
                  toggleToolsModal(true)
                }}
              >
                <PlusSignSmallIcon className="hyd-small-plus-ico"/>
              </Button>
            </div>
            }

          </div>

          {activeBanners && this.showBanners(activeBanners)}

          <div className="hyd-stat-container">
            <span className="hyd-very-big-bold-text-small">Ваша статистика</span>

            <div>
              <br/><br/>Тут будет статистика...
            </div>

          </div>

        </div>
        <ModalError type={0}/>
        <ModalError type={10}/>
        <ModalTools/>
        <ModalNotifications/>
      </>
    )
  }
}

const mapStateToProps = state => ({
  favoritesTools: favoritesToolsSelector(state),
  toolsList: toolsListSelector(state),
  activeBanners: activeBannersSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleToolsModal,
      dellToolFromFavorites,
      getActiveBanners,
      getNotifications,
      getTools,
      checkEarnedMoneyAmount,
    },
    dispatch
  )


const WrappedMainForm = Form.create({name: 'main'})(Main)

export default connect(mapStateToProps, mapDispatchToProps)(WrappedMainForm)

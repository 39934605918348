import React, { Component } from "react";
import { Row, Col, Form, Button, Modal, Input, Select, Tabs, } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cleave from "cleave.js/react";
import dateFormat from 'dateformat';
import { hasErrors, formItemLayout, inlineFormItemLayout, transform, getTodayDate, } from "../../../utils";
import { toggleBsoModal, doPaymentBso, patchIngosPayment, } from "../../../actions/osagoActions";
import { visibleBSOModalSelector, documentsPolicySelector, } from "../../../selectors/osagoSelectors";

const { Option } = Select;


  
class ModalOfflinePaymenuBSO extends Component {
    componentDidMount() {
        this.props.form.validateFields();
      }
    componentDidUpdate(prevProps, prevState, snapshot) {


    }

    showModal = () => {
        const { toggleBsoModal, } = this.props;
        toggleBsoModal(true);
    };

    handleOk = e => {
        const { toggleBsoModal,} = this.props;

        toggleBsoModal(false);
    };
    handleCancel = e => {
        const { toggleBsoModal, } = this.props;

        toggleBsoModal(false);
    };
    sendForm = () => {
        const { toggleBsoModal, doPaymentBso, patchIngosPayment, documentsPolicy,} = this.props;
        const { getFieldValue } = this.props.form;
        doPaymentBso({
            key: documentsPolicy && documentsPolicy.key, 
            agreement_id: documentsPolicy && documentsPolicy.agreement_id,
            series_receipt: getFieldValue('series_receipt'),
            number_receipt: getFieldValue('number_receipt'),
            payment: documentsPolicy.price,
        });
        patchIngosPayment({
          agreement_id: documentsPolicy.agreement_id,
          product_type: documentsPolicy.product_type,
        });
        toggleBsoModal(false);
    }
    render() {
        const {
            visibleBSOModal, 
            documentsPolicy,
        } = this.props;

        const {
            getFieldDecorator,
            getFieldsError,
            getFieldError,
            isFieldTouched,
            getFieldValue
        } = this.props.form;

        const seriesReceipt = "series_receipt";
        const seriesReceiptError = isFieldTouched(seriesReceipt) && getFieldError(seriesReceipt);

        const numberReceipt = "number_receipt";
        const numberReceiptError = isFieldTouched(numberReceipt) && getFieldError(numberReceipt);

        return (
            <>
                <Modal
                    title="Оплата по квитанции А77"
                    visible={visibleBSOModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width='500px'
                    closable={true}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps = {{ style: { display: 'none' }, /*disabled: true*/ }}
                >
                    <Form {...formItemLayout}>

                        {/* Серия */}
                        <Form.Item
                            label="Серия"
                            validateStatus={seriesReceiptError ? "error" : ""}
                            help={seriesReceiptError || ""}
                        >
                            <Row gutter={8}>
                                <Col span={24}>
                                    {getFieldDecorator(seriesReceipt, {
                                        rules: [
                                            {
                                                required: true,
                                                message: " "
                                            },

                                        ]
                                    })(<Input />)}
                                </Col>
                            </Row>
                        </Form.Item>


                        {/* Номер */}
                        <Form.Item
                            label="Номер"
                            validateStatus={numberReceiptError ? "error" : ""}
                            help={numberReceiptError || ""}
                        >
                            <Row gutter={8}>
                                <Col span={24}>
                                    {getFieldDecorator(numberReceipt, {
                                        rules: [
                                            {
                                                required: true,
                                                message: " "
                                            },

                                        ]
                                    })(<Input />)}
                                </Col>
                            </Row>
                        </Form.Item>

                        <Button
                            type="primary"
                            style={{ display: 'flex', margin: '0 auto' }}
                            disabled={
                                hasErrors(getFieldsError()) ||
                                (!getFieldValue(seriesReceipt)) || getFieldError(seriesReceipt) ||
                                (!getFieldValue(numberReceipt)) || getFieldError(numberReceipt)
                              }
                            onClick={() => {
                                    this.sendForm();
                            }}
                        >
                            Оплатить
                     </Button>
                    </Form>

                </Modal>
            </>
        );
    }
}



const mapStateToProps = state => ({
    visibleBSOModal: visibleBSOModalSelector(state),
    documentsPolicy: documentsPolicySelector(state),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            toggleBsoModal,
            doPaymentBso,
            patchIngosPayment,
        },
        dispatch
    );

const WrappedModalOfflinePaymenuBSOForm = Form.create({ name: 'modal_bso' })(ModalOfflinePaymenuBSO);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedModalOfflinePaymenuBSOForm);

import initialState from "../store/initialState";
import injectReducer from "../store/injectReducer";
import * as types from "../store/actionTypes";
import {
  constructField,
  frontDateFormat,
  validateNullsDelete,
} from "../utils"
import * as moment from "moment";


function constructDataForm(data) {
  validateNullsDelete(data)
  let dataForm = {...initialState.crossReducer.crossDataForm}

  dataForm["insured_sum"] = constructField("insured_sum", data["limit_sum"])

  let [policy_series, policy_number] = data["osago_policy_number"].split('-')

  dataForm["policy_series"] = constructField("policy_series", policy_series)
  dataForm["policy_number"] = constructField("policy_number", policy_number)

  return dataForm
}


export default injectReducer(initialState.crossReducer, {

  [types.CLEAR_IS_REQUEST_STATE]: (state) => ({
    ...state,
    isRequest: false,
  }),

  [types.CLEAR_FORM]: (state, {payload}) => {
    if (payload === 8) {
      return {
        ...state,
        ...initialState.crossReducer
      }
    } else {
      return {
        ...state
      }
    }
  },

  [types.GO_TO_NEXT_STEP_CROSS]: (state, action) => ({
    ...state,
    currentStep: action.payload
  }),

  [types.GO_TO_PREV_STEP_CROSS]: (state, action) => ({
    ...state,
    currentStep: action.payload
  }),

  [types.SET_DATA_FORM_CROSS]: (state, action) => ({
    ...state,
    crossDataForm: {...state.crossDataForm, ...action.payload.data}
  }),

  [types.SEND_DATA_FORM_CROSS]: state => {
    let stateCrossDataForm = state.crossDataForm

    let dataFormResult = {
      "date_sign": moment().format(frontDateFormat),
      "limit_sum": stateCrossDataForm.insured_sum.value,
      "osago_policy_number": `${stateCrossDataForm.policy_series.value}-${stateCrossDataForm.policy_number.value}`,
    }

    return {
      ...state,
      dataFormResult: {...dataFormResult, "draft_id": stateCrossDataForm.draft_id},
      calcResult: [],
      calculationComplete: false,
    };
  },

  [types.SEND_DATA_FORM_CROSS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.SEND_DATA_FORM_CROSS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      calcResult: payload,
      errors: null
    };
  },

  [types.SEND_DATA_FORM_CROSS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    dataFormResult: undefined,
    errors: {errors: payload.errors},
    visibleErrorModalCross: true
  }),

  [types.ADD_CALC_RESULT_CROSS]: (state, {payload}) => {
    return {
      ...state,
      calcResult: [...state.calcResult, payload.result],
    };
  },

  [types.CALC_ERROR_CROSS]: (state, {payload}) => ({
    ...state,
    errors: {errors: payload.errors},
    calculationInProgress: false,
    visibleErrorModalCross: true
  }),

  [types.GET_ONE_TIME_TOKEN_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalCross: true
  }),

  [types.ASSIGN_DRAFT_ID_CROSS]: (state, {payload}) => {
    const draft_id = payload.draft_id
    return {
      ...state,
      crossDataForm: {
        ...state.crossDataForm,
        draft_id,
      },
      dataFormResult: {
        ...state.dataFormResult,
        draft_id,
      },
    }
  },

  [types.START_CALCULATION_CROSS]: (state) => ({
    ...state,
    currentStep: 1,
    calculationInProgress: true
  }),

  [types.CALC_COMPLETE_CROSS]: (state) => ({
    ...state,
    calculationInProgress: false,
    calculationComplete: true,
  }),

  [types.GET_RISKS_AND_ATTRIBUTES_CROSS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_RISKS_AND_ATTRIBUTES_CROSS_SUCCESS]: (state, {payload}) => {
    let limit_sums = payload["democracy_risks_attrs"]["limit_sums"]

    let marks = Array.isArray(limit_sums) && Object.fromEntries(
      limit_sums.map(sum => [sum, {
        style: sum === limit_sums[0] ? {paddingLeft: 65} :
          sum === limit_sums.at(-1) ? {width: 180, paddingRight: 65} : {},
        label: `${Number(sum).toLocaleString("ru-RU")} ₽`
      }])
    )

    return {
      ...state,
      isRequest: false,
      limitSums: limit_sums,
      marks: marks,
      errors: null,
      risksAttributes: payload
    }
  },

  [types.GET_RISKS_AND_ATTRIBUTES_CROSS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload.errors},
    visibleErrorModalCross: true
  }),

  [types.TOGGLE_ERROR_MODAL]: (state, {payload}) => ({
    ...state,
    visibleErrorModalCross: payload
  }),

  [types.CREATE_POLICY_CROSS]: state => {
    return {
      ...state,
      dataFormResult: state.dataFormResult,
      urlDownloadPdf: undefined,
      policyCreated: false,
    };
  },
  [types.CREATE_POLICY_CROSS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    creatingPolicy: true,
    errors: null,
  }),

  [types.CREATE_POLICY_CROSS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      policyCreated: true,
      isRequest: false,
      creatingPolicy: false,
      documentsPolicy: payload,
      calc_id: payload.calc_id,
      errors: null,
      docs: undefined,
      insuranceDocuments: undefined,
      policyObjId: payload.policyObjId,
      policy_id: payload.policy_id,
    }
  },

  [types.CREATE_POLICY_CROSS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    policyCreated: false,
    creatingPolicy: false,
    errors: payload,
    visibleErrorModalCross: true
  }),


  [types.GET_PAYMENT_STATUS_CROSS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    paymentStatus: undefined,
    documents: undefined,
    errors: null,
  }),

  [types.GET_PAYMENT_STATUS_CROSS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      paymentStatus: payload.payment_status,
      documents: payload.documents,
      errors: null,
    };
  },

  [types.GET_PAYMENT_STATUS_CROSS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    paymentStatus: undefined,
    errors: payload,
    visibleErrorModalCross: true,
  }),


  [types.COPY_POLICY_CROSS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.COPY_POLICY_CROSS_SUCCESS]: (state, {payload}) => {
    let dataForm = constructDataForm(payload)
    return {
      ...state,
      isRequest: false,
      crossDataForm: {
        ...dataForm,
        draft_id: undefined
      },
      errors: null,
      crossKind: payload["cross_kind"] === "DK" ? 1 : 2,
      currentStep: 0,
      limitSums: payload["limit_sums"],
    }
  },

  [types.COPY_POLICY_CROSS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalCross: true,
  }),

  [types.RESUME_POLICY_CROSS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.RESUME_POLICY_CROSS_SUCCESS]: (state, {payload}) => {
    const draft_id = payload.id
    const calc_id = payload.policy_id
    let dataForm = constructDataForm(payload)
    let documentsPolicy = {
      documents: [],
      key: payload.insurance.ins_key,
      payment_url: payload.payment_url,
      policyObjId: draft_id,
      product_type: "cross",
    }
    let calcResult = [
      {
        key: payload.insurance.ins_key,
        name: payload.insurance.name,
        short_name: payload.insurance.short_name,
        price: payload.premium_sum,
        policy_number: payload.osago_policy_number,
        calc_id: calc_id,
        type: "cross",
      }
    ]
    let dataFormResult = {
      "date_sign": payload.date_sign,
      "limit_sum": dataForm["insured_sum"],
      "osago_policy_number": `${dataForm["policy_series"]}-${dataForm["policy_number"]}`,
    }
    return {
      ...state,
      isRequest: false,
      policyCreated: true,
      creatingPolicy: false,
      calcResult: calcResult,
      documentsPolicy: documentsPolicy,
      calc_id: calc_id,
      crossDataForm: {
        ...dataForm,
        draft_id: draft_id,
      },
      dataFormResult: {
        ...dataFormResult,
        draft_id: draft_id,
      },
      errors: null,
      currentStep: 2,
      crossKind: payload["cross_kind"] === "DK" ? 1 : 2,
    }
  },

  [types.RESUME_POLICY_CROSS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalCross: true,
  }),

  [types.RESUME_DRAFT_CROSS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.RESUME_DRAFT_CROSS_SUCCESS]: (state, {payload}) => {
    let dataForm = constructDataForm(payload)

    let dataFormResult = {
      "date_sign": moment().format(frontDateFormat),
      "limit_sum": dataForm["insured_sum"],
      "osago_policy_number": `${dataForm["policy_series"]}-${dataForm["policy_number"]}`,
    }

    const draft_id = payload.id
    return {
      ...state,
      isRequest: false,
      crossDataForm: {
        ...dataForm,
        draft_id: draft_id,
      },
      dataFormResult: {
        ...dataFormResult,
        draft_id: draft_id,
      },
      errors: null,
      currentStep: 0,
      crossKind: payload["cross_kind"] === "DK" ? 1 : 2,
    }
  },

  [types.RESUME_DRAFT_CROSS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalCross: true,
  }),

  [types.TOGGLE_CROSS_KIND]: (state, {payload}) => {
    let dataForm = {...state.crossDataForm}

    if (payload === 2) {
      let data = {...state.risksAttributes}
      dataForm["insured_sum"] = constructField("insured_sum", data["practice_risks_attrs"]["limit_sum"])
    }

    return ({
      ...state,
      crossDataForm: {...dataForm},
      crossKind: payload,
    })
  },

});

import React, {Component} from "react";
import {Row, Col, Form, Button, Modal, Input} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {formItemLayout, transform} from "../../../utils";
import {setDataForm, toggleTOModal, getTOInfo, clearDataInfoTO} from "../../../actions/osagoActions";
import {dataFormSelector, infoTOSelector, visibleTOModalSelector} from "../../../selectors/osagoSelectors";
import dateFormat from "dateformat";

class ModalDiagnosticCard extends Component {

  state = {
    showTOButton: false,
  };

  handleOk = () => {
    this.props.toggleTOModal(false);
  };

  handleCancel = () => {
    this.props.toggleTOModal(false);
  };

  render() {
    const {
      visibleTOModal,
      getTOInfo,
      infoTO,
      clearDataInfoTO,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldError,
      isFieldTouched,
      getFieldValue
    } = this.props.form;

    const vin = "vin";
    const vinError = isFieldTouched(vin) && getFieldError(vin);

    return (
      <>
        <Modal
          title="Проверка ТО"
          visible={visibleTOModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width='550px'
          closable={false}
        >
          <Form {...formItemLayout}>

            {/* VIN */}
            <Form.Item
              label="VIN:"
              validateStatus={vinError ? "error" : ""}
              help={vinError || ""}
            >
              <Row gutter={8}>
                <Col span={20}>
                  {getFieldDecorator(vin, {
                    rules: [{required: true, message: " "}],
                  })(<Input maxLength={17} size="large"/>)}
                </Col>
                <Col span={10}>
                  <Button
                    type="primary"
                    size="large"
                    disabled={vinError || !getFieldValue(vin)}
                    onClick={() => {
                      getTOInfo({vin: getFieldValue(vin)});
                      this.setState({getAutoInfo: true});
                      clearDataInfoTO();
                    }}
                  >
                    Проверить
                  </Button>
                </Col>
              </Row>
            </Form.Item>

            {infoTO && infoTO.date && infoTO.number && this.props.getTOInfo && (
              <>
                <Form.Item label="ТО действителен до">
                  <Row gutter={8}>
                    <Col span={12}>
                      <b>
                        {dateFormat(infoTO.date, "dd.mm.yyyy")}
                      </b>
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item label="ДК №">
                  <Row gutter={8}>
                    <Col span={12}>
                      <b>
                        {infoTO.number}
                      </b>
                    </Col>
                  </Row>
                </Form.Item>
              </>
            )}

          </Form>
        </Modal>
      </>
    );
  }
}


const ModalDiagnosticCardForm = Form.create({
  name: "modal_diagnostic_card",
  mapPropsToFields(props) {
    const {dataForm} = props;
    const transformed = transform({dataForm});
    return transformed.dataForm;
  },
  onFieldsChange(props, changedFields) {

    props.setDataForm(changedFields, 'diagnosticCard');
  }
})(ModalDiagnosticCard);


const mapStateToProps = state => ({
  dataForm: dataFormSelector(state),
  visibleTOModal: visibleTOModalSelector(state),
  infoTO: infoTOSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setDataForm,
      toggleTOModal,
      getTOInfo,
      clearDataInfoTO,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModalDiagnosticCardForm);

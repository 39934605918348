import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Preloader from "../../components/Preloader";
import {
  goToPrevStep,
  goToNextStep,
  setDataForm,
  clearIsRequestState,
} from "../../actions/mortgageActions";
import {clearForm} from "../../actions/accidentsSportActions";
import {
  isRequestSelector,
  currentStepSelector,
} from "../../selectors/mortgageSelectors";

import "./styles/index.scss";
import FirstStep from "./step-1";
import TwoStep from "./step-2";
import ThirdStep from "./step-3";
import FourthStep from "./step-4";
import FifthStep from "./step-5";
import ModalClearForm from "../../components/Form/ModalClearForm";
import {checkEarnedMoneyAmount, getNotifications} from "../../actions/mainActions";


class Mortgage extends Component {
  componentDidMount() {
    document.title = "Ипотека"

    const {clearIsRequestState, getNotifications, checkEarnedMoneyAmount} = this.props
    clearIsRequestState()
    getNotifications()
    checkEarnedMoneyAmount()
  }
  componentWillUnmount() {
    this.props.clearForm(7)
  }

  renderForms = () => {
    let {currentStep} = this.props;

    switch (currentStep) {
      case 0:
        return <FirstStep/>
      case 1:
        return <TwoStep/>
      case 2:
        return <ThirdStep/>
      case 3:
        return <FourthStep/>
      case 4:
        return <FifthStep/>
      default:
        return <FirstStep/>
    }
  };

  render() {
    const {
      isRequest,
    } = this.props;

    return (
      <>
        <Preloader loading={isRequest}/>
          <div className="osago-container">
              <span className="hyd-very-big-bold-text-small osago-header">Ипотека</span>
            {this.renderForms()}
          </div>
        <ModalClearForm type={1}/>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isRequest: isRequestSelector(state),
  currentStep: currentStepSelector(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToPrevStep,
      goToNextStep,
      setDataForm,
      clearIsRequestState,
      clearForm,
      getNotifications,
      checkEarnedMoneyAmount,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Mortgage);

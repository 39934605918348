import React from "react";
import {Col, Row, Form} from "antd";
import {ReactComponent as AlphaLogo} from "../../../assets/Icons/logos/AlphaLogo.svg";
import {ReactComponent as VskLogo} from "../../../assets/Icons/logos/VskLogoNew.svg";
import {ReactComponent as ReninsLogo} from "../../../assets/Icons/logos/ReninsLogo.svg";
import "./styles/index.scss";

const insLogos = {
  alfastrah: <AlphaLogo className={"precalculate-logo"}/>,
  alfa_regions: <AlphaLogo className={"precalculate-logo"}/>,
  vsk: <VskLogo className={"precalculate-logo"}/>,
  renesans: <ReninsLogo className={"precalculate-logo"}/>,
}

export default class PrecalculateForm extends React.Component {
  render() {

    const {
      value,
      index,
      userInfo,
      redAsterisk,
    } = this.props

    return (
      <> {!value.errors &&
        <Col span={8}>
          <span className={"hyd-precalculate-form"} key={value.key + index}>
              <div className={"precalculate-block"}>
                {insLogos[value.key]}
                <span className={"precalculate-data"}>
                  <span>Стоимость </span>
                  <span className={"hyd-osago-ins-result-value"}>
                    {Number(value.price).toLocaleString("ru-RU")} ₽
                    {redAsterisk()}
                  </span>

                  {value.agent_commission && value.agent_commission.forbidden ? <></> :
                    value.agent_commission && userInfo.show_commission &&
                    <span>
                      {" КВ "}
                      <span className={"hyd-osago-ins-result-value hyd-osago-ins-result-commission-percent"}>
                        {value.agent_commission.value}
                        {(value.agent_commission.identifier || !isNaN(value.agent_commission.value)) && "%"}
                      </span>
                    </span>
                  }
                </span>
              </div>
          </span>
        </Col>
      } </>
    )
  }
}
import {select, takeLatest} from 'redux-saga/effects';
import * as api from './api';
import * as types from '../store/actionTypes';

export function* getProfileSaga({payload}) {
  yield api.get("profile/", payload, types.GET_PROFILE);
}

export function* updateProfileSaga() {
  const dataFormResult = yield select((state) => state.profileReducer.dataFormResult)
  if (dataFormResult) {
    yield api.put("profile/", null, {...dataFormResult}, types.UPDATE_PROFILE);
  }
}

export function* getUserKladrSaga({payload}) {
  yield api.post("osago/get_kladr/", null, payload, types.GET_USER_KLADR)
}

export function* getRegionsSaga() {
  yield api.get("region/", null, types.GET_REGIONS)
}

export function* resetPasswordSaga() {
  yield api.post("reset_password/", null, null, types.RESET_PASSWORD)
}

export function* getReferralLinkSaga() {
  yield api.get("referral_link/", null, types.GET_REFERRAL_LINK)
}

export function* getProductReferralLinkSaga() {
  yield api.get("referral_link/", null, types.GET_PRODUCT_REFERRAL_LINK)
}

export function* getProductReferralQRCodeSaga() {
  yield api.get("/api/v1/referral_qr_code/", null, types.GET_PRODUCT_REFERRAL_QR_CODE, true)
}

export default function* () {
  yield takeLatest(types.GET_PROFILE, getProfileSaga);
  yield takeLatest(types.UPDATE_PROFILE, updateProfileSaga);
  yield takeLatest(types.GET_USER_KLADR, getUserKladrSaga);
  yield takeLatest(types.GET_REGIONS, getRegionsSaga);
  yield takeLatest(types.RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(types.GET_REFERRAL_LINK, getReferralLinkSaga);
  yield takeLatest(types.GET_PRODUCT_REFERRAL_LINK, getProductReferralLinkSaga);
  yield takeLatest(types.GET_PRODUCT_REFERRAL_QR_CODE, getProductReferralQRCodeSaga);
}

import {takeLatest, select} from 'redux-saga/effects';
import * as api from './api';
import * as types from '../store/actionTypes';
import {isObject} from '../utils';
import {oneTimeTokenSelector} from "../selectors/loginSelectors";
import {store} from "../store/configureStore";
import {getSocket} from "../socket";


export function* sendPrecalculateDataFormMortgageSaga() {
  const stateForm = yield select((state) => state.mortgageReducer.precalculateDataFormResult)
  let formFields = {}
  for (const item in stateForm) {
    formFields[item] = isObject(stateForm[item]) ? stateForm[item].value : stateForm[item];
  }
  formFields['offset'] = new Date().getTimezoneOffset()

  yield* api.get("one_time_token/", null, types.GET_ONE_TIME_TOKEN)
  const oneTimeToken = yield select(state => oneTimeTokenSelector(state))
  let insSocket = getSocket(oneTimeToken)

  insSocket.onmessage = function (e) {
    const data = JSON.parse(e.data);
    const type = data.type
    switch (type) {
      case 'calc_result':
        store.dispatch({
          type: types.ADD_PRECALCULATE_RESULT_MORTGAGE,
          payload: data
        })
        break
      case 'calc_error':
        store.dispatch({
          type: types.ERROR_PRECALCULATE_MORTGAGE,
          payload: data
        })
        insSocket.close()
        break
      case 'start_calculation':
        store.dispatch({
          type: types.START_PRECALCULATE_MORTGAGE,
          payload: {data: data}
        })
        break
      case 'calculation_complete':
        store.dispatch({
          type: types.PRECALCULATE_COMPLETE_MORTGAGE,
          payload: data
        })
        break
      default:
        store.dispatch({
          type: types.ERROR_PRECALCULATE_MORTGAGE,
          payload: data
        })
    }
    console.log(data)
  }
  insSocket.onclose = function () {
    const state = store.getState()
    if (!state.mortgageReducer.calculationComplete) {
      store.dispatch({
        type: types.ERROR_PRECALCULATE_MORTGAGE,
        payload: {errors: {error: ["Соединение с сервером разорвано!"]}}
      })
    }
    console.error('Chat socket closed unexpectedly');
  }
  insSocket.onopen = () => {
    console.log(formFields)
    insSocket.send(JSON.stringify({"type": "fast_calc", formFields}))
  }
}

export function* sendDataFormMortgageSaga() {
  const stateForm = yield select((state) => state.mortgageReducer.dataFormResult);
  let formFields = {};

  for (const item in stateForm) {
    formFields[item] = isObject(stateForm[item]) ? stateForm[item].value : stateForm[item];
  }
  formFields['offset'] = new Date().getTimezoneOffset();

  yield* api.get("one_time_token/", null, types.GET_ONE_TIME_TOKEN);
  const oneTimeToken = yield select(state => oneTimeTokenSelector(state));
  let insSocket = getSocket(oneTimeToken)

  insSocket.onmessage = function (e) {
    const data = JSON.parse(e.data);
    const type = data.type
    switch (type) {
      case 'calc_result':
        store.dispatch({
          type: types.ADD_CALC_RESULT_MORTGAGE,
          payload: data
        });
        break;
      case 'calc_error':
        store.dispatch({
          type: types.CALC_ERROR_MORTGAGE,
          payload: data
        });
        insSocket.close()
        break;
      case 'draft_id_assigned':
        store.dispatch({
          type: types.ASSIGN_DRAFT_ID_MORTGAGE,
          payload: data
        });
        break;
      case 'start_calculation':
        store.dispatch({
          type: types.START_CALCULATION_MORTGAGE,
          payload: {data: data}
        });
        break;
      case 'calculation_complete':
        store.dispatch({
          type: types.CALC_COMPLETE_MORTGAGE,
          payload: data
        });
        break;
      default:
        store.dispatch({
          type: types.CALC_ERROR_MORTGAGE,
          payload: data
        });
    }
    console.log(data)
  };
  insSocket.onclose = function () {
    const state = store.getState()
    if (!state.mortgageReducer.calculationComplete && !state.mortgageReducer.visibleErrorModalMortgage) {
      store.dispatch({
        type: types.CALC_ERROR_MORTGAGE,
        payload: {errors: {error: ["Соединение с сервером разорвано!"]}}
      });
    }
    console.error('Chat socket closed unexpectedly');
  };
  insSocket.onopen = () => {
    console.log(formFields)
    insSocket.send(JSON.stringify({"type": "mortgage", formFields}));
  }
}

export function* getLastNameArrayMortgageSaga({payload}) {
  yield api.post("osago/get_fio/", null, payload, types.GET_LASTNAME_ARRAY);
}

export function* getAddressDataSaga({address_data}) {
  yield api.post(
    "osago/get_kladr/",
    null,
    {address: address_data},
    types.GET_ADDRESS_DATA
  )
}

export function* createPolicyMortgageSaga({payload}) {
  const stateForm = yield select(state => state.mortgageReducer.dataFormResult);
  let formFields = {}

  formFields["draft_id"] = payload.calc_id
  formFields["premium"] = payload.price
  formFields["policy_nr"] = payload.policy_number
  formFields["id"] = stateForm.draft_id

  formFields["offset"] = new Date().getTimezoneOffset()
  formFields["agent_commission"] = payload.agent_commission;
  if (formFields["agent_commission"]) {
    formFields["agent_commission"].value = parseInt(formFields["agent_commission"].value);
  }
  yield api.post(
    `mortgage/create_policy/${payload.key}/`,
    null,
    formFields,
    types.CREATE_POLICY_MORTGAGE
  )
}

export function* getPaymentStatusSaga({payload}) {
  yield api.post(
    `mortgage/payment/${payload.key}/status/`,
    null,
    payload,
    types.GET_PAYMENT_STATUS_MORTGAGE
  );
}

export function* paymentLinkToEmailSaga({payload}) {
  yield api.post(
    `mortgage/payment/${payload.key}/payment_link_to_email/`,
    null,
    payload,
    types.PAYMENT_LINK_TO_EMAIL
  );
}

export function* getProfessionsSaga() {
  yield api.get(
    "get_professions/",
    null,
    types.GET_PROFESSIONS_MORTGAGE
  )
}

export function* getBanksSaga() {
  yield api.get(
    "get_banks_list/",
    null,
    types.GET_BANKS
  )
}

export function* getEmploymentStatusesSaga() {
  yield api.get(
    "get_employment_statuses_list/",
    null,
    types.GET_EMPLOYMENT_STATUSES
  )
}

export function* getHealthAbnormalitiesSaga() {
  yield api.get(
    "get_health_abnormalities_list/",
    null,
    types.GET_HEALTH_ABNORMALITIES
  )
}

export function* getFiasIdSaga({payload}) {
  yield api.get(
    "get_fias_id/",
    {region: payload},
    types.GET_FIAS_ID
  )
}

export function* sendMortgageApplicationSaga() {
  const stateForm = yield select(state => state.mortgageReducer.applicationDataFormResult)
  yield api.post('mortgage_calc_request/', null, stateForm, types.SEND_MORTGAGE_APPLICATION)
}

export default function* () {
  yield takeLatest(types.SEND_DATA_FORM_MORTGAGE, sendDataFormMortgageSaga);
  yield takeLatest(types.SEND_PRECALCULATE_DATA_FORM_MORTGAGE, sendPrecalculateDataFormMortgageSaga);
  yield takeLatest(types.GET_LASTNAME_ARRAY_MORTGAGE, getLastNameArrayMortgageSaga);
  yield takeLatest(types.GET_ADDRESS_DATA, getAddressDataSaga);
  yield takeLatest(types.CREATE_POLICY_MORTGAGE, createPolicyMortgageSaga);
  yield takeLatest(types.GET_PAYMENT_STATUS_MORTGAGE, getPaymentStatusSaga);
  yield takeLatest(types.PAYMENT_LINK_TO_EMAIL, paymentLinkToEmailSaga);
  yield takeLatest(types.GET_PROFESSIONS_MORTGAGE, getProfessionsSaga);
  yield takeLatest(types.GET_BANKS, getBanksSaga);
  yield takeLatest(types.GET_EMPLOYMENT_STATUSES, getEmploymentStatusesSaga);
  yield takeLatest(types.GET_HEALTH_ABNORMALITIES, getHealthAbnormalitiesSaga);
  yield takeLatest(types.GET_FIAS_ID, getFiasIdSaga);
  yield takeLatest(types.SEND_MORTGAGE_APPLICATION, sendMortgageApplicationSaga);
  yield takeLatest(types.SEND_MORTGAGE_APPLICATION_SUCCESS, getBanksSaga);
}

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage';

import rootSaga from '../sagas';
import reducersList from '../reducers';

const reducers = {
	...reducersList
};

const rootReducer = persistCombineReducers(
	{
		key: 'root',
		storage,
    timeout: null
	},
	reducers
);

const middlewares = [];
const sagaMiddleware = createSagaMiddleware();

if (process.env.NODE_ENV === 'development') {
	middlewares.push(logger);
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(sagaMiddleware, ...middlewares)),
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

import React, {Component} from "react";
import {Button, Form, Modal, Checkbox, Row} from "antd";
import {transform} from "../../../utils";
import {
  calcResultSelector,
  dataFormSelector,
  visibleKPModalSelector,
} from "../../../selectors/osagoSelectors";
import {bindActionCreators} from "redux";
import {
  printKP,
  toggleKPModal,
} from "../../../actions/osagoActions";
import {connect} from "react-redux";


class ModalKP extends Component {
  state = {
    selected_calc_results: [],
  }

  render() {
    const {
      visibleKPModal,
      printKP,
      toggleKPModal,
      calcResult
    } = this.props;
    const options = calcResult.filter(value => value.price)
    return (
      <>
        <Modal
          visible={visibleKPModal}
          cancelButtonProps={{style: {display: 'none'}}}
          okButtonProps={{style: {display: 'none'}}}
          footer={false}
          closable={false}
          width='440px'
          height='424px'
        >

          <Form
            style={{"marginLeft": "16px"}}
          >
            <div className="hyd-modal-head">Выберите страховые компании</div>
            <Checkbox.Group
              onChange={(checkedValues) => {
                this.setState({selected_calc_results: checkedValues})
              }}
            >
              {options.map((value, index) => {
                return (<Row key={index}>
                  <Checkbox
                    style={{"marginBottom": "10px"}}
                    className="hyd-checkbox-osago"
                    value={index}
                    disabled={
                      !this.state.selected_calc_results.includes(index) &&
                      this.state.selected_calc_results.map(index => options[index].key).includes(value.key)
                    }
                  >
                    <span
                      className={"hyd-checkbox-label-osago"}>{value.name} - {Number(value.price).toLocaleString("ru-RU")} ₽</span>
                  </Checkbox>
                </Row>)
              })}
            </Checkbox.Group>

            <div
              className="hyd-row-profile"
              style={{"marginTop": "20px"}}
            >
              <Button
                className="hyd-btn-small-3 hyd-btn-modal"
                onClick={() => {
                  let calc_results = options.map((result) => (
                      {"ins_key": result.key, "price": result.price}
                    )
                  ).filter((_, index) => this.state.selected_calc_results.includes(index))
                  printKP(calc_results)
                  toggleKPModal(false)
                }}
              >
                Получить КП
              </Button>

              <Button
                className="hyd-btn-small-grey"
                onClick={() => {
                  toggleKPModal(false)
                }}
              >
                Отмена
              </Button>
            </div>
          </Form>
        </Modal>
      </>
    )
  }
}


const WrappedModalKPForm = Form.create({
  name: "modal_kp",
  mapPropsToFields(props) {
    const {dataForm,} = props;
    const transformed = transform({dataForm});

    return transformed.dataForm;
  },
  onFieldsChange(props, changedFields) {
    props.setDataForm(changedFields);
  }
})(ModalKP);

const mapStateToProps = state => ({
  dataForm: dataFormSelector(state),
  visibleKPModal: visibleKPModalSelector(state),
  calcResult: calcResultSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      printKP,
      toggleKPModal,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(WrappedModalKPForm);

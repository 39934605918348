import moment from "moment/moment";
import {frontDateFormat} from "../../utils";
import {Form, Select} from "antd";
import React from "react";

const {Option} = Select;


export const checkSelectedBankKey = (banks, selectedBank, key) => {
  return banks.filter(bank => bank.id === selectedBank)[0]?.short_name === key
}

export const VTB_KEY = "vtb"
export const SBER_KEY = "sber"

export const isNeedCalcMortgage = (banks, selectedBank) => {
  return (
    checkSelectedBankKey(banks, selectedBank, VTB_KEY) ||
    checkSelectedBankKey(banks, selectedBank, SBER_KEY)
  )
}

export const professionFormItem = (professions, profession, professionError, getFieldDecorator) => {
  return (
    <>
      {/*Профессия*/}
      <Form.Item
        validateStatus={professionError ? "error" : ""}
        help={professionError || ""}
      >
        <div className="hyd-select-small hyd-mortgage-283-no-margin hyd-osago-select">
          {getFieldDecorator(profession, {
            rules: [{required: true}],
          })(
            <Select
              placeholder={"Профессия"}
              className="hyd-input-select-small"
              style={{width: "100%"}}
            >
              {professions && professions.map(({id, name}) =>
                <Option value={id} key={id}>
                  <div className={"hyd-select-tooltip"}>Профессия</div>
                  <div className={"hyd-select-value"}>{name}</div>
                </Option>
              )}
            </Select>
          )}
        </div>
      </Form.Item>
    </>
  )
}

export const changeEndDate = (targetValue, banks, bankValue, bankKey, loanTermMonths) => {
  let momentTargetValue = moment(targetValue, frontDateFormat, true)
  if (momentTargetValue.isValid()) {
    let result
    if (checkSelectedBankKey(banks, bankValue, bankKey)) {
      result = momentTargetValue.add(
        loanTermMonths, 'months').add(-1, 'days').format(frontDateFormat)
    } else {
      result = momentTargetValue.add(
        1, 'year').add(-1, 'days').format(frontDateFormat)
    }
    return result
  }
}

export const transformCoBorrowers = (dataForm, keys) => {
  const transformedCoBorrowers = []

  keys.forEach(key => {
    const newObj = {
      first_name: dataForm[`first_name_${key}`]?.value || '',
      last_name: dataForm[`last_name_${key}`]?.value || '',
      middle_name: dataForm[`middle_name_${key}`]?.value || '',
      birth_date: dataForm[`birth_date_${key}`]?.value || '',
      birth_place: dataForm[`birth_place_${key}`]?.value || '',
      email: dataForm[`email_${key}`]?.value || '',
      phone: dataForm[`phone_${key}`]?.value || '',
      gender: dataForm[`gender_${key}`]?.value || '',

      fact_address: dataForm[`fact_address_${key}`]?.value || '',
      fias_fact: dataForm[`fias_fact_${key}`]?.value || '',

      registration_address: dataForm[`registration_address_${key}`]?.value || '',
      is_fact_address: dataForm[`is_fact_address_${key}`]?.value || '',
      fias: dataForm[`fias_${key}`]?.value || '',

      division_code: dataForm[`division_code_${key}`]?.value || '',
      document_issue_date: dataForm[`document_issue_date_${key}`]?.value || '',
      document_issuer: dataForm[`document_issuer_${key}`]?.value || '',
      document_series: dataForm[`document_series_${key}`]?.value.replace(/\s+/g, "") || '',
      document_number: dataForm[`document_number_${key}`]?.value || '',

      health_abnormalities: dataForm[`health_abnormalities_${key}`]?.value || '',
      profession: dataForm[`profession_${key}`]?.value || '',
      property_share: dataForm[`property_share_${key}`]?.value || '',
    }
    transformedCoBorrowers.push(newObj)
  })

  return transformedCoBorrowers
}

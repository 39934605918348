import React, {Component} from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Checkbox,
  notification,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
  goToNextStep,
  goToPrevStep,
  setDataForm,
  toggleSelectedRegAddress,
  clearPolicyholderAddressData,
  savePolicyholderAddressData,
  clearPolicyholderFactAddressData,
  savePolicyholderFactAddressData,
  getAddressData,
  getProfessions,
  getEmploymentStatuses,
  getHealthAbnormalities,
  clearIsRequestState,
  addCoBorrower,
  changeQuantityCoBorrowers,
  calcPropertyShareSum,
} from "../../actions/mortgageActions";
import {
  banksSelector,
  isRequestSelector,
  currentStepSelector,
  errorsMortgageSelector,
  mortgageDataFormSelector,
  regAddressArrSelector,
  factAddressArrSelector,
  professionsSelector,
  employmentStatusesSelector,
  healthAbnormalitiesSelector,
  coBorrowersKeysSelector,
  coBorrowersQuantitySelector,
  coBorrowersQuantityMaxSelector,
} from "../../selectors/mortgageSelectors";
import ModalError from "../../components/ModalError";
import {
  transform,
  hasErrors,
} from "../../utils";
import "./styles/index.scss";
import "../../fonts/open-sans.css"
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import {ReactComponent as NumberInactiveIcon} from "../../assets/Icons/NumberInactive.svg";
import {ReactComponent as PlusSignBlueIcon} from "../../assets/Icons/mortgage/PlusSignBlue.svg";
import FirstStepDisabled from "../../components/MortgageDisabledSteps/FirstStepDisabled";
import {ReactComponent as QuestionIcon} from "../../assets/Icons/Question.svg";
import {checkSelectedBankKey, VTB_KEY} from "./mortgageUtils";
import CoBorrowers from "./coBorrowers";
import MortgagePerson from "./MortgagePerson";


class SecondStepMortgage extends Component {
  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  componentDidMount() {
    this.headerRef.current.scrollIntoView()
    const {
      getProfessions,
      coBorrowersQuantity,
      clearIsRequestState,
      getEmploymentStatuses,
      getHealthAbnormalities,
      changeQuantityCoBorrowers,
    } = this.props

    getProfessions()
    getEmploymentStatuses()
    getHealthAbnormalities()
    clearIsRequestState()
    changeQuantityCoBorrowers(coBorrowersQuantity)
  }

  componentDidUpdate(prevProps, prevState, snapshot?) {
    const {form, coBorrowersQuantity} = this.props

    if (coBorrowersQuantity !== prevProps.coBorrowersQuantity) {
      calcPropertyShareSum()
      form.validateFields([`property_share`], {force: true})
    }
  }

  handleSubmit = (e) => {
    const {form, currentStep, goToNextStep} = this.props;
    e.preventDefault()

    form.validateFields({force: true}, (err,) => {
      if (!err) {
        goToNextStep(currentStep + 1)
      } else {
        notification["error"]({
          message: err
        })
      }
    })
  }


  render() {
    const {
      form,
      banks,
      currentStep,
      goToPrevStep,
      addCoBorrower,
      coBorrowersKeys,
      coBorrowersQuantity,
      coBorrowersQuantityMax,
      clearPolicyholderAddressData,
      savePolicyholderAddressData,
      clearPolicyholderFactAddressData,
      savePolicyholderFactAddressData,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldsError,
      getFieldValue,
    } = form;

    const mortgageBank = "mortgage_bank";
    const notPublicOfficial = "not_public_official";

    return (
      <>
        <FirstStepDisabled
          goToPrevStep={() => goToPrevStep(currentStep - 1)}
        />

        <div className="step-title-active" ref={this.headerRef}>
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">2</span>
          </div>
          <span className="step-title-active-header">Данные о Заёмщике</span>
        </div>

        <Form
          layout="inline"
          onSubmit={this.handleSubmit}
          id="mortgage-step-2"
        >

          <MortgagePerson
            form={form}
            clearAddressData={clearPolicyholderAddressData}
            saveAddressData={savePolicyholderAddressData}
            clearFactAddressData={clearPolicyholderFactAddressData}
            saveFactAddressData={savePolicyholderFactAddressData}
            coBorrowersKeys={coBorrowersKeys}
          />

          {checkSelectedBankKey(banks, getFieldValue(mortgageBank), VTB_KEY) && coBorrowersQuantity > 0 &&
            coBorrowersKeys.map((k, index) => {
              return (
                <CoBorrowers
                  k={k}
                  form={form}
                  key={k + index}
                  index={index}
                  coBorrowersKeys={coBorrowersKeys}
                />
              )
            })
          }

          {checkSelectedBankKey(banks, getFieldValue(mortgageBank), VTB_KEY) &&
            coBorrowersQuantity < coBorrowersQuantityMax &&
            <Row style={{marginTop: 30}}>
              <Button
                className={"hyd-link-btn-2"}
                onClick={() => {
                  addCoBorrower()
                }}
              >
                <PlusSignBlueIcon/>
                <span style={{marginLeft: 5}}>Добавить созаёмщика</span>
              </Button>
            </Row>
          }

          {/* Назад/Далее */}
          <Row>
            <Form.Item>
              <Button
                className="hyd-btn-small hyd-mortgage-btn-proceed"
                type="primary"
                htmlType="submit"
                form="mortgage-step-2"
                key="submit"
                disabled={hasErrors(getFieldsError())}
              >
                Продолжить
              </Button>
            </Form.Item>
          </Row>

          <Col>
            {getFieldDecorator(notPublicOfficial, {initialValue: getFieldValue(notPublicOfficial)})(
              <Checkbox
                checked={getFieldValue(notPublicOfficial)}
                className={"hyd-norm-text"}
                style={{marginTop: 40}}
              >
                Не является публичным должностным лицом, его супругом, близким родственником
              </Checkbox>
            )}
            <QuestionIcon className="hyd-mortgage-q-ico"/>
          </Col>
        </Form>
        {
          [
            {number: 3, text: "Страхование"},
            {number: 4, text: "Финальные Условия"},
            {number: 5, text: "Оплата"},
          ].map(({number, text}) =>
            <div className="step-title-disabled" key={number}>
              <div className="step-icon-block">
                <NumberInactiveIcon className="hyd-large-num-ico"/>
                <span className="hyd-large-num-ico-num">{number}</span>
              </div>
              <span className="step-title-disabled-header">{text}</span>
            </div>
          )
        }
        <ModalError type={7}/>
      </>
    )
  }
}

const WrappedSecondStepMortgageForm = Form.create({
    name: "mortgage-step-2",
    mapPropsToFields(props) {
      const {mortgageDataForm} = props;
      const transformed = transform({mortgageDataForm});

      return transformed.mortgageDataForm;
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields);
    },
  }
)(SecondStepMortgage);

const mapStateToProps = (state) => ({
  banks: banksSelector(state),
  isRequest: isRequestSelector(state),
  currentStep: currentStepSelector(state),
  errors: errorsMortgageSelector(state),
  mortgageDataForm: mortgageDataFormSelector(state),
  regAddressArr: regAddressArrSelector(state),
  factAddressArr: factAddressArrSelector(state),
  professions: professionsSelector(state),
  employmentStatusesList: employmentStatusesSelector(state),
  healthAbnormalitiesList: healthAbnormalitiesSelector(state),
  coBorrowersQuantity: coBorrowersQuantitySelector(state),
  coBorrowersQuantityMax: coBorrowersQuantityMaxSelector(state),
  coBorrowersKeys: coBorrowersKeysSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPrevStep,
      goToNextStep,
      setDataForm,
      toggleSelectedRegAddress,
      clearPolicyholderAddressData,
      savePolicyholderAddressData,
      clearPolicyholderFactAddressData,
      savePolicyholderFactAddressData,
      getAddressData,
      getProfessions,
      getEmploymentStatuses,
      getHealthAbnormalities,
      clearIsRequestState,
      addCoBorrower,
      changeQuantityCoBorrowers,
      calcPropertyShareSum,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WrappedSecondStepMortgageForm);

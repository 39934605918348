import * as types from "../store/actionTypes";

export const getTools = () => ({
  type: types.GET_TOOLS
});

export const toggleToolsModal = (value) => ({
  type: types.TOGGLE_TOOLS_MODAL,
  payload: value
});

export const getFavoritesTools = () => ({
  type: types.GET_FAVORITES_TOOLS
});

export const addToolToTempList = (value) => ({
  type: types.ADD_TOOL_TO_TEMP_LIST,
  payload: value
});

export const clearToolsTempList = () => ({
  type: types.CLEAR_TOOLS_TEMP_LIST,
});

export const updateFavoritesTools = () => ({
  type: types.UPDATE_FAVORITES_TOOLS,
});

export const dellToolFromFavorites = (value) => ({
  type: types.DEL_TOOL_FROM_FAVORITES,
  payload: value
});

export const getActiveBanners = () => ({
  type: types.GET_ACTIVE_BANNERS,
});

export const getNotifications = (payload) => ({
  type: types.GET_NOTIFICATIONS,
  payload: payload,
})

export const updateNotificationStatus = (payload) => ({
    type: types.UPDATE_NOTIFICATION_STATUS,
    payload: payload
});

export const toggleNotificationsModal = value => ({
  type: types.TOGGLE_NOTIFICATIONS_MODAL,
  payload: value
});

export const toggleHiddenNotifications = value => ({
  type: types.TOGGLE_HIDDEN_NOTIFICATIONS,
  payload: value
});

export const checkEarnedMoneyAmount = (payload) => ({
  type: types.CHECK_EARNED_MONEY_AMOUNT,
  payload: payload,
})

import React, {Component} from "react";
import {Row, Form, Button, Popover, Popconfirm} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
  goToPrevStep,
  getConfig,
  toggleBsoModal,
  doPaymentNSS,
  getPaymentStatus,
  patchAlfaPayment,
  patchIngosPayment,
  clearPaymentStatus,
  getSberQRCode,
  getSberQRPaymentStatus
} from "../../actions/osagoActions";
import {
  currentStepSelector,
  documentsPolicySelector,
  isRequestSelector,
  configAdminSelector,
  documentsBSOSelector,
  documentsNSSSelector,
  paymentDocsSelector,
  paymentStatusSelector,
  alfaDocsSelector,
  ingosDocsSelector,
  insuranceDocumentsSelector,
  renessansDocsSelector,
  dataFormSelector,
  calcResultSelector,
  paymentDocTypeSelector,
} from "../../selectors/osagoSelectors";
import {tokenSelector, userInfoSelector,} from "../../selectors/loginSelectors";
import ModalError from "../../components/ModalError";
import ModalOfflinePaymenuBSO from "../../components/Form/ModalOfflinePaymenuBSO";
import ModalSberQRPayment from "../../components/Form/ModalSberQRPayment";
import FirstStepDisabled from "../../components/OsagoDisabledSteps/FirstStepDisabled";
import SecondStepDisabled from "../../components/OsagoDisabledSteps/SecondStedDisabled";
import ThirdStepDisabled from "../../components/OsagoDisabledSteps/ThirdStepDisabled";
import FourthStepDisabled from "../../components/OsagoDisabledSteps/FourthStepDisabled";
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import {ReactComponent as ErrorMarkIcon} from "../../assets/Icons/ErrorMark.svg";
import FifthStepDisabled from "../../components/OsagoDisabledSteps/FifthStepDisabled";
import {downloadDocsButton, get_doc} from "../../utils";


class SixthStep extends Component {
  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  componentDidMount() {
    this.headerRef.current.scrollIntoView()
    this.props.getConfig();
  }

  render() {
    const {
      documentsPolicy,
      configAdmin,
      doPaymentNSS,
      documentsNSS,
      getPaymentStatus,
      dataForm,
      calcResult,
      payment_status,
      userInfo,
      getSberQRCode,
      getSberQRPaymentStatus
    } = this.props;


    const is_original_documents =
      (this.props.insuranceDocuments && payment_status === 'paid') ||
      (documentsNSS && documentsNSS.documents && Array.isArray(documentsNSS.documents))
    return (
      <>
        <FirstStepDisabled/>
        <SecondStepDisabled
          dataForm={dataForm}
        />
        <ThirdStepDisabled
          dataForm={dataForm}
        />
        <FourthStepDisabled
          dataForm={dataForm}
        />
        <FifthStepDisabled
          value={calcResult.find(i => ((i.key === documentsPolicy.key) && (i.type === documentsPolicy.product_type)))}
          userInfo={userInfo}
        />
        <div className="step-title-active" ref={this.headerRef}>
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">6</span>
          </div>
          <span className="step-title-active-header">Предварительный просмотр документов</span>
        </div>
        {documentsNSS && documentsNSS.documents &&
          Array.isArray(documentsNSS.documents) &&
          <Row>
            {documentsNSS.documents.map((item, index) => {
              return (item.name && (downloadDocsButton(get_doc, item, index)))
            })}
          </Row>}
        {this.props.insuranceDocuments && payment_status === 'paid' &&
          <Row>
            {this.props.insuranceDocuments.map((item, index) => {
              return (item.name && (downloadDocsButton(get_doc, item, index)))
            })}
          </Row>}
        <Row>
          {/* Документы заявление, копия полиса, Представители */}
          {!is_original_documents && documentsPolicy &&
            Array.isArray(documentsPolicy.documents) &&
            documentsPolicy.documents.map((item, index) => {
              return (item.name && (downloadDocsButton(get_doc, item, index)))
            })}
        </Row>
        {documentsPolicy && documentsPolicy.add_product &&
          <>
            <div>
              <span>Дополнительный продукт: {documentsPolicy.add_product.product_name}</span>
              <span> {Number(documentsPolicy.add_product.premium_sum).toLocaleString("ru-RU")} ₽</span>
            </div>
            <Row>
              {!is_original_documents && documentsPolicy && documentsPolicy.add_product && documentsPolicy.add_product.documents &&
                Array.isArray(documentsPolicy.add_product.documents) &&
                documentsPolicy.add_product.documents.map((item, index) => {
                  return (item.name && (downloadDocsButton(get_doc, item, index)))
                })}
            </Row>
          </>
        }
        <div className={"hyd-row-profile"}>
          <div className={"hyd-osago-step6-payment"}>
            <div className={"hyd-osago-ins-result-head"}>
              Стоимость полиса
            </div>
            <div>
              {documentsPolicy && (
                <span className={"hyd-osago-ins-result-value hyd-osago-step6-value"}>
                {Number(documentsPolicy.price).toLocaleString("ru-RU")} ₽
              </span>
              )}
            </div>
          </div>
          {configAdmin.offline_payment && userInfo.user_type !== "AGENT_ONLINE" && documentsPolicy &&
            <>
              {["ingostrah", "alfastrah", "alfa_regions", "vsk", "rosgosstrah"].includes(documentsPolicy.key) &&
                ["bso", "ebso"].includes(documentsPolicy.product_type) && (
                  <>
                    {!is_original_documents ?
                      <div className={"hyd-osago-step6-payment"}>
                        <Popconfirm
                          placement="top"
                          title={"Подтвердить оформление и выпуск полиса?"}
                          onConfirm={() => doPaymentNSS({
                              insKey: documentsPolicy.key,
                              policyObjId: documentsPolicy.policyObjId,
                            })}
                          okText="Да"
                          cancelText="Нет"
                        >
                          <Button
                            className={"hyd-btn-small"}
                            style={{marginRight: 30}}
                            type="primary"
                          >
                            Сформировать квитанцию
                          </Button>
                        </Popconfirm>
                      </div> :
                      <Button
                        className={"hyd-osago-step6-payment hyd-btn-small hyd-policy-paid"}>
                        Полис оплачен
                      </Button>
                    }
                  </>
                )}
            </>
          }

          {
            documentsPolicy && userInfo.user_type === "AGENT_ONLINE" &&
            documentsPolicy.product_type === "ebso" && !["renesans"].includes(documentsPolicy.key) &&
            documentsPolicy.policy_seria && documentsPolicy.policy_number &&
            (
              <>
                {
                  (payment_status && payment_status === "paid") ?
                    <Button className={"hyd-btn-small hyd-policy-paid hyd-policy-paid-m"}>Полис оплачен</Button> :
                    <>
                      <div className={"hyd-osago-step6-payment"}>
                        <Button
                          className={"hyd-btn-small"}
                          onClick={() => {
                            getSberQRCode(documentsPolicy.policyObjId)
                          }}
                          type="primary"
                        >
                          Оплата по QR-коду
                        </Button>
                      </div>


                      <div className={"hyd-osago-step6-payment"}>
                        <Button
                          className={"hyd-btn-small"}
                          onClick={() => {
                            getSberQRPaymentStatus(documentsPolicy.policyObjId)
                          }}
                          type="primary"
                        >
                          Проверить статус оплаты
                        </Button>
                        {payment_status && !(payment_status === "paid") ?
                          <div className={"hyd-not-paid"}>
                            <span className={"hyd-not-paid-ico"}><ErrorMarkIcon/></span>
                            <span className={"hyd-not-paid-txt"}>Не оплачен</span>
                          </div> : ""
                        }
                      </div>
                    </>
                }
              </>
            )
          }

          {configAdmin.online_payment && documentsPolicy && (
            <>

              {(documentsPolicy.product_type === "eosago" || ["renesans"].includes(documentsPolicy.key)) && (
                <>
                  {payment_status === "paid" ?
                    <Button className={"hyd-btn-small hyd-policy-paid hyd-policy-paid-m"}>Полис оплачен</Button> :
                    <>
                      <div className={"hyd-osago-step6-payment"}>
                        <Button
                          className={"hyd-btn-small"}
                          onClick={() => {
                            window.open(documentsPolicy["payment_url"])
                          }}
                          type="primary"
                        >
                          Оплата картой
                        </Button>
                      </div>
                      <div className={"hyd-osago-step6-payment"}>
                        <Button
                          className={"hyd-btn-small"}
                          type="primary"
                          onClick={() => {
                            getPaymentStatus({
                              insKey: documentsPolicy.key,
                              policyObjId: documentsPolicy.policyObjId,
                            })

                          }}
                        >
                          Проверить статус оплаты
                        </Button>

                        {payment_status &&
                        !(payment_status === "paid") ?
                          <div className={"hyd-not-paid"}>
                            <span className={"hyd-not-paid-ico"}><ErrorMarkIcon/></span>
                            <span className={"hyd-not-paid-txt"}>Не оплачен</span>
                          </div> : ""
                        }
                      </div>
                    </>
                  }
                </>
              )}

              {documentsPolicy &&
                documentsPolicy.payment_url &&
                documentsPolicy.payment_url.errors &&
                documentsPolicy.payment_url.errors.error && (
                  <div className={"hyd-osago-step6-payment"}>
                    <Popover
                      content={documentsPolicy.payment_url.errors.error}
                      title="Ошибка"
                      trigger="hover"
                    >
                      <Button
                        className={"hyd-btn-small"}
                        type="primary"
                        disabled={true}
                      >
                        Оплата картой
                      </Button>
                    </Popover>
                  </div>
                )}
            </>
          )}

        </div>

        <ModalError type={1}/>
        <ModalOfflinePaymenuBSO/>
        <ModalSberQRPayment/>
      </>
    );
  }

}

const WrappedSixthStepForm = Form.create({name: "step-6"})(SixthStep);

const mapStateToProps = (state) => ({
  currentStep: currentStepSelector(state),
  documentsPolicy: documentsPolicySelector(state),
  isRequest: isRequestSelector(state),
  configAdmin: configAdminSelector(state),
  documentsBSO: documentsBSOSelector(state),
  documentsNSS: documentsNSSSelector(state),
  token: tokenSelector(state),
  paymentDocs: paymentDocsSelector(state),
  payment_status: paymentStatusSelector(state),
  alfa_docs: alfaDocsSelector(state),
  ingostrah_docs: ingosDocsSelector(state),
  insuranceDocuments: insuranceDocumentsSelector(state),
  renessans_docs: renessansDocsSelector(state),
  dataForm: dataFormSelector(state),
  calcResult: calcResultSelector(state),
  paymentDocType: paymentDocTypeSelector(state),
  userInfo: userInfoSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPrevStep,
      getConfig,
      toggleBsoModal,
      doPaymentNSS,
      getPaymentStatus,
      patchAlfaPayment,
      patchIngosPayment,
      clearPaymentStatus,
      getSberQRCode,
      getSberQRPaymentStatus,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedSixthStepForm);

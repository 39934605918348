import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
  getNotifications,
  toggleHiddenNotifications,
  toggleNotificationsModal,
  updateNotificationStatus,
} from "../../../actions/mainActions";
import {Button, Divider, Form, Modal, Skeleton, Spin} from "antd";
import {
  isRequestSelector,
  notificationsSelector,
  showHiddenNotificationsSelector,
  visibleNotificationsModalSelector,
} from "../../../selectors/mainSelectors";
import {ReactComponent as DeleteIcon} from '../../../assets/Icons/Delete.svg'
import moment from "moment/moment";

const sentStatus = "sent"
const openStatus = "open"
const hiddenStatus = "hidden"

class ModalNotifications extends Component {


  componentDidUpdate(prevProps, prevState, snapshot) {
    const {visibleNotificationsModal, updateNotificationStatus, notifications} = this.props

    if (!prevProps.visibleNotificationsModal && visibleNotificationsModal) {
      let notificationIds = notifications
        .filter(notification => notification.status === sentStatus)
        .map(notification => notification.id)
      let patchInfo = {
        new_status: openStatus,
        notification_ids: notificationIds,
      }
      updateNotificationStatus(patchInfo)
    }
  }

  showModal = () => {
    const {toggleNotificationsModal, toggleHiddenNotifications} = this.props
    toggleNotificationsModal(true)
    toggleHiddenNotifications(true)
  }

  handleOk = () => {
    const {toggleNotificationsModal, toggleHiddenNotifications} = this.props
    toggleNotificationsModal(false)
    toggleHiddenNotifications(false)
  }

  handleCancel = () => {
    const {toggleNotificationsModal, toggleHiddenNotifications} = this.props
    toggleNotificationsModal(false)
    toggleHiddenNotifications(false)
  }

  render() {
    const {
      isRequest,
      notifications,
      updateNotificationStatus,
      visibleNotificationsModal,
      showHiddenNotifications,
      toggleHiddenNotifications,
      getNotifications,
    } = this.props

    const showHiddenNotificationsBtn = (isHidden) => {
      return (
        <div>
          <Button
            className="hyd-link-btn-2"
            type="link"
            htmlType="button"
            onClick={() => {
              toggleHiddenNotifications(isHidden)
              isHidden ? getNotifications({is_hidden: true}) : getNotifications()
            }}
          >
            Скрытые уведомления
          </Button>
        </div>
      )
    }

    return (
      <Modal
        visible={visibleNotificationsModal}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        width='519px'
        bodyStyle={{overflowY: 'auto', maxHeight: 'calc(80vh - 200px)'}}
        closable={false}
        title={false}
        footer={false}
      >
        {
          isRequest ?
            <div><Spin/><Skeleton active={true}/></div> :
            <>
              {showHiddenNotifications && showHiddenNotificationsBtn(false)}
              {
                notifications && notifications.length ?
                  <>
                    <Divider/> {notifications.map((notification) => (
                    <div>
                      <div className={"hyd-notifications-grey-txt"}>
                        {notification["send_time"]} {moment(notification["send_date"]).format("DD.MM.YY")}
                      </div>
                      <span style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{width: 450, display: 'inline-block'}}>
                          {notification.message}
                        </span>
                        <span
                          onClick={() => {
                            if (!showHiddenNotifications) {
                              let patchInfo = {
                                new_status: hiddenStatus,
                                notification_ids: [notification.id],
                              }
                              updateNotificationStatus(patchInfo)
                            }
                          }}
                        >
                          <DeleteIcon className={"notification-del-ico"}/>
                        </span>
                      </span>
                      <Divider/>
                    </div>
                  ))}
                  </> : "Уведомлений нет"
              }
              {!showHiddenNotifications && showHiddenNotificationsBtn(true)}
            </>
        }
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  isRequest: isRequestSelector(state),
  notifications: notificationsSelector(state),
  visibleNotificationsModal: visibleNotificationsModalSelector(state),
  showHiddenNotifications: showHiddenNotificationsSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
      toggleNotificationsModal,
      toggleHiddenNotifications,
      updateNotificationStatus,
      getNotifications,
    },
    dispatch
  )

const WrappedModalViewDocsForm = Form.create(
  {name: 'notifications'})(ModalNotifications
)

export default connect(mapStateToProps, mapDispatchToProps)(WrappedModalViewDocsForm)

import React, {Component} from "react";
import {
  Row,
  Form,
  Button,
  Radio,
  Select,
  Tooltip,
  Input,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Cleave from "cleave.js/react";
import {
  goToPrevStep,
  goToNextStep,
  setDataForm,
  sendDataForm,
  getInsurances
} from "../../actions/osagoActions";
import {
  isRequestSelector,
  currentStepSelector,
  dataFormSelector,
  errorsSelector,
  visibleKbmModalSelector,
  visibleResultKbmModalSelector,
  insurancesSelector
} from "../../selectors/osagoSelectors";

import {
  hasErrors,
  transform,
  splitDate,
  frontDateFormat,
  getDateFromString,
} from "../../utils";
import ModalError from "../../components/ModalError";
import FirstStepDisabled from "../../components/OsagoDisabledSteps/FirstStepDisabled";
import SecondStepDisabled from "../../components/OsagoDisabledSteps/SecondStedDisabled";
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import {ReactComponent as ExclamationIcon} from "../../assets/Icons/Exclamation.svg";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import ThirdStepDisabled from "../../components/OsagoDisabledSteps/ThirdStepDisabled";
import {ReactComponent as NumberInactiveIcon} from "../../assets/Icons/NumberInactive.svg";
import moment from "moment";

const {Option} = Select;

export const contract_time_options = [
  {
    value: 1,
    text: "транзит (20 дней)"
  },
  {
    value: 2,
    text: "от 16 дней до 1 месяца"
  },
  {
    value: 3,
    text: "2 месяца"
  },
  {
    value: 4,
    text: "3 месяца"
  },
  {
    value: 5,
    text: "4 месяца"
  },
  {
    value: 6,
    text: "5 месяцев"
  },
  {
    value: 7,
    text: "6 месяцев"
  },
  {
    value: 8,
    text: "7 месяцев"
  },
  {
    value: 9,
    text: "8 месяцев"
  },
  {
    value: 10,
    text: "9 месяцев"
  },
  {
    value: 11,
    text: "10 месяцев и более"
  },
]

export const usage_time_options = [
  {
    value: 9,
    text: "транзит (20 дней)"
  },
  {
    value: 1,
    text: "3 месяца"
  },
  {
    value: 2,
    text: "4 месяца"
  },
  {
    value: 3,
    text: "5 месяцев"
  },
  {
    value: 4,
    text: "6 месяцев"
  },
  {
    value: 5,
    text: "7 месяцев"
  },
  {
    value: 6,
    text: "8 месяцев"
  },
  {
    value: 7,
    text: "9 месяцев"
  },
  {
    value: 8,
    text: "10 месяцев и более"
  },
]

export const purpose_use_options = [
  {
    value: 2,
    text: "Временный въезд в РФ"
  },
  {
    value: 3,
    text: "Дорожные и специальные ТС"
  },
  {
    value: 4,
    text: "Личная"
  },
  {
    value: 5,
    text: "Перевозка опасных грузов"
  },
  {
    value: 6,
    text: "Прокат/краткосрочная аренда"
  },
  {
    value: 7,
    text: "Прочее"
  },
  {
    value: 8,
    text: "Пассажирские перевозки"
  },
  /* <Option value={9}>Следование к месту регистрации/проведения ТО</Option> */
  {
    value: 10,
    text: "Такси, маршрутный таксомотор"
  },
  {
    value: 11,
    text: "Учебная езда"
  },
  {
    value: 12,
    text: "Экстренные и коммунальные службы"
  },
  /* <Option value={13}>Автомобиль зарегистрирован в иностранном государстве</Option> */
]

class FourthStep extends Component {
  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  state = {
    currentYear: null,
    isBtnDisabled: false,
  };

  componentDidMount() {
    this.headerRef.current.scrollIntoView()
    this.setState(
      {
        currentYear: new Date().getFullYear(),
        currentDate: new Date(),
      }
    );
    const {getInsurances} = this.props
    getInsurances()
    this.props.form.validateFields(["start_policy_date", "start_vehicle_date"], {force: true})
  }

  componentDidUpdate(prevProps) {
    const {
      errors,
    } = this.props;

    // if (
    //   isRequest !== prevProps.isRequest &&
    //   !isRequest &&
    //   !errors &&
    //   !visibleKbmModal &&
    //   !visibleResultKbmModal &&
    //   !visibleTOModal &&
    //   !visibleChoiceTOModal &&
    //   !visibleExistTOModal &&
    //   !buttonModalTO
    // ) {
    //   goToNextStep(currentStep + 1);
    // }

    if (errors !== prevProps.errors && errors) {
      // alert(errors)
    }
  }

  handleSubmit = (e) => {
    const {sendDataForm, currentStep} = this.props

    e.preventDefault()

    this.props.form.validateFields((err) => {
      if (!err) {
        this.setState({isBtnDisabled: true})
        goToNextStep(currentStep + 1)
        sendDataForm()
      }
    })
  }

  changeInsurDurationDate = (value, date) => {
    const {getFieldValue} = this.props.form;

    let cur_date = date !== undefined ? date : getFieldValue("start_policy_date")
    this.props.form.setFieldsValue({start_vehicle_date: cur_date});
    cur_date = cur_date.split(".");

    const obj = [
      {value: 2, duration: 1, vehicle_value: 1},
      {value: 3, duration: 2, vehicle_value: 1},
      {value: 4, duration: 3, vehicle_value: 1},
      {value: 5, duration: 4, vehicle_value: 2},
      {value: 6, duration: 5, vehicle_value: 3},
      {value: 7, duration: 6, vehicle_value: 4},
      {value: 8, duration: 7, vehicle_value: 5},
      {value: 9, duration: 8, vehicle_value: 6},
      {value: 10, duration: 12, vehicle_value: 7},
      {value: 11, duration: 12, vehicle_value: 8},
    ]; //duration в месяцах

    if (value === 1) {
      let result_date = new Date(
        +cur_date[2],
        +cur_date[1] - 1,
        +cur_date[0] + 19
      );
      let arr_date = splitDate(result_date);
      result_date = arr_date[0] + "." + arr_date[1] + "." + arr_date[2];
      this.props.form.setFieldsValue({finish_policy_date: result_date});
      this.props.form.setFieldsValue({finish_vehicle_date: result_date});
      this.props.form.setFieldsValue({vehicle_duration: 9});  // транзит периода использования авто
    }

    obj.map((item) => {
      if (item.value === value) {
        let result_date = new Date(
          +cur_date[2],
          +cur_date[1] - 1 + item.duration,
          +cur_date[0] - 1
        );
        let arr_date = splitDate(result_date);
        result_date = arr_date[0] + "." + arr_date[1] + "." + arr_date[2];
        this.props.form.setFieldsValue({finish_policy_date: result_date});
        this.props.form.setFieldsValue({finish_vehicle_date: result_date});
        this.props.form.setFieldsValue({vehicle_duration: item.vehicle_value});
      }
    });
  };

  changeVehicleDurationDate = (value, date) => {
    const {getFieldValue} = this.props.form;

    let cur_date = date !== undefined ? date : getFieldValue("start_policy_date")
    cur_date = cur_date.split(".");
    const obj = [
      {value: 1, duration: 3},
      {value: 2, duration: 4},
      {value: 3, duration: 5},
      {value: 4, duration: 6},
      {value: 5, duration: 7},
      {value: 6, duration: 8},
      {value: 7, duration: 12},
      {value: 8, duration: 12},
    ]; //duration в месяцах

    if (value === 9) {
      let result_date = new Date(
        +cur_date[2],
        +cur_date[1] - 1,
        +cur_date[0] + 19
      );
      let arr_date = splitDate(result_date);
      result_date = arr_date[0] + "." + arr_date[1] + "." + arr_date[2];
      this.props.form.setFieldsValue({finish_vehicle_date: result_date});
    }

    obj.map((item) => {
      if (item.value === value) {
        let result_date = new Date(
          +cur_date[2],
          +cur_date[1] - 1 + item.duration,
          +cur_date[0] - 1
        );
        let arr_date = splitDate(result_date);
        result_date = arr_date[0] + "." + arr_date[1] + "." + arr_date[2];
        this.props.form.setFieldsValue({finish_vehicle_date: result_date});
      }
    });
  };

  checkValueDate = (rule, value, callback) => {
    if (value) {
      let resultCurrentDate = new Date();

      if (moment(value, frontDateFormat, true).isValid()) {
        let result = getDateFromString(value.split("."))
        result.setHours(0, 0, 0, 0);
        resultCurrentDate.setHours(0, 0, 0, 0);

        if (result && result < resultCurrentDate) {
          callback(" ");
        } else {
          callback();
        }
      } else {
        callback(" ");
      }
    }
  }


  render() {
    const {
      currentStep,
      goToPrevStep,
      dataForm,
      insurances,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
    } = this.props.form;

    const startPolicyDate = "start_policy_date";
    const startPolicyDateError = getFieldError(startPolicyDate);

    const finishPolicyDate = "finish_policy_date";
    const finishPolicyDateError = getFieldError(finishPolicyDate);

    const startVehicleDate = "start_vehicle_date";
    const startVehicleDateError = getFieldError(startVehicleDate);

    const finishVehicleDate = "finish_vehicle_date";
    const finishVehicleDateError = getFieldError(finishVehicleDate);

    const prevPolicySerial = "previous_policy_serial";
    const prevPolicySerialError = getFieldError(prevPolicySerial);

    const prevPolicyNumber = "previous_policy_number";
    const prevPolicyNumberError = getFieldError(prevPolicyNumber);

    const vehicleDurationError = getFieldError("vehicle_duration");
    const trailerError = getFieldError("trailer");
    // const rentError = getFieldError("rent");
    const purposeUseError = getFieldError("purpose_use");
    const prevPolicyInsuranceError = getFieldError("previous_policy_insurance_company");
    const insurDurationError = getFieldError("insur_duration");

    return (
      <>
        <FirstStepDisabled
          goToPrevStep={() => goToPrevStep(currentStep - 3)}
        />
        <SecondStepDisabled
          dataForm={dataForm}
          goToPrevStep={() => goToPrevStep(currentStep - 2)}
        />
        <ThirdStepDisabled
          dataForm={dataForm}
          goToPrevStep={() => goToPrevStep(currentStep - 1)}
        />
        <div className="step-title-active" ref={this.headerRef}>
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">4</span>
          </div>
          <span className="step-title-active-header">Условия использования</span>
        </div>
        <Form layout="inline" onSubmit={this.handleSubmit}>
          <Row>
            {/* Срок действия договора */}
            <Form.Item
              validateStatus={insurDurationError ? "error" : ""}
              help={insurDurationError || ""}
            >
              <div className="hyd-osago-8-input hyd-select-small">
                {getFieldDecorator("duration", {
                  initialValue: 1,
                  rules: [{required: true, message: " "}],
                })(
                  <Select
                    className="hyd-input-select-small"
                    style={{width: "100%"}}
                    onChange={(value) => {
                      this.changeInsurDurationDate(value)
                    }}
                  >
                    {contract_time_options.map(({value, text}) =>
                      <Option value={value} key={value}>
                        <div className={"hyd-select-tooltip"}>Срок действия договора</div>
                        <div className={"hyd-select-value"}>{text}</div>
                      </Option>
                    )}
                  </Select>
                )}
              </div>
            </Form.Item>
            {/* Дата начала действия полиса */}
            <Form.Item
              validateStatus={startPolicyDateError ? "error" : ""}
              help={startPolicyDateError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input"
                htmlFor={"step-4_" + startPolicyDate}
                labelText={"Дата начала действия полиса"}
                divId={"float-label-small"}
                child={getFieldDecorator(startPolicyDate, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " ",
                    },
                    {
                      validator: this.checkValueDate,
                    },
                  ],
                })(
                  <Cleave
                    className="ant-input hyd-input-small hyd-osago-8-input"
                    key={JSON.stringify({
                      date: true,
                      delimiter: ".",
                      datePattern: ["d", "m", "Y"],
                    })}
                    options={{
                      date: true,
                      delimiter: ".",
                      datePattern: ["d", "m", "Y"],
                    }}
                    onChange={(e) => {
                      let dur = dataForm.duration.value
                      this.changeInsurDurationDate(dur, e.target.value)
                    }}
                  />
                )}
              />
            </Form.Item>

            {/* Дата окончания действия полиса */}
            <Form.Item
              validateStatus={finishPolicyDateError ? "error" : ""}
              help={finishPolicyDateError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input hyd-osago-no-margin"
                htmlFor={"step-4_" + finishPolicyDate}
                labelText={"Дата окончания действия полиса"}
                divId={"float-label-small"}
                child={getFieldDecorator(finishPolicyDate, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " ",
                    },
                    {
                      pattern: /^([0-9]{2}\.[0-9]{2}\.((19|20)[0-9])\d{1})$/g,
                      message: " ",
                    },
                  ],
                })(
                  <Cleave
                    className="ant-input hyd-input-small hyd-osago-8-input"
                    key={JSON.stringify({
                      date: true,
                      delimiter: ".",
                      datePattern: ["d", "m", "Y"],
                    })}
                    options={{
                      date: true,
                      delimiter: ".",
                      datePattern: ["d", "m", "Y"],
                    }}
                    disabled={true}
                  />
                )}
              />
            </Form.Item>
          </Row>
          <Row>
            {/* Период использования авто */}
            <Form.Item
              validateStatus={vehicleDurationError ? "error" : ""}
              help={vehicleDurationError || ""}
            >
              <div className="hyd-osago-8-input hyd-select-small">
                {getFieldDecorator("vehicle_duration", {
                  initialValue: 1,
                  rules: [{required: true, message: " "}],
                })(
                  <Select
                    className="hyd-input-select-small"
                    style={{width: "100%"}}
                    onChange={(value) => {
                      this.changeVehicleDurationDate(value)
                    }}
                  >
                    {usage_time_options.map(({value, text}) =>
                      <Option value={value} key={value}>
                        <div className={"hyd-select-tooltip"}>Период использования авто</div>
                        <div className={"hyd-select-value"}>{text}</div>
                      </Option>
                    )}
                  </Select>
                )}
              </div>
            </Form.Item>
            {/* Дата начала использования ТС */}
            <Form.Item
              validateStatus={startVehicleDateError ? "error" : ""}
              help={startVehicleDateError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input"
                htmlFor={"step-4_" + startVehicleDate}
                labelText={"Дата начала использования ТС"}
                divId={"float-label-small"}
                child={getFieldDecorator(startVehicleDate, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " ",
                    },
                    {
                      validator: this.checkValueDate,
                    },
                  ],
                })(
                  <Cleave
                    className="ant-input hyd-input-small hyd-osago-8-input"
                    key={JSON.stringify({
                      date: true,
                      delimiter: ".",
                      datePattern: ["d", "m", "Y"],
                    })}
                    options={{
                      date: true,
                      delimiter: ".",
                      datePattern: ["d", "m", "Y"],
                    }}
                    onChange={(e) => {
                      let dur = dataForm.duration.value
                      this.changeVehicleDurationDate(dur, e.target.value)
                    }}
                  />
                )}
              />
            </Form.Item>

            {/* Дата окончания использования ТС */}
            <Form.Item
              validateStatus={finishVehicleDateError ? "error" : ""}
              help={finishVehicleDateError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input hyd-osago-no-margin"
                htmlFor={"step-4_" + finishVehicleDate}
                labelText={"Дата окончания использования ТС"}
                divId={"float-label-small"}
                child={getFieldDecorator(finishVehicleDate, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " ",
                    },
                    {
                      validator: this.checkValueDate,
                      pattern: /^([0-9]{2}\.[0-9]{2}\.((19|20)[0-9])\d{1})$/g,
                      message: " ",
                    },
                  ],
                })(
                  <Cleave
                    className="ant-input hyd-input-small hyd-osago-8-input"
                    key={JSON.stringify({
                      date: true,
                      delimiter: ".",
                      datePattern: ["d", "m", "Y"],
                    })}
                    options={{
                      date: true,
                      delimiter: ".",
                      datePattern: ["d", "m", "Y"],
                    }}
                    disabled={true}
                  />
                )}
              />
            </Form.Item>
          </Row>
          <Row>
            {/* Цель использования */}
            <Form.Item
              validateStatus={purposeUseError ? "error" : ""}
              help={purposeUseError || ""}
            >
              <div className="hyd-osago-8-input hyd-select-small">
                {getFieldDecorator("purpose_use", {
                  initialValue: 1,
                  rules: [{required: true, message: " "}],
                })(
                  <Select
                    className="hyd-input-select-small"
                    style={{width: "100%"}}
                  >
                    {purpose_use_options.map(({value, text}) =>
                      <Option value={value} key={value}>
                        <div className={"hyd-select-tooltip"}>Цель использования</div>
                        <div className={"hyd-select-value"}>{text}</div>
                      </Option>
                    )}
                  </Select>
                )}
              </div>
            </Form.Item>
          </Row>
          <div className={"hyd-bold-text hyd-subtitle-2"}>
            Заполните для снижения стоимости полиса
            <Tooltip
              title={"Для снижения стоимости полиса\n" +
                "и пролонгации заполните поля\n" +
                "(СК, серия/номер полиса).\n" +
                "Не являются обязательными."}
              placement={"bottom"}
              overlayClassName={"hyd-tooltip"}
            >
              <span className={"hyd-s4-ico"}><ExclamationIcon/></span>
            </Tooltip>
          </div>
          <Row>
            <Form.Item
              validateStatus={prevPolicyInsuranceError ? "error" : ""}
              help={prevPolicyInsuranceError || ""}
            >
              <div className="hyd-osago-8-input hyd-select-small">
                {getFieldDecorator("previous_policy_insurance_company", {
                  // initialValue: undefined,
                  rules: [{required: false}],
                })(
                  <Select
                    placeholder={"СК предыдущего полиса"}
                    className="hyd-input-select-small"
                    style={{width: "100%"}}
                  >
                    {insurances && insurances.map(({id, short_name}) =>
                      <Option value={id} key={id}>
                        <div className={"hyd-select-tooltip"}>СК предыдущего полиса</div>
                        <div className={"hyd-select-value"}>{short_name}</div>
                      </Option>
                    )}
                  </Select>
                )}
              </div>
            </Form.Item>
            <Form.Item
              validateStatus={prevPolicySerialError ? "error" : ""}
              help={prevPolicySerialError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input"
                htmlFor={"step-3_" + prevPolicySerial}
                labelText={"Серия предыдущего полиса"}
                divId={"float-label-small"}
                child={getFieldDecorator(prevPolicySerial, {
                  rules: [
                    {
                      required: false,
                    },
                    {
                      pattern: /^[А-Яа-я]{3}$/g,
                      message: " "
                    }
                  ],
                })(<Input className={"hyd-input-small hyd-osago-8-input"} minLength={3} maxLength={3}/>)}
              />
            </Form.Item>
            <Form.Item
              validateStatus={prevPolicyNumberError ? "error" : ""}
              help={prevPolicyNumberError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input hyd-osago-no-margin"
                htmlFor={"step-3_" + prevPolicyNumber}
                labelText={"Номер предыдущего полиса"}
                divId={"float-label-small"}
                child={getFieldDecorator(prevPolicyNumber, {
                  rules: [
                    {
                      required: false,
                    },
                    {
                      pattern: /^[0-9]{10}$/g,
                      message: " ",
                    }
                  ],
                })(
                  <Input
                    maxLength={10}
                    className={"hyd-input-small hyd-osago-8-input"}
                  />
                )}
              />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              validateStatus={trailerError ? "error" : ""}
              help={trailerError || ""}
            >
              <div className={"hyd-bold-text hyd-subtitle"}>
                Наличие прицепа
              </div>
              {getFieldDecorator("trailer", {
                initialValue: 1,
                rules: [{required: true, message: " "}],
              })(
                <Radio.Group className={"hyd-radio-group hyd-radio-4"}>
                  <Radio value={1}>Нет</Radio>
                  <Radio value={2}>Да</Radio>
                </Radio.Group>
              )}
            </Form.Item>

            {/* Сдается в аренду */}
            {/*<Form.Item*/}
            {/*  validateStatus={rentError ? "error" : ""}*/}
            {/*  help={rentError || ""}*/}
            {/*>*/}
            {/*  <div className={"hyd-bold-text hyd-subtitle"}>*/}
            {/*    Сдается в аренду*/}
            {/*  </div>*/}
            {/*  {getFieldDecorator("rent", {*/}
            {/*    initialValue: 2,*/}
            {/*    rules: [{required: true, message: " "}],*/}
            {/*  })(*/}
            {/*    <Radio.Group className={"hyd-radio-group hyd-radio-4"}>*/}
            {/*      <Radio value={2}>Нет</Radio>*/}
            {/*      <Radio value={1}>Да</Radio>*/}
            {/*    </Radio.Group>*/}
            {/*  )}*/}
            {/*</Form.Item>*/}
          </Row>

          {/* Назад/Далее */}
          <Row>
            <Form.Item>
              <Button
                className="hyd-btn-small hyd-osago-btn-proceed"
                type="primary"
                htmlType="submit"
                disabled={hasErrors(getFieldsError()) || this.state.isBtnDisabled}
              >
                Продолжить
              </Button>
            </Form.Item>
          </Row>
        </Form>
        {[
          {number: 5, text: "Оформление полиса ОСАГО"},
          {number: 6, text: "Предварительный просмотр документов"},
        ].map(({number, text}) =>
          <div className="step-title-disabled" key={number}>
            <div className="step-icon-block">
              <NumberInactiveIcon className="hyd-large-num-ico"/>
              <span className="hyd-large-num-ico-num">{number}</span>
            </div>
            <span className="step-title-disabled-header">{text}</span>
          </div>
        )}
        <ModalError type={1}/>
      </>
    );
  }
}

const WrappedFourthStepForm = Form.create({
  name: "step-4",
  mapPropsToFields(props) {
    const {dataForm} = props;
    const transformed = transform({dataForm});

    return transformed.dataForm;
  },
  onFieldsChange(props, changedFields) {
    props.setDataForm(changedFields);
  },
})(FourthStep);

const mapStateToProps = (state) => ({
  isRequest: isRequestSelector(state),
  currentStep: currentStepSelector(state),
  dataForm: dataFormSelector(state),
  errors: errorsSelector(state),
  visibleKbmModal: visibleKbmModalSelector(state),
  visibleResultKbmModal: visibleResultKbmModalSelector(state),
  insurances: insurancesSelector(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPrevStep,
      goToNextStep,
      setDataForm,
      sendDataForm,
      getInsurances
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedFourthStepForm);

export const isRequestSelector = state => state.osagoReducer.isRequest;
export const creatingPolicySelector = state => state.osagoReducer.creatingPolicy;
export const currentStepSelector = state => state.osagoReducer.currentStep;
export const dataFormSelector = state => state.osagoReducer.dataForm;
export const dataFormResultSelector = state => state.osagoReducer.dataFormResult;
export const isProlongDisabledButtonsSelector = state => state.osagoReducer.isProlongDisabledButtons;
export const isProlongDisabledFieldsSelector = state => state.osagoReducer.isProlongDisabledFields;
export const isProlongCalcSuccessSelector = state => state.osagoReducer.isProlongCalcSuccess;
export const policyCreatedSelector = state => state.osagoReducer.policyCreated;
export const calcResultSelector = state => state.osagoReducer.calcResult;
export const paymentDocTypeSelector = state => state.osagoReducer.paymentDocType;
export const errorsSelector = state => state.osagoReducer.errors;
export const typeFormPersonStepTwoSelector = state => state.osagoReducer.typeFormPersonStepTwo;
export const typeFormPolicyOwnerStepTwoSelector = state => state.osagoReducer.typeFormPolicyOwnerStepTwo;
export const numberDriversStepThreeSelector = state => state.osagoReducer.numberDriversStepThree;
export const additionalDriversStepThreeSelector = state => state.osagoReducer.additionalDriversStepThree;
export const quantityAdditionalDriversSelector = state => state.osagoReducer.quantityAdditionalDrivers;
export const driverKBMSelector = state => state.osagoReducer.driver_kbm;
export const infoTOSelector = state => state.osagoReducer.infoTO;
export const typeCarIdentityIngosSelector = state => state.osagoReducer.typeCarIdentityIngos;
export const car_brandsSelector = state => state.osagoReducer.car_brands;
export const car_modelsSelector = state => state.osagoReducer.car_models;
export const modelModificationsSelector = state => state.osagoReducer.modelModifications;
export const countriesSelector = state => state.osagoReducer.countries;
export const infoPolicyOwnerJuristicSelector = state => state.osagoReducer.infoPolicyOwnerJuristic;
export const buttonTOSelector = state => state.osagoReducer.buttonTO;
export const inputCarPlateSelector = state => state.osagoReducer.withoutCarPlate;
export const inputVinSelector = state => state.osagoReducer.withoutVIN;
export const ingosVinSelector = state => state.osagoReducer.ingosWithoutVIN;
export const typeCarIdentitySelector = state => state.osagoReducer.typeCarIdentity;
export const typeTransportInsurerSelector = state => state.osagoReducer.typeTransportInsurer;
export const visibleErrorModalSelector = state => state.osagoReducer.visibleErrorModal;
export const visibleTOModalSelector = state => state.osagoReducer.visibleTOModal;
export const visibleChoiceTOModalSelector = state => state.osagoReducer.visibleChoiceTOModal;
export const visibleExistTOModalSelector = state => state.osagoReducer.visibleExistTOModal;
export const visibleResultKbmModalSelector = state => state.osagoReducer.visibleResultKbmModal;
export const documentsPolicySelector = state => state.osagoReducer.documentsPolicy;
export const adressArrSelector = state => state.osagoReducer.adressArr;
export const policyAdressArrSelector = state => state.osagoReducer.policyAdressArr;
export const selectedAdressSelector = state => state.osagoReducer.selectedAdress;
export const selectedCarOwnerAdressSelector = state => state.osagoReducer.selectedCarOwnerAdress;
export const visibleKbmModalSelector = state => state.osagoReducer.visibleKbmModal;
export const driverKbmSelector = state => state.osagoReducer.driverKbm;
export const isSelectedBrandSelector = state => state.osagoReducer.isSelectedBrand;
export const isSelectedModelSelector = state => state.osagoReducer.isSelectedModel;
export const calcIdSelector = state => state.osagoReducer.calc_id;
export const correlationIdSelector = state => state.osagoReducer.correlation_id;
export const lastNameArrSelector = state => state.osagoReducer.lastNameArr;
export const firstNameArrSelector = state => state.osagoReducer.firstNameArr;
export const genGenderSelector = state => state.osagoReducer.genGender;
export const patronymicArrSelector = state => state.osagoReducer.patronymicArr;
export const configAdminSelector = state => state.osagoReducer.configAdmin;
export const calculationInProgressSelector = state => state.osagoReducer.calculationInProgress;
export const visibleProlongationModalSelector = state => state.osagoReducer.visibleProlongationModal;
export const policyAdressJuristicArrSelector = state => state.osagoReducer.policyAdressJuristicArr;
export const selectedPolicyOwnerJuristicAdressSelector = state => state.osagoReducer.selectedPolicyOwnerJuristicAdress;
export const carAdressJuristicArrSelector = state => state.osagoReducer.carAdressJuristicArr;
export const selectedCarOwnerJuristicAdressSelector = state => state.osagoReducer.selectedCarOwnerJuristicAdress;
export const visibleOsagoModalSelector = state => state.osagoReducer.visibleOsagoModal;
export const visibleKPModalSelector = state => state.osagoReducer.visibleKPModal;
export const buttonModalTOSelector = state => state.osagoReducer.buttonModalTO;
export const visibleReasonTOSelector = state => state.osagoReducer.visibleReasonTO;
export const innInfoKbmModalSelector = state => state.osagoReducer.innInfoKbmModal;
export const keyNameInsuranceSelector = state => state.osagoReducer.keyNameInsurance;
export const visibleBSOModalSelector = state => state.osagoReducer.visibleBSOModal;
export const documentsBSOSelector = state => state.osagoReducer.documentsBSO;
export const documentsNSSSelector = state => state.osagoReducer.documentsNSS;
export const paymentDocsSelector = state => state.osagoReducer.paymentDocs;
export const paymentStatusSelector = state => state.osagoReducer.payment_status;
export const alfaDocsSelector = state => state.osagoReducer.alfa_docs;
export const ingosDocsSelector = state => state.osagoReducer.ingostrah_docs;
export const insuranceDocumentsSelector = state => state.osagoReducer.insuranceDocuments;
export const renessansDocsSelector = state => state.osagoReducer.renessans_docs;
export const insurancesSelector = state => state.osagoReducer.insurances;
export const vehicleDurationSelector = state => state.osagoReducer.dataForm.vehicle_duration.value;
export const visibleSberQRModalSelector = state => state.osagoReducer.visibleSberQRModal;
export const sberPaymentUrlSelector = state => state.osagoReducer.sberPaymentUrl;
export const sberQRImageSelector = state => state.osagoReducer.sberQRImage;
export const draftIdSelector = state => state.osagoReducer.dataForm.draft_id;
export const addendumFileListSelector = state => state.osagoReducer.addendumFileList;
export const isFormSuccessfullySentSelector = state => state.osagoReducer.isFormSuccessfullySent;
export const visibleModalAddendumApplicationSelector = state => state.osagoReducer.visibleModalAddendumApplication;

import * as types from "../store/actionTypes";

export const saveKladr = (
  value,
  address,
  okato,
  country,
  region,
  zip,
  city_name,
  city_kladr,
  street_kladr,
  street_name,
  house,
  block,
  flat,
  fias_id,
  house_fias_id,
) => ({
  type: types.SAVE_KLADR,
  payload: {
    value,
    address,
    okato,
    country,
    region,
    zip,
    city_name,
    city_kladr,
    street_kladr,
    street_name,
    house,
    block,
    flat,
    fias_id,
    house_fias_id,
  }
});

export const getInsurerKladr = value => ({
  type: types.GET_INSURER_KLADR,
  payload: value
});

export const getKindsOfSports = () => ({
  type: types.GET_KINDS_OF_SPORTS,
});

export const saveSportKindData = data => ({
  type: types.SAVE_SPORT_KIND_DATA,
  payload: data
});

export const setDataAccidentsSportForm = (data, object) => ({
  type: types.SET_DATA_ACCIDENTS_SPORT_FORM,
  payload: {data, object},
});

export const goToNextStep = step => ({
  type: types.GO_TO_NEXT_STEP_ACCIDENTS_SPORT,
  payload: step
});

export const goToPrevStep = step => ({
  type: types.GO_TO_PREV_STEP_ACCIDENTS_SPORT,
  payload: step
});

export const getSum = value => ({
  type: types.GET_SUM,
  payload: value
});

export const toggleInsurerSelectedAddress = value => ({
  type: types.SELECTED_INSURER_ADDRESS,
  payload: value
});

export const toggleIsSelectedSport = value => ({
  type: types.TOOGLE_IS_SELECTED_SPORT,
  payload: value
});

export const changeQuantityAdditionalInsurers = quantity => ({
  type: types.CHANGE_QUANTITY_ADDITIONAL_INSURERS,
  payload: quantity
});

export const removeInsurerFromListInsurers = key => ({
  type: types.REMOVE_INSURER_FROM_LIST_INSURERS,
  payload: key
});

export const sendDataAccidentSportForm = () => ({
  type: types.SEND_DATA_ACCIDENT_SPORT_FORM
});

export const createPolicyAccidentSport = (value) => ({
  type: types.CREATE_POLICY_ACCIDENT_SPORT,
  payload: {value}
});

export const getPaymentStatusAccidentSport = value => ({
  type: types.GET_PAYMENT_STATUS_ACCIDENT_SPORT,
  payload: value,
});



export const clearForm = (type) => ({
  type: types.CLEAR_FORM,
  payload: type,
});

export const clearIsRequestState = () => ({
  type: types.CLEAR_IS_REQUEST_STATE
});

import React from "react";
import {ReactComponent as NumberInactiveIcon} from "../../../assets/Icons/NumberInactive.svg";
import {Button, Row} from "antd";
import {contract_time_options, purpose_use_options, usage_time_options} from "../../../pages/Osago/step-4";

// const rent_options = [
//   {
//     value: 1,
//     text: "Да"
//   },
//   {
//     value: 2,
//     text: "Нет"
//   },
// ]
const trailer_options = [
  {
    value: 2,
    text: "Да"
  },
  {
    value: 1,
    text: "Нет"
  },
]
export default class FourthStepDisabled extends React.Component {
  render() {
    const {
      dataForm,
      goToPrevStep,
      isDisabled
    } = this.props
    return (
      <>
        <div className="step-title-disabled">
          <div className="step-icon-block">
            <NumberInactiveIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">4</span>
          </div>
          <span className="step-title-disabled-header">Условия использования</span>
          {goToPrevStep && !isDisabled &&
          <Button
            className="hyd-change-button-small"
            onClick={goToPrevStep}
          >
            Изменить
          </Button>}
        </div>
        <Row>
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Срок действия договора</div>
            <div className={"hyd-prev-step-value"}>
              {contract_time_options.find(e => dataForm.duration.value === e.value).text}
            </div>
          </div>
          {dataForm.start_policy_date.value &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата начала действия полиса</div>
            <div className={"hyd-prev-step-value"}>{dataForm.start_policy_date.value}</div>
          </div>}
          {dataForm.finish_policy_date.value &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата окончания действия полиса</div>
            <div className={"hyd-prev-step-value"}>{dataForm.finish_policy_date.value}</div>
          </div>}
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Период использования авто</div>
            <div className={"hyd-prev-step-value"}>
              {usage_time_options.find(e => dataForm.vehicle_duration.value === e.value).text}
            </div>
          </div>
          {dataForm.start_vehicle_date.value &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата начала использования</div>
            <div className={"hyd-prev-step-value"}>{dataForm.start_vehicle_date.value}</div>
          </div>}
          {dataForm.finish_vehicle_date.value &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата окончания использования</div>
            <div className={"hyd-prev-step-value"}>{dataForm.finish_vehicle_date.value}</div>
          </div>}
        </Row>
        <Row>
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Цель использования</div>
            <div className={"hyd-prev-step-value"}>
              {purpose_use_options.find(e => dataForm.purpose_use.value === e.value).text}
            </div>
          </div>
        </Row>
        <Row>
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Наличие прицепа</div>
            <div className={"hyd-prev-step-value"}>
              {trailer_options.find(e => dataForm.trailer.value === e.value).text}
            </div>
          </div>
          {/*<div className={"hyd-prev-step"}>*/}
          {/*  <div className={"hyd-prev-step-header"}>Сдается в аренду</div>*/}
          {/*  <div className={"hyd-prev-step-value"}>*/}
          {/*    {rent_options.find(e => dataForm.rent.value === e.value).text}*/}
          {/*  </div>*/}
          {/*</div>*/}
        </Row>
      </>
    )
  }
}
export const isRequestSelector = state => state.profileReducer.isRequest;
export const errorsProfileSelector = state => state.profileReducer.errors;
export const visibleErrorModalProfileSelector = state => state.profileReducer.visibleErrorModal;
export const userAddressArrSelector = state => state.profileReducer.userAddressArr;
export const dataFormSelector = state => state.profileReducer.dataForm;
export const creditLimitSelector = state => state.profileReducer.dataForm.credit_limit;
export const isEditModeOnSelector = state => state.profileReducer.isEditModeOn;
export const updatingCompleteSelector = state => state.profileReducer.updatingComplete;
export const resetPasswordCompleteSelector = state => state.profileReducer.resetPasswordComplete;
export const cardPaymentSystemSelector = state => state.profileReducer.cardPaymentSystem;
export const regionsSelector = state => state.profileReducer.regions;
export const isGenerateRefLinkCompleteSelector = state => state.profileReducer.isGenerateRefLinkComplete;
export const generatedReferralLinkSelector = state => state.profileReducer.generatedReferralLink;
export const QRCodeImageSelector = state => state.profileReducer.QRCodeImage;

export const isRequestSelector = state => state.accidentsSportReducer.isRequest;
export const calcResultSelector = state => state.accidentsSportReducer.calcResult;
export const currentStepSelector = state => state.accidentsSportReducer.currentStep;
export const documentsSelector = state => state.accidentsSportReducer.documents;
export const paymentDocsSelector = state => state.accidentsSportReducer.paymentDocs;
export const paymentStatusSelector = state => state.accidentsSportReducer.paymentStatus;
export const errorsAccidentsSportSelector = state => state.accidentsSportReducer.errors;
export const kindsOfSportsSelector = state => state.accidentsSportReducer.kindsOfSports;
export const isSelectedSportSelector = state => state.accidentsSportReducer.isSelectedSport;
export const documentsPolicySelector = state => state.accidentsSportReducer.documentsPolicy;
export const insurerAddressArrSelector = state => state.accidentsSportReducer.insurerAddressArr;
export const calculationInProgressSelector = state => state.accidentsSportReducer.calculationInProgress;
export const accidentsSportDataFormSelector = state => state.accidentsSportReducer.accidentsSportDataForm;
export const quantityAdditionalInsurersSelector = state => state.accidentsSportReducer.quantityAdditionalInsurers;
export const visibleErrorModalAccidentsSportSelector = state => state.accidentsSportReducer.visibleErrorModalAccidentsSport;

import React, {Component} from "react";
import {Form, Input, Button, Tabs, Select, AutoComplete, notification, Slider, Popconfirm, Tooltip} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {checkValueBirthDate, frontDateFormat, getDateFromString, hasErrors, nameRules, transform,} from "../../utils";

import "./styles/index.scss";
import "../../fonts/open-sans.css"
import ModalError from "../../components/ModalError";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import {ReactComponent as MirIcon} from "../../assets/Icons/cardSystems/mir.svg";
import {ReactComponent as JcbIcon} from "../../assets/Icons/cardSystems/jcb.svg";
import {ReactComponent as VisaIcon} from "../../assets/Icons/cardSystems/visa.svg";
import {ReactComponent as AmexIcon} from "../../assets/Icons/cardSystems/amex.svg";
import {ReactComponent as DinersIcon} from "../../assets/Icons/cardSystems/diners.svg";
import {ReactComponent as MaestroIcon} from "../../assets/Icons/cardSystems/maestro.svg";
import {ReactComponent as UnionpayIcon} from "../../assets/Icons/cardSystems/unionpay.svg";
import {ReactComponent as DiscoverIcon} from "../../assets/Icons/cardSystems/discover.svg";
import {ReactComponent as MastercardIcon} from "../../assets/Icons/cardSystems/mastercard.svg";
import {ReactComponent as ArrowRightSmallIcon} from "../../assets/Icons/ArrowRightSmall.svg";
import {ReactComponent as DocumentBlueIcon} from "../../assets/Icons/DocumentBlue.svg";
import {
  isRequestSelector,
  userAddressArrSelector,
  dataFormSelector,
  isEditModeOnSelector,
  errorsProfileSelector,
  updatingCompleteSelector,
  resetPasswordCompleteSelector,
  cardPaymentSystemSelector,
  creditLimitSelector,
  regionsSelector,
} from "../../selectors/profileSelectors";
import {userInfoSelector} from "../../selectors/loginSelectors";
import {
  clearIsRequestState,
  getProfile,
  setDataForm,
  getUserKladr,
  saveUserAddress,
  toggleUserSelectedAddress,
  saveCardPaymentSystem,
  toggleEditMode,
  toggleInnSnilsEditMode,
  updateProfile,
  resetPassword,
  getRegions,
} from "../../actions/profileActions";
import {checkEarnedMoneyAmount, getNotifications} from "../../actions/mainActions"
import Cleave from "cleave.js/react";
import Preloader from "../../components/Preloader";
import * as moment from "moment";
import * as keys from "../../routers/keys";

const {Option} = Select;
const {TabPane} = Tabs;
const agentsTxt = "Вы можете создать свою агентскую сеть и получать дополнительный доход. Подробнее у Вашего менеджера."

class Profile extends Component {
  componentDidMount() {
    document.title = "Профиль"

    const {
      getProfile,
      getRegions,
      toggleInnSnilsEditMode,
      toggleEditMode,
      clearIsRequestState,
      getNotifications,
      checkEarnedMoneyAmount,
    } = this.props
    getNotifications()
    clearIsRequestState()
    toggleEditMode(false)
    toggleInnSnilsEditMode(false)
    getRegions()
    getProfile()
    checkEarnedMoneyAmount()
  }

  componentDidUpdate(prevProps, prevState, snapshot?) {
    const {isRequest, updatingComplete, resetPasswordComplete,} = this.props
    if (updatingComplete !== prevProps.updatingComplete && !isRequest && updatingComplete) {
      notification['success']({
        message: `Профиль успешно обновлён`
      })
    }
    if (resetPasswordComplete !== prevProps.resetPasswordComplete && !isRequest && resetPasswordComplete) {
      notification['info']({
        message: `На ваш телефонный номер отправлено SMS с паролем.`
      })
    }
  }

  handleSubmit = (e) => {
    const {updateProfile} = this.props;

    e.preventDefault();
    this.props.form.validateFields((err, values, force = true) => {
      if (!err) {
        updateProfile(e.target.id)
      } else {
        console.log(err)
      }
    })
  }

  checkValueExperienceDate = (rule, value, callback) => {
    if (value) {
      let resultCurrentDate = new Date()
      let resultPastDate = new Date(new Date().setFullYear(new Date().getFullYear() - 55))
      let resultValueDate = value.split('.')
      if (moment(value, frontDateFormat, true).isValid()) {
        let result = getDateFromString(resultValueDate)
        if (result && (resultCurrentDate < result) || (result < resultPastDate)) {
          callback(" ")
        } else {
          callback()
        }
      } else {
        callback(" ")
      }
    } else {
      callback()
    }
  }

  checkValueCardDate = (rule, value, callback) => {
    if (value) {
      let currMonth = moment().startOf('month')
      let cardMonth = moment(value, "MM/YY")
      if (value.length === 5) {
        if (cardMonth < currMonth) {
          callback(" ")
        } else {
          callback()
        }
      } else {
        callback(" ")
      }
    } else {
      callback()
    }
  }

  onCardPaymentSystemChanged = (system) => {
    // system = amex mastercard visa diners discover jcb dankort instapayment uatp mir unionPay
    this.props.saveCardPaymentSystem(system)
  }

  render() {
    const {
      isRequest,
      getUserKladr,
      saveUserAddress,
      addressArr,
      toggleUserSelectedAddress,
      cardPaymentSystem,
      toggleEditMode,
      toggleInnSnilsEditMode,
      resetPassword,
      isEditModeOn,
      dataForm,
      creditLimit,
      regions,
      userInfo,
      history,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      getFieldValue,
      setFieldsValue,
    } = this.props.form;

    const marks = {
      0: {
        style: {
          fontSize: 16,
          color: "var(--color-two)",
          marginLeft: 11,
        },
        label: "0 ₽"
      },
      [creditLimit && Number(creditLimit["value"])]: {
        style: {
          fontSize: 16,
          color: "var(--color-two)",
          width: 180,
          paddingRight: 68,
        },
        label: creditLimit && Intl.NumberFormat('ru-RU').format(creditLimit["value"]) + " ₽"
      },
    }

    const receivables = undefined

    const username = "username";
    const usernameError = getFieldError(username);

    const accountType = "account_type";

    const lastName = "last_name";
    const lastNameError = getFieldError(lastName);

    const firstName = "first_name";
    const firstNameError = getFieldError(firstName);

    const middleName = "middle_name";
    const middleNameError = getFieldError(middleName);

    const phone = "phone";
    const phoneError = getFieldError(phone);

    const email = "email";
    const emailError = getFieldError(email);

    const region = "region";
    const regionError = getFieldError(region);


    const passport = "passport";
    const passportError = getFieldError(passport);

    const passportIssuedBy = "passport_issued_by";
    const passportIssuedByError = getFieldError(passportIssuedBy);

    const passportDate = "passport_date";
    const passportDateError = getFieldError(passportDate);

    const passportUniteCode = "passport_unite_code";
    const passportUniteCodeError = getFieldError(passportUniteCode);

    const address = "address";
    const addressError = getFieldError(address);

    const gender = "gender";

    const birthDate = "birth_date";
    const birthDateError = getFieldError(birthDate);


    const inn = "inn";
    const innError = getFieldError(inn);

    const snils = "snils";
    const snilsError = getFieldError(snils);


    const cardBankNum = "card_bank_num";
    const cardBankNumError = getFieldError(cardBankNum);

    const cardValidDate = "card_valid_date";
    const cardValidDateError = getFieldError(cardValidDate);

    const cardHolderName = "card_holder_name";
    const cardHolderNameError = getFieldError(cardHolderName);


    const bankRecipient = "bank_recipient";
    const bankRecipientError = getFieldError(bankRecipient);

    const bankAccount = "bank_account";
    const bankAccountError = getFieldError(bankAccount);

    const bankName = "bank_name";
    const bankNameError = getFieldError(bankName);

    const bankBik = "bank_bik";
    const bankBikError = getFieldError(bankBik);

    const bankKs = "bank_ks";
    const bankKsError = getFieldError(bankKs);

    const bankDivisionCode = "bank_division_code";
    const bankDivisionCodeError = getFieldError(bankDivisionCode);

    const bankDivisionAddress = "bank_division_address";
    const bankDivisionAddressError = getFieldError(bankDivisionAddress);


    return (
      <>
        <Preloader loading={isRequest}/>
        <div className="profile-container">
          <div className="float-child-profile" style={{width: 627}}>
            <span className="hyd-very-big-bold-text-small">Профиль</span>

            <Tabs className="hyd-tab hyd-tab-profile" defaultActiveKey="1" onChange={() => {
              toggleEditMode(false)
              toggleInnSnilsEditMode(false)
            }}>

              <TabPane tab={<span className="hyd-tab-pane">Аккаунт</span>} key="1">
                <Form
                  layout="inline"
                  onSubmit={this.handleSubmit}
                  id="profile-account"
                >
                  <div className="hyd-row-profile">
                    {/* Логин */}
                    <Form.Item
                      validateStatus={usernameError ? "error" : ""}
                      help={usernameError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + username}
                        labelText={"Логин"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(username, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                          ],
                        })(<Input className="hyd-input-small hyd-input-profile" disabled/>)}
                      />
                    </Form.Item>

                    {/* Тип аккаунта */}
                    <Form.Item
                      required={false}
                    >
                      <div className="hyd-select-small hyd-select-profile">
                        {getFieldDecorator(accountType, {
                          initialValue: "FIZ",
                          rules: [{required: true, message: " "}]
                        })(
                          <Select
                            className="hyd-input-select-small"
                            style={{width: "100%"}}
                            placeholder="Тип аккаунта"
                            disabled={!isEditModeOn}
                          >
                            <Option value={"FIZ"}>
                              <div className={"hyd-select-tooltip"}>Тип аккаунта</div>
                              <div className={"hyd-select-value"}>Физическое лицо</div>
                            </Option>
                            <Option value={"IND"}>
                              <div className={"hyd-select-tooltip"}>Тип аккаунта</div>
                              <div className={"hyd-select-value"}>ИП</div>
                            </Option>
                            <Option value={"JUR"} disabled>
                              <div className={"hyd-select-tooltip"}>Тип аккаунта</div>
                              <div className={"hyd-select-value"}>Юридическое лицо</div>
                            </Option>
                          </Select>
                        )}
                      </div>
                    </Form.Item>
                  </div>

                  <div className="hyd-row-profile">
                    {/* Фамилия */}
                    <Form.Item
                      validateStatus={lastNameError ? "error" : ""}
                      help={lastNameError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + lastName}
                        labelText={"Фамилия"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(lastName, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: nameRules,
                        })(<Input className="hyd-input-small hyd-input-profile" disabled={!isEditModeOn}/>)}
                      />
                    </Form.Item>

                    {/* Email */}
                    <Form.Item
                      validateStatus={emailError ? "error" : ""}
                      help={emailError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + email}
                        labelText={"Email"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(email, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                            {
                              pattern: /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?\s*)+$/g,
                              message: " "
                            },
                          ],
                        })(<Input className="hyd-input-small hyd-input-profile" disabled/>)}
                      />
                    </Form.Item>
                  </div>

                  <div className="hyd-row-profile">
                    {/* Имя */}
                    <Form.Item
                      validateStatus={firstNameError ? "error" : ""}
                      help={firstNameError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + firstName}
                        labelText={"Имя"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(firstName, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: nameRules,
                        })(<Input className="hyd-input-small hyd-input-profile" disabled={!isEditModeOn}/>)}
                      />
                    </Form.Item>

                    {/* Телефон */}
                    <Form.Item
                      validateStatus={phoneError ? "error" : ""}
                      help={phoneError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + phone}
                        labelText={"Телефон"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(phone, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                            {
                              pattern: /^\+?([0-9])\)?[ ]?([0-9]{3})[- ]?([0-9]{3})[- ]?([0-9]{2})[- ]?([0-9]{2})$/g,
                              message: " "
                            },
                          ],
                        })(
                          <Cleave
                            className="ant-input hyd-input-small hyd-osago-8-input"
                            key={"phone-1"}
                            options={{
                              phone: true,
                              phoneRegionCode: 'RU',
                              prefix: "+7",
                              noImmediatePrefix: true,
                            }}
                            disabled
                          />
                        )}
                      />
                    </Form.Item>
                  </div>

                  <div className="hyd-row-profile">
                    {/* Отчество */}
                    <Form.Item
                      validateStatus={middleNameError ? "error" : ""}
                      help={middleNameError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + middleName}
                        labelText={"Отчество"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(middleName, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              // только лат или только кириллица (допускается двойное имя с пробелом по центру или тире)
                              pattern: /^[А-Яа-яЁёa-zA-Z() -]+$/g,
                              message: " ",
                            },
                          ],
                        })(<Input className="hyd-input-small hyd-input-profile" disabled={!isEditModeOn}/>)}
                      />
                    </Form.Item>

                    {/* Регион */}
                    <Form.Item
                      validateStatus={regionError ? "error" : ""}
                      help={regionError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-autocomplete-profile-input hyd-autocomplete-profile"
                        htmlFor={"profile_" + region}
                        labelText={"Регион"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(region, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [{required: true, message: " "}],
                        })(
                          <AutoComplete
                            className="hyd-input-autocomplete-small"
                            dataSource={regions && regions.map(_ => _.text)}
                            filterOption={(inputValue, option) =>
                              option.props.children
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                            disabled={!isEditModeOn}
                          />
                        )}
                      />
                    </Form.Item>
                  </div>

                  <div className="hyd-row-profile">
                    {!isEditModeOn &&
                    <Button
                      style={{marginTop: 12}}
                      className="hyd-btn-small"
                      onClick={() => {
                        toggleEditMode(true)
                      }}
                    >
                      Редактировать
                    </Button>}

                    {isEditModeOn &&
                    <Button
                      style={{marginTop: 12}}
                      className="hyd-btn-small"
                      type="primary"
                      htmlType="submit"
                      form="profile-account"
                      key="submit"
                      disabled={hasErrors(getFieldsError())}
                    >
                      Сохранить
                    </Button>}

                    <div className="hyd-row-profile hyd-sms-pwd">
                      <Popconfirm
                        placement="top"
                        title={"Будет сформирован новый пароль и отправлен по SMS. Продолжить?"}
                        onConfirm={resetPassword}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button className="hyd-btn-small">Изменить пароль</Button>
                      </Popconfirm>
                    </div>
                  </div>
                </Form>

              </TabPane>

              <TabPane tab={<span className="hyd-tab-pane">Личные данные</span>} key="2">
                <Form
                  layout="inline"
                  onSubmit={this.handleSubmit}
                  id="profile-personal-data"
                >
                  <div className="hyd-row-profile">
                    {/* Серия и номер паспорта */}
                    <Form.Item
                      validateStatus={passportError ? "error" : ""}
                      help={passportError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + passport}
                        labelText={"Серия и номер паспорта"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(passport, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                            {
                              pattern: /^[0-9]{4} [0-9]{6}$/g,
                              message: " "
                            },
                          ],
                        })(<Cleave
                          key={"passport"}
                          className="ant-input hyd-input-small hyd-osago-8-input"
                          options={{
                            blocks: [4, 6],
                            delimiter: ' ',
                            numericOnly: false
                          }}
                          disabled={!isEditModeOn}
                        />)}
                      />
                    </Form.Item>

                    {/* Адрес регистрации */}
                    <Form.Item
                      validateStatus={addressError ? "error" : ""}
                      help={addressError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-autocomplete-profile-input hyd-autocomplete-profile"
                        htmlFor={"profile_" + address}
                        labelText={"Адрес регистрации"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(address, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                          ],
                        })(
                          <AutoComplete
                            className="hyd-input-autocomplete-small"
                            dataSource={addressArr}
                            onSelect={(inputValue) => {
                              toggleUserSelectedAddress(true);
                              addressArr.map((value) => {
                                // достаем адрес из массива полученного из DaData
                                if (inputValue === value.value) {
                                  let valueOfInput = getFieldValue(address);

                                  if (valueOfInput.indexOf("_") !== -1) {
                                    valueOfInput = valueOfInput.split("_")
                                    setFieldsValue({
                                      address: valueOfInput[1],
                                    })
                                  }
                                  saveUserAddress(
                                    value.value_cladr,
                                    value.text,
                                    value.okato,
                                    value.country,
                                    value.region,
                                    value.zip,
                                    value.city_name,
                                    value.city_kladr,
                                    value.street_kladr,
                                    value.street_name,
                                    value.house,
                                    value.block,
                                    value.flat,
                                    value.fias_id,
                                    value.house_fias_id,
                                  );
                                }
                              });
                            }}

                            onChange={(value) => {
                              if (value && value.length > 1) {
                                getUserKladr({address: value});
                              }
                              toggleUserSelectedAddress(false);
                            }}
                            disabled={!isEditModeOn}
                          />
                        )}
                      />
                    </Form.Item>
                  </div>

                  <div className="hyd-row-profile">
                    {/* Кем выдан */}
                    <Form.Item
                      validateStatus={passportIssuedByError ? "error" : ""}
                      help={passportIssuedByError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + passportIssuedBy}
                        labelText={"Кем выдан"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(passportIssuedBy, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                            {
                              pattern: /^[А-Яа-яЁё0-9\s-]{5,}$/g,
                              message: " "
                            },
                            {
                              pattern: /^.{5,}$/g,
                              message: " "
                            },
                          ],
                        })(<Input className="hyd-input-small hyd-input-profile" disabled={!isEditModeOn}
                                  style={{width: 598}}/>)}
                      />
                    </Form.Item>
                  </div>

                  <div className="hyd-row-profile">
                    {/* Дата выдачи */}
                    <Form.Item
                      validateStatus={passportDateError ? "error" : ""}
                      help={passportDateError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + passportDate}
                        labelText={"Дата выдачи"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(passportDate, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                            {
                              validator: this.checkValueExperienceDate,
                            },
                          ],
                        })(<Cleave
                          className="ant-input hyd-input-small hyd-osago-8-input"
                          key={passportDate}
                          options={{
                            date: true,
                            delimiter: ".",
                            datePattern: ["d", "m", "Y"]
                          }}
                          disabled={!isEditModeOn}
                        />)}
                      />
                    </Form.Item>

                    {/* Код подразделения */}
                    <Form.Item
                      validateStatus={passportUniteCodeError ? "error" : ""}
                      help={passportUniteCodeError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + passportUniteCode}
                        labelText={"Код подразделения"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(passportUniteCode, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                            {
                              pattern: /^[0-9]{3} [0-9]{3}$/g,
                              message: " "
                            },
                          ],
                        })(<Cleave
                          key={"passport"}
                          className="ant-input hyd-input-small hyd-osago-8-input"
                          options={{
                            blocks: [3, 3],
                            delimiter: ' ',
                            numericOnly: false
                          }}
                          disabled={!isEditModeOn}
                        />)}
                      />
                    </Form.Item>
                  </div>

                  <div className="hyd-row-profile">
                    {/* Пол */}
                    <Form.Item
                      required={false}
                    >
                      <div className="hyd-select-small hyd-select-profile">
                        {getFieldDecorator(gender, {
                          initialValue: "FIZ",
                          rules: [{required: true, message: " "}]
                        })(
                          <Select
                            className="hyd-input-select-small"
                            style={{width: "100%"}}
                            placeholder="Пол"
                            disabled={!isEditModeOn}
                          >
                            <Option value={"M"}>
                              <div className={"hyd-select-tooltip"}>Пол</div>
                              <div className={"hyd-select-value"}>Мужской</div>
                            </Option>
                            <Option value={"F"}>
                              <div className={"hyd-select-tooltip"}>Пол</div>
                              <div className={"hyd-select-value"}>Женский</div>
                            </Option>
                          </Select>
                        )}
                      </div>
                    </Form.Item>

                    {/* Дата рождения */}
                    <Form.Item
                      validateStatus={birthDateError ? "error" : ""}
                      help={birthDateError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + birthDate}
                        labelText={"Дата рождения"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(birthDate, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                            {
                              validator: checkValueBirthDate,
                            },
                          ],
                        })(<Cleave
                          className="ant-input hyd-input-small hyd-osago-8-input"
                          key={birthDate}
                          options={{
                            date: true,
                            delimiter: ".",
                            datePattern: ["d", "m", "Y"]
                          }}
                          disabled={!isEditModeOn}
                        />)}
                      />
                    </Form.Item>
                  </div>

                  <div className="hyd-row-profile">
                    {/* ИНН */}
                    <Form.Item
                      validateStatus={innError ? "error" : ""}
                      help={innError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + inn}
                        labelText={"ИНН"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(inn, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                            {
                              pattern: /^[0-9]{12}$/g,
                              message: " "
                            }
                          ],
                        })(<Input maxLength={12} className="hyd-input-small hyd-input-profile"
                                  disabled={!isEditModeOn}/>)}
                      />
                    </Form.Item>

                    {/* СНИЛС */}
                    <Form.Item
                      validateStatus={snilsError ? "error" : ""}
                      help={snilsError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + snils}
                        labelText={"СНИЛС"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(snils, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                            {
                              pattern: /^[0-9]{3} [0-9]{3} [0-9]{3} [0-9]{2}$/g,
                              message: " "
                            }
                          ],
                        })(<Cleave
                          key={"snils"}
                          className="ant-input hyd-input-small hyd-osago-8-input"
                          options={{
                            blocks: [3, 3, 3, 2],
                            delimiter: ' ',
                            numericOnly: false
                          }}
                          disabled={!isEditModeOn}
                        />)}
                      />
                    </Form.Item>
                  </div>

                  <div className="hyd-row-profile">
                    {!isEditModeOn &&
                    <Button
                      style={{marginTop: 12}}
                      className="hyd-btn-small"
                      onClick={() => {
                        toggleEditMode(true)
                      }}
                    >
                      Редактировать
                    </Button>}

                    {isEditModeOn &&
                    <Button
                      style={{marginTop: 12}}
                      className="hyd-btn-small"
                      type="primary"
                      htmlType="submit"
                      form="profile-personal-data"
                      key="submit"
                      disabled={hasErrors(getFieldsError())}
                    >
                      Сохранить
                    </Button>}
                  </div>
                </Form>

              </TabPane>

              <TabPane tab={<span className="hyd-tab-pane">Реквизиты</span>} key="3">

                <Tabs className="hyd-tab hyd-tab-profile" defaultActiveKey="4" onChange={() => {
                  toggleEditMode(false)
                }}>
                  <TabPane tab={<span className="hyd-tab-pane">Карта</span>} key="4">
                    <Form
                      layout="inline"
                      onSubmit={this.handleSubmit}
                      id="profile-card-details"
                    >
                      <div className="hyd-row-profile">
                        {/* Номер карты */}
                        <Form.Item
                          validateStatus={cardBankNumError ? "error" : ""}
                          help={cardBankNumError || ""}
                        >
                          <FloatingLabelInput
                            divClassName="hyd-input-profile"
                            htmlFor={"profile_" + cardBankNum}
                            labelText={"Номер карты"}
                            divId={"float-label-small"}
                            disabled={!isEditModeOn}
                            child={getFieldDecorator(cardBankNum, {
                              validateTrigger: ["onBlur", "onChange"],
                              rules: [
                                {
                                  required: true,
                                  message: " "
                                },
                              ],
                            })(<Cleave
                              style={{width: 598}}
                              className="ant-input hyd-input-small hyd-osago-8-input"
                              options={{
                                creditCard: true,
                                creditCardStrictMode: true,  // true will remove the 16 max-length restrain
                                onCreditCardTypeChanged: this.onCardPaymentSystemChanged
                              }}
                              disabled={!isEditModeOn}
                            />)}
                          />
                        </Form.Item>
                      </div>

                      <div className="hyd-row-profile">
                        {/* Дата */}
                        <Form.Item
                          validateStatus={cardValidDateError ? "error" : ""}
                          help={cardValidDateError || ""}
                        >
                          <FloatingLabelInput
                            divClassName="hyd-input-profile"
                            htmlFor={"profile_" + cardValidDate}
                            labelText={"Дата"}
                            divId={"float-label-small"}
                            disabled={!isEditModeOn}
                            child={getFieldDecorator(cardValidDate, {
                              validateTrigger: ["onBlur", "onChange"],
                              rules: [
                                {
                                  required: true,
                                  message: " "
                                },
                                {
                                  validator: this.checkValueCardDate,
                                },
                              ],
                            })(<Cleave
                              className="ant-input hyd-input-small hyd-osago-8-input"
                              key={JSON.stringify({
                                date: true,
                                delimiter: "/",
                                datePattern: ["m", "y"]
                              })}
                              options={{
                                date: true,
                                delimiter: "/",
                                datePattern: ["m", "y"],
                              }}
                              disabled={!isEditModeOn}
                            />)}
                          />
                        </Form.Item>

                        {/* Имя и фамилия */}
                        <Form.Item
                          validateStatus={cardHolderNameError ? "error" : ""}
                          help={cardHolderNameError || ""}
                        >
                          <FloatingLabelInput
                            divClassName="hyd-input-profile"
                            htmlFor={"profile_" + cardHolderName}
                            labelText={"Имя и фамилия"}
                            divId={"float-label-small"}
                            disabled={!isEditModeOn}
                            child={getFieldDecorator(cardHolderName, {
                              validateTrigger: ["onBlur", "onChange"],
                              rules: [
                                {
                                  required: true,
                                  whitespace: true,
                                  message: " "
                                },
                                {
                                  // только латинские буквы
                                  pattern: /^(([A-za-z])+(-|\s)?([A-za-z]+))$/g,
                                  message: " "
                                },
                              ],
                            })(<Input className="hyd-input-small hyd-input-profile" disabled={!isEditModeOn}/>)}
                          />
                        </Form.Item>
                      </div>

                      <div className="hyd-row-profile">
                        {!isEditModeOn &&
                        <Button
                          style={{marginTop: 12}}
                          className="hyd-btn-small"
                          onClick={() => {
                            toggleEditMode(true)
                          }}
                        >
                          Редактировать
                        </Button>}

                        {isEditModeOn &&
                        <Button
                          style={{marginTop: 12}}
                          className="hyd-btn-small"
                          type="primary"
                          htmlType="submit"
                          form="profile-card-details"
                          key="submit"
                          disabled={hasErrors(getFieldsError())}
                        >
                          Сохранить
                        </Button>}

                        {cardPaymentSystem === "mir" ?
                          <div className={"hyd-mir-box"}>
                            <svg className={"hyd-card-mir-ico"}><MirIcon/></svg>
                          </div>
                          : cardPaymentSystem === "mastercard" ?
                            <span className={"hyd-card-ico"}><MastercardIcon/></span>
                            : cardPaymentSystem === "maestro" ?
                              <span className={"hyd-card-ico"}><MaestroIcon/></span>
                              : cardPaymentSystem === "unionPay" ?
                                <span className={"hyd-card-ico"}><UnionpayIcon/></span>
                                : cardPaymentSystem === "discover" ?
                                  <span className={"hyd-card-ico"}><DiscoverIcon/></span>
                                  : cardPaymentSystem === "diners" ?
                                    <span className={"hyd-card-ico"}><DinersIcon/></span>
                                    : cardPaymentSystem === "visa" ?
                                      <span className={"hyd-card-ico"}><VisaIcon/></span>
                                      : cardPaymentSystem === "amex" ?
                                        <span className={"hyd-card-ico"}><AmexIcon/></span>
                                        : ["jcb", "jcb15"].includes(cardPaymentSystem) ?
                                          <span className={"hyd-card-ico"}><JcbIcon/></span>
                                          : <span>{cardPaymentSystem}</span> ? "" : ""}
                      </div>

                    </Form>
                  </TabPane>

                  <TabPane tab={<span className="hyd-tab-pane">Банковский счёт</span>} key="5">
                    <Form
                      layout="inline"
                      onSubmit={this.handleSubmit}
                      id="profile-bank-details"
                    >
                      <div className="hyd-row-profile">
                        {/* Получатель */}
                        <Form.Item
                          validateStatus={bankRecipientError ? "error" : ""}
                          help={bankRecipientError || ""}
                        >
                          <FloatingLabelInput
                            divClassName="hyd-input-profile"
                            htmlFor={"profile_" + bankRecipient}
                            labelText={"Получатель"}
                            divId={"float-label-small"}
                            disabled={!isEditModeOn}
                            child={getFieldDecorator(bankRecipient, {
                              validateTrigger: ["onBlur", "onChange"],
                              rules: [
                                {
                                  required: true,
                                  message: " "
                                },
                                {
                                  // только лат или только кириллица
                                  pattern: /^(([А-Яа-яЁё])+(-|\s)?([А-Яа-яЁё]+))$|^(([A-za-z])+(-|\s)?([A-za-z]+))$/g,
                                  message: " "
                                },
                              ],
                            })(<Input className="hyd-input-small hyd-input-profile" disabled={!isEditModeOn}/>)}
                          />
                        </Form.Item>

                        {/* Счёт получателя */}
                        <Form.Item
                          validateStatus={bankAccountError ? "error" : ""}
                          help={bankAccountError || ""}
                        >
                          <FloatingLabelInput
                            divClassName="hyd-input-profile"
                            htmlFor={"profile_" + bankAccount}
                            labelText={"Счёт получателя"}
                            divId={"float-label-small"}
                            disabled={!isEditModeOn}
                            child={getFieldDecorator(bankAccount, {
                              validateTrigger: ["onBlur", "onChange"],
                              rules: [
                                {
                                  required: true,
                                  message: " "
                                },
                                {
                                  pattern: /^[0-9]{3} [0-9]{2} [0-9]{3} [0-9]{1} [0-9]{4} [0-9]{7}$/g,
                                  message: " "
                                },
                              ],
                            })(<Cleave
                              key={"bankAccount"}
                              className="ant-input hyd-input-small hyd-osago-8-input"
                              options={{
                                blocks: [3, 2, 3, 1, 4, 7],
                                delimiter: ' ',
                                numericOnly: false
                              }}
                              disabled={!isEditModeOn}
                            />)}
                          />
                        </Form.Item>
                      </div>

                      <div className="hyd-row-profile">
                        {/* Банк */}
                        <Form.Item
                          validateStatus={bankNameError ? "error" : ""}
                          help={bankNameError || ""}
                        >
                          <FloatingLabelInput
                            divClassName="hyd-input-profile"
                            htmlFor={"profile_" + bankName}
                            labelText={"Банк"}
                            divId={"float-label-small"}
                            disabled={!isEditModeOn}
                            child={getFieldDecorator(bankName, {
                              validateTrigger: ["onBlur", "onChange"],
                              rules: [
                                {
                                  required: true,
                                  message: " "
                                },
                                {
                                  // только лат или только кириллица
                                  pattern: /^(([А-Яа-яЁё])+(-|\s)?([А-Яа-яЁё]+))$|^(([A-za-z])+(-|\s)?([A-za-z]+))$/g,
                                  message: " "
                                },
                              ],
                            })(<Input className="hyd-input-small hyd-input-profile" disabled={!isEditModeOn}/>)}
                          />
                        </Form.Item>

                        {/* БИК */}
                        <Form.Item
                          validateStatus={bankBikError ? "error" : ""}
                          help={bankBikError || ""}
                        >
                          <FloatingLabelInput
                            divClassName="hyd-input-profile"
                            htmlFor={"profile_" + bankBik}
                            labelText={"БИК"}
                            divId={"float-label-small"}
                            disabled={!isEditModeOn}
                            child={getFieldDecorator(bankBik, {
                              validateTrigger: ["onBlur", "onChange"],
                              rules: [
                                {
                                  required: true,
                                  message: " "
                                },
                                {
                                  pattern: /^[0-9]{9}$/g,
                                  message: " "
                                }
                              ],
                            })(<Input maxLength={9} className="hyd-input-small hyd-input-profile"
                                      disabled={!isEditModeOn}/>)}
                          />
                        </Form.Item>
                      </div>

                      <div className="hyd-row-profile">
                        {/* К/с */}
                        <Form.Item
                          validateStatus={bankKsError ? "error" : ""}
                          help={bankKsError || ""}
                        >
                          <FloatingLabelInput
                            divClassName="hyd-input-profile"
                            htmlFor={"profile_" + bankKs}
                            labelText={"К/с"}
                            divId={"float-label-small"}
                            disabled={!isEditModeOn}
                            child={getFieldDecorator(bankKs, {
                              validateTrigger: ["onBlur", "onChange"],
                              rules: [
                                {
                                  required: true,
                                  message: " "
                                },
                                {
                                  pattern: /^[0-9]{20}$/g,
                                  message: " "
                                }
                              ],
                            })(<Input maxLength={20} className="hyd-input-small hyd-input-profile"
                                      disabled={!isEditModeOn}/>)}
                          />
                        </Form.Item>

                        {/* Код подразделения */}
                        <Form.Item
                          validateStatus={bankDivisionCodeError ? "error" : ""}
                          help={bankDivisionCodeError || ""}
                        >
                          <FloatingLabelInput
                            divClassName="hyd-input-profile"
                            htmlFor={"profile_" + bankDivisionCode}
                            labelText={"Код подразделения"}
                            divId={"float-label-small"}
                            disabled={!isEditModeOn}
                            child={getFieldDecorator(bankDivisionCode, {
                              validateTrigger: ["onBlur", "onChange"],
                              rules: [
                                {
                                  required: true,
                                  message: " "
                                },
                              ],
                            })(<Input maxLength={20} className="hyd-input-small hyd-input-profile"
                                      disabled={!isEditModeOn}/>)}
                          />
                        </Form.Item>
                      </div>

                      <div className="hyd-row-profile">
                        {/* Адрес подразделения */}
                        <Form.Item
                          validateStatus={bankDivisionAddressError ? "error" : ""}
                          help={bankDivisionAddressError || ""}
                        >
                          <FloatingLabelInput
                            divClassName="hyd-input-profile"
                            htmlFor={"profile_" + bankDivisionAddress}
                            labelText={"Адрес подразделения"}
                            divId={"float-label-small"}
                            disabled={!isEditModeOn}
                            child={getFieldDecorator(bankDivisionAddress, {
                              validateTrigger: ["onBlur", "onChange"],
                              rules: [
                                {
                                  required: true,
                                  message: " "
                                },
                              ],
                            })(<Input className="hyd-input-small hyd-input-profile" disabled={!isEditModeOn}/>)}
                          />
                        </Form.Item>
                      </div>

                      <div className="hyd-row-profile">
                        {!isEditModeOn &&
                        <Button
                          style={{marginTop: 12}}
                          className="hyd-btn-small"
                          onClick={() => {
                            toggleEditMode(true)
                          }}
                        >
                          Редактировать
                        </Button>}

                        {isEditModeOn &&
                        <Button
                          style={{marginTop: 12}}
                          className="hyd-btn-small"
                          type="primary"
                          htmlType="submit"
                          form="profile-bank-details"
                          key="submit"
                          disabled={hasErrors(getFieldsError())}
                        >
                          Сохранить
                        </Button>}
                      </div>

                    </Form>
                  </TabPane>
                </Tabs>

              </TabPane>

            </Tabs>
          </div>

          <div className="float-child-profile">

            <div className="hyd-profile-menu">
              <Tooltip title={!userInfo["has_agents"] ? agentsTxt : ""} placement={"bottom"}>
                <Button
                  className={`hyd-arrow-btn ${!userInfo["has_agents"] && "hyd-btn-disabled"}`}
                  onClick={() => {
                    userInfo["has_agents"] && history.replace(keys.AGENTS)
                  }}>
                  Агенты
                  <div className="hyd-arrow-btn-ico"><ArrowRightSmallIcon/></div>
                </Button>
              </Tooltip>
            </div>

            <div className="hyd-profile-menu">
              <Button
                className="hyd-arrow-btn"
                onClick={() => {
                  history.replace(keys.REFERRAL_LINK)
                }}>
                Реферальная ссылка
                <div className="hyd-arrow-btn-ico"><ArrowRightSmallIcon/></div>
              </Button>
            </div>

            <div className="hyd-profile-menu">
              <Button
                className="hyd-arrow-btn"
                style={{height: 152}}
                onClick={() => {
                  notification['warning']({
                    message: `Недоступно.`
                  })
                }}>
                <span className="hyd-profile-btn-main-txt">Агент “Онлайн”</span>
                <span className="hyd-profile-btn-txt">Ваш статус. Зарабатывайте больше, став агентом “Классик”</span>
                <div className="hyd-arrow-btn-ico"><ArrowRightSmallIcon/></div>
              </Button>
            </div>

            <div className="hyd-profile-menu">
              <div className="hyd-profile-menu-info">
                <span className="hyd-profile-btn-main-txt">Ваш менеджер</span>
                <div className="hyd-profile-menu-manager">
                  <span>{dataForm.supervisor_data["last_name"] + " " + dataForm.supervisor_data["first_name"]}</span><br/>
                  <span className="hyd-profile-menu-txt">Телефон </span>
                  <span>{
                    dataForm.supervisor_data["sup_phone"] &&
                    dataForm.supervisor_data["sup_phone"].replace(/(\d)(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4')
                  }</span><br/>
                  <span className="hyd-profile-menu-txt">Почта </span>
                  <span>{dataForm.supervisor_data["sup_email"]}</span>
                  {/*<a href={"mailto:" + dataForm.supervisor_data["sup_email"]}>{dataForm.supervisor_data["sup_email"]}</a>*/}
                </div>
              </div>
            </div>

            <div className="hyd-profile-menu-info">
              <span className="hyd-profile-btn-main-txt">Дебиторская задолженность</span>
              {creditLimit && Number(creditLimit["value"]) ?
                <div className="hyd-profile-slider-container">
                  <Slider
                    className={"hyd-profile-slider"}
                    trackStyle={{backgroundColor: "var(--color-four)"}}
                    marks={marks}
                    step={1}
                    min={0}
                    max={creditLimit && creditLimit["value"]}
                    value={receivables}  // TODO set the value of receivables after we decide where to get such value
                  />
                  <span className="hyd-receivables">
                    {Number.isInteger(receivables) ?
                      Intl.NumberFormat('ru-RU').format(receivables) + " ₽" : "Нет информации"}
                  </span>
                </div>
                : <div>Нет информации</div>}
            </div>

            <div className="hyd-profile-menu hyd-profile-menu-docs">
              <Button
                className="hyd-link-btn"
                onClick={() => {
                  notification['warning']({
                    message: `Недоступно.`
                  })
                }}>
                <div className="hyd-profile-download-doc-ico">
                  <span className="hyd-profile-download-doc">Договор оферты</span>
                  <DocumentBlueIcon/>
                </div>
              </Button>
            </div>

            <div className="hyd-profile-menu hyd-profile-menu-docs">
              <Button
                className="hyd-link-btn"
                onClick={() => {
                  notification['warning']({
                    message: `Недоступно.`
                  })
                }}>
                <div className="hyd-profile-download-doc-ico">
                  <span className="hyd-profile-download-doc">Агентский договор</span>
                  <DocumentBlueIcon/>
                </div>
              </Button>
            </div>

          </div>

        </div>
        <ModalError type={5}/>
      </>
    );
  }
}

const WrappedProfileForm = Form.create({
    name: 'profile',
    mapPropsToFields(props) {
      const {dataForm} = props;
      const transformed = transform({dataForm});

      return transformed.dataForm;
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields);
    },
  }
)(Profile);

const mapStateToProps = state => ({
  isRequest: isRequestSelector(state),
  isEditModeOn: isEditModeOnSelector(state),
  updatingComplete: updatingCompleteSelector(state),
  resetPasswordComplete: resetPasswordCompleteSelector(state),
  cardPaymentSystem: cardPaymentSystemSelector(state),
  creditLimit: creditLimitSelector(state),
  addressArr: userAddressArrSelector(state),
  dataForm: dataFormSelector(state),
  errors: errorsProfileSelector(state),
  regions: regionsSelector(state),
  userInfo: userInfoSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearIsRequestState,
      setDataForm,
      getProfile,
      getUserKladr,
      saveUserAddress,
      toggleUserSelectedAddress,
      saveCardPaymentSystem,
      toggleEditMode,
      toggleInnSnilsEditMode,
      resetPassword,
      updateProfile,
      getRegions,
      getNotifications,
      checkEarnedMoneyAmount,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WrappedProfileForm);

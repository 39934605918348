import React, {Component} from "react";
import {Form, Input, Button, Tabs, Row, Checkbox, notification} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {hasErrors, transform, isListsNotEmpty, baseRules, baseTrigger} from "../../utils";

import "./styles/index.scss";
import "../../fonts/open-sans.css"
import ModalError from "../../components/ModalError";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import Cleave from "cleave.js/react";
import {
  clearForm,
  toggleCascoKind,
  sendDataForm,
  setDataForm,
  uploadPtsFile,
  removePtsFile,
  uploadStsFile,
  removeStsFile,
  uploadPassportFile,
  removePassportFile,
  uploadCurrentPolicyFile,
  removeCurrentPolicyFile,
  uploadJuridicalCardFile,
  removeJuridicalCardFile,
  uploadDrivingLicenseFile,
  removeDrivingLicenseFile,
  clearIsRequestState,
  toggleModalCASCO,
} from "../../actions/cascoActions";
import {
  isRequestSelector,
  passportFileListSelector,
  ptsFileListSelector,
  stsFileListSelector,
  currentPolicyFileListSelector,
  juridicalCardFileListSelector,
  drivingLicenseFileListSelector,
  isFormSuccessfullySentSelector,
  cascoKindSelector,
  errorsCascoSelector,
  cascoDataFormSelector,
} from "../../selectors/cascoSelectors";
import Preloader from "../../components/Preloader";
import ModalApplication from "../../components/Form/ModalApplication";
import uploadButton from "../../components/UploadButton";
import {checkEarnedMoneyAmount, getNotifications} from "../../actions/mainActions";

const {TabPane} = Tabs;
const {TextArea} = Input;


class CASCO extends Component {
  componentDidMount() {
    document.title = "КАСКО"

    const {clearIsRequestState, getNotifications, checkEarnedMoneyAmount} = this.props
    clearIsRequestState()
    getNotifications()
    checkEarnedMoneyAmount()
  }

  componentWillUnmount() {
    const {clearForm} = this.props
    clearForm(3)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {isRequest, isFormSuccessfullySent, toggleModalCASCO} = this.props
    if (isFormSuccessfullySent !== prevProps.isFormSuccessfullySent && !isRequest && isFormSuccessfullySent) {
      toggleModalCASCO(true)
    }
  }

  handleSubmit = (e) => {
    const {
      sendDataForm,
      cascoKind,
      ptsFileList,
      stsFileList,
      passportFileList,
      juridicalCardFileList,
      drivingLicenseFileList,
    } = this.props
    e.preventDefault()

    this.props.form.validateFields((err,) => {
      let commonDocs = [ptsFileList, stsFileList]
      let allDocs
      if (cascoKind === 1) {
        allDocs = [...commonDocs, passportFileList, drivingLicenseFileList]
      } else {
        allDocs = [...commonDocs, juridicalCardFileList]
      }
      let allDocsFilled = isListsNotEmpty(...allDocs)
      if (!allDocsFilled) {
        notification["error"]({
          message: "Приложите все необходимые документы!"
        })
      }
      if (!err && allDocsFilled) {
        sendDataForm()
      }
    })
  }

  render() {
    const {
      isRequest,
      cascoKind,
      toggleCascoKind,
      uploadPtsFile,
      removePtsFile,
      uploadPassportFile,
      removePassportFile,
      uploadStsFile,
      removeStsFile,
      uploadCurrentPolicyFile,
      removeCurrentPolicyFile,
      uploadJuridicalCardFile,
      removeJuridicalCardFile,
      uploadDrivingLicenseFile,
      removeDrivingLicenseFile,
      ptsFileList,
      stsFileList,
      passportFileList,
      currentPolicyFileList,
      juridicalCardFileList,
      drivingLicenseFileList,
    } = this.props

    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
    } = this.props.form

    const pledgeLease = "pledge_lease";
    const pledgeLeaseError = getFieldError(pledgeLease);

    const insuredSum = "insured_sum";
    const insuredSumError = getFieldError(insuredSum);

    const note = "note";
    const noteError = getFieldError(note);

    const franchise = "franchise";
    const franchiseError = getFieldError(franchise);

    const mileage = "mileage";
    const mileageError = getFieldError(mileage);

    const policyholderFullName = "policyholder_full_name";
    const policyholderFullNameError = getFieldError(policyholderFullName);

    const PTS = "pts"
    const STS = "sts"
    const CurrentPolicy = "current_policy"
    const Passport = "passport"
    const JuridicalCard = "juridical_card"
    const DrivingLicense = "driving_license"
    const commonDocs = [
      {
        value: 3,
        value_back: CurrentPolicy,
        text: "Действующий полис КАСКО (если есть в наличии)",
        multiple: false,
        fileList: currentPolicyFileList,
        actionUpload: uploadCurrentPolicyFile,
        actionRemove: removeCurrentPolicyFile,
      },
      {
        value: 1,
        value_back: PTS,
        text: "ПТС / ЭПТС (3 стр.)",
        multiple: false,
        fileList: ptsFileList,
        actionUpload: uploadPtsFile,
        actionRemove: removePtsFile,
      },
      {
        value: 2,
        value_back: STS,
        text: "СТС",
        multiple: false,
        fileList: stsFileList,
        actionUpload: uploadStsFile,
        actionRemove: removeStsFile,
      },
    ]

    const personDocs = [
      {
        value: 4,
        value_back: Passport,
        text: "Паспорт собственника",
        multiple: false,
        fileList: passportFileList,
        actionUpload: uploadPassportFile,
        actionRemove: removePassportFile,
      },
      {
        value: 5,
        value_back: DrivingLicense,
        text: "Водительское удостоверение",
        multiple: true,
        fileList: drivingLicenseFileList,
        actionUpload: uploadDrivingLicenseFile,
        actionRemove: removeDrivingLicenseFile,
      },
    ]

    const juridicalDocs = [
      {
        value: 6,
        value_back: JuridicalCard,
        text: "Карточка юр. лица",
        multiple: false,
        fileList: juridicalCardFileList,
        actionUpload: uploadJuridicalCardFile,
        actionRemove: removeJuridicalCardFile,
      },
    ]

    return (
      <>
        <Preloader loading={isRequest}/>

        <div className="casco-container">
          <span className="hyd-very-big-bold-text-small">КАСКО</span>

          <div className="">Форма заявки для расчёта</div>

          <div className="step-title-active">
            <div className="step-icon-block">
              <NumberIcon className="hyd-large-num-ico"/>
              <span className="hyd-large-num-ico-num">1</span>
            </div>
            <span className="step-title-active-header">Заявка</span>
          </div>

          <div className={"casco-lease-txt"}>Указать банк / Лизинговую компанию</div>

          <Form layout="inline" onSubmit={this.handleSubmit} id={"casco"}>
            <Row>
              {/* Залог / лизинг */}
              <Form.Item
                validateStatus={pledgeLeaseError ? "error" : ""}
                help={pledgeLeaseError || ""}
              >
                <FloatingLabelInput
                  divClassName="casco-wide-input"
                  htmlFor={"casco_" + pledgeLease}
                  labelText={"Залог / лизинг"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(pledgeLease, {
                    validateTrigger: baseTrigger,
                    rules: baseRules,
                  })(<Input className={"ant-input hyd-input-small"}/>)}
                />
              </Form.Item>

              {/* Страховая сумма */}
              <Form.Item
                validateStatus={insuredSumError ? "error" : ""}
                help={insuredSumError || ""}
              >
                <FloatingLabelInput
                  divClassName="casco-input"
                  htmlFor={"casco_" + insuredSum}
                  labelText={"Страховая сумма, ₽"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(insuredSum, {
                    validateTrigger: baseTrigger,
                    rules: baseRules,
                  })(
                    <Cleave
                      className="ant-input hyd-input-small"
                      key={insuredSum}
                      options={{
                        numeral: true,
                        numeralPositiveOnly: true,
                        numeralIntegerScale: 20,
                        numeralDecimalMark: ',',
                        delimiter: ' ',
                      }}
                    />
                  )}
                />
              </Form.Item>
            </Row>

            <Row>
              {/* ФИО Страхователя */}
              <Form.Item
                validateStatus={policyholderFullNameError ? "error" : ""}
                help={policyholderFullNameError || ""}
                style={{marginTop: 20}}
              >
                <FloatingLabelInput
                  divClassName="casco-wide-input"
                  htmlFor={"casco_" + policyholderFullName}
                  labelText={"ФИО Страхователя"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(policyholderFullName, {
                    validateTrigger: baseTrigger,
                    rules: baseRules,
                  })(<Input className={"ant-input hyd-input-small"}/>)}
                />
              </Form.Item>

              {/* Франшиза */}
              <Form.Item
                validateStatus={franchiseError ? "error" : ""}
                help={franchiseError || ""}
                style={{marginTop: 20}}
              >
                <FloatingLabelInput
                  divClassName="casco-input"
                  htmlFor={"casco_" + franchise}
                  labelText={"Франшиза, ₽"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(franchise, {
                    validateTrigger: baseTrigger,
                    rules: baseRules,
                  })(
                    <Cleave
                      className="ant-input hyd-input-small"
                      key={franchise}
                      options={{
                        numeral: true,
                        numeralPositiveOnly: true,
                        numeralIntegerScale: 20,
                        numeralDecimalMark: ',',
                        delimiter: ' ',
                      }}
                    />
                  )}
                />
              </Form.Item>
            </Row>

            <Row>
              {/* Примечание */}
              <Form.Item
                validateStatus={noteError ? "error" : ""}
                help={noteError || ""}
                style={{marginTop: 20}}
              >
                <FloatingLabelInput
                  divClassName="casco-wide-input"
                  htmlFor={"casco_" + note}
                  labelText={"Примечание"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(note, {
                    validateTrigger: baseTrigger,
                    rules: baseRules,
                  })(<Input className={"ant-input hyd-input-small"}/>)}
                />
              </Form.Item>

              <Form.Item
                validateStatus={mileageError ? "error" : ""}
                help={mileageError || ""}
                style={{marginTop: 20}}
              >
                <FloatingLabelInput
                  divClassName="casco-input"
                  htmlFor={"casco_" + mileage}
                  labelText={"Пробег, км"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(mileage, {
                    validateTrigger: baseTrigger,
                    rules: [
                      ...baseRules,
                      {
                        pattern: /^([0-9])+$/g,
                        message: " "
                      }
                    ]
                  })(<Input className="ant-input hyd-input-small" maxLength={7}/>)}
                />
              </Form.Item>
            </Row>


            <div className="step-title-active">
              <div className="step-icon-block">
                <NumberIcon className="hyd-large-num-ico"/>
                <span className="hyd-large-num-ico-num">2</span>
              </div>
              <span className="step-title-active-header">Загрузить документы</span>
            </div>

            <Tabs
              className="hyd-tab"
              defaultActiveKey={cascoKind.toString()}
              onChange={(k) => {
                toggleCascoKind(parseInt(k))
              }}
            >
              <TabPane tab={<span className="hyd-tab-pane">Физическое лицо</span>} key="1">
                <Row>
                  {commonDocs.map(item => uploadButton(
                    item.text, item.multiple, item.fileList, item.actionUpload, item.actionRemove,
                  ))}
                </Row>
                <Row>
                  {personDocs.map(item => uploadButton(
                    item.text, item.multiple, item.fileList, item.actionUpload, item.actionRemove,
                  ))}
                </Row>
              </TabPane>

              <TabPane tab={<span className="hyd-tab-pane">Юридическое лицо</span>} key="2">
                <Row>
                  {commonDocs.map(item => uploadButton(
                    item.text, item.multiple, item.fileList, item.actionUpload, item.actionRemove,
                  ))}
                </Row>
                <Row>
                  {juridicalDocs.map(item => uploadButton(
                    item.text, item.multiple, item.fileList, item.actionUpload, item.actionRemove,
                  ))}
                </Row>
              </TabPane>
            </Tabs>

            <Row>
              <Checkbox
                checked={true}
                className={"hyd-norm-text mortgage-checkbox-disabled"}
                style={{marginTop: 20}}
                disabled
              >
                Согласен на обработку персональных данных
              </Checkbox>
            </Row>

            <Button
              className="hyd-btn-small"
              type="primary"
              htmlType="submit"
              style={{marginTop: 20}}
              disabled={hasErrors(getFieldsError())}
            >
              Отправить заявку
            </Button>
          </Form>

        </div>
        <ModalError type={3}/>
        <ModalApplication/>
      </>
    )
  }
}

const mapStateToProps = state => ({
  cascoDataForm: cascoDataFormSelector(state),
  isRequest: isRequestSelector(state),
  ptsFileList: ptsFileListSelector(state),
  errors: errorsCascoSelector(state),
  cascoKind: cascoKindSelector(state),
  stsFileList: stsFileListSelector(state),
  passportFileList: passportFileListSelector(state),
  currentPolicyFileList: currentPolicyFileListSelector(state),
  juridicalCardFileList: juridicalCardFileListSelector(state),
  drivingLicenseFileList: drivingLicenseFileListSelector(state),
  isFormSuccessfullySent: isFormSuccessfullySentSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearForm,
      toggleCascoKind,
      sendDataForm,
      setDataForm,
      uploadPtsFile,
      uploadStsFile,
      removeStsFile,
      removePtsFile,
      uploadPassportFile,
      removePassportFile,
      uploadCurrentPolicyFile,
      removeCurrentPolicyFile,
      uploadJuridicalCardFile,
      removeJuridicalCardFile,
      uploadDrivingLicenseFile,
      removeDrivingLicenseFile,
      clearIsRequestState,
      toggleModalCASCO,
      getNotifications,
      checkEarnedMoneyAmount,
    },
    dispatch
  )


const WrappedCASCOForm = Form.create({
    name: 'casco-form',
    mapPropsToFields(props) {
      const {cascoDataForm} = props
      const transformed = transform({cascoDataForm})

      return transformed.cascoDataForm
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields)
    },
  }
)(CASCO)

export default connect(mapStateToProps, mapDispatchToProps)(WrappedCASCOForm)

export const exportDocsSelector = state => state.contractsReducer.exportDocs;
export const isRequestSelector = state => state.contractsReducer.isRequest;
export const contractsListSelector = state => state.contractsReducer.contractsList;
export const selectedObjIdSelector = state => state.contractsReducer.selectedObjId;
export const contractsCountSelector = state => state.contractsReducer.contractsCount;
export const visibleViewDocsModalSelector = state => state.contractsReducer.visibleViewDocsModal;
export const policyDocsSelector = state => state.contractsReducer.policyDocs;
export const policyDocumentSelector = state => state.contractsReducer.policyDocument;
export const policyDocTypeSelector = state => state.contractsReducer.policyDocType;
export const errorsContractsSelector = state => state.contractsReducer.errors;
export const visibleErrorModalContractsSelector = state => state.contractsReducer.visibleErrorModal;
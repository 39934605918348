import React, {Component} from "react";
import {
  Row,
  Form,
  Input,
  Select,
  AutoComplete,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Cleave from "cleave.js/react";

import {
  setDataForm,
  toggleSelectedObjectAddress,
  clearObjectAddressData,
  saveObjectAddressData,
  getAddressData,
  reConstructAddressStr,
} from "../../../actions/mortgageActions";
import {
  banksSelector,
  mortgageDataFormSelector,
  objectAddressArrSelector,
} from "../../../selectors/mortgageSelectors";
import {
  transform,
  mortgageObjectTypes,
  mortgageInsuranceObjects,
  mortgageBuildingMaterials,
  mortgageFloorMaterials,
} from "../../../utils";
import FloatingLabelInput from "../../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import {checkSelectedBankKey, SBER_KEY} from "../../../pages/Mortgage/mortgageUtils";

const {Option} = Select;


class Property extends Component {

  render() {
    const {
      mortgageBank = "",
      objectCost = "",
      objectCostError = "",
      objectWear = "",
      objectWearError = "",
      floorsNum = "",
      floorsNumError = "",
      objectArea = "",
      objectAreaError = "",
      cadastralNumber = "",
      cadastralNumberError = "",
      objectType = "",
      objectTypeError = "",
      insuranceObject = "",
      insuranceObjectError = "",
      constructionYear = "",
      constructionYearError = "",
      overhaulYear = "",
      overhaulYearError = "",
      buildingMaterial = "",
      buildingMaterialError = "",
      floorMaterial = "",
      floorMaterialError = "",
      objectAddress = "",
      objectAddressError = "",
      country = "",
      countryError = "",
      postcode = "",
      postcodeError = "",
      region = "",
      regionError = "",
      locality = "",
      localityError = "",
      street = "",
      streetError = "",
      house = "",
      houseError = "",
      corpus = "",
      corpusError = "",
      building = "",
      buildingError = "",
      flat = "",
      flatError = "",
      room = "",
      roomError = "",
      objectAddressCustomString = "",
      objectAddressCustomStringError = "",
      objectAddressArr,
      toggleSelectedObjectAddress,
      clearObjectAddressData,
      saveObjectAddressData,
      getAddressData,
      reConstructAddressStr,
      banks,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldValue,
      setFieldsValue,
    } = this.props.form;


    return (
      <>
        <Form
          layout="inline"
          onSubmit={this.handleSubmit}
          id="mortgage-step-3"
        >
          <div className={"hyd-bold-text-small"} style={{marginTop: "20px", marginBottom: "20px"}}>Объект страхования
          </div>

          <Row>
            {/* Стоимость объекта */}
            <Form.Item
              validateStatus={objectCostError ? "error" : ""}
              help={objectCostError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-283"
                htmlFor={"mortgage-step-3_" + objectCost}
                labelText={"Стоимость объекта, ₽"}
                divId={"float-label-small"}
                child={getFieldDecorator(objectCost, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                  ],
                })(
                  <Cleave
                    className="ant-input hyd-input-small hyd-mortgage-debt"
                    key={`object_cost`}
                    options={{
                      numeral: true,
                      numeralPositiveOnly: true,
                      numeralDecimalMark: ',',
                      delimiter: ' ',
                    }}
                  />
                )}
              />
            </Form.Item>

            {/* Площадь */}
            <Form.Item
              validateStatus={objectAreaError ? "error" : ""}
              help={objectAreaError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-283"
                htmlFor={"mortgage-step-3_" + objectArea}
                labelText={"Площадь, кв.м."}
                divId={"float-label-small"}
                child={getFieldDecorator(objectArea, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                  ],
                })(
                  <Cleave
                    className="ant-input hyd-input-small hyd-mortgage-debt"
                    key={`object_area`}
                    options={{
                      numeral: true,
                      numeralPositiveOnly: true,
                      numeralDecimalMark: ',',
                      delimiter: ' ',
                    }}
                  />
                )}
              />
            </Form.Item>

            {/* Кадастровый номер */}
            <Form.Item
              validateStatus={cadastralNumberError ? "error" : ""}
              help={cadastralNumberError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-283-no-margin"
                htmlFor={"mortgage-step-3_" + cadastralNumber}
                labelText={"Кадастровый номер"}
                divId={"float-label-small"}
                child={getFieldDecorator(cadastralNumber, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " ",
                    },
                    {
                      pattern: /^\d{2}:\d{2}:\d{6,7}:\d{1,5}$/g,
                      message: " "
                    }
                  ],
                })(<Input className="hyd-input-small hyd-mortgage-283-no-margin" maxLength={19}/>)}
              />
            </Form.Item>

          </Row>

          {checkSelectedBankKey(banks, getFieldValue(mortgageBank), SBER_KEY) &&
            <Row style={{marginTop: 20}}>

              {/* Износ */}
              <Form.Item
                validateStatus={objectWearError ? "error" : ""}
                help={objectWearError || ""}
              >
                <FloatingLabelInput
                  divClassName="hyd-mortgage-137"
                  htmlFor={"mortgage-step-3_" + objectWear}
                  labelText={"Износ, %"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(objectWear, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [
                      {
                        required: false,
                        message: " "
                      },
                    ],
                  })(
                    <Cleave
                      className="ant-input hyd-input-small hyd-mortgage-debt"
                      key={`object_wear`}
                      options={{
                        numeral: true,
                        numeralPositiveOnly: true,
                        numeralDecimalMark: ',',
                        delimiter: ' ',
                      }}
                    />
                  )}
                />
              </Form.Item>

              {/* Кол-во этажей */}
              <Form.Item
                validateStatus={floorsNumError ? "error" : ""}
                help={floorsNumError || ""}
              >
                <FloatingLabelInput
                  divClassName="hyd-mortgage-137-v2"
                  htmlFor={"mortgage-step-3_" + floorsNum}
                  labelText={"Кол-во этажей"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(floorsNum, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [
                      {
                        required: true,
                        message: " "
                      },
                      {
                        pattern: /^\d{1,3}$/g,
                        message: " "
                      }
                    ],
                  })(<Input className="hyd-input-small hyd-mortgage-137-v2" maxLength={3}/>)}
                />
              </Form.Item>

            </Row>
          }


          <Row style={{marginTop: 20}}>
            {/* Тип объекта */}
            <Form.Item
              validateStatus={objectTypeError ? "error" : ""}
              help={objectTypeError || ""}
            >
              <div className="hyd-select-small hyd-mortgage-283 hyd-osago-select">
                {getFieldDecorator(objectType, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [{required: true, message: " "}],
                })(
                  <Select
                    className="hyd-input-select-small"
                    style={{width: "100%"}}
                    onSelect={() => {
                      clearObjectAddressData()
                      setFieldsValue({
                          room: undefined
                        }
                      )
                    }}
                  >
                    {mortgageObjectTypes.map(({value, text, disabled}) =>
                      <Option value={value} key={value} disabled={disabled}>
                        <div className={"hyd-select-tooltip"}>Тип объекта</div>
                        <div>{text}</div>
                      </Option>
                    )}
                  </Select>
                )}
              </div>
            </Form.Item>

            {/* Объект страхования */}
            {checkSelectedBankKey(banks, getFieldValue(mortgageBank), SBER_KEY) &&
              <Form.Item
                validateStatus={insuranceObjectError ? "error" : ""}
                help={insuranceObjectError || ""}
              >
                <div className="hyd-select-small hyd-mortgage-283 hyd-osago-select">
                  {getFieldDecorator(insuranceObject, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [{required: true, message: " "}],
                  })(
                    <Select
                      className="hyd-input-select-small"
                      style={{width: "100%"}}
                      dropdownStyle={{minWidth: "40%", borderRadius: 10}}
                    >
                      {mortgageInsuranceObjects.map(({value, text, disabled}) =>
                        <Option value={value} key={value} disabled={disabled}>
                          <div className={"hyd-select-tooltip"}>Объект страхования</div>
                          <div>{text}</div>
                        </Option>
                      )}
                    </Select>
                  )}
                </div>
              </Form.Item>
            }

            {/* Год постройки */}
            <Form.Item
              validateStatus={constructionYearError ? "error" : ""}
              help={constructionYearError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-137"
                htmlFor={"mortgage-step-3_" + constructionYear}
                labelText={"Год постройки"}
                divId={"float-label-small"}
                child={getFieldDecorator(constructionYear, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: !getFieldValue(constructionYear),
                      message: " "
                    },
                  ]
                })(
                  <Cleave
                    className="ant-input hyd-input-small hyd-mortgage-137"
                    placeholder=""
                    key={JSON.stringify({
                      date: true,
                      datePattern: ["Y"]
                    })}
                    options={{
                      date: true,
                      datePattern: ["Y"]
                    }}
                  />
                )}
              />
            </Form.Item>

            {/* Год капитального ремонта */}
            {checkSelectedBankKey(banks, getFieldValue(mortgageBank), SBER_KEY) &&
              <Form.Item
                validateStatus={overhaulYearError ? "error" : ""}
                help={overhaulYearError || ""}
              >
                <FloatingLabelInput
                  divClassName="hyd-mortgage-136"
                  htmlFor={"mortgage-step-3_" + overhaulYear}
                  labelText={"Год кап. рем."}
                  divId={"float-label-small"}
                  child={getFieldDecorator(overhaulYear, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [
                      {
                        required: false,
                        message: " "
                      },
                    ]
                  })(
                    <Cleave
                      className="ant-input hyd-input-small hyd-mortgage-136"
                      placeholder=""
                      key={JSON.stringify({
                        date: true,
                        datePattern: ["Y"]
                      })}
                      options={{
                        date: true,
                        datePattern: ["Y"]
                      }}
                    />
                  )}
                />
              </Form.Item>
            }

          </Row>

          {checkSelectedBankKey(banks, getFieldValue(mortgageBank), SBER_KEY) &&
            <Row style={{marginTop: 20}}>
              {/* Материал строения */}
              <Form.Item
                validateStatus={buildingMaterialError ? "error" : ""}
                help={buildingMaterialError || ""}
              >
                <div className="hyd-select-small hyd-mortgage-283 hyd-osago-select">
                  {getFieldDecorator(buildingMaterial, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [{required: true, message: " "}],
                  })(
                    <Select
                      className="hyd-input-select-small"
                      style={{width: "100%"}}
                    >
                      {mortgageBuildingMaterials.map(({value, text}) =>
                        <Option value={value} key={value}>
                          <div className={"hyd-select-tooltip"}>Материал строения</div>
                          <div>{text}</div>
                        </Option>
                      )}
                    </Select>
                  )}
                </div>
              </Form.Item>

              {/* Материал перекрытий */}
              <Form.Item
                validateStatus={floorMaterialError ? "error" : ""}
                help={floorMaterialError || ""}
              >
                <div className="hyd-select-small hyd-mortgage-283 hyd-osago-select">
                  {getFieldDecorator(floorMaterial, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [{required: true, message: " "}],
                  })(
                    <Select
                      className="hyd-input-select-small"
                      style={{width: "100%"}}
                    >
                      {mortgageFloorMaterials.map(({value, text}) =>
                        <Option value={value} key={value}>
                          <div className={"hyd-select-tooltip"}>Материал перекрытий</div>
                          <div>{text}</div>
                        </Option>
                      )}
                    </Select>
                  )}
                </div>
              </Form.Item>
            </Row>
          }

          <div className={"hyd-bold-text-small"} style={{marginTop: "40px", marginBottom: "20px"}}>
            Адрес объекта страхования
          </div>
          <Row>
            {/* Адрес объекта */}
            <Form.Item
              validateStatus={objectAddressError ? "error" : ""}
              help={objectAddressError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-909 hyd-osago-autocomplete"
                htmlFor={"mortgage-step-3_" + objectAddress}
                labelText={"Введите адрес"}
                divId={"float-label-small"}
                child={getFieldDecorator(objectAddress, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: " "
                    },
                  ],
                })(
                  <AutoComplete
                    className="hyd-input-autocomplete-small"
                    dataSource={objectAddressArr}
                    onSelect={(inputValue) => {
                      toggleSelectedObjectAddress(true)
                      objectAddressArr.map((value) => {
                        // достаем адрес, регион, страну из массива полученного с бэка
                        if (inputValue === value.value) {
                          let valueOfInput = getFieldValue(objectAddress)
                          if (valueOfInput.indexOf("_") !== -1) {
                            valueOfInput = valueOfInput.split("_")
                            setFieldsValue({
                              objectAddress: valueOfInput[1],
                            })
                          }
                          clearObjectAddressData()
                          saveObjectAddressData(
                            value.text,
                            value.country,
                            value.region,
                            value.zip,
                            value.city_name,
                            value.street,
                            value.house,
                            value.block,
                            value.block_type,
                            value.block_type_full,
                            value.flat,
                            value.fias_id,
                            value.value_kladr,
                            value.city_kladr,
                            value.region_with_type,
                            value.area_with_type,
                            value.city_with_type,
                            value.settlement_with_type,
                            value.house_type,
                            value.flat_type,
                            value.city_kladr_id,
                            value.settlement_kladr_id,
                          )
                        }
                      })
                    }}
                    onChange={(value) => {
                      if (value && value.length > 1) {
                        getAddressData(value, "property")
                      }
                      toggleSelectedObjectAddress(false)
                    }}
                  />
                )}
              />
            </Form.Item>
          </Row>

          <Row style={{marginTop: 20}}>
            {/* Государство */}
            <Form.Item
              validateStatus={countryError ? "error" : ""}
              help={countryError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-137 hyd-osago-low-margin"
                htmlFor={"mortgage-step-1_" + country}
                labelText={"Государство"}
                divId={"float-label-small"}
                child={getFieldDecorator(country, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                  ],
                })(
                  <Input
                    className="hyd-input-small hyd-mortgage-137"
                    disabled
                  />
                )}
              />
            </Form.Item>

            {/* Индекс */}
            <Form.Item
              validateStatus={postcodeError ? "error" : ""}
              help={postcodeError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-137-v2"
                htmlFor={"mortgage-step-3_" + postcode}
                labelText={"Индекс"}
                divId={"float-label-small"}
                child={getFieldDecorator(postcode, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: " "
                    },
                    {
                      pattern: /^\d{6}$/g,
                      message: " "
                    }
                  ],
                })(<Input
                  className="hyd-input-small hyd-mortgage-137-v2"
                  maxLength={6}
                />)}
              />
            </Form.Item>

            {/* Республика, край, область */}
            <Form.Item
              validateStatus={regionError ? "error" : ""}
              help={regionError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-283 hyd-osago-low-margin"
                htmlFor={"mortgage-step-3_" + region}
                labelText={"Республика, край, область"}
                divId={"float-label-small"}
                child={getFieldDecorator(region, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: " "
                    },
                  ],
                })(
                  <Input
                    className="hyd-input-small hyd-mortgage-283"
                    maxLength={100}
                    onChange={(e) => {
                      reConstructAddressStr(e.target.value, region)
                    }}
                  />
                )}
              />
            </Form.Item>

            {/* Населённый пункт */}
            <Form.Item
              validateStatus={localityError ? "error" : ""}
              help={localityError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-283-no-margin hyd-osago-low-margin"
                htmlFor={"mortgage-step-3_" + locality}
                labelText={"Населённый пункт"}
                divId={"float-label-small"}
                child={getFieldDecorator(locality, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: " "
                    },
                  ],
                })(
                  <Input
                    className="hyd-input-small hyd-mortgage-283-no-margin"
                    maxLength={100}
                    onChange={(e) => {
                      reConstructAddressStr(e.target.value, locality)
                    }}
                  />
                )}
              />
            </Form.Item>
          </Row>

          <Row style={{marginTop: 20}}>
            {/* Улица */}
            <Form.Item
              validateStatus={streetError ? "error" : ""}
              help={streetError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-283 hyd-osago-low-margin"
                htmlFor={"mortgage-step-3_" + street}
                labelText={"Улица"}
                divId={"float-label-small"}
                child={getFieldDecorator(street, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: " "
                    },
                  ],
                })(
                  <Input
                    className="hyd-input-small hyd-mortgage-283"
                    maxLength={100}
                    onChange={(e) => {
                      reConstructAddressStr(e.target.value, street)
                    }}
                  />
                )}
              />
            </Form.Item>

            {/* Дом */}
            <Form.Item
              validateStatus={houseError ? "error" : ""}
              help={houseError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-137 hyd-osago-low-margin"
                htmlFor={"mortgage-step-3_" + house}
                labelText={"Дом"}
                divId={"float-label-small"}
                child={getFieldDecorator(house, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: " "
                    },
                  ],
                })(
                  <Input
                    className="hyd-input-small hyd-mortgage-137"
                    maxLength={10}
                    onChange={(e) => {
                      reConstructAddressStr(e.target.value, house)
                    }}
                  />
                )}
              />
            </Form.Item>

            {/* Корпус */}
            <Form.Item
              validateStatus={corpusError ? "error" : ""}
              help={corpusError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-137-v2 hyd-osago-low-margin"
                htmlFor={"mortgage-step-3_" + corpus}
                labelText={"Корпус"}
                divId={"float-label-small"}
                child={getFieldDecorator(corpus, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: false,
                      message: " "
                    },
                  ],
                })(
                  <Input
                    className="hyd-input-small hyd-mortgage-137-v2"
                    maxLength={5}
                    onChange={(e) => {
                      reConstructAddressStr(e.target.value, corpus)
                    }}
                  />
                )}
              />
            </Form.Item>

            {/* Строение */}
            <Form.Item
              validateStatus={buildingError ? "error" : ""}
              help={buildingError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-137 hyd-osago-low-margin"
                htmlFor={"mortgage-step-3_" + building}
                labelText={"Строение"}
                divId={"float-label-small"}
                child={getFieldDecorator(building, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: false,
                      message: " "
                    },
                  ],
                })(
                  <Input
                    className="hyd-input-small hyd-mortgage-137"
                    maxLength={5}
                    onChange={(e) => {
                      reConstructAddressStr(e.target.value, building)
                    }}
                  />
                )}
              />
            </Form.Item>

            {/* Квартира */}
            <Form.Item
              validateStatus={flatError ? "error" : ""}
              help={flatError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-136 hyd-osago-low-margin"
                htmlFor={"mortgage-step-3_" + flat}
                labelText={"Квартира"}
                divId={"float-label-small"}
                child={getFieldDecorator(flat, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: false,
                      whitespace: true,
                      message: " "
                    },
                  ],
                })(
                  <Input
                    className="hyd-input-small hyd-mortgage-136"
                    maxLength={5}
                    onChange={(e) => {
                      reConstructAddressStr(e.target.value, flat)
                    }}
                  />
                )}
              />
            </Form.Item>
          </Row>

          <Row style={{marginTop: 20}}>
            {/* Комната */}
            {getFieldValue(objectType) === 2 && <Form.Item
              validateStatus={roomError ? "error" : ""}
              help={roomError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-137 hyd-osago-low-margin"
                htmlFor={"mortgage-step-3_" + room}
                labelText={"Комната"}
                divId={"float-label-small"}
                child={getFieldDecorator(room, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                  ],
                })(
                  <Input
                    className="hyd-input-small hyd-mortgage-137"
                    maxLength={5}
                    onChange={(e) => {
                      reConstructAddressStr(e.target.value, room)
                    }}
                  />
                )}
              />
            </Form.Item>}
          </Row>

          <Row>
            <Form.Item
              validateStatus={objectAddressCustomStringError ? "error" : ""}
              help={objectAddressCustomStringError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-909"
                htmlFor={"mortgage-step-3_" + objectAddressCustomString}
                labelText={"Адрес строкой: регион, населённый пункт, улица, дом, корпус, строение, квартира"}
                divId={"float-label-small"}
                child={getFieldDecorator(objectAddressCustomString, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                  ],
                })(
                  <Input
                    className="hyd-input-small hyd-mortgage-909"
                  />
                )}
              />
            </Form.Item>
          </Row>

        </Form>
      </>
    );
  }
}

const WrappedPropertyForm = Form.create({
    name: "mortgage-step-3",
    mapPropsToFields(props) {
      const {mortgageDataForm} = props;
      const transformed = transform({mortgageDataForm});

      return transformed.mortgageDataForm;
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields);
    },
  }
)(Property);

const mapStateToProps = (state) => ({
  banks: banksSelector(state),
  mortgageDataForm: mortgageDataFormSelector(state),
  objectAddressArr: objectAddressArrSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDataForm,
      toggleSelectedObjectAddress,
      clearObjectAddressData,
      saveObjectAddressData,
      getAddressData,
      reConstructAddressStr,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WrappedPropertyForm);

import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Redirect, withRouter} from "react-router-dom";

import Preloader from "../../components/Preloader";
import {clearForm} from "../../actions/accidentsSportActions";
import {clearIsRequestState} from "../../actions/osagoActions";
import {isRequestSelector} from "../../selectors/osagoSelectors";

import * as keys from "../../routers/keys";
import {signOut, checkAuthentication} from "../../actions/loginActions";
import {tokenSelector, userInfoSelector} from "../../selectors/loginSelectors";

import "./styles/index.scss";
import {Tooltip, Layout} from 'antd';
import {ReactComponent as LogoutIcon} from '../../assets/Icons/Logout.svg';
import {ReactComponent as PersonIcon} from '../../assets/Icons/Person.svg';
import {ReactComponent as QuestionIcon} from '../../assets/Icons/Question.svg';
import {ReactComponent as HeadLineIcon} from '../../assets/Icons/HeadLine.svg';
import {isNumeric, scrollToTop} from "../../utils";
import {ReactComponent as LogoHeadIcon} from "../../assets/Icons/logos/LogoHead.svg";
import {getMoneyButton, mainDropdownMenu, showNotifications} from "../HeaderBase";
import {toggleNotificationsModal} from "../../actions/mainActions";
import {
  earnedBonusesSelector,
  earnedMoneySelector,
  notificationsSelector
} from "../../selectors/mainSelectors";

const {Header} = Layout;


class HeaderLogOut extends Component {

  componentDidMount() {
    this.props.checkAuthentication()
    this.props.clearIsRequestState()
  }

  render() {
    const {
      token,
      signOut,
      isRequest,
      userInfo,
      history,
      notifications,
      toggleNotificationsModal,
      earnedBonuses,
      earnedMoney,
    } = this.props;

    if (!token) {
      return <Redirect to={keys.AUTH}/>;
    }

    return (
      <Header>
        <Preloader loading={isRequest}/>
        <div className="hyd-header-container">

          <div className="hyd-header-left">
            <span
              className="hyd-logo hyd-logo-header"
              onClick={() => {
                if (this.props.location.pathname === keys.HOME) {scrollToTop()}
                history.replace(keys.HOME)
              }}
            >
              <LogoHeadIcon/>
            </span>

            <span
              className="hyd-person-ico"
              onClick={() => {history.replace(keys.PROFILE)}}
            >
              <PersonIcon className="hyd-person-ico"/>
              {userInfo && userInfo["user_header_name"]}
            </span>

            {token && (
              <span
                onClick={() => {
                  signOut();
                }}
              >
                <LogoutIcon className="hyd-logout-ico"/>
              </span>
            )}
          </div>

          <div className="hyd-header-right">
            <div className="hyd-header-cash">
              <span className="hyd-header-cash-text">
                Заработано
              </span>
              <p className="inter-lineage">
              </p>
              <span className="hyd-header-money-text">
                {earnedMoney && isNumeric(earnedMoney) ? Number(earnedMoney).toLocaleString("ru-RU") : "0.0"} ₽
              </span>
            </div>

            <div className="hyd-header-bonus">
              <span className="hyd-header-bonus-text">
                Бонусы
              </span>
              <p className="inter-lineage">
              </p>
              <span className="hyd-header-money-text">
                {earnedBonuses} ₽
              </span>
            </div>

            <Tooltip
              trigger="click"
              title="Бонусы за выполнение KPI"
            >
              <QuestionIcon className="hyd-q-ico"/>
            </Tooltip>

            {getMoneyButton(earnedMoney)}
            {showNotifications(notifications, toggleNotificationsModal)}

          </div>

          <div className="hyd-header-divider">
            <HeadLineIcon/>
          </div>

          <div className="hyd-header-bottom">
            <div className="hyd-header-menu">
              {mainDropdownMenu(history)}
            </div>
          </div>

        </div>
      </Header>
    )
  }
}

const mapStateToProps = state => ({
  token: tokenSelector(state),
  isRequest: isRequestSelector(state),
  userInfo: userInfoSelector(state),
  notifications: notificationsSelector(state),
  earnedBonuses: earnedBonusesSelector(state),
  earnedMoney: earnedMoneySelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signOut,
      checkAuthentication,
      clearIsRequestState,
      clearForm,
      toggleNotificationsModal,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderLogOut)
)

import React from "react";
import {ReactComponent as NumberInactiveIcon} from "../../../assets/Icons/NumberInactive.svg";
import {Button, Row} from "antd";
import {connect} from "react-redux";
import {crossDataFormSelector, isRequestSelector} from "../../../selectors/crossSelectors";


class FirstStepDisabled extends React.Component {
  render() {
    const {
      dataForm,
      goToPrevStep,
      isDisabled
    } = this.props

    return (
      <>
        <div className="step-title-disabled">
          <div className="step-icon-block">
            <NumberInactiveIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">1</span>
          </div>
          <span className="step-title-disabled-header">Данные для расчёта</span>
          {goToPrevStep && !isDisabled &&
            <Button
              className="hyd-change-button-small"
              onClick={goToPrevStep}
            >
              Изменить
            </Button>}
        </div>

        <Row>
          {dataForm.insured_sum &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Страховая сумма</div>
              <div className={"hyd-prev-step-value"}>{Number(dataForm.insured_sum.value).toLocaleString("ru-RU")} ₽
              </div>
            </div>}

          {dataForm.policy_series &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Серия полиса СК Гелиос</div>
              <div className={"hyd-prev-step-value"}>{dataForm.policy_series.value}</div>
            </div>}

          {dataForm.policy_number &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Номер полиса СК Гелиос</div>
              <div className={"hyd-prev-step-value"}>{dataForm.policy_number.value}</div>
            </div>}
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => (
  {
    dataForm: crossDataFormSelector(state),
    isRequest: isRequestSelector(state),
  });

export default connect(mapStateToProps)(FirstStepDisabled);
import React, {Component} from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Radio,
  Slider,
  Select,
  Button,
  Checkbox,
  AutoComplete,
  Tooltip,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Cleave from "cleave.js/react";

import {
  getSum,
  saveKladr,
  goToNextStep,
  getInsurerKladr,
  getKindsOfSports,
  saveSportKindData,
  clearIsRequestState,
  toggleIsSelectedSport,
  setDataAccidentsSportForm,
  sendDataAccidentSportForm,
  toggleInsurerSelectedAddress,
  removeInsurerFromListInsurers,
  changeQuantityAdditionalInsurers,
} from "../../actions/accidentsSportActions";
import {
  isRequestSelector,
  currentStepSelector,
  kindsOfSportsSelector,
  isSelectedSportSelector,
  insurerAddressArrSelector,
  errorsAccidentsSportSelector,
  accidentsSportDataFormSelector,
  quantityAdditionalInsurersSelector,
} from "../../selectors/accidentsSportSelectors";
import ModalError from "../../components/ModalError";
import {
  transform,
  hasErrors,
  getDateFromString,
  nameRules,
  checkValueBirthDate,
  checkValueBirthDateChild,
  frontDateFormat,
} from "../../utils";
import "./styles/index.scss";
import "../../fonts/open-sans.css"
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import {ReactComponent as NumberInactiveIcon} from "../../assets/Icons/NumberInactive.svg";
import {ReactComponent as QuestionIcon} from "../../assets/Icons/Question.svg";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import AdditionalInsurers from "./AdditionalInsurers";
import Preloader from "../../components/Preloader";
import moment from "moment";

const {Option} = Select;

let id = 0;

const markValues = [50000, 100000, 150000, 200000, 250000, 300000, 350000, 400000, 450000, 500000]

const marks = markValues.reduce((acc, value) => {
  const label = `${value / 1000} т.`
  const style = value === 500000 ? { width: 40 } : { marginLeft: 5 }
  acc[value] = { style, label }
  return acc;
}, {})

class FirstStepAccidentsSport extends Component {
  state = {
    loading: false,
    cleaveKey: 0,
  };

  componentDidMount() {
    this.props.clearIsRequestState()
    const {getKindsOfSports} = this.props;
    getKindsOfSports()
  }

  handleSubmit = (e) => {
    const {sendDataAccidentSportForm} = this.props;

    e.preventDefault();
    this.props.form.validateFields((err, values, force = true) => {
      if (!err) {
        sendDataAccidentSportForm()
      } else {
        console.log(err)
      }
    })
  }

  changeMainInsurerLastName = (e) => {
    const {getFieldValue, setFieldsValue} = this.props.form;

    let policy_owner_is_insurer = getFieldValue("policy_owner_is_insurer");

    if (policy_owner_is_insurer) {
      setFieldsValue({main_insurer_last_name: e.target.value});
    }
  }

  changeMainInsurerFirstName = (e) => {
    const {getFieldValue, setFieldsValue} = this.props.form;

    let policy_owner_is_insurer = getFieldValue("policy_owner_is_insurer");

    if (policy_owner_is_insurer) {
      setFieldsValue({main_insurer_name: e.target.value});
    }
  }

  changeMainInsurerMiddleName = (e) => {
    const {getFieldValue, setFieldsValue} = this.props.form;

    let policy_owner_is_insurer = getFieldValue("policy_owner_is_insurer");

    if (policy_owner_is_insurer) {
      setFieldsValue({main_insurer_middle_name: e.target.value});
    }
  }

  changeMainInsurerBirthDate = (e) => {
    const {getFieldValue, setFieldsValue} = this.props.form;

    let policy_owner_is_insurer = getFieldValue("policy_owner_is_insurer");

    if (policy_owner_is_insurer) {
      setFieldsValue({main_insurer_birth_date: e.target.value});
    }
  }

  onChangeSlider = e => {
    const {getSum} = this.props;
    getSum(e);
  }

  onChangePolicyOwnerIsInsurerCheckbox = e => {
    const {setFieldsValue, getFieldValue} = this.props.form;

    if (e.target.checked) {
      setFieldsValue({
        main_insurer_last_name: getFieldValue("insurer_last_name"),
        main_insurer_name: getFieldValue("insurer_first_name"),
        main_insurer_middle_name: getFieldValue("insurer_middle_name"),
        main_insurer_birth_date: getFieldValue("insurer_birth_date"),
      })
    } else {
      setFieldsValue({
        main_insurer_last_name: "",
        main_insurer_name: "",
        main_insurer_middle_name: "",
        main_insurer_birth_date: "",
      })
    }
  }

  changeRadioDurationInsurance = e => {
    const {getFieldValue} = this.props.form;
    let value = getFieldValue("start_policy_date");

    if (e.target.value === 1) { //если срок страхования на 1 год
      if (value) {
        this.changeYear(value);  //прибавляем год к сроку окончания страхования
      }
    }
  }

  getTwoDigitDateFormat = (date) => {
    return (date < 10) ? '0' + date : '' + date;
  }

  changeYear = (resultValueDate) => {
    const {setFieldsValue} = this.props.form;
    if (resultValueDate.split('.').length === 3) {
      let finish_date = moment(resultValueDate, frontDateFormat)
        .add(1, 'years')
        .subtract(1, "days")
        .format(frontDateFormat)
      setFieldsValue({"end_policy_date": finish_date})
    }
  }

  checkValueExperienceDate = (rule, value, callback) => {
    const {accidentsSportDataForm} = this.props;
    if (value) {
      let resultCurrentDate = new Date()
      let resultPastDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100))
      let birthDate = getDateFromString(accidentsSportDataForm["insurer_birth_date"].value.split('.'))
      let resultValueDate = value.split('.')
      if (moment(value, frontDateFormat, true).isValid()) {
        let result = getDateFromString(resultValueDate)
        if (result && (resultCurrentDate < result) || (result < resultPastDate)) {
          callback(" ")
        } else if (moment(result).diff(moment(birthDate), 'years', true) < 14) { // 14 yo valid
          callback(" ")
        } else {
          callback()
        }
      } else {
        callback(" ")
      }
    } else {
      callback()
    }
  }

  checkValueDate = (rule, value, callback) => {
    if (value) {
      const {getFieldValue} = this.props.form;
      if (rule.field === "start_policy_date" && getFieldValue("duration_insurance") === 1) { //если изменяется дата начала страхования и выбран период на один год
        if (value) {
          this.changeYear(value); //изменяем дату окончания в соответствии с новой датой начала страхования
        }
        // callback();
      }

      if (rule.field === "end_policy_date" && getFieldValue("duration_insurance") === 2) {
        let startDate = getFieldValue("start_policy_date")
        let resultStartDate = startDate.split(".")
        startDate = getDateFromString(resultStartDate)

        let finishDate = value
        let resultFinishDate = finishDate.split(".")
        finishDate = getDateFromString(resultFinishDate)

        if (finishDate <= startDate) {
          callback(" ")
        } else {
          callback()
        }
        callback()
      }
    }

    if (moment(value, frontDateFormat, true).isValid()) {
      let resultCurrentDate = new Date() //
      let result = getDateFromString(value.split("."))

      result.setHours(0, 0, 0, 0)
      resultCurrentDate.setHours(0, 0, 0, 0)

      if (result && result < resultCurrentDate) { //проверяем или введенная дата больше равна сегодняшней
        callback(" ")
      } else {
        callback()
      }
    } else {
      callback(" ")
    }
  }

  changeRadioTypeInsurance = e => {
    const {setFieldsValue} = this.props.form;
    if (e.target.value === 1) { // если НС здоровье
      setFieldsValue({
        sport_kind: [],
      })
    }
  }

  onCustomSeriesNumDocChange = (event) => {
    const {setFieldsValue} = this.props.form;
    if (event.target.value !== "") {
      this.setState({isActive: true});
    } else {
      this.setState({isActive: false});
    }
    let resultValueDate = event.target.value.split(" ")
    if (resultValueDate.length === 2) {
      let series = resultValueDate[0]
      let number = resultValueDate[1]
      setFieldsValue({
        insurer_number_document: number,
        insurer_series_document: series
      })
    } else {
      setFieldsValue({
        insurer_number_document: event.target.rawValue,
        insurer_series_document: ""
      })
    }
  }

  remove = (k) => {
    const {form, removeInsurerFromListInsurers} = this.props
    // can use data-binding to get
    const keys = form.getFieldValue("keys")

    // We need at least one
    // if (keys.length === 1) {
    //   return;
    // }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter((key) => key !== k),
    })
    removeInsurerFromListInsurers(k)
  }

  add = () => {
    const {form} = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("keys")
    const nextKeys = keys.concat(id++)
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    })
  }


  render() {
    const {
      saveKladr,
      isRequest,
      addressArr,
      kindsOfSports,
      getInsurerKladr,
      saveSportKindData,
      toggleIsSelectedSport,
      quantityAdditionalInsurers,
      toggleInsurerSelectedAddress,
      changeQuantityAdditionalInsurers,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      getFieldValue,
      setFieldsValue,
    } = this.props.form;

    const durationInsurance = "duration_insurance";
    const durationInsuranceError = getFieldError(durationInsurance);

    const startDateInsurance = "start_policy_date";
    const startDateInsuranceError = getFieldError(startDateInsurance);

    const finishDateInsurance = "end_policy_date";
    const finishDateInsuranceError = getFieldError(finishDateInsurance);

    const typeInsurance = "product_name";
    const typeInsuranceError = getFieldError(typeInsurance);

    const leisure = "leisure";
    const leisureError = getFieldError(leisure);

    const sport = "sport";
    const sportError = getFieldError(sport);

    const sportKind = "sport_kind";
    const sportKindError = getFieldError(sportKind);

    const territoryInsurance = "territory";
    const territoryInsuranceError = getFieldError(territoryInsurance);

    const isProfessionalAthletes = "is_professional";
    const policyOwnerIsInsurer = "policy_owner_is_insurer";
    const physicalInjuries = "physical_injuries";
    const disability = "disability";
    const death = "death";

    const coverage = "sum_range";
    const coverageError = getFieldError(coverage);

    const policyOwnerLastName = "insurer_last_name";
    const policyOwnerLastNameError = getFieldError(policyOwnerLastName);

    const policyOwnerFirstName = "insurer_first_name";
    const policyOwnerFirstNameError = getFieldError(policyOwnerFirstName);

    const policyOwnerMiddleName = "insurer_middle_name";
    const policyOwnerMiddleNameError = getFieldError(policyOwnerMiddleName);

    const policyOwnerBirthDate = "insurer_birth_date";
    const policyOwnerBirthDateError = getFieldError(policyOwnerBirthDate);

    const policyOwnerSeriesDocument = "insurer_series_document";
    const policyOwnerSeriesDocumentError = getFieldError(policyOwnerSeriesDocument);

    const policyOwnerNumberDocument = "insurer_number_document";
    const policyOwnerNumberDocumentError = getFieldError(policyOwnerNumberDocument);

    const documentSeriesNumber = "series_number_document";
    const documentSeriesNumberError = getFieldError(documentSeriesNumber);

    const experienceDate = "insurer_document_issue_date";
    const experienceDateError = getFieldError(experienceDate);

    const issuer = "insurer_document_issue_by";
    const issuerError = getFieldError(issuer);

    const policyOwnerAddress = "insurer_address_text";
    const policyOwnerAddressError = getFieldError(policyOwnerAddress);

    const policyOwnerPhone = "insurer_phone";
    const policyOwnerPhoneError = getFieldError(policyOwnerPhone);

    const policyOwnerEmail = "insurer_email";
    const policyOwnerEmailError = getFieldError(policyOwnerEmail);

    const mainInsurerLastName = "main_insurer_last_name";
    const mainInsurerLastNameError = getFieldError(mainInsurerLastName);

    const mainInsurerName = "main_insurer_name";
    const mainInsurerNameError = getFieldError(mainInsurerName);

    const mainInsurerMiddleName = "main_insurer_middle_name";
    const mainInsurerMiddleNameError = getFieldError(mainInsurerMiddleName);

    const mainInsurerBirthDate = "main_insurer_birth_date";
    const mainInsurerBirthDateError = getFieldError(mainInsurerBirthDate);

    const showMainInsurer = () => {
      return (
        <Row>
          <div className={"hyd-insurers-number-accidents"}>
            Застрахованный №1
          </div>

          {/* Фамилия */}
          <Form.Item
            validateStatus={mainInsurerLastNameError ? "error" : ""}
            help={mainInsurerLastNameError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"accidents-sport-step-1_" + mainInsurerLastName}
              labelText={"Фамилия"}
              divId={"float-label-small"}
              child={getFieldDecorator(mainInsurerLastName, {
                initialValue:
                  this.props.accidentsSportDataForm.main_insurer_last_name &&
                  this.props.accidentsSportDataForm.main_insurer_last_name.value,
                validateTrigger: ["onBlur", "onChange"],
                rules: nameRules,
              })(<Input className={"ant-input hyd-input-small hyd-osago-8-input"}/>)}
            />
          </Form.Item>

          {/* Имя */}
          <Form.Item
            validateStatus={mainInsurerNameError ? "error" : ""}
            help={mainInsurerNameError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"accidents-sport-step-1_" + mainInsurerName}
              labelText={"Имя"}
              divId={"float-label-small"}
              child={getFieldDecorator(mainInsurerName, {
                initialValue:
                  this.props.accidentsSportDataForm.main_insurer_name &&
                  this.props.accidentsSportDataForm.main_insurer_name.value,
                validateTrigger: ["onBlur", "onChange"],
                rules: nameRules,
              })(<Input className={"ant-input hyd-input-small hyd-osago-8-input"}/>)}
            />
          </Form.Item>

          {/* Отчество */}
          <Form.Item
            validateStatus={mainInsurerMiddleNameError ? "error" : ""}
            help={mainInsurerMiddleNameError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input hyd-osago-no-margin"
              htmlFor={"accidents-sport-step-1_" + mainInsurerMiddleName}
              labelText={"Отчество"}
              divId={"float-label-small"}
              child={getFieldDecorator(mainInsurerMiddleName, {
                initialValue:
                  this.props.accidentsSportDataForm.main_insurer_middle_name &&
                  this.props.accidentsSportDataForm.main_insurer_middle_name.value,
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    // только лат или только кириллица (допускается двойное отчество с пробелом по центру или тире)
                    pattern: /^(([А-Яа-яЁё])+(-|\s)?([А-Яа-яЁё]+))$|^(([A-za-z])+(-|\s)?([A-za-z]+))$/g,
                    message: " "
                  },
                ],
              })(<Input className={"ant-input hyd-input-small hyd-osago-8-input"}/>)}
            />
          </Form.Item>

          {/* Дата рождения */}
          <Form.Item
            validateStatus={mainInsurerBirthDateError ? "error" : ""}
            help={mainInsurerBirthDateError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"accidents-sport-step-1_" + mainInsurerBirthDate}
              labelText={"Дата рождения"}
              divId={"float-label-small"}
              child={getFieldDecorator(mainInsurerBirthDate, {
                initialValue:
                  this.props.accidentsSportDataForm.main_insurer_birth_date &&
                  this.props.accidentsSportDataForm.main_insurer_birth_date.value,
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: !getFieldValue(mainInsurerBirthDate),
                    message: " "
                  },
                  {
                    validator: checkValueBirthDateChild,
                  },
                ],
              })(
                <Cleave
                  className="ant-input hyd-input-small hyd-osago-8-input"
                  key={mainInsurerBirthDate}
                  htmlRef={(ref) => (this.ccInput = ref)}
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"],
                  }}
                />
              )}
            />
          </Form.Item>

          {/*<Row>*/}
          {/*  <div className={"hyd-emphasise-block-small"} id={"hyd-add-insurer"}>*/}
          {/*    <Form.Item>*/}
          {/*      <Button*/}
          {/*        className={"hyd-btn-small hyd-osago-btn"}*/}
          {/*        onClick={this.add}*/}
          {/*        style={{width: "100%", marginRight: "auto"}}*/}
          {/*      >*/}
          {/*        Добавить*/}
          {/*      </Button>*/}
          {/*    </Form.Item>*/}
          {/*  </div>*/}
          {/*</Row>*/}

        </Row>
      )
    }

    getFieldDecorator("keys", {initialValue: getFieldValue("keys")});
    const keys = getFieldValue("keys");
    changeQuantityAdditionalInsurers(keys.length);

    const formItems = keys.map((k, index) => {
      const newIndex = index + 2
      return (
        <AdditionalInsurers
          isLast={quantityAdditionalInsurers === index + 1}
          form={this.props.form}
          k={k}
          key={k + index}
          index={newIndex}
          keys={keys}
          remove={this.remove}
          add={this.add}
          checkValueDate={checkValueBirthDateChild}
        />
      );
    });


    return (
      <div style={{width: 911}}>
        <Preloader loading={isRequest}/>
        <div className="step-title-active-small">
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">1</span>
          </div>

          <span className="step-title-active-header">Оформление</span>
        </div>
        <Form
          layout="inline"
          onSubmit={this.handleSubmit}
          id="accidents-sport-step-1"
        >

          <Row gutter={8}>
            <Col span={10}>
              {/* Срок страхования */}
              <div className={"hyd-bold-text-small accident-m-7"}>Срок страхования</div>

              <Form.Item
                validateStatus={durationInsuranceError ? "error" : ""}
                help={durationInsuranceError || ""}
              >
                {getFieldDecorator(durationInsurance, {
                  initialValue: getFieldValue(durationInsurance),
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [{required: true, message: " "}]
                })(
                  <Radio.Group
                    name={durationInsurance}
                    onChange={(e) => {
                      this.changeRadioDurationInsurance(e);
                    }}
                    className={"hyd-radio-group"}
                  >
                    <Radio className={"hyd-norm-text"} value={1}>На 1 год</Radio>
                    <Radio className={"hyd-norm-text"} value={2}>Произвольный период</Radio>
                  </Radio.Group>
                )}
              </Form.Item>

              {/* Дата начала страхования */}
              <Row gutter={0}>
                <Form.Item
                  validateStatus={startDateInsuranceError ? "error" : ""}
                  help={startDateInsuranceError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-accident-input-1"
                    htmlFor={"accidents-sport-step-1_" + startDateInsurance}
                    labelText={"Дата начала"}
                    divId={"float-label-small"}
                    child={getFieldDecorator(startDateInsurance, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        {
                          required: true,
                          message: " "
                        },
                        {
                          validator: this.checkValueDate,
                        }
                      ]
                    })(
                      <Cleave
                        className="ant-input hyd-input-small hyd-accident-input-1"
                        key={startDateInsurance}
                        htmlRef={(ref) => (this.ccInput = ref)}
                        options={{
                          date: true,
                          delimiter: ".",
                          datePattern: ["d", "m", "Y"]
                        }}
                        onChange={() => {
                          if (getFieldValue(durationInsurance) === 2) {
                            this.props.form.setFieldsValue({
                              end_policy_date: undefined,
                            });
                          }
                        }}
                      />
                    )}
                  />
                </Form.Item>

                {/* Дата окончания страхования */}
                <Form.Item
                  validateStatus={finishDateInsuranceError ? "error" : ""}
                  help={finishDateInsuranceError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-accident-input-1"
                    htmlFor={"accidents-sport-step-1_" + finishDateInsurance}
                    labelText={"Дата окончания"}
                    divId={"float-label-small"}
                    disabled={getFieldValue(durationInsurance) === 1}
                    child={getFieldDecorator(finishDateInsurance, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        {
                          required: getFieldValue(durationInsurance) === 2,
                          message: " "
                        },
                        {
                          validator: this.checkValueDate,
                        }
                      ]
                    })(
                      <Cleave
                        className="ant-input hyd-input-small hyd-accident-input-1"
                        key={finishDateInsurance}
                        htmlRef={(ref) => (this.ccInput = ref)}
                        options={{
                          date: true,
                          delimiter: ".",
                          datePattern: ["d", "m", "Y"]
                        }}
                        disabled={getFieldValue(durationInsurance) === 1}
                      />
                    )}
                  />
                </Form.Item>
              </Row>
            </Col>

            <Col>
              {/* Территория страхования */}
              <div className={"hyd-bold-text-small accident-m-7"}>Территория страхования</div>
              <Form.Item
                validateStatus={territoryInsuranceError ? "error" : ""}
                help={territoryInsuranceError || ""}
              >
                {getFieldDecorator(territoryInsurance, {
                  initialValue: getFieldValue(territoryInsurance),
                  rules: [{required: true, message: " "}]
                })(
                  <Radio.Group
                    name={territoryInsurance}
                    className={"hyd-radio-group"}
                  >
                    <Radio className={"hyd-norm-text"} value={2}>Весь мир</Radio>
                    <Radio className={"hyd-norm-text"} value={1}>РФ</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>

          {/* НС Здоровье */}
          <Form.Item
            style={{display: "none"}}
            validateStatus={leisureError ? "error" : ""}
            help={leisureError || ""}
          >
            {getFieldDecorator(leisure, {
              validateTrigger: ["onBlur", "onChange"],
              rules: [{required: false, message: " "}]
            })(<Input/>)}
            />
          </Form.Item>

          {/* НС Спорт */}
          <Form.Item
            style={{display: "none"}}
            validateStatus={sportError ? "error" : ""}
            help={sportError || ""}
          >
            {getFieldDecorator(sport, {
              validateTrigger: ["onBlur", "onChange"],
              rules: [{required: false, message: " "}]
            })(<Input/>)}
          </Form.Item>

          <Row gutter={8}>
            <Col span={10}>
              {/* Тип страхования */}
              <div className={"hyd-bold-text-small accident-m-7"}>Тип страхования</div>
              <Form.Item
                validateStatus={typeInsuranceError ? "error" : ""}
                help={typeInsuranceError || ""}
              >
                {getFieldDecorator(typeInsurance, {
                  initialValue: getFieldValue(typeInsurance),
                  rules: [{required: true, message: " "}]
                })(
                  <Radio.Group
                    name={typeInsurance}
                    onChange={(e) => {
                      this.changeRadioTypeInsurance(e);
                    }}
                    style={{margin: "0 0 8px 0"}}
                    className={"hyd-radio-group"}
                  >
                    <Radio className={"hyd-norm-text"} value={1}>НС Здоровье</Radio>
                    <Radio className={"hyd-norm-text"} value={2}>НС Спорт</Radio>
                  </Radio.Group>
                )}
              </Form.Item>

              {/* Виды спорта */}
              {getFieldValue(typeInsurance) === 2 &&
                <>
                  <Form.Item
                    validateStatus={sportKindError ? "error" : ""}
                    help={sportKindError || ""}
                  >
                    <FloatingLabelInput
                      divClassName="hyd-select-small accident-sport-selector"
                      htmlFor={"accidents-sport-step-1_" + sportKind}
                      labelText={"Вид спорта"}
                      divId={"float-label-small"}
                      child={getFieldDecorator(sportKind, {
                        initialValue: getFieldValue(sportKind) || [],
                        validateTrigger: ["onBlur", "onChange"],
                        rules: [
                          {
                            required: true,
                            message: " ",
                            type: "array"
                          }
                        ],
                      })(
                        <Select
                          mode="multiple"
                          allowClear
                          className="hyd-select-small"
                          filterOption={(inputValue, option) =>
                            option.props.children
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) === 0
                          }
                          onSelect={(inputValue, option) => {
                            const sportData = kindsOfSports.find(
                              (item) => +item.value === +inputValue
                            )
                            saveSportKindData(sportData)
                            toggleIsSelectedSport(true)
                          }}
                          onChange={() => {
                            toggleIsSelectedSport(false)
                          }}
                        >
                          {kindsOfSports && kindsOfSports.map(({value, text}) =>
                            <Option
                              disabled={getFieldValue(sportKind).length > 2 ? !getFieldValue(sportKind).includes(value) : false}
                              value={value}
                            >
                              {text}
                            </Option>
                          )}
                        </Select>
                      )}
                    />
                  </Form.Item>

                  {getFieldDecorator(isProfessionalAthletes, {})(
                    <Checkbox
                      style={{marginTop: 10}}
                      checked={getFieldValue(isProfessionalAthletes)}
                      className={"hyd-norm-text"}
                    >
                      Спортсмены-профессионалы
                    </Checkbox>
                  )}

                  <Tooltip
                    trigger="hover"
                    title="Категорию можно определить исходя из места работы и должности застрахованного"
                  >
                    <QuestionIcon className="hyd-q-ico-2"/>
                  </Tooltip>
                </>
              }

            </Col>

            <Col offset={10}>
              {/* ириски и Риски */}
              <div className={"hyd-bold-text-small accident-m-16"}>Риски</div>

              {getFieldDecorator(death, {})(
                <Checkbox
                  disabled checked={getFieldValue(death)} className={"hyd-norm-text mortgage-checkbox-disabled"}>
                  Смерть в результате НС
                </Checkbox>
              )}
              <div className={"accident-m-16"}/>
              {getFieldDecorator(disability, {})(
                <Checkbox checked={getFieldValue(disability)} className={"hyd-norm-text"}>
                  Инвалидность 1, 2 группы в результате НС
                </Checkbox>
              )}
              <div className={"accident-m-16"}/>
              {getFieldDecorator(physicalInjuries, {})(
                <Checkbox checked={getFieldValue(physicalInjuries)} className={"hyd-norm-text"}>
                  Травма в результате НС
                </Checkbox>
              )}
            </Col>
          </Row>

          {/* Покрытие */}
          <div
            className={"hyd-bold-text-small"}
            style={{margin: "40px 0 8px 0"}}
          >
            Покрытие {Intl.NumberFormat('ru-RU').format(getFieldValue(coverage))} ₽
          </div>

          <Form.Item
            validateStatus={coverageError ? "error" : ""}
            help={coverageError || ""}
            className="slider"
          >
            {getFieldDecorator(coverage, {
              initialValue: getFieldValue(coverage),
              rules: [
                {
                  required: true,
                  message: " "
                },
              ]
            })(
              <Slider
                className={"accident-sport-slider"}
                trackStyle={{backgroundColor: "var(--color-four)"}}
                handleStyle={{borderColor: "var(--color-seven)"}}
                marks={marks}
                step={50000}
                min={50000}
                max={500000}
                tooltipVisible={false}
                onChange={this.onChangeSlider}
              />
            )}
          </Form.Item>

          {/* Страхователь */}
          <div className={"hyd-bold-text-small"} style={{margin: "40px 0 16px 0"}}>Страхователь</div>

          <Col>
            {getFieldDecorator(policyOwnerIsInsurer, {
              initialValue: getFieldValue(policyOwnerIsInsurer),
            })(
              <Checkbox
                onChange={this.onChangePolicyOwnerIsInsurerCheckbox}
                checked={getFieldValue(policyOwnerIsInsurer)}
                className={"hyd-norm-text"}
                style={{marginBottom: 16}}
              >
                Застрахованный
              </Checkbox>
            )}
          </Col>

          {/* Фамилия */}
          <Form.Item
            validateStatus={policyOwnerLastNameError ? "error" : ""}
            help={policyOwnerLastNameError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"accidents-sport-step-1_" + policyOwnerLastName}
              labelText={"Фамилия"}
              divId={"float-label-small"}
              child={getFieldDecorator(policyOwnerLastName, {
                validateTrigger: ["onBlur", "onChange"],
                rules: nameRules,
              })(
                <Input
                  className={"ant-input hyd-input-small hyd-osago-8-input"}
                  onChange={(e) => {
                    this.changeMainInsurerLastName(e);
                  }}
                />
              )}
            />
          </Form.Item>

          {/* Имя */}
          <Form.Item
            validateStatus={policyOwnerFirstNameError ? "error" : ""}
            help={policyOwnerFirstNameError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"accidents-sport-step-1_" + policyOwnerFirstName}
              labelText={"Имя"}
              divId={"float-label-small"}
              child={getFieldDecorator(policyOwnerFirstName, {
                validateTrigger: ["onBlur", "onChange"],
                rules: nameRules,
              })(
                <Input
                  className={"ant-input hyd-input-small hyd-osago-8-input"}
                  onChange={(e) => {
                    this.changeMainInsurerFirstName(e);
                  }}
                />
              )}
            />
          </Form.Item>

          {/* Отчество */}
          <Form.Item
            validateStatus={policyOwnerMiddleNameError ? "error" : ""}
            help={policyOwnerMiddleNameError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input hyd-osago-no-margin"
              htmlFor={"accidents-sport-step-1_" + policyOwnerMiddleName}
              labelText={"Отчество"}
              divId={"float-label-small"}
              child={getFieldDecorator(policyOwnerMiddleName, {
                rules: [
                  {
                    // только лат или только кириллица (допускается двойное отчество с пробелом по центру или тире)
                    pattern: /^(([А-Яа-яЁё])+(-|\s)?([А-Яа-яЁё]+))$|^(([A-za-z])+(-|\s)?([A-za-z]+))$/g,
                    message: " "
                  },
                ],
              })(
                <Input
                  className={"ant-input hyd-input-small hyd-osago-8-input"}
                  onChange={(e) => {
                    this.changeMainInsurerMiddleName(e);
                  }}
                />
              )}
            />
          </Form.Item>

          {/* Дата рождения */}
          <Form.Item
            validateStatus={policyOwnerBirthDateError ? "error" : ""}
            help={policyOwnerBirthDateError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"accidents-sport-step-1_" + policyOwnerBirthDate}
              labelText={"Дата рождения"}
              divId={"float-label-small"}
              child={getFieldDecorator(policyOwnerBirthDate, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " ",
                  },
                  {
                    validator: checkValueBirthDate,
                  },
                ]
              })(
                <Cleave
                  className="ant-input hyd-input-small hyd-osago-8-input"
                  key={policyOwnerBirthDate}
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  }}
                  onChange={(e) => {
                    this.changeMainInsurerBirthDate(e);
                  }}
                />
              )}
            />
          </Form.Item>

          {/* Паспорт */}
          <div className={"hyd-bold-text-small"} style={{margin: "20px 0 16px 0"}}>Паспорт РФ</div>

          {/* Серия */}
          <Form.Item
            style={{display: "none"}}
            validateStatus={policyOwnerSeriesDocumentError ? "error" : ""}
            help={policyOwnerSeriesDocumentError || ""}
          >
            {getFieldDecorator(policyOwnerSeriesDocument, {
              validateTrigger: ["onBlur", "onChange"],
              rules: [{required: true, message: " "}]
            })(<Input/>)}
          </Form.Item>

          {/* Номер */}
          <Form.Item
            style={{display: "none"}}
            validateStatus={policyOwnerNumberDocumentError ? "error" : ""}
            help={policyOwnerNumberDocumentError || ""}
          >
            {getFieldDecorator(policyOwnerNumberDocument, {
              validateTrigger: ["onBlur", "onChange"],
              rules: [{required: true, message: " "}]
            })(<Input/>)}
          </Form.Item>

          <Form.Item
            validateStatus={documentSeriesNumberError ? "error" : ""}
            help={documentSeriesNumberError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"accidents-sport-step-1_" + documentSeriesNumber}
              labelText={"Серия и номер паспорта"}
              divId={"float-label-small"}
              child={getFieldDecorator(documentSeriesNumber, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    pattern: /^[0-9]{4} [0-9]{6}$/g,
                    message: " "
                  },
                ],
              })(
                <Cleave
                  key={documentSeriesNumber}
                  className="ant-input hyd-input-small hyd-osago-8-input"
                  options={{
                    blocks: [4, 6],
                    delimiter: ' ',
                    numericOnly: false
                  }}
                  onChange={this.onCustomSeriesNumDocChange}
                />
              )}
            />
          </Form.Item>

          {/* Дата выдачи */}
          <Form.Item
            validateStatus={experienceDateError ? "error" : ""}
            help={experienceDateError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"accidents-sport-step-1_" + experienceDate}
              labelText={"Дата выдачи"}
              divId={"float-label-small"}
              child={getFieldDecorator(experienceDate, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: !getFieldValue(experienceDate),
                    message: " "
                  },
                  {
                    validator: this.checkValueExperienceDate,
                  },
                ]
              })(
                <Cleave
                  className="ant-input hyd-input-small hyd-osago-8-input"
                  key={experienceDate}
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  }}
                />
              )}
            />
          </Form.Item>

          <Form.Item
            validateStatus={issuerError ? "error" : ""}
            help={issuerError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-accident-input-3"
              htmlFor={"accidents-sport-step-1_" + issuer}
              labelText={"Кем выдан"}
              divId={"float-label-small"}
              child={getFieldDecorator(issuer, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    pattern: /^[А-Яа-яЁё0-9\s-]{5,}$/g,
                    message: " "
                  },
                  {
                    pattern: /^.{5,}$/g,
                    message: " "
                  },
                ]
              })(
                <Input
                  className="ant-input hyd-input-small hyd-accident-input-3"
                />
              )}
            />
          </Form.Item>

          {/* Получить КЛАДР */}
          <Form.Item
            validateStatus={policyOwnerAddressError ? "error" : ""}
            help={policyOwnerAddressError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-accident-input-3 hyd-osago-autocomplete"
              htmlFor={"accidents-sport-step-1_" + policyOwnerAddress}
              labelText={"Адрес регистрации"}
              divId={"float-label-small"}
              child={getFieldDecorator(policyOwnerAddress, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                ],
              })(
                <AutoComplete
                  className="hyd-input-autocomplete-small"
                  dataSource={addressArr}
                  onSelect={(inputValue) => {
                    toggleInsurerSelectedAddress(true)
                    addressArr.map((value) => {
                      // достаем адрес, регион, страну из массива полученного с бэка
                      if (inputValue === value.value) {
                        let valueOfInput = getFieldValue(policyOwnerAddress)

                        if (valueOfInput.indexOf("_") !== -1) {
                          valueOfInput = valueOfInput.split("_")
                          setFieldsValue({
                            policyOwnerAddress: valueOfInput[1],
                          })
                        }
                        saveKladr(
                          valueOfInput[0],
                          value.text,
                          value.okato,
                          value.country,
                          value.region,
                          value.zip,
                          value.city_name,
                          value.city_kladr,
                          value.street_kladr,
                          value.street_name,
                          value.house,
                          value.block,
                          value.flat,
                          value.fias_id,
                          value.house_fias_id,
                        )
                      }
                    })
                  }}

                  onChange={(value) => {
                    if (value && value.length > 1) {
                      getInsurerKladr({address: value})
                    }
                    toggleInsurerSelectedAddress(false)
                  }}
                />
              )}
            />
          </Form.Item>

          {/* Контакты */}
          <div className={"hyd-bold-text-small"} style={{margin: "20px 0 16px 0"}}>Контакты</div>

          {/* Email */}
          <Form.Item
            validateStatus={policyOwnerEmailError ? "error" : ""}
            help={policyOwnerEmailError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"accidents-sport-step-1_" + policyOwnerEmail}
              labelText={"Email"}
              divId={"float-label-small"}
              child={getFieldDecorator(policyOwnerEmail, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {required: true, message: " "},
                  {
                    pattern: /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?\s*)+$/g,
                    message: " "
                  }
                ]
              })(<Input className="ant-input hyd-input-small hyd-osago-8-input"/>)}
            />
          </Form.Item>

          {/* Телефон */}
          <Form.Item
            validateStatus={policyOwnerPhoneError ? "error" : ""}
            help={policyOwnerPhoneError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"accidents-sport-step-1_" + policyOwnerPhone}
              labelText={"Телефон"}
              divId={"float-label-small"}
              child={getFieldDecorator(policyOwnerPhone, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    pattern: /^\+?([0-9]{1})\)?[ ]?([0-9]{3})[- ]?([0-9]{3})[- ]?([0-9]{2})[- ]?([0-9]{2})$/g,
                    message: " "
                  },
                ]
              })(
                <Cleave
                  className="ant-input hyd-input-small hyd-osago-8-input"
                  key={policyOwnerPhone}
                  options={{
                    phone: true,
                    prefix: "+7 ",
                    phoneRegionCode: 'RU',
                    noImmediatePrefix: true,
                  }}
                />
              )}
            />
          </Form.Item>

          {/* Застрахованные */}
          <div className={"hyd-bold-text-small"} style={{margin: "16px 0 0 0"}}>Застрахованные</div>
          {showMainInsurer()}
          {formItems}

          {/* Рассчитать */}
          <Form.Item style={{marginTop: 58}}>
            <Button
              className="hyd-btn-small"
              type="primary"
              htmlType="submit"
              form="accidents-sport-step-1"
              key="submit"
              disabled={hasErrors(getFieldsError())}
            >
              Рассчитать
            </Button>
          </Form.Item>

        </Form>
        {[
          {number: 2, text: "Расчет"},
          {number: 3, text: "Оплата"},
        ].map(({number, text}) =>
          <div className="step-title-disabled-small" key={number}>
            <div className="step-icon-block">
              <NumberInactiveIcon className="hyd-large-num-ico"/>
              <span className="hyd-large-num-ico-num">{number}</span>
            </div>
            <span className="step-title-disabled-header">{text}</span>
          </div>
        )}

        <ModalError type={4}/>
      </div>
    )
  }

}

const WrappedFirstStepAccidentsSportForm = Form.create({
    name: "accidents-sport-step-1",
    mapPropsToFields(props) {
      const {accidentsSportDataForm} = props;
      const transformed = transform({accidentsSportDataForm});

      return transformed.accidentsSportDataForm;
    },
    onFieldsChange(props, changedFields) {
      props.setDataAccidentsSportForm(changedFields);
    },
  }
)(FirstStepAccidentsSport);

const mapStateToProps = (state) => ({
  isRequest: isRequestSelector(state),
  currentStep: currentStepSelector(state),
  kindsOfSports: kindsOfSportsSelector(state),
  errors: errorsAccidentsSportSelector(state),
  addressArr: insurerAddressArrSelector(state),
  accidentsSportDataForm: accidentsSportDataFormSelector(state),
  quantityAdditionalInsurers: quantityAdditionalInsurersSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSum,
      saveKladr,
      goToNextStep,
      getInsurerKladr,
      getKindsOfSports,
      saveSportKindData,
      clearIsRequestState,
      toggleIsSelectedSport,
      isSelectedSportSelector,
      setDataAccidentsSportForm,
      sendDataAccidentSportForm,
      toggleInsurerSelectedAddress,
      removeInsurerFromListInsurers,
      changeQuantityAdditionalInsurers,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedFirstStepAccidentsSportForm);

import React from "react";
import {Button, Row} from "antd";
import {ReactComponent as GreyCircleIcon} from "../../../assets/Icons/GreyCircle.svg";
import {ReactComponent as NumberInactiveIcon} from "../../../assets/Icons/NumberInactive.svg";


export default class SecondStepDisabled extends React.Component {
  render() {
    const {
      value,
      docs,
      goToPrevStep,
      userInfo,
    } = this.props

    return (
      <>
        <div className="step-title-disabled-small">
          <div className="step-icon-block">
            <NumberInactiveIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">2</span>
          </div>
          <span className="step-title-disabled-header">Расчет</span>
          {goToPrevStep &&
          <Button
            className="hyd-change-button-small"
            onClick={goToPrevStep}
          >
            Изменить
          </Button>}
        </div>

        <Row>
          <div className={"hyd-osago-insurance-name-block hyd-prev-step"}>
            <GreyCircleIcon/>
            <div className={"hyd-osago-insurance-name"}>
              <span>{docs.insurance_name} </span>
              <span>
                {docs.type}
              </span>
            </div>
          </div>
          {value && <div className={"hyd-osago-ins-result-item hyd-prev-step"}>
            <div className={"hyd-osago-ins-result-head"}>Стоимость</div>
            <div className={"hyd-osago-ins-result-value"}>{Number(value.price).toLocaleString("ru-RU")} ₽</div>
          </div>}
          {userInfo.show_commission && value && value.agent_commission &&
              <div className={"hyd-osago-ins-result-item hyd-prev-step"}>
                <div className={"hyd-osago-ins-result-head"}>Комиссия агента</div>
                <div className={"hyd-osago-ins-result-value"}>
                  {(value.agent_commission.identifier || !isNaN(value.agent_commission.value)) &&
                      (Number(value.agent_commission.value) * Number(docs.price) / 100.0).toLocaleString(
                          "ru-RU", {style: "currency", currency: "RUB"})}
                  {" "}{value.agent_commission.value} {(value.agent_commission.identifier ||
                    !isNaN(value.agent_commission.value)) && "%"}
                </div>
              </div>}
        </Row>
      </>
    )
  }
}

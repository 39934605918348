//login types of actions;
export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";

export const SIGN_OUT = "SIGN_OUT";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";

export const REFRESH_NEW_TOKEN = "REFRESH_NEW_TOKEN";

// registration types of actions
export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";

export const SAVE_REF_ID = "SAVE_REF_ID";

//osago types of actions

export const PREV_STEP = "PREV_STEP";
export const NEXT_STEP = "NEXT_STEP";
export const SET_DATA_FORM = "SET_DATA_FORM";

export const GET_INFO_BY_VIN = "GET_INFO_BY_VIN";
export const GET_INFO_BY_VIN_REQUEST = "GET_INFO_BY_VIN_REQUEST";
export const GET_INFO_BY_VIN_SUCCESS = "GET_INFO_BY_VIN_SUCCESS";
export const GET_INFO_BY_VIN_FAILURE = "GET_INFO_BY_VIN_FAILURE";

export const SEND_DATA_FORM = "SEND_DATA_FORM";
export const SEND_DATA_FORM_REQUEST = "SEND_DATA_FORM_REQUEST";
export const SEND_DATA_FORM_SUCCESS = "SEND_DATA_FORM_SUCCESS";
export const SEND_DATA_FORM_FAILURE = "SEND_DATA_FORM_FAILURE";

export const TOGGLE_TYPE_FORM_PERSON_STEP_TWO =
  "TOGGLE_TYPE_FORM_PERSON_STEP_TWO";
export const TOGGLE_TYPE_FORM_POLICY_OWNER_STEP_TWO =
  "TOGGLE_TYPE_FORM_POLICY_OWNER_STEP_TWO";
export const IS_POLICY_OWNER = "IS_POLICY_OWNER";
export const CLEAR_FORM_STEP_TWO = "CLEAR_FORM_STEP_TWO";
export const NUMBER_DRIVERS_STEP_THREE = "NUMBER_DRIVERS_STEP_THREE";
export const ADD_ADDITIONAL_DRIVERS_STEP_THREE =
  "ADD_ADDITIONAL_DRIVERS_STEP_THREE";
export const REMOVE_DRIVER_FROM_LIST_DRIVERS =
  "REMOVE_DRIVER_FROM_LIST_DRIVERS";
export const CLEAR_LIST_DRIVERS = "CLEAR_LIST_DRIVERS";
export const CHANGE_QUANTITY_ADDITIONAL_DRIVERS = "CHANGE_QUANTITY_ADDITIONAL_DRIVERS";

export const GET_PAYMENT_STATUS = "GET_PAYMENT_STATUS";
export const GET_PAYMENT_STATUS_REQUEST = "GET_PAYMENT_STATUS_REQUEST";
export const GET_PAYMENT_STATUS_SUCCESS = "GET_PAYMENT_STATUS_SUCCESS";
export const GET_PAYMENT_STATUS_FAILURE = "GET_PAYMENT_STATUS_FAILURE";


export const PATCH_ALFA_PAYMENT = "PATCH_ALFA_PAYMENT";
export const PATCH_ALFA_PAYMENT_REQUEST = "PATCH_ALFA_PAYMENT_REQUEST";
export const PATCH_ALFA_PAYMENT_SUCCESS = "PATCH_ALFA_PAYMENT_SUCCESS";
export const PATCH_ALFA_PAYMENT_FAILURE = "PATCH_ALFA_PAYMENT_FAILURE";

export const PATCH_INGOS_PAYMENT = "PATCH_INGOS_PAYMENT";
export const PATCH_INGOS_PAYMENT_REQUEST = "PATCH_INGOS_PAYMENT_REQUEST";
export const PATCH_INGOS_PAYMENT_SUCCESS = "PATCH_INGOS_PAYMENT_SUCCESS";
export const PATCH_INGOS_PAYMENT_FAILURE = "PATCH_INGOS_PAYMENT_FAILURE";

export const GET_DRIVER_KBM = "GET_DRIVER_KBM";
export const GET_DRIVER_KBM_REQUEST = "GET_DRIVER_KBM_REQUEST";
export const GET_DRIVER_KBM_SUCCESS = "GET_DRIVER_KBM_SUCCESS";
export const GET_DRIVER_KBM_FAILURE = "GET_DRIVER_KBM_FAILURE";

export const GET_KBM_POLICY_CAR_OWNER_JURISTIC =
  "GET_KBM_POLICY_CAR_OWNER_JURISTIC";
export const GET_KBM_POLICY_CAR_OWNER_JURISTIC_REQUEST =
  "GET_KBM_POLICY_CAR_OWNER_JURISTIC_REQUEST";
export const GET_KBM_POLICY_CAR_OWNER_JURISTIC_SUCCESS =
  "GET_KBM_POLICY_CAR_OWNER_JURISTIC_SUCCESS";
export const GET_KBM_POLICY_CAR_OWNER_JURISTIC_FAILURE =
  "GET_KBM_POLICY_CAR_OWNER_JURISTIC_FAILURE";

export const GET_KLADR_POLICY_CAR_OWNER = "GET_KLADR_POLICY_CAR_OWNER";
export const GET_KLADR_POLICY_CAR_OWNER_REQUEST =
  "GET_KLADR_POLICY_CAR_OWNER_REQUEST";
export const GET_KLADR_POLICY_CAR_OWNER_SUCCESS =
  "GET_KLADR_POLICY_CAR_OWNER_SUCCESS";
export const GET_KLADR_POLICY_CAR_OWNER_FAILURE =
  "GET_KLADR_POLICY_CAR_OWNER_FAILURE";

export const GET_KLADR_POLICY_CAR_OWNER_JURISTIC =
  "GET_KLADR_POLICY_CAR_OWNER_JURISTIC";
export const GET_KLADR_POLICY_CAR_OWNER_JURISTIC_REQUEST =
  "GET_KLADR_POLICY_CAR_OWNER_JURISTIC_REQUEST";
export const GET_KLADR_POLICY_CAR_OWNER_JURISTIC_SUCCESS =
  "GET_KLADR_POLICY_CAR_OWNER_JURISTIC_SUCCESS";
export const GET_KLADR_POLICY_CAR_OWNER_JURISTIC_FAILURE =
  "GET_KLADR_POLICY_CAR_OWNER_JURISTIC_FAILURE";

export const SAVE_POLICY_OWNER_JURISTIC_CLADR = "SAVE_POLICY_OWNER_JURISTIC_CLADR";

export const SELECTED_ADDRESS_POLICY_OWNER_JURISTIC = "SELECTED_ADDRESS_POLICY_OWNER_JURISTIC";

export const GET_KLADR_CAR_OWNER = "GET_KLADR_CAR_OWNER";
export const GET_KLADR_CAR_OWNER_REQUEST = "GET_KLADR_CAR_OWNER_REQUEST";
export const GET_KLADR_CAR_OWNER_SUCCESS = "GET_KLADR_CAR_OWNER_SUCCESS";
export const GET_KLADR_CAR_OWNER_FAILURE = "GET_KLADR_CAR_OWNER_FAILURE";

export const GET_KLADR_CAR_OWNER_JURISTIC = "GET_KLADR_CAR_OWNER_JURISTIC";
export const GET_KLADR_CAR_OWNER_JURISTIC_REQUEST =
  "GET_KLADR_CAR_OWNER_JURISTIC_REQUEST";
export const GET_KLADR_CAR_OWNER_JURISTIC_SUCCESS =
  "GET_KLADR_CAR_OWNER_JURISTIC_SUCCESS";
export const GET_KLADR_CAR_OWNER_JURISTIC_FAILURE =
  "GET_KLADR_CAR_OWNER_JURISTIC_FAILURE";

export const SAVE_CAR_OWNER_JURISTIC_CLADR = "SAVE_CAR_OWNER_JURISTIC_CLADR";
export const SELECTED_ADDRESS_CAR_OWNER_JURISTIC = "SELECTED_ADDRESS_CAR_OWNER_JURISTIC";

export const GET_ALFA_PROLONGATION = "GET_ALFA_PROLONGATION";
export const GET_ALFA_PROLONGATION_REQUEST = "GET_ALFA_PROLONGATION_REQUEST";
export const GET_ALFA_PROLONGATION_SUCCESS = "GET_ALFA_PROLONGATION_SUCCESS";
export const GET_ALFA_PROLONGATION_FAILURE = "GET_ALFA_PROLONGATION_FAILURE";

export const GET_ALFA_AGREEMENT_DATA = "GET_ALFA_AGREEMENT_DATA";
export const GET_ALFA_AGREEMENT_DATA_REQUEST = "GET_ALFA_AGREEMENT_DATA_REQUEST";
export const GET_ALFA_AGREEMENT_DATA_SUCCESS = "GET_ALFA_AGREEMENT_DATA_SUCCESS";
export const GET_ALFA_AGREEMENT_DATA_FAILURE = "GET_ALFA_AGREEMENT_DATA_FAILURE";

export const GET_VSK_PROLONGATION = "GET_VSK_PROLONGATION";
export const GET_VSK_PROLONGATION_REQUEST = "GET_VSK_PROLONGATION_REQUEST";
export const GET_VSK_PROLONGATION_SUCCESS = "GET_VSK_PROLONGATION_SUCCESS";
export const GET_VSK_PROLONGATION_FAILURE = "GET_VSK_PROLONGATION_FAILURE";

export const GET_VSK_AGREEMENT_DATA = "GET_VSK_AGREEMENT_DATA";
export const GET_VSK_AGREEMENT_DATA_REQUEST = "GET_VSK_AGREEMENT_DATA_REQUEST";
export const GET_VSK_AGREEMENT_DATA_SUCCESS = "GET_VSK_AGREEMENT_DATA_SUCCESS";
export const GET_VSK_AGREEMENT_DATA_FAILURE = "GET_VSK_AGREEMENT_DATA_FAILURE";

export const GET_RGS_PROLONGATION = "GET_RGS_PROLONGATION";
export const GET_RGS_PROLONGATION_REQUEST = "GET_RGS_PROLONGATION_REQUEST";
export const GET_RGS_PROLONGATION_SUCCESS = "GET_RGS_PROLONGATION_SUCCESS";
export const GET_RGS_PROLONGATION_FAILURE = "GET_RGS_PROLONGATION_FAILURE";

export const GET_RGS_AGREEMENT_DATA = "GET_RGS_AGREEMENT_DATA";
export const GET_RGS_AGREEMENT_DATA_REQUEST = "GET_RGS_AGREEMENT_DATA_REQUEST";
export const GET_RGS_AGREEMENT_DATA_SUCCESS = "GET_RGS_AGREEMENT_DATA_SUCCESS";
export const GET_RGS_AGREEMENT_DATA_FAILURE = "GET_RGS_AGREEMENT_DATA_FAILURE";

export const GET_RENESSANS_PROLONGATION = "GET_RENESSANS_PROLONGATION";
export const GET_RENESSANS_PROLONGATION_REQUEST = "GET_RENESSANS_PROLONGATION_REQUEST";
export const GET_RENESSANS_PROLONGATION_SUCCESS = "GET_RENESSANS_PROLONGATION_SUCCESS";
export const GET_RENESSANS_PROLONGATION_FAILURE = "GET_RENESSANS_PROLONGATION_FAILURE";

export const GET_SEVA_PROLONGATION = "GET_SEVA_PROLONGATION";
export const GET_SEVA_PROLONGATION_REQUEST = "GET_SEVA_PROLONGATION_REQUEST";
export const GET_SEVA_PROLONGATION_SUCCESS = "GET_SEVA_PROLONGATION_SUCCESS";
export const GET_SEVA_PROLONGATION_FAILURE = "GET_SEVA_PROLONGATION_FAILURE";

export const GET_RENESSANS_AGREEMENT_DATA = "GET_RENESSANS_AGREEMENT_DATA";
export const GET_RENESSANS_AGREEMENT_DATA_REQUEST = "GET_RENESSANS_AGREEMENT_DATA_REQUEST";
export const GET_RENESSANS_AGREEMENT_DATA_SUCCESS = "GET_RENESSANS_AGREEMENT_DATA_SUCCESS";
export const GET_RENESSANS_AGREEMENT_DATA_FAILURE = "GET_RENESSANS_AGREEMENT_DATA_FAILURE";

export const GET_INGOS_AGREEMENT_DATA = "GET_INGOS_AGREEMENT_DATA";
export const GET_INGOS_AGREEMENT_DATA_REQUEST = "GET_INGOS_AGREEMENT_DATA_REQUEST";
export const GET_INGOS_AGREEMENT_DATA_SUCCESS = "GET_INGOS_AGREEMENT_DATA_SUCCESS";
export const GET_INGOS_AGREEMENT_DATA_FAILURE = "GET_INGOS_AGREEMENT_DATA_FAILURE";

export const GET_INGOS_PROLONGATION = "GET_INGOS_PROLONGATION";
export const GET_INGOS_PROLONGATION_REQUEST = "GET_INGOS_PROLONGATION_REQUEST";
export const GET_INGOS_PROLONGATION_SUCCESS = "GET_INGOS_PROLONGATION_SUCCESS";
export const GET_INGOS_PROLONGATION_FAILURE = "GET_INGOS_PROLONGATION_FAILURE";

export const GET_TO_INFO = "GET_TO_INFO";
export const GET_TO_INFO_REQUEST = "GET_TO_INFO_REQUEST";
export const GET_TO_INFO_SUCCESS = "GET_TO_INFO_SUCCESS";
export const GET_TO_INFO_FAILURE = "GET_TO_INFO_FAILURE";

export const GET_CAR_BRANDS = "GET_CAR_BRANDS";
export const GET_CAR_BRANDS_REQUEST = "GET_CAR_BRANDS_REQUEST";
export const GET_CAR_BRANDS_SUCCESS = "GET_CAR_BRANDS_SUCCESS";
export const GET_CAR_BRANDS_FAILURE = "GET_CAR_BRANDS_FAILURE";

export const GET_CAR_MODELS = "GET_CAR_MODELS";
export const GET_CAR_MODELS_REQUEST = "GET_CAR_MODELS_REQUEST";
export const GET_CAR_MODELS_SUCCESS = "GET_CAR_MODELS_SUCCESS";
export const GET_CAR_MODELS_FAILURE = "GET_CAR_MODELS_FAILURE";

export const GET_MODEL_MODIFICATIONS = "GET_MODEL_MODIFICATIONS";
export const GET_MODEL_MODIFICATIONS_REQUEST = "GET_MODEL_MODIFICATIONS_REQUEST";
export const GET_MODEL_MODIFICATIONS_SUCCESS = "GET_MODEL_MODIFICATIONS_SUCCESS";
export const GET_MODEL_MODIFICATIONS_FAILURE = "GET_MODEL_MODIFICATIONS_FAILURE";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

export const GET_INFO_POLICY_OWNER_BY_INN = "GET_INFO_POLICY_OWNER_BY_INN";
export const GET_INFO_POLICY_OWNER_BY_INN_REQUEST =
  "GET_INFO_POLICY_OWNER_BY_INN_REQUEST";
export const GET_INFO_POLICY_OWNER_BY_INN_SUCCESS =
  "GET_INFO_POLICY_OWNER_BY_INN_SUCCESS";
export const GET_INFO_POLICY_OWNER_BY_INN_FAILURE =
  "GET_INFO_POLICY_OWNER_BY_INN_FAILURE";

export const GET_INFO_CAR_OWNER_BY_INN = "GET_INFO_CAR_OWNER_BY_INN";
export const GET_INFO_CAR_OWNER_BY_INN_REQUEST =
  "GET_INFO_CAR_OWNER_BY_INN_REQUEST";
export const GET_INFO_CAR_OWNER_BY_INN_SUCCESS =
  "GET_INFO_CAR_OWNER_BY_INN_SUCCESS";
export const GET_INFO_CAR_OWNER_BY_INN_FAILURE =
  "GET_INFO_CAR_OWNER_BY_INN_FAILURE";

export const GET_OWNER_DRIVER_KBM = "GET_OWNER_DRIVER_KBM";
export const GET_OWNER_DRIVER_KBM_REQUEST = "GET_OWNER_DRIVER_KBM_REQUEST";
export const GET_OWNER_DRIVER_KBM_SUCCESS = "GET_OWNER_DRIVER_KBM_SUCCESS";
export const GET_OWNER_DRIVER_KBM_FAILURE = "GET_OWNER_DRIVER_KBM_FAILURE";

export const GET_CAR_OWNER_DRIVER_KBM = "GET_CAR_OWNER_DRIVER_KBM";
export const GET_CAR_OWNER_DRIVER_KBM_REQUEST = "GET_CAR_OWNER_DRIVER_KBM_REQUEST";
export const GET_CAR_OWNER_DRIVER_KBM_SUCCESS = "GET_CAR_OWNER_DRIVER_KBM_SUCCESS";
export const GET_CAR_OWNER_DRIVER_KBM_FAILURE = "GET_CAR_OWNER_DRIVER_KBM_FAILURE";

export const CLEAR_DATA_POLICY_OWNER = "CLEAR_DATA_POLICY_OWNER";
export const CLEAR_DATA_POLICY_OWNER_JURISTIC =
  "CLEAR_DATA_POLICY_OWNER_JURISTIC";

export const CLEAR_DATA_CAR_OWNER = "CLEAR_DATA_CAR_OWNER";
export const CLEAR_DATA_CAR_OWNER_JURISTIC = "CLEAR_DATA_CAR_OWNER_JURISTIC";
export const CLEAR_DATA_CAR_PLATE = "CLEAR_DATA_CAR_PLATE";
export const CLEAR_DATA_VIN = "CLEAR_DATA_VIN";
export const CLEAR_MARK_MODEL = "CLEAR_MARK_MODEL";
export const CLEAR_DATA_DK = "CLEAR_DATA_DK";
export const CLEAR_DATA_BODY_NUMBER = "CLEAR_DATA_BODY_NUMBER";
export const CLEAR_DATA_CHASSIS_NUMBER = "CLEAR_DATA_CHASSIS_NUMBER";
export const CLEAR_DATA_TO = "CLEAR_DATA_TO";
export const CLEAR_PAYMENT_STATUS = "CLEAR_PAYMENT_STATUS";

export const WITHOUT_CAR_PLATE = "WITHOUT_CAR_PLATE";
export const WITHOUT_VIN = "WITHOUT_VIN";
export const INGOS_VIN = "INGOS_VIN";

export const SHOW_TO_BUTTON = "SHOW_TO_BUTTON";

export const TOGGLE_TYPE_CAR_IDENTITY = "TOGGLE_TYPE_CAR_IDENTITY";
export const TOGGLE_INGOS_TYPE_CAR_IDENTITY = "TOGGLE_INGOS_TYPE_CAR_IDENTITY";
export const TOGGLE_TYPE_TRANSPORT_INSURER = "TOGGLE_TYPE_TRANSPORT_INSURER";
export const TOGGLE_ERROR_MODAL = "TOGGLE_ERROR_MODAL";
export const TOGGLE_TO_MODAL = "TOGGLE_TO_MODAL";
export const TOGGLE_CHOICE_TO_MODAL = "TOGGLE_CHOICE_TO_MODAL";
export const TOGGLE_EXIST_TO_MODAL = "TOGGLE_EXIST_TO_MODAL";
export const TOGGLE_KBM_MODAL = "TOGGLE_KBM_MODAL";
export const TOGGLE_KBM_RESULT_MODAL = "TOGGLE_KBM_RESULT_MODAL";
export const TOGGLE_BSO_MODAL = "TOGGLE_BSO_MODAL";
export const TOGGLE_KP_MODAL = "TOGGLE_KP_MODAL";

export const CREATE_POLICY = "CREATE_POLICY";
export const CREATE_POLICY_REQUEST = "CREATE_POLICY_REQUEST";
export const CREATE_POLICY_SUCCESS = "CREATE_POLICY_SUCCESS";
export const CREATE_POLICY_FAILURE = "CREATE_POLICY_FAILURE";

export const GET_PAYMENT_STATUS_COMMON = "GET_PAYMENT_STATUS_COMMON";
export const GET_PAYMENT_STATUS_COMMON_REQUEST = "GET_PAYMENT_STATUS_COMMON_REQUEST";
export const GET_PAYMENT_STATUS_COMMON_SUCCESS = "GET_PAYMENT_STATUS_COMMON_SUCCESS";
export const GET_PAYMENT_STATUS_COMMON_FAILURE = "GET_PAYMENT_STATUS_COMMON_FAILURE";

export const CLEAR_KBM = "CLEAR_KBM";

export const SAVE_CLADR = "SAVE_CLADR";
export const SAVE_POLICY_OWNER_CLADR = "SAVE_POLICY_OWNER_CLADR";

export const SAVE_CAR_BRAND_DATA = "SAVE_CAR_BRAND_DATA";
export const SAVE_CAR_BRAND = "SAVE_CAR_BRAND";
export const SAVE_CAR_MODEL_DATA = "SAVE_CAR_MODEL_DATA";
export const SAVE_CAR_MODEL = "SAVE_CAR_MODEL";
export const SAVE_MODEL_MODIFICATIONS_DATA = "SAVE_MODEL_MODIFICATIONS_DATA";
export const SAVE_POLICY_OWNER_COUNTRY_DATA = "SAVE_POLICY_OWNER_COUNTRY_DATA";

export const SELECTED_ADDRESS = "SELECTED_ADDRESS";
export const SELECTED_CAR_OWNER_ADDRESS = "SELECTED_CAR_OWNER_ADDRESS";

export const GET_KBM = "GET_KBM";
export const GET_KBM_REQUEST = "GET_KBM_REQUEST";
export const GET_KBM_SUCCESS = "GET_KBM_SUCCESS";
export const GET_KBM_FAILURE = "GET_KBM_FAILURE";

export const CLEAR_MODAL_DRIVER_KBM = "CLEAR_MODAL_DRIVER_KBM";

export const TOOGLE_IS_SELECTED_BRAND = "TOOGLE_IS_SELECTED_BRAND";
export const TOOGLE_IS_SELECTED_MODEL = "TOOGLE_IS_SELECTED_MODEL";
export const TOOGLE_CATEGORY = "TOOGLE_CATEGORY";

export const GET_KBM_DRIVER_NO_LIMIT = "GET_KBM_DRIVER_NO_LIMIT";
export const GET_KBM_DRIVER_NO_LIMIT_REQUEST =
  "GET_KBM_DRIVER_NO_LIMIT_REQUEST";
export const GET_KBM_DRIVER_NO_LIMIT_SUCCESS =
  "GET_KBM_DRIVER_NO_LIMIT_SUCCESS";
export const GET_KBM_DRIVER_NO_LIMIT_FAILURE =
  "GET_KBM_DRIVER_NO_LIMIT_FAILURE";

export const GET_LASTNAME_ARRAY = "GET_LASTNAME_ARRAY";
export const GET_LASTNAME_ARRAY_REQUEST = "GET_LASTNAME_ARRAY_REQUEST";
export const GET_LASTNAME_ARRAY_SUCCESS = "GET_LASTNAME_ARRAY_SUCCESS";
export const GET_LASTNAME_ARRAY_FAILURE = "GET_LASTNAME_ARRAY_FAILURE";

export const DEFINE_GENDER = "DEFINE_GENDER";


export const GET_GONFIG = "GET_GONFIG";
export const GET_GONFIG_REQUEST = "GET_GONFIG_REQUEST";
export const GET_GONFIG_SUCCESS = "GET_GONFIG_SUCCESS";
export const GET_GONFIG_FAILURE = "GET_GONFIG_FAILURE";

export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAILURE = "GET_USER_INFO_FAILURE";

export const GET_AGENTS = "GET_AGENTS";
export const GET_AGENTS_REQUEST = "GET_AGENTS_REQUEST";
export const GET_AGENTS_SUCCESS = "GET_AGENTS_SUCCESS";
export const GET_AGENTS_FAILURE = "GET_AGENTS_FAILURE";

export const GET_KV_CATEGORIES = "GET_KV_CATEGORIES";
export const GET_KV_CATEGORIES_REQUEST = "GET_KV_CATEGORIES_REQUEST";
export const GET_KV_CATEGORIES_SUCCESS = "GET_KV_CATEGORIES_SUCCESS";
export const GET_KV_CATEGORIES_FAILURE = "GET_KV_CATEGORIES_FAILURE";

export const GET_AGENTS_TYPES = "GET_AGENTS_TYPES";
export const GET_AGENTS_TYPES_REQUEST = "GET_AGENTS_TYPES_REQUEST";
export const GET_AGENTS_TYPES_SUCCESS = "GET_AGENTS_TYPES_SUCCESS";
export const GET_AGENTS_TYPES_FAILURE = "GET_AGENTS_TYPES_FAILURE";

export const GET_ALL_AGENTS = "GET_ALL_AGENTS";
export const GET_ALL_AGENTS_REQUEST = "GET_ALL_AGENTS_REQUEST";
export const GET_ALL_AGENTS_SUCCESS = "GET_ALL_AGENTS_SUCCESS";
export const GET_ALL_AGENTS_FAILURE = "GET_ALL_AGENTS_FAILURE";

export const UPDATE_AGENT = "UPDATE_AGENT";
export const UPDATE_AGENT_REQUEST = "UPDATE_AGENT_REQUEST";
export const UPDATE_AGENT_SUCCESS = "UPDATE_AGENT_SUCCESS";
export const UPDATE_AGENT_FAILURE = "UPDATE_AGENT_FAILURE";

export const ADD_AGENT_TO_LIST = "ADD_AGENT_TO_LIST";
export const ADD_SUPERVISOR_TO_LIST = "ADD_SUPERVISOR_TO_LIST";

export const CLEAR_SELECTED_AGENTS_LIST = "CLEAR_SELECTED_AGENTS_LIST";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const CLEAR_AGENTS_FILTERS = "CLEAR_AGENTS_FILTERS";
export const SET_IS_SHOW_KV_FILTER = "SET_IS_SHOW_KV_FILTER";
export const SET_IS_ACTIVE_FILTER = "SET_IS_ACTIVE_FILTER";
export const SET_IS_ONLINE_FILTER = "SET_IS_ONLINE_FILTER";
export const UPDATE_AGENTS_TYPES_FILTER = "UPDATE_AGENTS_TYPES_FILTER";
export const UPDATE_KV_CATEGORIES_FILTER = "UPDATE_KV_CATEGORIES_FILTER";

export const TOGGLE_IS_ALL_AGENTS_RECEIVED = "TOGGLE_IS_ALL_AGENTS_RECEIVED";

export const TOOGLE_PROLONGATION_MODAL = "TOOGLE_PROLONGATION_MODAL";

//accidents types of actions
export const GET_SUM = "GET_SUM";



export const CLEAR_FORM = "CLEAR_FORM";
export const TOGGLE_CLEAR_FORM_MODAL = "TOGGLE_CLEAR_FORM_MODAL";

//accidentsSport types of actions
export const CHANGE_QUANTITY_ADDITIONAL_INSURERS = "CHANGE_QUANTITY_ADDITIONAL_INSURERS";
export const REMOVE_INSURER_FROM_LIST_INSURERS = "REMOVE_INSURER_FROM_LIST_INSURERS";
export const GO_TO_NEXT_STEP_ACCIDENTS_SPORT = "GO_TO_NEXT_STEP_ACCIDENTS_SPORT";
export const GO_TO_PREV_STEP_ACCIDENTS_SPORT = "GO_TO_PREV_STEP_ACCIDENTS_SPORT";
export const SET_DATA_ACCIDENTS_SPORT_FORM = "SET_DATA_ACCIDENTS_SPORT_FORM";
export const TOOGLE_IS_SELECTED_SPORT = "TOOGLE_IS_SELECTED_SPORT";
export const SELECTED_INSURER_ADDRESS = "SELECTED_INSURER_ADDRESS";
export const SAVE_SPORT_KIND_DATA = "SAVE_SPORT_KIND_DATA";
export const SAVE_KLADR = "SAVE_KLADR";

export const GET_INSURER_KLADR = "GET_INSURER_KLADR";
export const GET_INSURER_KLADR_REQUEST = "GET_INSURER_KLADR_REQUEST";
export const GET_INSURER_KLADR_SUCCESS = "GET_INSURER_KLADR_SUCCESS";
export const GET_INSURER_KLADR_FAILURE = "GET_INSURER_KLADR_FAILURE";

export const GET_KINDS_OF_SPORTS = "GET_KINDS_OF_SPORTS";
export const GET_KINDS_OF_SPORTS_REQUEST = "GET_KINDS_OF_SPORTS_REQUEST";
export const GET_KINDS_OF_SPORTS_SUCCESS = "GET_KINDS_OF_SPORTS_SUCCESS";
export const GET_KINDS_OF_SPORTS_FAILURE = "GET_KINDS_OF_SPORTS_FAILURE";

export const SEND_DATA_ACCIDENT_SPORT_FORM = "SEND_DATA_ACCIDENT_SPORT_FORM";
export const SEND_DATA_ACCIDENT_SPORT_FORM_REQUEST = "SEND_DATA_ACCIDENT_SPORT_FORM_REQUEST";
export const SEND_DATA_ACCIDENT_SPORT_FORM_SUCCESS = "SEND_DATA_ACCIDENT_SPORT_FORM_SUCCESS";
export const SEND_DATA_ACCIDENT_SPORT_FORM_FAILURE = "SEND_DATA_ACCIDENT_SPORT_FORM_FAILURE";

export const ADD_CALC_RESULT_ACCIDENT_SPORT = "ADD_CALC_RESULT_ACCIDENT_SPORT";
export const ASSIGN_DRAFT_ID_ACCIDENT_SPORT = "ASSIGN_DRAFT_ID_ACCIDENT_SPORT";
export const CALC_ERROR_ACCIDENT_SPORT = "CALC_ERROR_ACCIDENT_SPORT";
export const START_CALCULATION_ACCIDENT_SPORT = "START_CALCULATION_ACCIDENT_SPORT";
export const CALC_COMPLETE_ACCIDENT_SPORT = "CALC_COMPLETE_ACCIDENT_SPORT";

export const CREATE_POLICY_ACCIDENT_SPORT = "CREATE_POLICY_ACCIDENT_SPORT";
export const CREATE_POLICY_ACCIDENT_SPORT_REQUEST = "CREATE_POLICY_ACCIDENT_SPORT_REQUEST";
export const CREATE_POLICY_ACCIDENT_SPORT_SUCCESS = "CREATE_POLICY_ACCIDENT_SPORT_SUCCESS";
export const CREATE_POLICY_ACCIDENT_SPORT_FAILURE = "CREATE_POLICY_ACCIDENT_SPORT_FAILURE";

export const GET_PAYMENT_STATUS_ACCIDENT_SPORT = "GET_PAYMENT_STATUS_ACCIDENT_SPORT";
export const GET_PAYMENT_STATUS_ACCIDENT_SPORT_REQUEST = "GET_PAYMENT_STATUS_ACCIDENT_SPORT_REQUEST";
export const GET_PAYMENT_STATUS_ACCIDENT_SPORT_SUCCESS = "GET_PAYMENT_STATUS_ACCIDENT_SPORT_SUCCESS";
export const GET_PAYMENT_STATUS_ACCIDENT_SPORT_FAILURE = "GET_PAYMENT_STATUS_ACCIDENT_SPORT_FAILURE";

export const RESUME_POLICY_ACCIDENTS_SPORT = "RESUME_POLICY_ACCIDENTS_SPORT";
export const RESUME_POLICY_ACCIDENTS_SPORT_REQUEST = "RESUME_POLICY_ACCIDENTS_SPORT_REQUEST";
export const RESUME_POLICY_ACCIDENTS_SPORT_SUCCESS = "RESUME_POLICY_ACCIDENTS_SPORT_SUCCESS";
export const RESUME_POLICY_ACCIDENTS_SPORT_FAILURE = "RESUME_POLICY_ACCIDENTS_SPORT_FAILURE";

export const RESUME_POLICY_MORTGAGE = "RESUME_POLICY_MORTGAGE";
export const RESUME_POLICY_MORTGAGE_REQUEST = "RESUME_POLICY_MORTGAGE_REQUEST";
export const RESUME_POLICY_MORTGAGE_SUCCESS = "RESUME_POLICY_MORTGAGE_SUCCESS";
export const RESUME_POLICY_MORTGAGE_FAILURE = "RESUME_POLICY_MORTGAGE_FAILURE";

export const RESUME_POLICY_CROSS = "RESUME_POLICY_CROSS";
export const RESUME_POLICY_CROSS_REQUEST = "RESUME_POLICY_CROSS_REQUEST";
export const RESUME_POLICY_CROSS_SUCCESS = "RESUME_POLICY_CROSS_SUCCESS";
export const RESUME_POLICY_CROSS_FAILURE = "RESUME_POLICY_CROSS_FAILURE";

export const COPY_POLICY_ACCIDENTS_SPORT = "COPY_POLICY_ACCIDENTS_SPORT";
export const COPY_POLICY_ACCIDENTS_SPORT_REQUEST = "COPY_POLICY_ACCIDENTS_SPORT_REQUEST";
export const COPY_POLICY_ACCIDENTS_SPORT_SUCCESS = "COPY_POLICY_ACCIDENTS_SPORT_SUCCESS";
export const COPY_POLICY_ACCIDENTS_SPORT_FAILURE = "COPY_POLICY_ACCIDENTS_SPORT_FAILURE";

export const COPY_POLICY_MORTGAGE = "COPY_POLICY_MORTGAGE";
export const COPY_POLICY_MORTGAGE_REQUEST = "COPY_POLICY_MORTGAGE_REQUEST";
export const COPY_POLICY_MORTGAGE_SUCCESS = "COPY_POLICY_MORTGAGE_SUCCESS";
export const COPY_POLICY_MORTGAGE_FAILURE = "COPY_POLICY_MORTGAGE_FAILURE";

export const COPY_POLICY_CROSS = "COPY_POLICY_CROSS";
export const COPY_POLICY_CROSS_REQUEST = "COPY_POLICY_CROSS_REQUEST";
export const COPY_POLICY_CROSS_SUCCESS = "COPY_POLICY_CROSS_SUCCESS";
export const COPY_POLICY_CROSS_FAILURE = "COPY_POLICY_CROSS_FAILURE";

export const PAY_POLICY_ACCIDENTS_SPORT = "PAY_POLICY_ACCIDENTS_SPORT";
export const PAY_POLICY_ACCIDENTS_SPORT_REQUEST = "PAY_POLICY_ACCIDENTS_SPORT_REQUEST";
export const PAY_POLICY_ACCIDENTS_SPORT_SUCCESS = "PAY_POLICY_ACCIDENTS_SPORT_SUCCESS";
export const PAY_POLICY_ACCIDENTS_SPORT_FAILURE = "PAY_POLICY_ACCIDENTS_SPORT_FAILURE";


export const TOGGLE_OSAGO_MODAL = "TOGGLE_OSAGO_MODAL";
export const TOGGLE_BUTTON_MODAL_TO = "TOGGLE_BUTTON_MODAL_TO";
export const TOGGLE_REASON_TO = "TOGGLE_REASON_TO";


export const GET_KBM_JURISTIC = "GET_KBM_JURISTIC";
export const GET_KBM_JURISTIC_REQUEST = "GET_KBM_JURISTIC_REQUEST";
export const GET_KBM_JURISTIC_SUCCESS = "GET_KBM_JURISTIC_SUCCESS";
export const GET_KBM_JURISTIC_FAILURE ="GET_KBM_JURISTIC_FAILURE";


export const GET_INFO_KBM_MODAL_BY_INN = "GET_INFO_KBM_MODAL_BY_INN";
export const GET_INFO_KBM_MODAL_BY_INN_REQUEST =
  "GET_INFO_KBM_MODAL_BY_INN_REQUEST";
export const GET_INFO_KBM_MODAL_BY_INN_SUCCESS =
  "GET_INFO_KBM_MODAL_BY_INN_SUCCESS";
export const GET_INFO_KBM_MODAL_BY_INN_FAILURE =
  "GET_INFO_KBM_MODAL_BY_INN_FAILURE";


export const CHANGE_DATE_INFO_TO = "CHANGE_DATE_INFO_TO";

export const CLEAR_IS_REQUEST_STATE = "CLEAR_IS_REQUEST_STATE"

export const DO_PAYMENT_BSO = "DO_PAYMENT_BSO";
export const DO_PAYMENT_BSO_REQUEST =
  "DO_PAYMENT_BSO_REQUEST";
export const DO_PAYMENT_BSO_SUCCESS =
  "DO_PAYMENT_BSO_SUCCESS";
export const DO_PAYMENT_BSO_FAILURE =
  "DO_PAYMENT_BSO_FAILURE";


export const DO_PAYMENT_NSS = "DO_PAYMENT_NSS";
export const DO_PAYMENT_NSS_REQUEST = "DO_PAYMENT_NSS_REQUEST";
export const DO_PAYMENT_NSS_SUCCESS = "DO_PAYMENT_NSS_SUCCESS";
export const DO_PAYMENT_NSS_FAILURE = "DO_PAYMENT_NSS_FAILURE";

export const GET_EXPORT_DOCUMENT = "GET_EXPORT_DOCUMENT";
export const GET_EXPORT_DOCUMENT_REQUEST =
  "GET_EXPORT_DOCUMENT_REQUEST";
export const GET_EXPORT_DOCUMENT_SUCCESS =
  "GET_EXPORT_DOCUMENT_SUCCESS";
export const GET_EXPORT_DOCUMENT_FAILURE =
  "GET_EXPORT_DOCUMENT_FAILURE";
// export const GET_KLADR_POLICY_CAR_OWNER_JURISTIC = "GET_KLADR_POLICY_CAR_OWNER_JURISTIC";
// export const GET_KLADR_POLICY_CAR_OWNER_JURISTIC_REQUEST = "GET_KLADR_POLICY_CAR_OWNER_JURISTIC_REQUEST";
// export const GET_KLADR_POLICY_CAR_OWNER_JURISTIC_SUCCESS = "GET_KLADR_POLICY_CAR_OWNER_JURISTIC_SUCCESS";
// export const GET_KLADR_POLICY_CAR_OWNER_JURISTIC_FAILURE = "GET_KLADR_POLICY_CAR_OWNER_JURISTIC_FAILURE";

export const GET_CONTRACTS_LIST = "GET_CONTRACTS_LIST";
export const GET_CONTRACTS_LIST_REQUEST = "GET_CONTRACTS_LIST_REQUEST";
export const GET_CONTRACTS_LIST_SUCCESS = "GET_CONTRACTS_LIST_SUCCESS";
export const GET_CONTRACTS_LIST_FAILURE = "GET_CONTRACTS_LIST_FAILURE";

export const RESUME_DRAFT = "RESUME_DRAFT";
export const RESUME_DRAFT_REQUEST = "RESUME_DRAFT_REQUEST";
export const RESUME_DRAFT_SUCCESS = "RESUME_DRAFT_SUCCESS";
export const RESUME_DRAFT_FAILURE = "RESUME_DRAFT_FAILURE";

export const RESUME_DRAFT_MORTGAGE = "RESUME_DRAFT_MORTGAGE";
export const RESUME_DRAFT_MORTGAGE_REQUEST = "RESUME_DRAFT_MORTGAGE_REQUEST";
export const RESUME_DRAFT_MORTGAGE_SUCCESS = "RESUME_DRAFT_MORTGAGE_SUCCESS";
export const RESUME_DRAFT_MORTGAGE_FAILURE = "RESUME_DRAFT_MORTGAGE_FAILURE";

export const RESUME_DRAFT_CROSS = "RESUME_DRAFT_CROSS";
export const RESUME_DRAFT_CROSS_REQUEST = "RESUME_DRAFT_CROSS_REQUEST";
export const RESUME_DRAFT_CROSS_SUCCESS = "RESUME_DRAFT_CROSS_SUCCESS";
export const RESUME_DRAFT_CROSS_FAILURE = "RESUME_DRAFT_CROSS_FAILURE";

export const RESUME_POLICY = "RESUME_POLICY";
export const RESUME_POLICY_REQUEST = "RESUME_POLICY_REQUEST";
export const RESUME_POLICY_SUCCESS = "RESUME_POLICY_SUCCESS";
export const RESUME_POLICY_FAILURE = "RESUME_POLICY_FAILURE";

export const RESUME_POLICY_PROLONGATION = "RESUME_POLICY_PROLONGATION";
export const RESUME_POLICY_PROLONGATION_REQUEST = "RESUME_POLICY_PROLONGATION_REQUEST";
export const RESUME_POLICY_PROLONGATION_SUCCESS = "RESUME_POLICY_PROLONGATION_SUCCESS";
export const RESUME_POLICY_PROLONGATION_FAILURE = "RESUME_POLICY_PROLONGATION_FAILURE";

export const RESUME_DRAFT_PROLONGATION = "RESUME_DRAFT_PROLONGATION";
export const RESUME_DRAFT_PROLONGATION_REQUEST = "RESUME_DRAFT_PROLONGATION_REQUEST";
export const RESUME_DRAFT_PROLONGATION_SUCCESS = "RESUME_DRAFT_PROLONGATION_SUCCESS";
export const RESUME_DRAFT_PROLONGATION_FAILURE = "RESUME_DRAFT_PROLONGATION_FAILURE";

export const COPY_POLICY = "COPY_POLICY";
export const COPY_POLICY_REQUEST = "COPY_POLICY_REQUEST";
export const COPY_POLICY_SUCCESS = "COPY_POLICY_SUCCESS";
export const COPY_POLICY_FAILURE = "COPY_POLICY_FAILURE";


export const SELECT_POLICY = "SELECT_POLICY";

export const TOGGLE_VIEW_DOCS_MODAL = "TOGGLE_VIEW_DOCS_MODAL";

export const TOGGLE_TOOLS_MODAL = "TOGGLE_TOOLS_MODAL";

export const GET_POLICY_DOCS = "GET_POLICY_DOCS";
export const GET_POLICY_DOCS_REQUEST = "GET_POLICY_DOCS_REQUEST";
export const GET_POLICY_DOCS_SUCCESS = "GET_POLICY_DOCS_SUCCESS";
export const GET_POLICY_DOCS_FAILURE = "GET_POLICY_DOCS_FAILURE";


export const GET_ONE_TIME_TOKEN = "GET_ONE_TIME_TOKEN";
export const GET_ONE_TIME_TOKEN_REQUEST = "GET_ONE_TIME_TOKEN_REQUEST";
export const GET_ONE_TIME_TOKEN_SUCCESS = "GET_ONE_TIME_TOKEN_SUCCESS";
export const GET_ONE_TIME_TOKEN_FAILURE = "GET_ONE_TIME_TOKEN_FAILURE";

export const CHECK_AUTHENTICATION = "CHECK_AUTHENTICATION";

export const GET_INSURANCES = "GET_INSURANCES";
export const GET_INSURANCES_REQUEST = "GET_INSURANCES_REQUEST";
export const GET_INSURANCES_SUCCESS = "GET_INSURANCES_SUCCESS";
export const GET_INSURANCES_FAILURE = "GET_INSURANCES_FAILURE";

export const ADD_CALC_RESULT = "ADD_CALC_RESULT";
export const ASSIGN_DRAFT_ID = "ASSIGN_DRAFT_ID";
export const CALC_ERROR = "CALC_ERROR";
export const START_CALCULATION = "START_CALCULATION";

export const CALC_COMPLETE = "CALC_COMPLETE";

export const POPULATE_BRAND_MODEL_DATA = "POPULATE_BRAND_MODEL_DATA";

export const ADD_TOOL_TO_TEMP_LIST = "ADD_TOOL_TO_TEMP_LIST";
export const CLEAR_TOOLS_TEMP_LIST = "CLEAR_TOOLS_TEMP_LIST";

export const DEL_TOOL_FROM_FAVORITES = "DEL_TOOL_FROM_FAVORITES";
export const DEL_TOOL_FROM_FAVORITES_REQUEST = "DEL_TOOL_FROM_FAVORITES_REQUEST";
export const DEL_TOOL_FROM_FAVORITES_SUCCESS = "DEL_TOOL_FROM_FAVORITES_SUCCESS";
export const DEL_TOOL_FROM_FAVORITES_FAILURE = "DEL_TOOL_FROM_FAVORITES_FAILURE";

export const SET_DATA_PROFILE_FORM = "SET_DATA_PROFILE_FORM";
export const SELECTED_USER_ADDRESS = "SELECTED_USER_ADDRESS";
export const EDIT_MODE = "EDIT_MODE";
export const EDIT_MODE_INN_SNILS = "EDIT_MODE_INN_SNILS";
export const SAVE_USER_ADDRESS = "SAVE_USER_ADDRESS";
export const SAVE_CARD_PAYMENT_SYSTEM = "SAVE_CARD_PAYMENT_SYSTEM";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

export const GET_USER_KLADR = "GET_USER_KLADR";
export const GET_USER_KLADR_REQUEST = "GET_USER_KLADR_REQUEST";
export const GET_USER_KLADR_SUCCESS = "GET_USER_KLADR_SUCCESS";
export const GET_USER_KLADR_FAILURE = "GET_USER_KLADR_FAILURE";

export const GET_REGIONS = "GET_REGIONS";
export const GET_REGIONS_REQUEST = "GET_REGIONS_REQUEST";
export const GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS";
export const GET_REGIONS_FAILURE = "GET_REGIONS_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const GET_REFERRAL_LINK = "GET_REFERRAL_LINK";
export const GET_REFERRAL_LINK_REQUEST = "GET_REFERRAL_LINK_REQUEST";
export const GET_REFERRAL_LINK_SUCCESS = "GET_REFERRAL_LINK_SUCCESS";
export const GET_REFERRAL_LINK_FAILURE = "GET_REFERRAL_LINK_FAILURE";

export const GET_PRODUCT_REFERRAL_LINK = "GET_PRODUCT_REFERRAL_LINK";
export const GET_PRODUCT_REFERRAL_LINK_REQUEST = "GET_PRODUCT_REFERRAL_LINK_REQUEST";
export const GET_PRODUCT_REFERRAL_LINK_SUCCESS = "GET_PRODUCT_REFERRAL_LINK_SUCCESS";
export const GET_PRODUCT_REFERRAL_LINK_FAILURE = "GET_PRODUCT_REFERRAL_LINK_FAILURE";

export const GET_PRODUCT_REFERRAL_QR_CODE = "GET_PRODUCT_REFERRAL_QR_CODE";
export const GET_PRODUCT_REFERRAL_QR_CODE_REQUEST = "GET_PRODUCT_REFERRAL_QR_CODE_REQUEST";
export const GET_PRODUCT_REFERRAL_QR_CODE_SUCCESS = "GET_PRODUCT_REFERRAL_QR_CODE_SUCCESS";
export const GET_PRODUCT_REFERRAL_QR_CODE_FAILURE = "GET_PRODUCT_REFERRAL_QR_CODE_FAILURE";

export const GET_TOOLS = "GET_TOOLS";
export const GET_TOOLS_REQUEST = "GET_TOOLS_REQUEST";
export const GET_TOOLS_SUCCESS = "GET_TOOLS_SUCCESS";
export const GET_TOOLS_FAILURE = "GET_TOOLS_FAILURE";

export const GET_FAVORITES_TOOLS = "GET_FAVORITES_TOOLS";
export const GET_FAVORITES_TOOLS_REQUEST = "GET_FAVORITES_TOOLS_REQUEST";
export const GET_FAVORITES_TOOLS_SUCCESS = "GET_FAVORITES_TOOLS_SUCCESS";
export const GET_FAVORITES_TOOLS_FAILURE = "GET_FAVORITES_TOOLS_FAILURE";

export const GET_ACTIVE_BANNERS = "GET_ACTIVE_BANNERS";
export const GET_ACTIVE_BANNERS_REQUEST = "GET_ACTIVE_BANNERS_REQUEST";
export const GET_ACTIVE_BANNERS_SUCCESS = "GET_ACTIVE_BANNERS_SUCCESS";
export const GET_ACTIVE_BANNERS_FAILURE = "GET_ACTIVE_BANNERS_FAILURE";

export const UPDATE_FAVORITES_TOOLS = "UPDATE_FAVORITES_TOOLS";
export const UPDATE_FAVORITES_TOOLS_REQUEST = "UPDATE_FAVORITES_TOOLS_REQUEST";
export const UPDATE_FAVORITES_TOOLS_SUCCESS = "UPDATE_FAVORITES_TOOLS_SUCCESS";
export const UPDATE_FAVORITES_TOOLS_FAILURE = "UPDATE_FAVORITES_TOOLS_FAILURE";


export const GO_TO_NEXT_STEP_MORTGAGE = "GO_TO_NEXT_STEP_MORTGAGE";
export const GO_TO_PREV_STEP_MORTGAGE = "GO_TO_PREV_STEP_MORTGAGE";
export const SET_DATA_FORM_MORTGAGE = "SET_DATA_FORM_MORTGAGE";
export const DEFINE_GENDER_MORTGAGE = "DEFINE_GENDER_MORTGAGE";
export const MORTGAGE_SELECTED_REG_ADDRESS = "MORTGAGE_SELECTED_REG_ADDRESS";
export const MORTGAGE_SELECTED_OBJECT_ADDRESS = "MORTGAGE_SELECTED_OBJECT_ADDRESS";
export const CLEAR_POLICYHOLDER_ADDRESS_DATA_MORTGAGE = "CLEAR_POLICYHOLDER_ADDRESS_DATA_MORTGAGE";
export const CLEAR_POLICYHOLDER_FACT_ADDRESS_DATA_MORTGAGE = "CLEAR_POLICYHOLDER_FACT_ADDRESS_DATA_MORTGAGE";
export const CLEAR_CO_BORROWER_ADDRESS_DATA_MORTGAGE = "CLEAR_CO_BORROWER_ADDRESS_DATA_MORTGAGE";
export const CLEAR_CO_BORROWER_FACT_ADDRESS_DATA_MORTGAGE = "CLEAR_CO_BORROWER_FACT_ADDRESS_DATA_MORTGAGE";
export const SAVE_POLICYHOLDER_ADDRESS_DATA_MORTGAGE = "SAVE_POLICYHOLDER_ADDRESS_DATA_MORTGAGE";
export const SAVE_POLICYHOLDER_FACT_ADDRESS_DATA_MORTGAGE = "SAVE_POLICYHOLDER_FACT_ADDRESS_DATA_MORTGAGE";
export const SAVE_CO_BORROWER_ADDRESS_DATA_MORTGAGE = "SAVE_CO_BORROWER_ADDRESS_DATA_MORTGAGE";
export const SAVE_CO_BORROWER_FACT_ADDRESS_DATA_MORTGAGE = "SAVE_CO_BORROWER_FACT_ADDRESS_DATA_MORTGAGE";
export const CLEAR_OBJECT_ADDRESS_DATA_MORTGAGE = "CLEAR_OBJECT_ADDRESS_DATA_MORTGAGE";
export const SAVE_OBJECT_ADDRESS_DATA_MORTGAGE = "SAVE_OBJECT_ADDRESS_DATA_MORTGAGE";
export const CLEAR_FORM_STEP_3 = "CLEAR_FORM_STEP_3";
export const RE_CONSTRUCT_ADDRESS_STR = "RE_CONSTRUCT_ADDRESS_STR";
export const ADD_CO_BORROWER = "ADD_CO_BORROWER";
export const REMOVE_CO_BORROWER = "REMOVE_CO_BORROWER";
export const SET_DEFAULT_PROPERTY_SHARE = "SET_DEFAULT_PROPERTY_SHARE";
export const CHANGE_QUANTITY_CO_BORROWERS = "CHANGE_QUANTITY_CO_BORROWERS";
export const REMOVE_RISK = "REMOVE_RISK";
export const CALC_PROPERTY_SHARE_SUM = "CALC_PROPERTY_SHARE_SUM";
export const TOGGLE_MORTGAGE_APPLICATION_MODAL = "TOGGLE_MORTGAGE_APPLICATION_MODAL";

export const SEND_DATA_FORM_MORTGAGE = "SEND_DATA_FORM_MORTGAGE";
export const SEND_DATA_FORM_MORTGAGE_REQUEST = "SEND_DATA_FORM_MORTGAGE_REQUEST";
export const SEND_DATA_FORM_MORTGAGE_SUCCESS = "SEND_DATA_FORM_MORTGAGE_SUCCESS";
export const SEND_DATA_FORM_MORTGAGE_FAILURE = "SEND_DATA_FORM_MORTGAGE_FAILURE";

export const SEND_MORTGAGE_APPLICATION = "SEND_MORTGAGE_APPLICATION";
export const SEND_MORTGAGE_APPLICATION_REQUEST = "SEND_MORTGAGE_APPLICATION_REQUEST";
export const SEND_MORTGAGE_APPLICATION_SUCCESS = "SEND_MORTGAGE_APPLICATION_SUCCESS";
export const SEND_MORTGAGE_APPLICATION_FAILURE = "SEND_MORTGAGE_APPLICATION_FAILURE";

export const SEND_ADDENDUM_APPLICATION = "SEND_ADDENDUM_APPLICATION";
export const SEND_ADDENDUM_APPLICATION_REQUEST = "SEND_ADDENDUM_APPLICATION_REQUEST";
export const SEND_ADDENDUM_APPLICATION_SUCCESS = "SEND_ADDENDUM_APPLICATION_SUCCESS";
export const SEND_ADDENDUM_APPLICATION_FAILURE = "SEND_ADDENDUM_APPLICATION_FAILURE";

export const TOGGLE_ADDENDUM_MODAL = "TOGGLE_ADDENDUM_MODAL";

export const GET_LASTNAME_ARRAY_MORTGAGE = "GET_LASTNAME_ARRAY_MORTGAGE";
export const GET_LASTNAME_ARRAY_MORTGAGE_REQUEST = "GET_LASTNAME_ARRAY_MORTGAGE_REQUEST";
export const GET_LASTNAME_ARRAY_MORTGAGE_SUCCESS = "GET_LASTNAME_ARRAY_MORTGAGE_SUCCESS";
export const GET_LASTNAME_ARRAY_MORTGAGE_FAILURE = "GET_LASTNAME_ARRAY_MORTGAGE_FAILURE";

export const GET_ADDRESS_DATA = "GET_ADDRESS_DATA";
export const GET_ADDRESS_DATA_REQUEST = "GET_ADDRESS_DATA_REQUEST";
export const GET_ADDRESS_DATA_SUCCESS = "GET_ADDRESS_DATA_SUCCESS";
export const GET_ADDRESS_DATA_FAILURE = "GET_ADDRESS_DATA_FAILURE";

export const ADD_CALC_RESULT_MORTGAGE = "ADD_CALC_RESULT_MORTGAGE";
export const ASSIGN_DRAFT_ID_MORTGAGE = "ASSIGN_DRAFT_ID_MORTGAGE";
export const CALC_ERROR_MORTGAGE = "CALC_ERROR_MORTGAGE";
export const START_CALCULATION_MORTGAGE = "START_CALCULATION_MORTGAGE";
export const CALC_COMPLETE_MORTGAGE = "CALC_COMPLETE_MORTGAGE";

export const SEND_PRECALCULATE_DATA_FORM_MORTGAGE = "SEND_PRECALCULATE_DATA_FORM_MORTGAGE";

export const SEND_PRECALCULATE_DATA_FORM_MORTGAGE_REQUEST = "SEND_PRECALCULATE_DATA_FORM_MORTGAGE_REQUEST";
export const SEND_PRECALCULATE_DATA_FORM_MORTGAGE_SUCCESS = "SEND_PRECALCULATE_DATA_FORM_MORTGAGE_SUCCESS";
export const SEND_PRECALCULATE_DATA_FORM_MORTGAGE_FAILURE = "SEND_PRECALCULATE_DATA_FORM_MORTGAGE_FAILURE";

export const ADD_PRECALCULATE_RESULT_MORTGAGE = "ADD_PRECALCULATE_RESULT_MORTGAGE";
export const ERROR_PRECALCULATE_MORTGAGE = "ERROR_PRECALCULATE_MORTGAGE";
export const START_PRECALCULATE_MORTGAGE = "START_PRECALCULATE_MORTGAGE";
export const PRECALCULATE_COMPLETE_MORTGAGE = "PRECALCULATE_COMPLETE_MORTGAGE";

export const CREATE_POLICY_MORTGAGE = "CREATE_POLICY_MORTGAGE";
export const CREATE_POLICY_MORTGAGE_REQUEST = "CREATE_POLICY_MORTGAGE_REQUEST";
export const CREATE_POLICY_MORTGAGE_SUCCESS = "CREATE_POLICY_MORTGAGE_SUCCESS";
export const CREATE_POLICY_MORTGAGE_FAILURE = "CREATE_POLICY_MORTGAGE_FAILURE";

export const GET_PAYMENT_STATUS_MORTGAGE = "GET_PAYMENT_STATUS_MORTGAGE";
export const GET_PAYMENT_STATUS_MORTGAGE_REQUEST = "GET_PAYMENT_STATUS_MORTGAGE_REQUEST";
export const GET_PAYMENT_STATUS_MORTGAGE_SUCCESS = "GET_PAYMENT_STATUS_MORTGAGE_SUCCESS";
export const GET_PAYMENT_STATUS_MORTGAGE_FAILURE = "GET_PAYMENT_STATUS_MORTGAGE_FAILURE";

export const GET_PROFESSIONS_MORTGAGE = "GET_PROFESSIONS_MORTGAGE";
export const GET_PROFESSIONS_MORTGAGE_REQUEST = "GET_PROFESSIONS_MORTGAGE_REQUEST";
export const GET_PROFESSIONS_MORTGAGE_SUCCESS = "GET_PROFESSIONS_MORTGAGE_SUCCESS";
export const GET_PROFESSIONS_MORTGAGE_FAILURE = "GET_PROFESSIONS_MORTGAGE_FAILURE";

export const GET_BANKS = "GET_BANKS";
export const GET_BANKS_REQUEST = "GET_BANKS_REQUEST";
export const GET_BANKS_SUCCESS = "GET_BANKS_SUCCESS";
export const GET_BANKS_FAILURE = "GET_BANKS_FAILURE";

export const GET_EMPLOYMENT_STATUSES = "GET_EMPLOYMENT_STATUSES";
export const GET_EMPLOYMENT_STATUSES_REQUEST = "GET_EMPLOYMENT_STATUSES_REQUEST";
export const GET_EMPLOYMENT_STATUSES_SUCCESS = "GET_EMPLOYMENT_STATUSES_SUCCESS";
export const GET_EMPLOYMENT_STATUSES_FAILURE = "GET_EMPLOYMENT_STATUSES_FAILURE";

export const GET_HEALTH_ABNORMALITIES = "GET_HEALTH_ABNORMALITIES";
export const GET_HEALTH_ABNORMALITIES_REQUEST = "GET_HEALTH_ABNORMALITIES_REQUEST";
export const GET_HEALTH_ABNORMALITIES_SUCCESS = "GET_HEALTH_ABNORMALITIES_SUCCESS";
export const GET_HEALTH_ABNORMALITIES_FAILURE = "GET_HEALTH_ABNORMALITIES_FAILURE";

export const GET_FIAS_ID = "GET_FIAS_ID";
export const GET_FIAS_ID_REQUEST = "GET_FIAS_ID_REQUEST";
export const GET_FIAS_ID_SUCCESS = "GET_FIAS_ID_SUCCESS";
export const GET_FIAS_ID_FAILURE = "GET_FIAS_ID_FAILURE";

export const PAYMENT_LINK_TO_EMAIL = "PAYMENT_LINK_TO_EMAIL";
export const PAYMENT_LINK_TO_EMAIL_REQUEST = "PAYMENT_LINK_TO_EMAIL_REQUEST";
export const PAYMENT_LINK_TO_EMAIL_SUCCESS = "PAYMENT_LINK_TO_EMAIL_SUCCESS";
export const PAYMENT_LINK_TO_EMAIL_FAILURE = "PAYMENT_LINK_TO_EMAIL_FAILURE";


export const GO_TO_NEXT_STEP_CROSS = "GO_TO_NEXT_STEP_CROSS";
export const GO_TO_PREV_STEP_CROSS = "GO_TO_PREV_STEP_CROSS";
export const SET_DATA_FORM_CROSS = "SET_DATA_FORM_CROSS";
export const ADD_CALC_RESULT_CROSS = "ADD_CALC_RESULT_CROSS";
export const ASSIGN_DRAFT_ID_CROSS = "ASSIGN_DRAFT_ID_CROSS";
export const CALC_ERROR_CROSS = "CALC_ERROR_CROSS";
export const START_CALCULATION_CROSS = "START_CALCULATION_CROSS";
export const CALC_COMPLETE_CROSS = "CALC_COMPLETE_CROSS";
export const TOGGLE_CROSS_KIND = "TOGGLE_CROSS_KIND";

export const GET_RISKS_AND_ATTRIBUTES_CROSS = "GET_RISKS_AND_ATTRIBUTES_CROSS";
export const GET_RISKS_AND_ATTRIBUTES_CROSS_REQUEST = "GET_RISKS_AND_ATTRIBUTES_CROSS_REQUEST";
export const GET_RISKS_AND_ATTRIBUTES_CROSS_SUCCESS = "GET_RISKS_AND_ATTRIBUTES_CROSS_SUCCESS";
export const GET_RISKS_AND_ATTRIBUTES_CROSS_FAILURE = "GET_RISKS_AND_ATTRIBUTES_CROSS_FAILURE";

export const SEND_DATA_FORM_CROSS = "SEND_DATA_FORM_CROSS";
export const SEND_DATA_FORM_CROSS_REQUEST = "SEND_DATA_FORM_CROSS_REQUEST";
export const SEND_DATA_FORM_CROSS_SUCCESS = "SEND_DATA_FORM_CROSS_SUCCESS";
export const SEND_DATA_FORM_CROSS_FAILURE = "SEND_DATA_FORM_CROSS_FAILURE";

export const CREATE_POLICY_CROSS = "CREATE_POLICY_CROSS";
export const CREATE_POLICY_CROSS_REQUEST = "CREATE_POLICY_CROSS_REQUEST";
export const CREATE_POLICY_CROSS_SUCCESS = "CREATE_POLICY_CROSS_SUCCESS";
export const CREATE_POLICY_CROSS_FAILURE = "CREATE_POLICY_CROSS_FAILURE";

export const GET_PAYMENT_STATUS_CROSS = "GET_PAYMENT_STATUS_CROSS";
export const GET_PAYMENT_STATUS_CROSS_REQUEST = "GET_PAYMENT_STATUS_CROSS_REQUEST";
export const GET_PAYMENT_STATUS_CROSS_SUCCESS = "GET_PAYMENT_STATUS_CROSS_SUCCESS";
export const GET_PAYMENT_STATUS_CROSS_FAILURE = "GET_PAYMENT_STATUS_CROSS_FAILURE";

export const PRINT_KP = "PRINT_KP";
export const PRINT_KP_REQUEST = "PRINT_KP_REQUEST";
export const PRINT_KP_SUCCESS = "PRINT_KP_SUCCESS";
export const PRINT_KP_FAILURE = "PRINT_KP_FAILURE";

export const UPLOAD_PASSPORT_FILE = "UPLOAD_PASSPORT_FILE";
export const REMOVE_PASSPORT_FILE = "REMOVE_PASSPORT_FILE";

export const UPLOAD_PASSPORT_FILES = "UPLOAD_PASSPORT_FILES";
export const REMOVE_PASSPORT_FILES = "REMOVE_PASSPORT_FILES";

export const UPLOAD_ADDENDUM_FILES = "UPLOAD_ADDENDUM_FILES";
export const REMOVE_ADDENDUM_FILES = "REMOVE_ADDENDUM_FILES";

export const UPLOAD_PTS_FILE = "UPLOAD_PTS_FILE";
export const REMOVE_PTS_FILE = "REMOVE_PTS_FILE";

export const UPLOAD_STS_FILE = "UPLOAD_STS_FILE";
export const REMOVE_STS_FILE = "REMOVE_STS_FILE";

export const UPLOAD_CURRENT_POLICY_FILE = "UPLOAD_CURRENT_POLICY_FILE";
export const REMOVE_CURRENT_POLICY_FILE = "REMOVE_CURRENT_POLICY_FILE";

export const UPLOAD_JURIDICAL_CARD_FILE = "UPLOAD_JURIDICAL_CARD_FILE";
export const REMOVE_JURIDICAL_CARD_FILE = "REMOVE_JURIDICAL_CARD_FILE";

export const UPLOAD_DRIVING_LICENSE_FILE = "UPLOAD_DRIVING_LICENSE_FILE";
export const REMOVE_DRIVING_LICENSE_FILE = "REMOVE_DRIVING_LICENSE_FILE";

export const UPLOAD_LOAN_AGREEMENT_FILE = "UPLOAD_LOAN_AGREEMENT_FILE";
export const REMOVE_LOAN_AGREEMENT_FILE = "REMOVE_LOAN_AGREEMENT_FILE";

export const UPLOAD_EVALUATION_REPORT_FILE = "UPLOAD_EVALUATION_REPORT_FILE";
export const REMOVE_EVALUATION_REPORT_FILE = "REMOVE_EVALUATION_REPORT_FILE";

export const UPLOAD_EGRN_FILE = "UPLOAD_EGRN_FILE";
export const REMOVE_EGRN_FILE = "REMOVE_EGRN_FILE";

export const SET_DATA_FORM_CASCO = "SET_DATA_FORM_CASCO";

export const SEND_DATA_FORM_CASCO = "SEND_DATA_FORM_CASCO";
export const SEND_DATA_FORM_CASCO_REQUEST = "SEND_DATA_FORM_CASCO_REQUEST";
export const SEND_DATA_FORM_CASCO_SUCCESS = "SEND_DATA_FORM_CASCO_SUCCESS";
export const SEND_DATA_FORM_CASCO_FAILURE = "SEND_DATA_FORM_CASCO_FAILURE";

export const TOGGLE_CASCO_KIND = "TOGGLE_CASCO_KIND";
export const TOGGLE_CASCO_MODAL = "TOGGLE_CASCO_MODAL";

export const GET_SBER_QR_CODE = "GET_SBER_QR_CODE";
export const GET_SBER_QR_CODE_REQUEST = "GET_SBER_QR_CODE_REQUEST";
export const GET_SBER_QR_CODE_SUCCESS = "GET_SBER_QR_CODE_SUCCESS";
export const GET_SBER_QR_CODE_FAILURE = "GET_SBER_QR_CODE_FAILURE";
export const GET_SBER_QR_PAYMENT_STATUS = "GET_SBER_QR_PAYMENT_STATUS";
export const GET_SBER_QR_PAYMENT_STATUS_REQUEST = "GET_SBER_QR_PAYMENT_STATUS_REQUEST";
export const GET_SBER_QR_PAYMENT_STATUS_SUCCESS = "GET_SBER_QR_PAYMENT_STATUS_SUCCESS";
export const GET_SBER_QR_PAYMENT_STATUS_FAILURE = "GET_SBER_QR_PAYMENT_STATUS_FAILURE";
export const TOGGLE_SBER_QR_MODAL = "TOGGLE_SBER_QR_MODAL";


// notifications
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";

export const UPDATE_NOTIFICATION_STATUS = "UPDATE_NOTIFICATION_STATUS";
export const UPDATE_NOTIFICATION_STATUS_REQUEST = "UPDATE_NOTIFICATION_STATUS_REQUEST";
export const UPDATE_NOTIFICATION_STATUS_SUCCESS = "UPDATE_NOTIFICATION_STATUS_SUCCESS";
export const UPDATE_NOTIFICATION_STATUS_FAILURE = "UPDATE_NOTIFICATION_STATUS_FAILURE";

export const TOGGLE_NOTIFICATIONS_MODAL = "TOGGLE_NOTIFICATIONS_MODAL";
export const TOGGLE_HIDDEN_NOTIFICATIONS = "TOGGLE_HIDDEN_NOTIFICATIONS";


// earned money
export const CHECK_EARNED_MONEY_AMOUNT = "CHECK_EARNED_MONEY_AMOUNT";

export const CHECK_EARNED_MONEY_AMOUNT_REQUEST = "CHECK_EARNED_MONEY_AMOUNT_REQUEST";
export const CHECK_EARNED_MONEY_AMOUNT_SUCCESS = "CHECK_EARNED_MONEY_AMOUNT_SUCCESS";
export const CHECK_EARNED_MONEY_AMOUNT_FAILURE = "CHECK_EARNED_MONEY_AMOUNT_FAILURE";


// renewal
export const GET_RENEWAL_POLICIES = "GET_RENEWAL_POLICIES";
export const GET_RENEWAL_POLICIES_REQUEST = "GET_RENEWAL_POLICIES_REQUEST";
export const GET_RENEWAL_POLICIES_SUCCESS = "GET_RENEWAL_POLICIES_SUCCESS";
export const GET_RENEWAL_POLICIES_FAILURE = "GET_RENEWAL_POLICIES_FAILURE";

export const SELECT_RENEWAL_POLICY = "SELECT_RENEWAL_POLICY";

export const COPY_RENEWAL_POLICY_PARAMS= "COPY_RENEWAL_POLICY_PARAMS";

import React, {Component} from "react";
import {
  Row,
  Form,
  Button,
  Checkbox,
  notification,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Cleave from "cleave.js/react";

import {
  goToNextStep,
  goToPrevStep,
  setDataForm,
  sendDataForm,
  clearFormStep3,
  removeRiskAction,
  clearObjectAddressData,
} from "../../actions/mortgageActions";
import {
  banksSelector,
  isRequestSelector,
  isLifeRiskSelector,
  currentStepSelector,
  errorsMortgageSelector,
  isPropertyRiskSelector,
  mortgageDataFormSelector,
} from "../../selectors/mortgageSelectors";
import ModalError from "../../components/ModalError";
import {
  transform,
  hasErrors,
  isValidDate,
  getDateFromString,
  frontDateFormat,
} from "../../utils";
import "./styles/index.scss";
import "../../fonts/open-sans.css"
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import {ReactComponent as NumberInactiveIcon} from "../../assets/Icons/NumberInactive.svg";
import {ReactComponent as DelGreyIcon} from "../../assets/Icons/mortgage/DelGrey.svg";
import FirstStepDisabled from "../../components/MortgageDisabledSteps/FirstStepDisabled";
import SecondStepDisabled from "../../components/MortgageDisabledSteps/SecondStepDisabled";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import Property from "../../components/Form/MortgageStep3/Property";
import insuranceConditions from "../../assets/files/mortgage/insuranceConditions.pdf";
import moment from "moment";
import {changeEndDate, checkSelectedBankKey, VTB_KEY} from "./mortgageUtils";


class ThirdStepMortgage extends Component {
  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  state = {
    isBtnDisabled: false,
  };

  componentDidMount() {
    this.headerRef.current.scrollIntoView()

    const {form, banks} = this.props
    const {getFieldValue, setFieldsValue} = form

    let result = changeEndDate(
      getFieldValue("start_policy_date"),
      banks,
      getFieldValue("mortgage_bank"),
      VTB_KEY,
      parseInt(getFieldValue("loan_term_in_month")),
    )
    setFieldsValue({["end_policy_date"]: result})
  }

  handleSubmit = (e) => {
    const {form, sendDataForm, currentStep, goToNextStep} = this.props;

    e.preventDefault();

    form.validateFields({force: true}, (err,) => {
      if (!err) {
        this.setState({isBtnDisabled: true})
        goToNextStep(currentStep + 1)
        sendDataForm()
      } else {
        notification["error"]({
          message: err
        })
      }
    })
  }

  checkStartPolicyDate = (rule, value, callback) => {
    const {mortgageDataForm} = this.props;
    let application_date = getDateFromString(mortgageDataForm["application_date"].value.split('.'))
    let resultValueDate = value.split('.')
    if (moment(value, frontDateFormat, true).isValid()) {
      let result = getDateFromString(resultValueDate)
      if (result && result < application_date) {
        callback(" ")
      } else {
        callback()
      }
    } else {
      callback(" ")
    }
  }

  render() {
    const {
      form,
      banks,
      isLifeRisk,
      currentStep,
      goToPrevStep,
      isPropertyRisk,
    } = this.props;

    const {
      getFieldDecorator,
      setFieldsValue,
      getFieldsError,
      getFieldError,
      getFieldValue,
    } = form;

    const mortgageBank = "mortgage_bank";

    const insureAccidentsIllnesses = "insurance_accidents_illnesses";

    const startPolicyDate = "start_policy_date";
    const startPolicyDateError = getFieldError(startPolicyDate);

    const finishPolicyDate = "end_policy_date";
    const finishPolicyDateError = getFieldError(finishPolicyDate);

    const applicationDate = "application_date";
    const applicationDateError = getFieldError(applicationDate);

    const objectCost = "object_cost";
    const objectCostError = getFieldError(objectCost);

    const objectWear = "object_wear";
    const objectWearError = getFieldError(objectWear);

    const floorsNum = "floors_num";
    const floorsNumError = getFieldError(floorsNum);

    const objectArea = "object_area";
    const objectAreaError = getFieldError(objectArea);

    const cadastralNumber = "cadastr_nr";
    const cadastralNumberError = getFieldError(cadastralNumber);

    const isTermsConfirmed = "is_terms_confirmed";

    const objectType = "property_type";
    const objectTypeError = getFieldError(objectType);

    const insuranceObject = "insurance_object";
    const insuranceObjectError = getFieldError(insuranceObject);

    const constructionYear = "construction_year";
    const constructionYearError = getFieldError(constructionYear);

    const overhaulYear = "overhaul_year";
    const overhaulYearError = getFieldError(overhaulYear);

    const buildingMaterial = "building_material";
    const buildingMaterialError = getFieldError(buildingMaterial);

    const floorMaterial = "floor_material";
    const floorMaterialError = getFieldError(floorMaterial);

    const objectAddress = "property_address";
    const objectAddressError = getFieldError(objectAddress);

    const country = "country";
    const countryError = getFieldError(country);

    const postcode = "property_address_zip_code";
    const postcodeError = getFieldError(postcode);

    const region = "property_address_region";
    const regionError = getFieldError(region);

    const locality = "property_address_city";
    const localityError = getFieldError(locality);

    const street = "property_address_street";
    const streetError = getFieldError(street);

    const house = "property_address_house";
    const houseError = getFieldError(house);

    const corpus = "property_address_corpus";
    const corpusError = getFieldError(corpus);

    const building = "property_address_building";
    const buildingError = getFieldError(building);

    const flat = "property_address_flat";
    const flatError = getFieldError(flat);

    const room = "property_address_room";
    const roomError = getFieldError(room);

    const objectAddressCustomString = "address_str";
    const objectAddressCustomStringError = getFieldError(objectAddressCustomString);

    const getInsuranceTerm = () => {
      return (
        <>

          <Row>
            {getFieldDecorator(isTermsConfirmed, {initialValue: true})(
              <Checkbox
                checked={getFieldValue(isTermsConfirmed)}
                className={"hyd-norm-text mortgage-checkbox-disabled"}
                style={{
                  marginTop: 20
                }}
                disabled
              >

                <a
                  onClick={event => {
                    event.stopPropagation()
                  }}
                  href={insuranceConditions}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Подтверждаю условия страхования
                </a>
              </Checkbox>
            )}
          </Row>

          {riskHeader("Срок страхования", false)}

          <Form.Item
            validateStatus={startPolicyDateError ? "error" : ""}
            help={startPolicyDateError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-mortgage-137"
              htmlFor={"mortgage-step-3_" + startPolicyDate}
              labelText={"Дата начала"}
              divId={"float-label-small"}
              child={getFieldDecorator(startPolicyDate, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    validator: this.checkStartPolicyDate,
                  },
                ]
              })(
                <Cleave
                  className="ant-input hyd-input-small hyd-mortgage-137"
                  placeholder=""
                  key={JSON.stringify({
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  })}
                  onChange={(value) => {
                    let result = changeEndDate(
                      value.target.value,
                      banks,
                      getFieldValue(mortgageBank),
                      VTB_KEY,
                      parseInt(getFieldValue("loan_term_in_month")),
                    )
                    setFieldsValue({["end_policy_date"]: result})
                  }}
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  }}
                />
              )}
            />
          </Form.Item>

          <Form.Item
            validateStatus={finishPolicyDateError ? "error" : ""}
            help={finishPolicyDateError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-mortgage-137-v2"
              htmlFor={"mortgage-step-3_" + finishPolicyDate}
              labelText={"Дата Окончания"}
              divId={"float-label-small"}
              child={getFieldDecorator(finishPolicyDate, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    validator: isValidDate,
                  },
                ]
              })(
                <Cleave
                  className="ant-input hyd-input-small hyd-mortgage-137-v2"
                  placeholder=""
                  key={JSON.stringify({
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  })}
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  }}
                />
              )}
            />
          </Form.Item>

          <Form.Item
            validateStatus={applicationDateError ? "error" : ""}
            help={applicationDateError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-mortgage-137-v2"
              htmlFor={"mortgage-step-3_" + applicationDate}
              labelText={"Дата Заявления"}
              divId={"float-label-small"}
              child={getFieldDecorator(applicationDate, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: !getFieldValue(applicationDate),
                    message: " "
                  },
                  {
                    validator: isValidDate,
                  },
                ]
              })(
                <Cleave
                  className="ant-input hyd-input-small hyd-mortgage-137-v2"
                  placeholder=""
                  key={JSON.stringify({
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  })}
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  }}
                />
              )}
            />
          </Form.Item>
        </>
      )
    }

    const removeRisk = (riskKey) => {
      const {removeRiskAction} = this.props
      return (
        <>
          {
            checkSelectedBankKey(banks, getFieldValue(mortgageBank), VTB_KEY) &&
            getFieldValue("is_life_risk") && getFieldValue("is_property_risk") &&
            <span
              className="delete-risk"
              onClick={() => {
                removeRiskAction(riskKey)
              }}
            >
              <span className="delete-risk-txt">Удалить риск</span>
              <DelGreyIcon className="delete-risk-ico"/>
            </span>
          }
        </>
      )
    }

    const riskHeader = (riskName, showDelete = true, riskKey = "") => {
      return (
        <div className="risk-header">
          <span className="risk-header-txt">{riskName}</span>
          {showDelete && removeRisk(riskKey)}
        </div>
      )
    }

    const getLifeHealthForm = () => {
      return (
        <div>
          <Row>
            {riskHeader("Жизнь и здоровье", true, "is_life_risk")}
            {getFieldDecorator(insureAccidentsIllnesses, {initialValue: true})(
              <Checkbox
                checked={getFieldValue(insureAccidentsIllnesses)}
                className={"hyd-norm-text mortgage-checkbox-disabled"}
                disabled
              >
                Страховать от несчастных случаев и болезней
              </Checkbox>
            )}
          </Row>
        </div>
      )
    }

    const getPropertyForm = () => {
      const {form} = this.props

      return (
        <>
          {riskHeader("Имущество", true, "is_property_risk")}
          <Property
            form={form}
            mortgageBank={mortgageBank}
            objectCost={objectCost}
            objectCostError={objectCostError}
            objectWear={objectWear}
            objectWearError={objectWearError}
            floorsNum={floorsNum}
            floorsNumError={floorsNumError}
            objectArea={objectArea}
            objectAreaError={objectAreaError}
            cadastralNumber={cadastralNumber}
            cadastralNumberError={cadastralNumberError}
            objectType={objectType}
            objectTypeError={objectTypeError}
            insuranceObject={insuranceObject}
            insuranceObjectError={insuranceObjectError}
            constructionYear={constructionYear}
            constructionYearError={constructionYearError}
            overhaulYear={overhaulYear}
            overhaulYearError={overhaulYearError}
            buildingMaterial={buildingMaterial}
            buildingMaterialError={buildingMaterialError}
            floorMaterial={floorMaterial}
            floorMaterialError={floorMaterialError}
            objectAddress={objectAddress}
            objectAddressError={objectAddressError}
            country={country}
            countryError={countryError}
            postcode={postcode}
            postcodeError={postcodeError}
            region={region}
            regionError={regionError}
            locality={locality}
            localityError={localityError}
            street={street}
            streetError={streetError}
            house={house}
            houseError={houseError}
            corpus={corpus}
            corpusError={corpusError}
            building={building}
            buildingError={buildingError}
            flat={flat}
            flatError={flatError}
            room={room}
            roomError={roomError}
            objectAddressCustomString={objectAddressCustomString}
            objectAddressCustomStringError={objectAddressCustomStringError}
          />
        </>
      )
    }

    return (
      <>
        <FirstStepDisabled
          goToPrevStep={() => goToPrevStep(currentStep - 2)}
        />
        <SecondStepDisabled
          goToPrevStep={() => goToPrevStep(currentStep - 1)}
        />

        <div className="step-title-active" ref={this.headerRef}>
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">3</span>
          </div>

          <span className="step-title-active-header">Оформление</span>
        </div>

        <Form
          layout="inline"
          onSubmit={this.handleSubmit}
          id="mortgage-step-3"
        >

          {isLifeRisk && getLifeHealthForm()}

          {isPropertyRisk && getPropertyForm()}

          {getInsuranceTerm()}

          {/* Назад/Далее */}
          <Row>
            <Form.Item>
              <Button
                className="hyd-btn-small hyd-mortgage-btn-proceed"
                type="primary"
                htmlType="submit"
                form="mortgage-step-3"
                key="submit"
                disabled={hasErrors(getFieldsError() || this.state.isBtnDisabled)}
              >
                Рассчитать
              </Button>
            </Form.Item>
          </Row>

        </Form>
        {[
          {number: 4, text: "Финальные Условия"},
          {number: 5, text: "Оплата"},
        ].map(({number, text}) =>
          <div className="step-title-disabled" key={number}>
            <div className="step-icon-block">
              <NumberInactiveIcon className="hyd-large-num-ico"/>
              <span className="hyd-large-num-ico-num">{number}</span>
            </div>
            <span className="step-title-disabled-header">{text}</span>
          </div>
        )}
        <ModalError type={7}/>
      </>
    );
  }
}

const WrappedThirdStepMortgageForm = Form.create({
    name: "mortgage-step-3",
    mapPropsToFields(props) {
      const {mortgageDataForm} = props;
      const transformed = transform({mortgageDataForm});

      return transformed.mortgageDataForm;
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields);
    },
  }
)(ThirdStepMortgage);

const mapStateToProps = (state) => ({
  banks: banksSelector(state),
  isRequest: isRequestSelector(state),
  isLifeRisk: isLifeRiskSelector(state),
  errors: errorsMortgageSelector(state),
  currentStep: currentStepSelector(state),
  isPropertyRisk: isPropertyRiskSelector(state),
  mortgageDataForm: mortgageDataFormSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPrevStep,
      goToNextStep,
      setDataForm,
      sendDataForm,
      clearFormStep3,
      removeRiskAction,
      clearObjectAddressData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WrappedThirdStepMortgageForm);

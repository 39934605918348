import React, { Component } from "react";
import {
    Row,
    Col,
    Form,
    Select,
    Button,
    Typography,
    Modal,
    Result,
    Icon,
} from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from 'react-router-dom';
import { toggleErrorModal } from "../../../actions/osagoActions";
import { signOut, refreshToken } from "../../../actions/loginActions";
// import { toggleClearFormModal} from "../../../actions/accidentsActions";
import {clearForm} from "../../../actions/accidentsSportActions";
import { visibleErrorModalSelector, errorsSelector, calcResultSelector } from "../../../selectors/osagoSelectors";
import { refreshSelector, errorsSelectorLogin, visibleErrorModalSelectorLogin }  from "../../../selectors/loginSelectors";
import { hasErrors, formItemLayout, tailFormItemLayout, transform } from "../../../utils";
import * as keys from '../../../routers/keys';
import history from '../../../modules/history.js';
const { Option } = Select;
const { Title, Paragraph, Text } = Typography;


class ModalClearForm extends Component {

    state = {
        showAttempt: false,
    };

    componentWillMount() {
        const { refreshToken, refresh, errors, history } = this.props;
    }

    componentDidUpdate(prevProps) {
        const { refreshToken, refresh, errors, } = this.props;

    }
    showErrorModal = () => {

        // this.props.toggleClearFormModal(true);
    };

    reload = () => {
        window.location.reload()
    }

    handleErrorModalOk = e => {


        // this.props.toggleClearFormModal(false);
        this.props.clearForm(this.props.type);

        setTimeout(this.reload,2000);
        
      
    };
    handleErrorModalCancel = e => {
        // this.props.toggleClearFormModal(false);
    };
    render() {


        const { showAttempt } = this.state;
        const {
            // toggleClearFormModal,
            visibleErrorModal,
            errorsOsago,
            signOut,
            refresh,
            refreshToken,
            visibleErrorModalLogin,
            errorsLogin,
            errorsAccidents,
            visibleErrorModalAccident,
            visibleClearFormModal,
            type,
        } = this.props;

        //const errors = errorsOsago ? errorsOsago : errorsLogin;
        // let errors=null;

        // if(errorsOsago) {
        //     errors = errorsOsago;
        // }
        // else if(errorsLogin) {
        //     errors = errorsLogin;
        // }
        // else if(errorsAccidents) {
        //     errors = errorsAccidents;
        // }

        return (

            <>
                <Modal
                    title="Уведомление"
                    visible={visibleClearFormModal }
                    onOk={this.handleErrorModalOk}
                    onCancel={this.handleErrorModalCancel}
                    cancelText="Отмена"
                    closable={false}
                >
                    <Result
                        status="warning"
                        title="Внимание"
                    >
                        <div className="desc">
                            <Paragraph>
                                <Text
                                    strong
                                    style={{
                                        fontSize: 16,
                                    }}
                                >
                                    Вы уверены,что хотите очистить форму? Все данные с формы будут удалены.

                                </Text>
                            </Paragraph>
                        </div>
                        
                    </Result>
                </Modal>
              
            </>
        )

    }
}


const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {

        clearForm,
        // toggleClearFormModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModalClearForm);


  
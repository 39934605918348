import {takeLatest} from 'redux-saga/effects';
import * as api from './api';
import * as types from '../store/actionTypes';


export function* getRenewalPoliciesSaga({payload}) {
  yield api.get(`renewal_policies/`, payload, types.GET_RENEWAL_POLICIES);
}

export default function* () {
  yield takeLatest(types.GET_RENEWAL_POLICIES, getRenewalPoliciesSaga);
}

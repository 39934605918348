import initialState from '../store/initialState';
import injectReducer from '../store/injectReducer';
import * as types from '../store/actionTypes';


export default injectReducer(initialState.loginReducer, {
    [types.SIGN_IN]: (state) => {
        return {
            ...state,
        };
    },

    [types.SIGN_IN_REQUEST]: (state) => ({
        ...state,
        isRequest: true,
        errors: null
    }),

    [types.SIGN_IN_SUCCESS]: (state, {payload}) => ({
        ...state,
        isRequest: false,
        errors: null,
        token: payload.token,
    }),

    [types.SIGN_IN_FAILURE]: (state, {payload}) => ({
        ...state,
        isRequest: false,
        errors: payload,
        visibleErrorModal: true
    }),

    [types.SIGN_OUT]: (state) => ({
        ...state,
        ...initialState.loginReducer,
    }),

    [types.REFRESH_TOKEN]: (state) => {
        return {
            ...state,
        };
    },

    [types.REFRESH_TOKEN_REQUEST]: (state) => ({
        ...state,
        isRequest: true,
        errors: null
    }),

    [types.REFRESH_TOKEN_SUCCESS]: (state, {payload}) => ({
        ...state,
        isRequest: false,
        token: payload.token,
        errors: null,
    }),

    [types.REFRESH_TOKEN_FAILURE]: (state) => ({
        ...state,
        isRequest: false,
    }),

    [types.GET_ONE_TIME_TOKEN_SUCCESS]: (state, {payload}) => ({
        ...state,
        oneTimeToken: payload.ticket_uuid
    }),

    [types.TOGGLE_ERROR_MODAL]: (state, { payload }) => ({
        ...state,
        visibleErrorModal: payload,
    }),

    [types.REFRESH_NEW_TOKEN]: (state, {payload}) => {
        return {
            ...state,
            token: payload.token,
        };
    },

    [types.CLEAR_IS_REQUEST_STATE]: (state) => ({
        ...state,
        isRequest: false,
    }),

    [types.GET_USER_INFO_REQUEST]: state => ({
        ...state,
        isRequest: true,
        errors: null,
    }),

    [types.GET_USER_INFO_SUCCESS]: (state, {payload}) => {
        return {
            ...state,
            isRequest: false,
            userInfo: payload,
            errors: null
        };
    },

    [types.GET_USER_INFO_FAILURE]: (state, {payload}) => ({
        ...state,
        isRequest: false,
        visibleErrorModal: true,
        errors: payload
    }),
});


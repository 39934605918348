import React, {Component} from "react";
import {Button, Form, Modal, notification} from "antd";
import {transform} from "../../../utils";
import {ReactComponent as CopyBlue} from "../../../assets/Icons/CopyBlue.svg";
import {
  dataFormSelector,
  visibleSberQRModalSelector,
  sberPaymentUrlSelector,
  sberQRImageSelector,
} from "../../../selectors/osagoSelectors";
import {bindActionCreators} from "redux";
import {toggleSberQRModal} from "../../../actions/osagoActions";
import {connect} from "react-redux";


class ModalSberQRPayment extends Component {
  render() {
    const {
      visibleSberQRModal,
      toggleSberQRModal,
      sberPaymentUrl,
      sberQRImage,
    } = this.props;

    return (
      <>
        <Modal
          visible={visibleSberQRModal}
          cancelButtonProps={{style: {display: 'none'}}}
          okButtonProps={{style: {display: 'none'}}}
          footer={false}
          closable={false}
          width='360px'
          height='424px'
        >

          <Form
            style={{"marginLeft": "16px"}}
          >
            <img height={280} width={280} src={`data:image/jpeg;base64,${sberQRImage}`} alt={"QR код"}/>
            <div className={"hyd-hard-link"}>
              <a
                onClick={(event) => {
                  event.preventDefault()
                  setTimeout(async () => await navigator.clipboard.writeText(sberPaymentUrl).then(() => {
                    notification["success"]({message: "Ссылка скопирована!", duration: 30, placement: "bottomLeft"})
                  }, () => {
                    notification["error"]({
                      message: "Ошибка при копировании ссылки!",
                      duration: 30,
                      placement: "bottomLeft"
                    })
                  }))
                }}
              >
                {"Ссылка на оплату"} <CopyBlue/>
              </a>
            </div>
            <div
              className="hyd-row-profile"
              style={{"marginTop": "20px"}}
            >
              <Button
                className="hyd-btn-small hyd-btn-modal hyd-btn-modal-full"
                onClick={() => {
                  toggleSberQRModal(false)
                }}
              >
                ОК
              </Button>
            </div>
          </Form>
        </Modal>
      </>
    )
  }
}


const WrappedModalSberQRPayment = Form.create({
  name: "modal_sber_qr",
  mapPropsToFields(props) {
    const {dataForm,} = props;
    const transformed = transform({dataForm});

    return transformed.dataForm;
  },
  onFieldsChange(props, changedFields) {
    props.setDataForm(changedFields);
  }
})(ModalSberQRPayment);

const mapStateToProps = state => ({
  dataForm: dataFormSelector(state),
  visibleSberQRModal: visibleSberQRModalSelector(state),
  sberPaymentUrl: sberPaymentUrlSelector(state),
  sberQRImage: sberQRImageSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleSberQRModal,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(WrappedModalSberQRPayment);
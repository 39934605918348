import React, {Component} from "react";
import {connect, Provider} from "react-redux";
import {
  Router,
  Route,
  Switch,
  Redirect,
  withRouter
} from "react-router-dom";
import {PersistGate} from "redux-persist/es/integration/react";
import {Layout, ConfigProvider, Affix} from "antd";
import ruRU from 'antd/lib/locale/ru_RU';
import history from "./modules/history.js";
import * as keys from "./routers/keys";
import Main from "./pages/Main";
import Kbm from "./pages/KBM";
import Addendum from "./pages/Addendum";
import Osago from "./pages/Osago";
import Mortgage from "./pages/Mortgage";
import Cross from "./pages/Cross";
import CASCO from "./pages/CASCO";
import Auth from "./pages/Auth";
import Renewal from "./pages/Renewal";
import Registration from "./pages/Registration";
import AccidentsSport from "./pages/AccidentsSport";
import Contracts from "./pages/Contracts"
import Profile from "./pages/Profile"
import Agents from "./pages/Agents"
import Prolongation from "./pages/Prolongation"
import ReferralLink from "./pages/ReferralLink";
import {store, persistor} from "./store/configureStore";
import "antd/dist/antd.css";
import "./App.css";
import ScrollToTop from "./components/ScrollToTop/";
import HeaderLogOut from "./components/HeaderLogOut/";
import HeaderSticky from "./components/HeaderSticky/";
import FooterContacts from "./components/FooterContacts/";
import {tokenSelector} from "./selectors/loginSelectors";
import {bindActionCreators} from "redux";


const mapStateToProps = state => ({
  token: tokenSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {},
    dispatch
  )

const PrivateRoute = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(({component, token, ...rest}) => {
    if (!token) {
      return <Redirect to={keys.AUTH}/>
    }
    return <Route {...rest} component={component}/>
  }))

const PublicRoute = ({component, ...rest}) => {
  return <Route exact {...rest} component={component}/>
}

class App extends Component {
  state = {
    sticky: false
  }

  listenScrollEvent = () => {
    if (window.scrollY > 115) {
      this.setState({sticky: true})
    } else {
      this.setState({sticky: false})
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  render() {
    let pathsHeaderFooter = Object.values(keys).filter(value => ![keys.REGISTRATION, keys.AUTH].includes(value))

    return (
      <ConfigProvider locale={ruRU}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <div className={"container-outer"}>
              <Layout className="layout-wrapper">
                <Router history={history}>
                  <ScrollToTop/>
                  <Route exact path={pathsHeaderFooter} component={HeaderLogOut}/>
                  {this.state.sticky &&
                    <Affix>
                      <HeaderSticky/>
                    </Affix>}
                  <Switch>
                    <PrivateRoute exact path={keys.HOME} component={Main}/>
                    <PrivateRoute path={keys.PROFILE} component={Profile}/>
                    <PrivateRoute path={keys.AGENTS} component={Agents}/>
                    <PrivateRoute path={keys.OSAGO} component={Osago}/>
                    <PrivateRoute path={keys.ACCIDENTS_SPORT} component={AccidentsSport}/>
                    <PrivateRoute path={keys.CONTRACTS} component={Contracts}/>
                    <PublicRoute path={keys.AUTH} component={Auth}/>
                    <PublicRoute path={keys.REGISTRATION} component={Registration}/>
                    <PrivateRoute path={keys.KBM} component={Kbm}/>
                    <PrivateRoute path={keys.ADDENDUM} component={Addendum}/>
                    <PrivateRoute path={keys.PROLONGATION} component={Prolongation}/>
                    <PrivateRoute path={keys.REFERRAL_LINK} component={ReferralLink}/>
                    <PrivateRoute path={keys.MORTGAGE} component={Mortgage}/>
                    <PrivateRoute path={keys.CROSS} component={Cross}/>
                    <PrivateRoute path={keys.CASCO} component={CASCO}/>
                    <PrivateRoute path={keys.RENEWAL} component={Renewal}/>
                  </Switch>
                  <Route exact path={pathsHeaderFooter} component={FooterContacts}/>
                </Router>
              </Layout>
            </div>
          </PersistGate>
        </Provider>
      </ConfigProvider>
    );
  }
}

export default App;

import React from "react";
import {Row} from "antd";

export default class ProlongationCalcDisabled extends React.Component {
  render() {
    const {
      lastName,
      inn,
      vin,
      bodyNum,
      chassisNum,
      oldPolicySerial,
      oldPolicyNumber,
      newPolicyStart,
      newPolicyEnd,
    } = this.props

    return (
      <>
        <div className="step-title-disabled">
          <Row>
            {lastName && !inn &&
              <div className={"hyd-prev-step"}>
                <div className={"hyd-prev-step-header"}>Фамилия</div>
                <div className={"hyd-prev-step-value"}>{lastName}</div>
              </div>
            }
            {inn &&
              <div className={"hyd-prev-step"}>
                <div className={"hyd-prev-step-header"}>ИНН</div>
                <div className={"hyd-prev-step-value"}>{inn}</div>
              </div>
            }
            {vin &&
              <div className={"hyd-prev-step"}>
                <div className={"hyd-prev-step-header"}>VIN</div>
                <div className={"hyd-prev-step-value"}>{vin}</div>
              </div>
            }
            {bodyNum &&
              <div className={"hyd-prev-step"}>
                <div className={"hyd-prev-step-header"}>Номер кузова</div>
                <div className={"hyd-prev-step-value"}>{bodyNum}</div>
              </div>
            }
            {chassisNum &&
              <div className={"hyd-prev-step"}>
                <div className={"hyd-prev-step-header"}>Номер шасси</div>
                <div className={"hyd-prev-step-value"}>{chassisNum}</div>
              </div>
            }
            {oldPolicySerial && oldPolicyNumber &&
              <div className={"hyd-prev-step"}>
                <div className={"hyd-prev-step-header"}>Серия и номер полиса</div>
                <div className={"hyd-prev-step-value"}>{oldPolicySerial} {oldPolicyNumber}</div>
              </div>
            }
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Начало срока</div>
              <div className={"hyd-prev-step-value"}>{newPolicyStart}</div>
            </div>
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Окончание срока</div>
              <div className={"hyd-prev-step-value"}>{newPolicyEnd}</div>
            </div>
          </Row>
        </div>
      </>
    )
  }
}
import React, {Component} from "react";
// import {Layout, Steps, Row, Button, Typography} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Preloader from "../../components/Preloader";
import {
  goToPrevStep,
  goToNextStep,
  setDataForm,
  clearIsRequestState,
} from "../../actions/osagoActions";
import {clearForm} from "../../actions/accidentsSportActions";
import {
  isRequestSelector,
  currentStepSelector,
} from "../../selectors/osagoSelectors";

import "./styles/index.scss";
import FirstStep from "./step-1";
import TwoStep from "./step-2";
import ThirdStep from "./step-3";
import FourthStep from "./step-4";
import FifthStep from "./step-5";
import SixthStep from "./step-6";
import ModalClearForm from "../../components/Form/ModalClearForm";
import {checkEarnedMoneyAmount, getNotifications} from "../../actions/mainActions";
// import ModalClearForm from "../../components/Form/ModalClearForm";
//
// const {Step} = Steps;
// const {Content} = Layout;
// const {Title} = Typography;

class Osago extends Component {
  componentDidMount() {
    document.title = "ОСАГО"

    const {clearIsRequestState, getNotifications, checkEarnedMoneyAmount} = this.props
    clearIsRequestState()
    getNotifications()
    checkEarnedMoneyAmount()
  }
  componentWillUnmount() {
    this.props.clearForm(1)
  }

  renderForms = () => {
    let {currentStep} = this.props;

    switch (currentStep) {
      case 0:
        return <FirstStep/>;
      case 1:
        return <TwoStep/>;
      case 2:
        return <ThirdStep/>;
      case 3:
        return <FourthStep/>;
      case 4:
        return <FifthStep/>;
      case 5:
        return <SixthStep/>;
      default:
        return <FirstStep/>;
    }
  };

  // onChangeStep = step => {
  //   const {currentStep, goToPrevStep} = this.props;
  //
  //   if (step <= currentStep) {
  //     goToPrevStep(step);
  //   }
  // };

  render() {
    const {
      isRequest,
      // currentStep,
    } = this.props;

    return (
      <>
        <Preloader loading={isRequest}/>
          <div className="osago-container">
              <span className="hyd-very-big-bold-text-small osago-header">Оформление ОСАГО</span>
            {this.renderForms()}
          </div>
        <ModalClearForm type={1}/>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isRequest: isRequestSelector(state),
  currentStep: currentStepSelector(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToPrevStep,
      goToNextStep,
      setDataForm,
      clearIsRequestState,
      clearForm,
      getNotifications,
      checkEarnedMoneyAmount,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Osago);

import * as types from "../store/actionTypes";


export const getAgents = (payload) => ({
    type: types.GET_AGENTS,
    payload: payload
});

export const getKVCategories = () => ({
    type: types.GET_KV_CATEGORIES
});

export const getAgentsTypes = () => ({
    type: types.GET_AGENTS_TYPES
});

export const getAllAgents = (payload) => ({
    type: types.GET_ALL_AGENTS,
    payload: payload
});

export const clearIsRequestState = () => ({
    type: types.CLEAR_IS_REQUEST_STATE
});

export const addAgentToList = (value) => ({
    type: types.ADD_AGENT_TO_LIST,
    payload: value
});

export const addSupervisorToList = (value) => ({
    type: types.ADD_SUPERVISOR_TO_LIST,
    payload: value
});

export const clearSelectedAgentsList = () => ({
    type: types.CLEAR_SELECTED_AGENTS_LIST,
});

export const updateAgent = (payload) => ({
    type: types.UPDATE_AGENT,
    payload: payload
});

export const isAllAgentsReceivedToggle = value => ({
    type: types.TOGGLE_IS_ALL_AGENTS_RECEIVED,
    payload: value
});

export const setPageSize = pageSze => ({
    type: types.SET_PAGE_SIZE,
    payload: pageSze
});

export const setCurrentPage = currentPage => ({
    type: types.SET_CURRENT_PAGE,
    payload: currentPage
});

export const clearAgentsFilters = () => ({
    type: types.CLEAR_AGENTS_FILTERS,
});

export const setIsActiveFilter = value => ({
    type: types.SET_IS_ACTIVE_FILTER,
    payload: value
});

export const setIsOnlineFilter = value => ({
    type: types.SET_IS_ONLINE_FILTER,
    payload: value
});

export const setIsShowKVFilter = value => ({
    type: types.SET_IS_SHOW_KV_FILTER,
    payload: value
});

export const updateAgentsTypesFilter = value => ({
    type: types.UPDATE_AGENTS_TYPES_FILTER,
    payload: value
});

export const updateKVCategoriesFilter = value => ({
    type: types.UPDATE_KV_CATEGORIES_FILTER,
    payload: value
});

import React from "react";

class FloatingLabelInput extends React.Component {
  state = {
    isActive: !!(this.props.child.props.value),
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.isActive !== !!(this.props.child.props.value)) {
      this.setState({isActive: !!(this.props.child.props.value)})
    }
  }

  render() {
    const {
      htmlFor,
      labelText,
      divClassName,
      child,
      divId,
      disabled,
      always_active,
    } = this.props

    const onCustomChange = (event) => {
      if ((event.target && event.target.value !== '') || (!event.target && event !== '')) {
        this.setState({isActive: true});
      } else {
        this.setState({isActive: false});
      }
    }
    const child1 = React.cloneElement(child, {
      onChange: (event) => {
        child.props.onChange(event);
        onCustomChange(event);
      }})

    return (
      <div className={divClassName} id={divId ? divId : "float-label"}>
        {child1}
        <label
          className={(this.state.isActive || always_active) ? "Active" : disabled ? "Disabled" : ""}
          htmlFor={htmlFor}>
          <span>{labelText}</span>
        </label>
      </div>
    )
  }
}

export default FloatingLabelInput
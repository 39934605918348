import {takeLatest, select} from 'redux-saga/effects';
import * as api from './api';
import * as types from '../store/actionTypes';

export function* getAgentsSaga({payload}) {
  yield api.get(`agents/`, payload, types.GET_AGENTS);
}

export function* getKVCategoriesSaga() {
  yield api.get(`kv_categories/`, null, types.GET_KV_CATEGORIES);
}

export function* getAgentsTypesSaga() {
  yield api.get(`agents_types/`, null, types.GET_AGENTS_TYPES);
}

export function* getAllAgentsSaga({payload}) {
  yield api.get(`all_agents/`, payload, types.GET_ALL_AGENTS);
}

export function* updateAgentSaga({payload}) {
  let show_commission = payload.showCommission
  let ag_type = payload.agentType
  let op_type = payload.opType
  let kv = payload.kv
  let order = payload.o

  for (let id of payload.agents) {
    yield api.patch(`agents/`, {id, op_type, kv, show_commission, ag_type}, null, types.UPDATE_AGENT);
  }

  const errors = yield select((state) => state.agentsReducer.errors)
  if (!errors) {
    let isAllAgentsReceived = yield select((state) => state.agentsReducer.isAllAgentsReceived)
    let currentPage = yield select((state) => state.agentsReducer.currentPage)
    let pageSize = yield select((state) => state.agentsReducer.pageSize)
    let filterShowKV = yield select((state) => state.agentsReducer.filterShowKV)
    let filterIsActive = yield select((state) => state.agentsReducer.filterIsActive)
    let filterIsOnline = yield select((state) => state.agentsReducer.filterIsOnline)
    let agentsTypesSelectedFilters = yield select((state) => state.agentsReducer.agentsTypesSelectedFilters)
    let kvCategoriesSelectedFilters = yield select((state) => state.agentsReducer.kvCategoriesSelectedFilters)
    let offset = (currentPage - 1) * pageSize
    let agentsPayload = {
      limit: pageSize,
      offset: offset,
      o: order,
      is_active: filterIsActive,
      is_online: filterIsOnline,
      show_commission: filterShowKV,
      user_type: agentsTypesSelectedFilters,
      kv_category: kvCategoriesSelectedFilters,
    }
    if (isAllAgentsReceived) {
      yield api.get(`all_agents/`, agentsPayload, types.GET_AGENTS);
    } else {
      yield api.get(`agents/`, agentsPayload, types.GET_AGENTS);
    }
  }
}

export default function* () {
  yield takeLatest(types.GET_AGENTS, getAgentsSaga);
  yield takeLatest(types.UPDATE_AGENT, updateAgentSaga);
  yield takeLatest(types.GET_ALL_AGENTS, getAllAgentsSaga);
  yield takeLatest(types.GET_AGENTS_TYPES, getAgentsTypesSaga);
  yield takeLatest(types.GET_KV_CATEGORIES, getKVCategoriesSaga);
}

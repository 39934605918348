import React, {Component} from "react";
import {Button, Empty, Form, Layout, notification, Skeleton, Spin, Table,} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {transform,} from "../../utils";

import "./styles/index.scss";
import "../../fonts/open-sans.css"
import ModalError from "../../components/ModalError";
import ModalApplication from "../../components/Form/ModalApplication";
import {copyPolicy} from "../../actions/contractsActions";
import {checkEarnedMoneyAmount, getNotifications} from "../../actions/mainActions";
import {
  selectRenewalPolicy, clearIsRequestState, getRenewalPolicies, copyRenewalPolicyParams,
} from "../../actions/renewalActions";
import {userInfoSelector} from "../../selectors/loginSelectors";
import {isRequestSelector, renewalPoliciesSelector, selectedObjIdSelector} from "../../selectors/renewalSelectors";
import {
  DRAFT,
  ORDERED,
  rowSelection,
  generateFilteredColumn,
  generateDateSortedColumn,
  generateRegularColumn,
  generateIndexColumn,
  generateSearchedColumn,
} from "../../utilsTable";

const {Content} = Layout;


class Renewal extends Component {
  state = {
    filteredInfo: null,
    sortedInfo: null,
    searchText: '',
    searchedColumn: '',
    pageNum: 1,
    pageSize: 10,
    index: 0,
  }

  componentDidMount() {
    document.title = "Продление"

    const {clearIsRequestState, getNotifications, getRenewalPolicies, checkEarnedMoneyAmount} = this.props
    getNotifications()
    checkEarnedMoneyAmount()
    getRenewalPolicies()
    clearIsRequestState()
  }

  handleChange = (pagination, filters, sorter: { currentDataSource: [] }) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      pageSize: pagination.pageSize,
      pageNum: pagination.current,
    })
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({searchText: ''})
  }

  render() {
    const {
      userInfo,
      isRequest,
      copyPolicy,
      selectedObjId,
      renewalPolicies,
      selectRenewalPolicy,
      copyRenewalPolicyParams,
    } = this.props

    let {sortedInfo, filteredInfo, pageNum, pageSize} = this.state
    sortedInfo = sortedInfo || {}
    filteredInfo = filteredInfo || {}

    const renewalPoliciesColumns = [
      generateIndexColumn("index", "№", pageNum, pageSize),
      generateFilteredColumn(renewalPolicies, filteredInfo, "renewal_status", "CRM"),
      generateFilteredColumn(renewalPolicies, filteredInfo, "insurance_name", "Компания"),
      generateDateSortedColumn(sortedInfo, "start_policy_date", "Начало действия полиса"),
      generateDateSortedColumn(sortedInfo, "finish_policy_date", "Окончание действия полиса"),
      generateSearchedColumn(this.state, this.searchInput, this.handleSearch, this.handleReset, "policy_number", "№ договора"),
      generateFilteredColumn(renewalPolicies, filteredInfo, "policy_status", "Статус полиса"),
      generateFilteredColumn(renewalPolicies, filteredInfo, "payment_status", "Статус оплаты"),
      generateRegularColumn("vin", "VIN"),
    ]

    return (
      <>
        <Content className="content-wrapper">
          <div className="renewal-container">

            <div className="hyd-contracts-title">
              <span className="hyd-very-big-bold-text-small">
                Продление
                <span className="hyd-contracts-count">
                  {renewalPolicies.length}
                </span>
              </span>
            </div>

            <Button
              className="hyd-medium-btn-grey hyd-contracts-btn"
              target="_blank"
              disabled={!selectedObjId["obj_type"]}
              onClick={() => {
                if (userInfo["is_superuser"] || [ORDERED, DRAFT].includes(selectedObjId["policy_status"])) {
                  if (selectedObjId["draft_type"] === "services") {
                    notification["info"]({
                      message: `Копирование пролонгации невозможно`
                    })
                  } else {
                    copyPolicy(selectedObjId.draft_id)
                    copyRenewalPolicyParams({
                      renewalInsuranceId: parseInt(selectedObjId.insurance_id),
                      renewalPolicySerial: selectedObjId.policy_series,
                      renewalPolicyNum: selectedObjId.policy_num,
                    })
                  }
                } else {
                  notification["info"]({
                    message: `Копировать полис можно только в статусе ${ORDERED} или ${DRAFT}`
                  })
                }
              }}
            >
              Скопировать
            </Button>

            <div className="hyd-contracts-table">
              <Table
                key={this.state.index}
                size={"small"}
                columns={renewalPoliciesColumns}
                dataSource={isRequest ? [] : renewalPolicies}
                locale={{
                  emptyText: isRequest ?
                    <div><Spin/><Skeleton active={true}/></div> :
                    <Empty/>
                }}
                onChange={this.handleChange}
                rowSelection={rowSelection(selectRenewalPolicy)}
                rowClassName={"hyd-table-content"}
                pagination={{
                  pageSizeOptions: ["5", "10", "20", "50", "100"],
                  showSizeChanger: true,
                  hideOnSinglePage: true,
                  size: "large",
                }}
                scroll={{x: "max-content"}}
              />
            </div>
          </div>
        </Content>

        <ModalError type={10}/>
        <ModalError type={1}/>
        <ModalApplication/>
      </>
    )
  }
}

const mapStateToProps = state => ({
  renewalPolicies: renewalPoliciesSelector(state),
  selectedObjId: selectedObjIdSelector(state),
  isRequest: isRequestSelector(state),
  userInfo: userInfoSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      copyPolicy,
      getNotifications,
      selectRenewalPolicy,
      checkEarnedMoneyAmount,
      getRenewalPolicies,
      clearIsRequestState,
      copyRenewalPolicyParams,
    },
    dispatch
  )


const WrappedRenewalForm = Form.create({
    name: "renewal-form",
    mapPropsToFields(props) {
      const {renewalDataForm} = props
      const transformed = transform({renewalDataForm})

      return transformed.renewalDataForm
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields)
    },
  }
)(Renewal)

export default connect(mapStateToProps, mapDispatchToProps)(WrappedRenewalForm)

import {takeLatest, select} from 'redux-saga/effects';
import * as api from './api';
import * as types from '../store/actionTypes';
import {isObject} from '../utils';
import {oneTimeTokenSelector} from "../selectors/loginSelectors";
import {store} from "../store/configureStore";
import {getSocket} from "../socket";


export function* sendDataFormSaga() {
  const stateForm = yield select((state) => state.crossReducer.dataFormResult);
  let formFields = {};

  for (const item in stateForm) {
    formFields[item] = isObject(stateForm[item]) ? stateForm[item].value : stateForm[item];
  }
  formFields["offset"] = new Date().getTimezoneOffset()

  let cross_kind = yield select(state => state.crossReducer.crossKind)
  let risks_attributes = yield select(state => state.crossReducer.risksAttributes)

  if (cross_kind === 2) {
    formFields["product_code"] = risks_attributes["practice_risks_attrs"]["product_name"]
    formFields["isn_tariff"] = risks_attributes["practice_risks_attrs"]["isn_tariff"]
    formFields["attr_isn"] = risks_attributes["practice_risks_attrs"]["attr_isn"]
    formFields["cross_kind"] = "PK"
    formFields["limit_sum"] = risks_attributes["practice_risks_attrs"]["limit_sum"]
  } else {
    formFields["product_code"] = risks_attributes["democracy_risks_attrs"]["product_name"]
    formFields["isn_tariff"] = risks_attributes["democracy_risks_attrs"]["isn_tariff"]
    formFields["attr_isn"] = risks_attributes["democracy_risks_attrs"]["attr_isn"]
    formFields["cross_kind"] = "DK"
  }

  yield* api.get("one_time_token/", null, types.GET_ONE_TIME_TOKEN);
  const oneTimeToken = yield select(state => oneTimeTokenSelector(state));
  let insSocket = getSocket(oneTimeToken)

  insSocket.onmessage = function (e) {
    const data = JSON.parse(e.data);
    const type = data.type
    switch (type) {
      case 'calc_result':
        store.dispatch({
          type: types.ADD_CALC_RESULT_CROSS,
          payload: data
        });
        break;
      case 'calc_error':
        store.dispatch({
          type: types.CALC_ERROR_CROSS,
          payload: data
        });
        insSocket.close()
        break;
      case 'draft_id_assigned':
        store.dispatch({
          type: types.ASSIGN_DRAFT_ID_CROSS,
          payload: data
        });
        break;
      case 'start_calculation':
        store.dispatch({
          type: types.START_CALCULATION_CROSS,
          payload: {data: data}
        });
        break;
      case 'calculation_complete':
        store.dispatch({
          type: types.CALC_COMPLETE_CROSS,
          payload: data
        });
        break;
      default:
        store.dispatch({
          type: types.CALC_ERROR_CROSS,
          payload: data
        });
    }
    console.log(data)
  };
  insSocket.onclose = function () {
    const state = store.getState()
    if (!state.crossReducer.calculationComplete && !state.crossReducer.visibleErrorModalCross) {
      store.dispatch({
        type: types.CALC_ERROR_CROSS,
        payload: {errors: {error: ["Соединение с сервером разорвано!"]}}
      });
    }
    console.error('Chat socket closed unexpectedly');
  };
  insSocket.onopen = () => {
    console.log(formFields)
    insSocket.send(JSON.stringify({"type": "cross", formFields}));
  }
}

export function* getRisksAndAttributesSaga() {
  yield api.get(
    "cross/get_risks_and_attributes/gelios/",
    null,
    types.GET_RISKS_AND_ATTRIBUTES_CROSS
  )
}

export function* createPolicySaga({payload}) {
  const stateForm = yield select(state => state.crossReducer.dataFormResult);
  let formFields = {}

  formFields["premium"] = payload.price
  formFields["agreement_isn"] = payload.agreement_isn
  formFields["id"] = stateForm.draft_id
  formFields["offset"] = new Date().getTimezoneOffset()

  formFields["agent_commission"] = payload.agent_commission;
  if (formFields["agent_commission"]) {
    formFields["agent_commission"].value = parseInt(formFields["agent_commission"].value);
  }

  yield api.post(
    `cross/create_policy/${payload.key}/`,
    null,
    formFields,
    types.CREATE_POLICY_CROSS
  )
}

export function* getPaymentStatusSaga({payload}) {
  yield api.post(
    `cross/payment/${payload.key}/status/`,
    null,
    payload,
    types.GET_PAYMENT_STATUS_CROSS
  );
}



export default function* () {
  yield takeLatest(types.SEND_DATA_FORM_CROSS, sendDataFormSaga);
  yield takeLatest(types.GET_RISKS_AND_ATTRIBUTES_CROSS, getRisksAndAttributesSaga);
  yield takeLatest(types.CREATE_POLICY_CROSS, createPolicySaga);
  yield takeLatest(types.GET_PAYMENT_STATUS_CROSS, getPaymentStatusSaga);
}

import * as types from "../store/actionTypes";

export const goToNextStep = step => ({
  type: types.GO_TO_NEXT_STEP_CROSS,
  payload: step
});

export const goToPrevStep = step => ({
  type: types.GO_TO_PREV_STEP_CROSS,
  payload: step
});

export const setDataForm = (data, object) => ({
  type: types.SET_DATA_FORM_CROSS,
  payload: {data, object}
});

export const sendDataForm = () => ({
  type: types.SEND_DATA_FORM_CROSS
});

export const clearForm = (type) => ({
  type: types.CLEAR_FORM,
  payload: type,
});

export const clearIsRequestState = () => ({
  type: types.CLEAR_IS_REQUEST_STATE
});

export const getRisksAndAttributes = () => ({
  type: types.GET_RISKS_AND_ATTRIBUTES_CROSS,
});

export const createPolicy = value => ({
  type: types.CREATE_POLICY_CROSS,
  payload: value
});

export const getPaymentStatus = value => ({
  type: types.GET_PAYMENT_STATUS_CROSS,
  payload: value
});



export const toggleCrossKind = value => ({
  type: types.TOGGLE_CROSS_KIND,
  payload: value
});

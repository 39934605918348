import React, {Component} from "react";
import {Button, Form, Modal} from "antd";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {visibleModalCASCOApplicationSelector} from "../../../selectors/cascoSelectors";
import {toggleModalCASCO, clearForm as clearFormCASCO} from "../../../actions/cascoActions";
import {ReactComponent as YesIcon} from "../../../assets/Icons/CASCO/YES.svg"
import {visibleModalMortgageApplicationSelector} from "../../../selectors/mortgageSelectors";
import {
  getBanks,
  getProfessions,
  toggleModalMortgageApplication,
  clearForm as clearFormMortgage,
} from "../../../actions/mortgageActions";
import {visibleModalAddendumApplicationSelector} from "../../../selectors/osagoSelectors";
import {toggleModalAddendum} from "../../../actions/osagoActions";


class ModalApplication extends Component {

  render() {
    const {
      visibleModalCASCOApplication,
      visibleModalMortgageApplication,
      visibleModalAddendumApplication,
      toggleModalAddendum,
      toggleModalMortgageApplication,
      clearFormMortgage,
      toggleModalCASCO,
      clearFormCASCO,
      getProfessions,
      getBanks,
    } = this.props
    return (
      <Modal
        visible={visibleModalCASCOApplication || visibleModalMortgageApplication || visibleModalAddendumApplication}
        cancelButtonProps={{style: {display: 'none'}}}
        okButtonProps={{style: {display: 'none'}}}
        footer={false}
        closable={false}
        width="440px"
        height="376px"
      >
        <div style={{margin: 16, display: "flex", flexDirection: "column", alignItems: "center"}}>
          <div>
            <YesIcon/>
          </div>
          <div style={{marginTop: 11, marginBottom: 10, fontSize: 24, fontWeight: 700, color: '#000'}}>
            Заявка отправлена
          </div>
          <div style={{marginBottom: 20, fontSize: 16, fontWeight: 400, color: '#000'}}>
            С вами скоро свяжутся
          </div>

          <div>
            <Button
              className="hyd-btn-small-2"
              style={{width: 360}}
              onClick={() => {
                if (visibleModalCASCOApplication) {
                  toggleModalCASCO(false)
                  clearFormCASCO(3)
                } else if (visibleModalMortgageApplication) {
                  toggleModalMortgageApplication(false)
                  clearFormMortgage(7)
                  getBanks()
                  getProfessions()
                } else if (visibleModalAddendumApplication) {
                  toggleModalAddendum(false)
                  clearFormMortgage(1)
                }
              }}
            >
              Ок
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}


const WrappedModalApplicationForm = Form.create({
  name: "modal_application",
})(ModalApplication)

const mapStateToProps = state => ({
  visibleModalCASCOApplication: visibleModalCASCOApplicationSelector(state),
  visibleModalMortgageApplication: visibleModalMortgageApplicationSelector(state),
  visibleModalAddendumApplication: visibleModalAddendumApplicationSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleModalMortgageApplication,
      clearFormMortgage,
      toggleModalAddendum,
      toggleModalCASCO,
      clearFormCASCO,
      getProfessions,
      getBanks,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(WrappedModalApplicationForm)

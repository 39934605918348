import React, {Component} from "react";
import {
  Row,
  Form,
  Radio,
  Input,
  Select,
  AutoComplete,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Cleave from "cleave.js/react";

import {
  goToNextStep,
  goToPrevStep,
  setDataForm,
  toggleSelectedRegAddress,
  getAddressData,
  getProfessions,
  getEmploymentStatuses,
  getHealthAbnormalities,
  clearIsRequestState,
  addCoBorrower,
  calcPropertyShareSum,
} from "../../actions/mortgageActions";
import {
  banksSelector,
  isRequestSelector,
  currentStepSelector,
  errorsMortgageSelector,
  mortgageDataFormSelector,
  regAddressArrSelector,
  factAddressArrSelector,
  professionsSelector,
  employmentStatusesSelector,
  healthAbnormalitiesSelector,
  propertyShareSumSelector,
} from "../../selectors/mortgageSelectors";
import {
  transform,
  nameRules,
  mortgageIdentityDocs,
  mortgageMaritalStatuses,
  checkValueBirthDate,
  getDateFromString,
  frontDateFormat,
} from "../../utils";
import "./styles/index.scss";
import "../../fonts/open-sans.css"
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import petrovich from "petrovich";
import {checkSelectedBankKey, VTB_KEY, SBER_KEY, professionFormItem} from "./mortgageUtils";
import moment from "moment/moment";

const {Option} = Select;


class MortgagePerson extends Component {

  componentDidUpdate(prevProps, prevState, snapshot?) {
    const {form, coBorrowersKeys} = this.props
    const {getFieldValue} = form

    const prevPropertyShare = prevProps.mortgageDataForm.property_share
      && prevProps.mortgageDataForm.property_share.value

    if (getFieldValue("property_share") !== prevPropertyShare) {
      this.validateShares()
    }

    coBorrowersKeys && coBorrowersKeys.length && coBorrowersKeys
      .forEach(key => {
        const prevPropertyShareK = prevProps.mortgageDataForm[`property_share_${key}`]
          && prevProps.mortgageDataForm[`property_share_${key}`].value

        if (getFieldValue(`property_share_${key}`) !== prevPropertyShareK) {
          this.validateShares()
        }
      })
  }

  validateShares = () => {
    const {form, coBorrowersKeys} = this.props
    const {validateFields} = form

    validateFields([`property_share`], {force: true})

    coBorrowersKeys && coBorrowersKeys.length && coBorrowersKeys
      .forEach(key => {
        validateFields([`property_share_${key}`], {force: true})
      })
  }

  validateGender = (rule, value, callback) => {
    let setFieldsValue = this.props.form.setFieldsValue

    if (petrovich.detect_gender(value) === 'male') {
      setFieldsValue({'gender': 'M'});
      callback()
    } else if (petrovich.detect_gender(value) === 'female') {
      setFieldsValue({'gender': 'F'});
      callback()
    } else {
      setFieldsValue({'gender': 'M'});
      callback()
    }
  }

  checkValueExperienceDate = (rule, value, callback) => {
    const {mortgageDataForm} = this.props;
    if (value) {
      let resultCurrentDate = new Date()
      let resultPastDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100))
      let birthDate = getDateFromString(mortgageDataForm["birth_date"].value.split('.'))
      let resultValueDate = value.split('.')
      if (moment(value, frontDateFormat, true).isValid()) {
        let result = getDateFromString(resultValueDate)
        if (result && (resultCurrentDate < result) || (result < resultPastDate)) {
          callback(" ")
        } else if (moment(result).diff(moment(birthDate), 'years', true) < 14) { // 14 yo valid
          callback(" ")
        } else {
          callback()
        }
      } else {
        callback(" ")
      }
    } else {
      callback()
    }
  }

  render() {
    const {
      k = "",
      form,
      professions,
      employmentStatusesList,
      healthAbnormalitiesList,
      banks,
      toggleSelectedRegAddress,
      regAddressArr,
      factAddressArr,
      getAddressData,
      clearAddressData,
      saveAddressData,
      calcPropertyShareSum,
      propertyShareSum,
      clearFactAddressData,
      saveFactAddressData,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldError,
      getFieldValue,
      setFieldsValue,
    } = form;


    const validatePropertyShare = (rule, value, callback) => {
      calcPropertyShareSum()
      if (value) {
        if (propertyShareSum !== 100) {
          callback(" ")
        } else {
          callback()
        }
      } else {
        callback(" ")
      }
    }

    const mortgageBank = "mortgage_bank";

    const lastName = `last_name${k}`;
    const lastNameError = getFieldError(lastName);

    const middleName = `middle_name${k}`;
    const middleNameError = getFieldError(middleName);

    const name = `first_name${k}`;
    const nameError = getFieldError(name);

    const gender = `gender${k}`;
    const genderError = getFieldError(gender);

    const propertyShare = `property_share${k}`;
    const propertyShareError = getFieldError(propertyShare);

    const birthDate = `birth_date${k}`;
    const birthDateError = getFieldError(birthDate);

    const phone = `phone${k}`;
    const phoneError = getFieldError(phone);

    const email = `email${k}`;
    const emailError = getFieldError(email);

    const identityDoc = `identity_doc${k}`;
    const identityDocError = getFieldError(identityDoc);

    const identityDocSeries = `document_series${k}`;
    const identityDocSeriesError = getFieldError(identityDocSeries);

    const identityDocNumber = `document_number${k}`;
    const identityDocNumberError = getFieldError(identityDocNumber);

    const identityDocDate = `document_issue_date${k}`;
    const identityDocDateError = getFieldError(identityDocDate);

    const identityDocDivisionCode = `division_code${k}`;
    const identityDocDivisionCodeError = getFieldError(identityDocDivisionCode);

    const identityDocIssuer = `document_issuer${k}`;
    const identityDocIssuerError = getFieldError(identityDocIssuer);

    const birthPlace = `birth_place${k}`;
    const birthPlaceError = getFieldError(birthPlace);

    const registrationAddress = `registration_address${k}`;
    const registrationAddressError = getFieldError(registrationAddress);

    const isFactAddress = `is_fact_address${k}`;
    const isFactAddressError = getFieldError(isFactAddress);

    const factAddress = `fact_address${k}`;
    const factAddressError = getFieldError(factAddress);

    const maritalStatus = `marital_status${k}`;
    const maritalStatusError = getFieldError(maritalStatus);

    const employmentStatus = `employment_status${k}`;
    const employmentStatusError = getFieldError(employmentStatus);

    const healthAbnormalities = `health_abnormalities${k}`;
    const healthAbnormalitiesError = getFieldError(healthAbnormalities);

    const profession = `profession${k}`;
    const professionError = getFieldError(profession);

    const clearedKey = parseInt(k.replace("_", ""))

    return (
      <>

        <Row>
          <Form.Item
            validateStatus={lastNameError ? "error" : ""}
            help={lastNameError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-mortgage-283 hyd-osago-autocomplete"
              htmlFor={"mortgage-step-2_" + lastName}
              labelText={"Фамилия"}
              divId={"float-label-small"}
              child={getFieldDecorator(lastName, {
                validateTrigger: ["onBlur", "onChange"],
                rules: nameRules,
              })(<Input className={"ant-input hyd-input-small hyd-osago-8-input"}/>)}
            />
          </Form.Item>

          <Form.Item
            validateStatus={nameError ? "error" : ""}
            help={nameError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-mortgage-283 hyd-input-autocomplete-small"
              htmlFor={"mortgage-step-2_" + name}
              labelText={"Имя"}
              divId={"float-label-small"}
              child={getFieldDecorator(name, {
                validateTrigger: ["onBlur", "onChange"],
                rules: nameRules,
              })(<Input className={"ant-input hyd-input-small hyd-osago-8-input"}/>)}
            />
          </Form.Item>
          <Form.Item
            validateStatus={middleNameError ? "error" : ""}
            help={middleNameError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-mortgage-283-no-margin hyd-osago-autocomplete-small"
              htmlFor={"mortgage-step-2_" + middleName}
              labelText={"Отчество"}
              divId={"float-label-small"}
              child={getFieldDecorator(middleName, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: false,
                    message: " "
                  },
                  {
                    // только лат или только кириллица (допускается двойное имя с пробелом по центру или тире)
                    pattern: /^[А-Яа-яЁёa-zA-Z() -]+$/g,
                    message: " ",
                  },
                  {
                    validator: this.validateGender,
                  },
                ],
              })(<Input className={"ant-input hyd-input-small hyd-osago-8-input"}/>)}
            />
          </Form.Item>
        </Row>

        <Row style={{marginTop: 20}}>
          <Form.Item
            validateStatus={birthDateError ? "error" : ""}
            help={birthDateError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-mortgage-283"
              htmlFor={"mortgage-step-2_" + birthDate}
              labelText={"Дата рождения"}
              divId={"float-label-small"}
              child={getFieldDecorator(birthDate, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: !getFieldValue(birthDate),
                    message: " "
                  },
                  {
                    validator: checkValueBirthDate,
                  },
                ]
              })(
                <Cleave
                  className="ant-input hyd-input-small hyd-mortgage-283"
                  placeholder=""
                  key={JSON.stringify({
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  })}
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  }}
                />
              )}
            />
          </Form.Item>

          <Form.Item
            validateStatus={genderError ? "error" : ""}
            help={genderError || ""}
          >
            <div className="hyd-select-small hyd-mortgage-283 hyd-osago-select">
              {getFieldDecorator(gender, {
                initialValue: "M",
                validateTrigger: ["onBlur", "onChange"],
                rules: [{required: true, message: " "}]
              })(
                <Select
                  placeholder={"Пол"}
                  className="hyd-input-select-small"
                  style={{width: "100%"}}
                  name={gender}>
                  <Option value="M">
                    <div className={"hyd-select-tooltip"}>Пол</div>
                    <div className={"hyd-select-value"}>Мужской</div>
                  </Option>
                  <Option value="F">
                    <div className={"hyd-select-tooltip"}>Пол</div>
                    <div className={"hyd-select-value"}>Женский</div>
                  </Option>
                </Select>
              )}
            </div>
          </Form.Item>

          {checkSelectedBankKey(banks, getFieldValue(mortgageBank), VTB_KEY) &&
            <Form.Item
              validateStatus={
                propertyShareError || !getFieldValue(propertyShare) || !parseInt(getFieldValue(propertyShare))
                  ? "error" : ""
              }
              help={propertyShareError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-283-no-margin"
                htmlFor={"mortgage-step-1_" + propertyShare}
                labelText={"Доля, %"}
                divId={"float-label-small"}
                child={getFieldDecorator(propertyShare, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                    {
                      validator: validatePropertyShare
                    }
                  ],
                })(
                  <Cleave
                    className="ant-input hyd-input-small hyd-mortgage-debt"
                    key={`rate`}
                    options={{
                      numeral: true,
                      numeralPositiveOnly: true,
                      numeralDecimalMark: ',',
                      delimiter: ' ',
                    }}
                    maxLength={3}
                  />
                )}
              />
            </Form.Item>
          }
        </Row>

        <Row style={{marginTop: 20}}>
          <Form.Item
            validateStatus={phoneError ? "error" : ""}
            help={phoneError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-mortgage-283"
              htmlFor={"mortgage-step-2_" + phone}
              labelText={"Телефон"}
              divId={"float-label-small"}
              child={getFieldDecorator(phone, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " ",
                  },
                  {
                    pattern: /^\+?([0-9]{1})\)?[ ]?([0-9]{3})[- ]?([0-9]{3})[- ]?([0-9]{2})[- ]?([0-9]{2})$/g,
                    message: " "
                  },
                ],
              })(
                <Cleave
                  className="ant-input hyd-input-small hyd-mortgage-283"
                  key={"phone-1"}
                  options={{
                    phone: true,
                    phoneRegionCode: 'RU',
                    prefix: "+7",
                    noImmediatePrefix: true,
                  }}
                />
              )}
            />
          </Form.Item>

          <Form.Item
            validateStatus={emailError ? "error" : ""}
            help={emailError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-mortgage-283"
              htmlFor={"mortgage-step-2_" + email}
              labelText={"E-mail"}
              divId={"float-label-small"}
              child={getFieldDecorator(email, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " ",
                  },
                  {
                    pattern: /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?\s*)+$/g,
                    message: " ",
                  },
                ],
              })(<Input className="hyd-input-small hyd-mortgage-283"/>)}
            />
          </Form.Item>
        </Row>

        <Row style={{marginTop: 20}}>
          {/* Документы */}
          <Form.Item
            validateStatus={identityDocError ? "error" : ""}
            help={identityDocError || ""}
          >
            <div className="hyd-select-small hyd-mortgage-283 hyd-osago-select">
              {getFieldDecorator(identityDoc, {
                rules: [{required: true, message: " "}],
              })(
                <Select
                  placeholder={"Документы"}
                  className="hyd-input-select-small"
                  style={{width: "100%"}}
                >
                  {mortgageIdentityDocs.map(({value, text}) =>
                    <Option value={value} key={value}>
                      <div className={"hyd-select-tooltip"}>Документы</div>
                      <div>{text}</div>
                    </Option>
                  )}
                </Select>
              )}
            </div>
          </Form.Item>

          {/* Серия */}
          <Form.Item
            validateStatus={identityDocSeriesError ? "error" : ""}
            help={identityDocSeriesError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-mortgage-79"
              htmlFor={"mortgage-step-2_" + identityDocSeries}
              labelText={"Серия"}
              divId={"float-label-small"}
              child={getFieldDecorator(identityDocSeries, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    pattern: /^\d{2} \d{2}$/g,
                    message: " "
                  }
                ]
              })(
                <Cleave
                  key={identityDocSeries}
                  className="ant-input hyd-input-small hyd-mortgage-79"
                  options={{
                    blocks: [2, 2],
                    delimiter: " ",
                    numericOnly: true,
                    numeralPositiveOnly: true,
                  }}
                />
              )}
            />
          </Form.Item>

          {/* Номер */}
          <Form.Item
            validateStatus={identityDocNumberError ? "error" : ""}
            help={identityDocNumberError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-mortgage-194"
              htmlFor={"mortgage-step-2_" + identityDocNumber}
              labelText={"Номер"}
              divId={"float-label-small"}
              child={getFieldDecorator(identityDocNumber, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    pattern: /^\d{6}$/g,
                    message: " "
                  }
                ]
              })(<Input className="hyd-input-small hyd-mortgage-194" maxLength={6}/>)}
            />
          </Form.Item>

          {/* Дата выдачи */}
          <Form.Item
            validateStatus={identityDocDateError ? "error" : ""}
            help={identityDocDateError || ""}
            style={{marginLeft: 20}}
          >
            <FloatingLabelInput
              divClassName={"hyd-mortgage-137 hyd-osago-no-margin"}
              htmlFor={"mortgage-step-2_" + identityDocDate}
              labelText={"Дата выдачи"}
              divId={"float-label-small"}
              child={getFieldDecorator(identityDocDate, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    validator: this.checkValueExperienceDate,
                  },
                ],
              })(
                <Cleave
                  className={
                    "ant-input hyd-input-small hyd-mortgage-137"
                  }
                  key={JSON.stringify({
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"],
                  })}
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"],
                  }}
                />
              )}
            />
          </Form.Item>

          {/* Код подразделения */}
          <Form.Item
            validateStatus={identityDocDivisionCodeError ? "error" : ""}
            help={identityDocDivisionCodeError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-mortgage-136"
              htmlFor={"mortgage-step-2_" + identityDocDivisionCode}
              labelText={"Код подразд."}
              divId={"float-label-small"}
              child={getFieldDecorator(identityDocDivisionCode, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    pattern: /^\d{3}-\d{3}$/g,
                    message: " "
                  }
                ]
              })(
                <Cleave
                  key={identityDocSeries}
                  className="ant-input hyd-input-small hyd-mortgage-136"
                  options={{
                    blocks: [3, 3],
                    delimiter: "-",
                    numericOnly: true,
                    numeralPositiveOnly: true,
                  }}
                />
              )}
            />
          </Form.Item>
        </Row>

        <Row style={{marginTop: 20}}>
          {/* Кем выдан */}
          <Form.Item
            validateStatus={identityDocIssuerError ? "error" : ""}
            help={identityDocIssuerError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-mortgage-597"
              htmlFor={"mortgage-step-2_" + identityDocIssuer}
              labelText={"Кем выдан"}
              divId={"float-label-small"}
              child={getFieldDecorator(identityDocIssuer, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    pattern: /^[А-Яа-яЁё\d\s-]{5,}$/g,
                    message: " "
                  },
                  {
                    pattern: /^.{5,}$/g,
                    message: " "
                  },
                ]
              })(
                <Input
                  className="ant-input hyd-input-small hyd-mortgage-597"
                />
              )}
            />
          </Form.Item>
        </Row>

        <Row style={{marginTop: 20}}>
          {/* Место рождения */}
          <Form.Item
            validateStatus={birthPlaceError ? "error" : ""}
            help={birthPlaceError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-mortgage-597"
              htmlFor={"mortgage-step-2_" + birthPlace}
              labelText={"Место рождения"}
              divId={"float-label-small"}
              child={getFieldDecorator(birthPlace, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                ],
              })(
                <Input
                  className="hyd-input-small hyd-mortgage-597"
                  maxLength={1000}
                />
              )}
            />
          </Form.Item>
        </Row>

        <Row style={{marginTop: 20}}>
          {/* Адрес регистрации */}
          <Form.Item
            validateStatus={registrationAddressError ? "error" : ""}
            help={registrationAddressError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-mortgage-597 hyd-osago-autocomplete"
              htmlFor={"mortgage-step-2_" + registrationAddress}
              labelText={"Адрес регистрации"}
              divId={"float-label-small"}
              child={getFieldDecorator(registrationAddress, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                ],
              })(
                <AutoComplete
                  className="hyd-input-autocomplete-small"
                  dataSource={regAddressArr}
                  onSelect={(inputValue) => {
                    toggleSelectedRegAddress(true)
                    regAddressArr.map((value) => {
                      // достаем адрес, регион, страну из массива полученного с бэка
                      if (inputValue === value.value) {
                        let valueOfInput = getFieldValue(registrationAddress)

                        if (valueOfInput.indexOf("_") !== -1) {
                          valueOfInput = valueOfInput.split("_")
                          setFieldsValue({
                            registrationAddress: valueOfInput[1],
                          })
                        }
                        if (/^\d+$/.test(clearedKey)) {
                          clearAddressData(clearedKey)
                          saveAddressData(clearedKey, value)
                        } else {
                          clearAddressData()
                          saveAddressData(
                            value.text,
                            value.country,
                            value.region,
                            value.zip,
                            value.city_name,
                            value.street,
                            value.house,
                            value.block,
                            value.block_type,
                            value.block_type_full,
                            value.flat,
                            value.fias_id,
                            value.value_kladr,
                            value.city_kladr,
                            value.region_with_type,
                            value.area_with_type,
                            value.city_with_type,
                            value.settlement_with_type,
                            value.house_type,
                            value.flat_type,
                            value.city_kladr_id,
                            value.settlement_kladr_id,
                          )
                        }

                      }
                    })
                  }}

                  onChange={(value) => {
                    if (value && value.length > 1) {
                      getAddressData(value, "reg")
                    }
                    toggleSelectedRegAddress(false)
                  }}
                />
              )}
            />
          </Form.Item>
        </Row>

        <Row>
          <Form.Item
            validateStatus={isFactAddressError ? "error" : ""}
            help={isFactAddressError || ""}
            style={{marginTop: 10}}
          >
            {getFieldDecorator(isFactAddress, {
              initialValue: getFieldValue(isFactAddress),
              validateTrigger: ["onBlur", "onChange"],
              rules: [{required: true, message: " "}]
            })(
              <Radio.Group
                name={isFactAddress}
                onChange={(e) => {
                  console.log(e.target.value)
                }}
                className={"hyd-radio-group"}
              >
                <span className={"hyd-radio-txt"}>Фактический адрес совпадает с адресом регистрации</span>
                <Radio className={"hyd-norm-text"} value={1}>Да</Radio>
                <Radio className={"hyd-norm-text"} value={2}>Нет</Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Row>

        {getFieldValue(isFactAddress) === 2 &&
          <Row style={{marginTop: 10}}>
            <Form.Item
              validateStatus={factAddressError ? "error" : ""}
              help={factAddressError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-597 hyd-osago-autocomplete"
                htmlFor={"mortgage-step-2_" + factAddress}
                labelText={"Фактический адрес"}
                divId={"float-label-small"}
                child={getFieldDecorator(factAddress, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                  ],
                })(
                  <AutoComplete
                    className="hyd-input-autocomplete-small"
                    dataSource={factAddressArr}
                    onSelect={(inputValue) => {
                      toggleSelectedRegAddress(true)
                      factAddressArr.map((value) => {
                        if (inputValue === value.value) {
                          let valueOfInput = getFieldValue(factAddress)

                          if (valueOfInput.indexOf("_") !== -1) {
                            valueOfInput = valueOfInput.split("_")
                            setFieldsValue({
                              factAddress: valueOfInput[1],
                            })
                          }
                          if (/^\d+$/.test(clearedKey)) {
                            clearFactAddressData(clearedKey)
                            saveFactAddressData(clearedKey, value)
                          } else {
                            clearFactAddressData()
                            saveFactAddressData(
                              value.text,
                              value.country,
                              value.region,
                              value.zip,
                              value.city_name,
                              value.street,
                              value.house,
                              value.block,
                              value.block_type,
                              value.block_type_full,
                              value.flat,
                              value.fias_id,
                              value.value_kladr,
                              value.city_kladr,
                              value.region_with_type,
                              value.area_with_type,
                              value.city_with_type,
                              value.settlement_with_type,
                              value.house_type,
                              value.flat_type,
                              value.city_kladr_id,
                              value.settlement_kladr_id,
                            )
                          }

                        }
                      })
                    }}

                    onChange={(value) => {
                      if (value && value.length > 1) {
                        getAddressData(value, "fact")
                      }
                      toggleSelectedRegAddress(false)
                    }}
                  />
                )}
              />
            </Form.Item>
          </Row>
        }

        <Row style={{marginTop: 20}}>
          {checkSelectedBankKey(banks, getFieldValue(mortgageBank), SBER_KEY) &&
            <>
              {/* Семейное положение */}
              <Form.Item
                validateStatus={maritalStatusError ? "error" : ""}
                help={maritalStatusError || ""}
              >
                <div className="hyd-select-small hyd-mortgage-283 hyd-osago-select">
                  {getFieldDecorator(maritalStatus, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [{required: true, message: " "}],
                  })(
                    <Select
                      className="hyd-input-select-small"
                      style={{width: "100%"}}
                      onSelect={() => {
                        setFieldsValue({}
                        )
                      }}
                    >
                      {mortgageMaritalStatuses.map(({value, text}) =>
                        <Option value={value} key={value}>
                          <div className={"hyd-select-tooltip"}>Семейное положение</div>
                          <div>{text}</div>
                        </Option>
                      )}
                    </Select>
                  )}
                </div>
              </Form.Item>

              {/* Место работы / Статус занятости */}
              <Form.Item
                validateStatus={employmentStatusError ? "error" : ""}
                help={employmentStatusError || ""}
              >
                <div className="hyd-select-small hyd-mortgage-283 hyd-osago-select">
                  {getFieldDecorator(employmentStatus, {
                    rules: [{required: true}],
                  })(
                    <Select
                      placeholder={"Место работы"}
                      className="hyd-input-select-small"
                      style={{width: "100%"}}
                    >
                      {employmentStatusesList && employmentStatusesList.map(({id, name}) =>
                        <Option value={id} key={id}>
                          <div className={"hyd-select-tooltip"}>Место работы</div>
                          <div className={"hyd-select-value"}>{name}</div>
                        </Option>
                      )}
                    </Select>
                  )}
                </div>
              </Form.Item>
            </>
          }

          {checkSelectedBankKey(banks, getFieldValue(mortgageBank), VTB_KEY) &&
            <>
              {/* Отклонения по здоровью */}
              <Form.Item
                validateStatus={healthAbnormalitiesError ? "error" : ""}
                help={healthAbnormalitiesError || ""}
              >
                <div className="hyd-select-small hyd-mortgage-283 hyd-osago-select">
                  {getFieldDecorator(healthAbnormalities, {
                    rules: [{required: true}],
                  })(
                    <Select
                      placeholder={"Отклонения по здоровью"}
                      className="hyd-input-select-small"
                      style={{width: "100%"}}
                    >
                      {healthAbnormalitiesList && healthAbnormalitiesList.map(({id, name}) =>
                        <Option value={id} key={id}>
                          <div className={"hyd-select-tooltip"}>Отклонения по здоровью</div>
                          <div className={"hyd-select-value"}>{name}</div>
                        </Option>
                      )}
                    </Select>
                  )}
                </div>
              </Form.Item>
            </>
          }

          {professionFormItem(professions, profession, professionError, getFieldDecorator)}
        </Row>

      </>
    )
  }
}

const WrappedMortgagePersonForm = Form.create({
    name: "mortgage-step-2",
    mapPropsToFields(props) {
      const {mortgageDataForm} = props;
      const transformed = transform({mortgageDataForm});

      return transformed.mortgageDataForm;
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields);
    },
  }
)(MortgagePerson);

const mapStateToProps = (state) => ({
  banks: banksSelector(state),
  isRequest: isRequestSelector(state),
  currentStep: currentStepSelector(state),
  errors: errorsMortgageSelector(state),
  mortgageDataForm: mortgageDataFormSelector(state),
  regAddressArr: regAddressArrSelector(state),
  factAddressArr: factAddressArrSelector(state),
  professions: professionsSelector(state),
  employmentStatusesList: employmentStatusesSelector(state),
  healthAbnormalitiesList: healthAbnormalitiesSelector(state),
  propertyShareSum: propertyShareSumSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPrevStep,
      goToNextStep,
      setDataForm,
      toggleSelectedRegAddress,
      getAddressData,
      getProfessions,
      getEmploymentStatuses,
      getHealthAbnormalities,
      clearIsRequestState,
      addCoBorrower,
      calcPropertyShareSum,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WrappedMortgagePersonForm);

import React, {Component} from "react";
import {Row, Form, Button, Modal, Col} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {toggleChoiceTOModal, toggleTOModal, toggleExistTOModal} from "../../../actions/osagoActions";
import {infoTOSelector, visibleChoiceTOModalSelector, visibleTOModalSelector,} from "../../../selectors/osagoSelectors";
import dateFormat from "dateformat";
import {formItemLayout} from "../../../utils";


class ModalChoiceTO extends Component {
  componentDidMount() {
    this.props.form.validateFields();
  }

  showModal = () => {
    this.props.toggleChoiceTOModal(true);
  };

  handleOk = e => {
    this.props.toggleChoiceTOModal(false);
  };

  handleCancel = e => {
    this.props.toggleChoiceTOModal(false);
  };

  render() {
    const {
      visibleChoiceTOModal,
      infoTO,
    } = this.props;

    const {
      getFieldValue
    } = this.props.form;

    return (
      <>
        <Modal
          title="Необходимо оформить ТО"
          visible={visibleChoiceTOModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width='500px'
          closable={false}
        >
          <Form {...formItemLayout}>

            {infoTO && infoTO.date && infoTO.number && (
              <>
                <Form.Item label="ТО действителен до">
                  <Row gutter={8}>
                    <Col span={12}>
                      <b>
                        {dateFormat(infoTO.date, "dd.mm.yyyy")}
                      </b>
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item label="ДК №">
                  <Row gutter={8}>
                    <Col span={12}>
                      <b>
                        {infoTO.number}
                      </b>
                    </Col>
                  </Row>
                </Form.Item>
              </>
            )}

            <Form.Item style={{textAlign: 'right'}}>
              <Button
                href={"https://dcard-online.com/"}
                target="_blank"
                type="primary"
              >
                Оформить ТО
              </Button>
            </Form.Item>

          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  visibleChoiceTOModal: visibleChoiceTOModalSelector(state),
  visibleTOModal: visibleTOModalSelector(state),
  infoTO: infoTOSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleChoiceTOModal,
      toggleTOModal,
      toggleExistTOModal,
    },
    dispatch
  );

const WrappedModalChoiceTOForm = Form.create({name: 'modal_choice_to'})(ModalChoiceTO);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedModalChoiceTOForm);

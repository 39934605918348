import React, {Component} from "react";
import {
  Row,
  Spin,
  Form,
  Input,
  Select,
  Button,
  Tooltip,
  Checkbox,
  notification,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Cleave from "cleave.js/react";

import {
  getBanks,
  clearForm,
  getFiasId,
  setDataForm,
  goToNextStep,
  sendDataForm,
  getProfessions,
  removeCoBorrower,
  uploadPassportFiles,
  removePassportFiles,
  clearIsRequestState,
  clearObjectAddressData,
  setDefaultPropertyShare,
  sendMortgageApplication,
  sendPrecalculateDataForm,
  toggleModalMortgageApplication,
  uploadLoanAgreementFiles,
  removeLoanAgreementFiles,
  uploadEvaluationReportFiles,
  removeEvaluationReportFiles,
  uploadEGRNFiles,
  removeEGRNFiles,
} from "../../actions/mortgageActions";
import {
  banksSelector,
  fiasIdSelector,
  isRequestSelector,
  isLifeRiskSelector,
  currentStepSelector,
  professionsSelector,
  isPropertyRiskSelector,
  errorsMortgageSelector,
  coBorrowersKeysSelector,
  mortgageDataFormSelector,
  passportFileListSelector,
  precalculateResultSelector,
  calculationInProgressSelector,
  isFormSuccessfullySentSelector,
  evaluationReportFileListSelector,
  loanAgreementFileListSelector,
  EGRNFileListSelector,
} from "../../selectors/mortgageSelectors";
import {userInfoSelector} from "../../selectors/loginSelectors";
import ModalError from "../../components/ModalError";
import {
  baseRules,
  transform,
  hasErrors,
  isValidDate,
  baseTrigger,
  isListsNotEmpty,
  checkValueBirthDate,
  mortgageObjectTypes,
} from "../../utils";
import "./styles/index.scss";
import "../../fonts/open-sans.css"
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import {ReactComponent as NumberInactiveIcon} from "../../assets/Icons/NumberInactive.svg";
import {ReactComponent as QuestionIcon} from "../../assets/Icons/Question.svg";
import {ReactComponent as ArrowDownIcon} from "../../assets/Icons/mortgage/ArrowDown.svg";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import Preloader from "../../components/Preloader";
import PrecalculateForm from "../../components/Form/PrecalculateForm";
import {checkSelectedBankKey, isNeedCalcMortgage, professionFormItem, VTB_KEY} from "./mortgageUtils";
import uploadButton from "../../components/UploadButton";
import ModalApplication from "../../components/Form/ModalApplication";

const {Option} = Select;
const {TextArea} = Input;


class FirstStepMortgage extends Component {
  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  state = {
    timeoutId: undefined,
    cityTimeoutId: undefined,
  }

  componentDidMount() {
    const {getBanks, getProfessions, clearIsRequestState} = this.props
    getBanks()
    getProfessions()
    clearIsRequestState()
  }

  // componentWillUnmount() {
  //   const {clearForm} = this.props
  //   clearForm(7)
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      isRequest, isFormSuccessfullySent, toggleModalMortgageApplication, fiasId, sendPrecalculateDataForm} = this.props
    if (isFormSuccessfullySent !== prevProps.isFormSuccessfullySent && !isRequest && isFormSuccessfullySent) {
      toggleModalMortgageApplication(true)
    }
    if (fiasId && fiasId !== prevProps.fiasId && !isRequest) {
      sendPrecalculateDataForm()
    }
  }

  isApplicationTypesConfigCorrect = () => {
    const {form} = this.props
    const {getFieldValue} = form

    let applicationIsLifeAndHealthInsurance = getFieldValue("application_is_life_and_health_insurance")
    let applicationIsPropertyInsurance = getFieldValue("application_is_property_insurance")
    let applicationIsTitlePlusProperty = getFieldValue("application_is_title_plus_property")

    return !((!applicationIsLifeAndHealthInsurance && !applicationIsPropertyInsurance && !applicationIsTitlePlusProperty))

  }

  handleSubmit = (e) => {
    const {
      form,
      banks,
      currentStep,
      goToNextStep,
      fiasId,
      passportFileList,
      sendMortgageApplication,
    } = this.props
    const {validateFields, getFieldValue} = form

    e.preventDefault()

    if (isNeedCalcMortgage(banks, getFieldValue("mortgage_bank")) && !fiasId) {
      notification["error"]({
        message: `fias_id для города '${getFieldValue(`insurance_city`)}' не найден. Проверьте корректность введённых данных.`
      })
    } else {
      validateFields((err,) => {
        if (isNeedCalcMortgage(banks, getFieldValue("mortgage_bank")) && !err) {
          goToNextStep(currentStep + 1)
        } else if (!isNeedCalcMortgage(banks, getFieldValue("mortgage_bank")) && !err) {
          if (!isListsNotEmpty(passportFileList)) {
            notification["error"]({
              message: "Приложите необходимые документы!"
            })
          } else if (!this.isApplicationTypesConfigCorrect()) {
            notification["error"]({
              message: "Недопустимая конфигурация типов полисов!"
            })
          } else {
            sendMortgageApplication()
          }
        } else {
          notification["error"]({
            message: err
          })
        }
      })
    }
  }

  runTimeoutLogic = () => {
    if (this.state.timeoutId) {
      this.resetTimeout()
    } else {
      this.startTimeout()
    }
  }

  onFormChange = () => {
    const {form, banks} = this.props
    const {validateFields, getFieldValue} = form

    if (isNeedCalcMortgage(banks, getFieldValue("mortgage_bank"))) {
      validateFields((err,) => {
        if (!err) {
          this.runTimeoutLogic()
        } else {
          if (err.hasOwnProperty("birth_date") && getFieldValue("is_property_risk")) {
            this.runTimeoutLogic()
          }
        }
      })
    }
  }

  startTimeout = () => {
    const {sendPrecalculateDataForm} = this.props
    const timeoutId = setTimeout(() => {
      sendPrecalculateDataForm()
    }, 1000)
    this.setState({timeoutId: timeoutId})
  }

  resetTimeout = () => {
    clearTimeout(this.state.timeoutId)
    this.startTimeout()  // Immediately start the timeout again
  }

  handleCityInputChange = (event) => {
    const {getFiasId} = this.props
    let value = event.target.value

    // Clear any existing timer
    clearTimeout(this.state.cityTimeoutId)

    // Set a new timer to trigger the function after a delay (e.g., 1000ms)
    this.setState({
      cityTimeoutId: setTimeout(() => {
        getFiasId(value)
      }, 1000)
    })
  }

  onChangePolicyType = (e) => {
    let {form} = this.props
    let {setFieldsValue, getFieldValue} = form
    let {target} = e
    let {checked} = target
    let {id} = target

    let applicationIsPropertyInsurance = getFieldValue("application_is_property_insurance")
    let applicationIsTitlePlusProperty = getFieldValue("application_is_title_plus_property")

    if (checked) {
      if (id.includes("application_is_property_insurance")) {
        if (applicationIsTitlePlusProperty) {
          setFieldsValue({application_is_title_plus_property: false})
        }
      }
      if (id.includes("application_is_title_plus_property")) {
        if (applicationIsPropertyInsurance) {
          setFieldsValue({application_is_property_insurance: false})
        }
      }
    }
  }


  render() {
    const {
      form,
      banks,
      userInfo,
      isRequest,
      professions,
      coBorrowersKeys,
      passportFileList,
      loanAgreementFileList,
      evaluationReportFileList,
      EGRNFileList,
      removeCoBorrower,
      isLifeRiskChecked,
      precalculateResult,
      uploadPassportFiles,
      removePassportFiles,
      uploadLoanAgreementFiles,
      removeLoanAgreementFiles,
      uploadEvaluationReportFiles,
      removeEvaluationReportFiles,
      uploadEGRNFiles,
      removeEGRNFiles,
      calculationInProgress,
      clearObjectAddressData,
      setDefaultPropertyShare,
    } = this.props;

    const {
      getFieldError,
      getFieldValue,
      getFieldsError,
      setFieldsValue,
      getFieldDecorator,
    } = form;

    const mortgageBank = "mortgage_bank";
    const mortgageBankError = getFieldError(mortgageBank);

    const debtRest = "loan_amount";
    const debtRestError = getFieldError(debtRest);

    const mortgageRate = "rate";
    const mortgageRateError = getFieldError(mortgageRate);

    const mortgageContractNumber = "contract_number";
    const mortgageContractNumberError = getFieldError(mortgageContractNumber);

    const mortgageDateStart = "mortgage_contract_start_date";
    const mortgageDateStartError = getFieldError(mortgageDateStart);

    const loanAgreementIssueDate = "loan_agreement_issue_date";
    const loanAgreementIssueDateError = getFieldError(loanAgreementIssueDate);

    const loanTermInMonth = "loan_term_in_month";
    const loanTermInMonthError = getFieldError(loanTermInMonth);

    const birthDate = "birth_date";
    const birthDateError = getFieldError(birthDate);

    const gender = "gender";
    const genderError = getFieldError(gender);

    const objectType = "property_type";
    const objectTypeError = getFieldError(objectType);

    const insuranceCity = "insurance_city";
    const insuranceCityError = getFieldError(insuranceCity);

    const isLifeRisk = "is_life_risk";
    const isPropertyRisk = "is_property_risk";

    const applicationIsLifeAndHealthInsurance = "application_is_life_and_health_insurance";
    const applicationIsPropertyInsurance = "application_is_property_insurance";
    const applicationIsTitlePlusProperty = "application_is_title_plus_property";
    const applicationIsWoodenFloors = "application_is_wooden_floors";
    const applicationIsGas = "application_is_gas";

    const profession = "profession";
    const professionError = getFieldError(profession);

    const note = "note";
    const noteError = getFieldError(note);

    const Passport = "passport"
    const LoanAgreement = "loan_agreement"
    const evaluationReport = "evaluation_report"
    const EGRN = "EGRN"
    const personDocs = [
      {
        value: 7,
        value_back: Passport,
        text: "Паспорт РФ (1-2 стр.)",
        multiple: true,
        fileList: passportFileList,
        actionUpload: uploadPassportFiles,
        actionRemove: removePassportFiles,
      },
      {
        value: 8,
        value_back: LoanAgreement,
        text: "Кред. договор",
        multiple: true,
        fileList: loanAgreementFileList,
        actionUpload: uploadLoanAgreementFiles,
        actionRemove: removeLoanAgreementFiles,
      },
      {
        value: 9,
        value_back: evaluationReport,
        text: "Отчёт об оценке",
        multiple: true,
        fileList: evaluationReportFileList,
        actionUpload: uploadEvaluationReportFiles,
        actionRemove: removeEvaluationReportFiles,
      },
      {
        value: 10,
        value_back: EGRN,
        text: "ЕГРН",
        multiple: true,
        fileList: EGRNFileList,
        actionUpload: uploadEGRNFiles,
        actionRemove: removeEGRNFiles,
      },
    ]


    const textLife = () => {
      return (
        <div style={{padding: "4px 12px"}}>
          <div>Не является публичным должностным лицом, его</div>
          <div>супругом, близким родственником</div>
          <br/>
          <div style={{fontWeight: 700}}>Высокий риск, требуется согласование:</div>
          <div style={{paddingTop: 16}}>Значительные отклонения по здоровью</div>
          <div style={{marginTop: 16}}>Опасный спорт</div>
          <div style={{marginTop: 16}}>Опасная работа</div>
        </div>
      )
    }

    const textProperty = () => {
      return (
        <div style={{padding: "4px 12px"}}>
          <div style={{fontWeight: 700}}>Высокий риск, требуется согласование:</div>
          <div style={{marginTop: 16}}>Год постройки не ранее 1960 года</div>
          <div style={{marginTop: 16}}>Высокий износ (45%)</div>
          <div style={{marginTop: 16}}>Наличие кап. ремонта/перепланирования</div>
          <div style={{marginTop: 16}}>
            На территории объекта находятся источники повыш. пож. опасности (газовая колонка, газовая плита)
          </div>
          <div style={{marginTop: 16}}>Материал строения/материал перекрытий из горючих материалов</div>
          <div style={{marginTop: 16}}>
            Объект имущества расположен на территории РФ, кроме республики Крым,
            г. Севастополь, Донецкой Народной Республики, Луганской Народной Республики,
            Херсонской области, Запорожской области
          </div>
        </div>
      )
    }

    const textTooltipCity = () => {
      return (
        <div style={{padding: "4px 12px"}}>
          <span>
            Введите название города, в котором расположен объект страхования.
            Если населенный пункт, в котором расположен объект страхования, не является Городом,
            введите ближайший к данному населенному пункту Город
          </span>
        </div>
      )
    }

    const textTooltipPropertyType = () => {
      return (
        <div style={{padding: "4px 12px"}}>
          <span>
            Комнату можно застраховать, если в залоге у банка находится только комната, а не вся квартира
          </span>
        </div>
      )
    }

    const lifeRiskDataUI = () => {
      return (
        <>
          <span style={{marginRight: 5}}>
            Жизнь и здоровье
          </span>
          {
            <Tooltip title={textLife} trigger="click" overlayClassName={"hyd-tooltip hyd-tooltip-wide"}>
              <QuestionIcon className="hyd-mortgage-q-ico"/>
            </Tooltip>
          }
        </>
      )
    }

    const propertyRiskDataUI = () => {
      return (
        <>
          <span style={{marginRight: 5}}>
            Имущество
          </span>
          {
            <Tooltip title={textProperty} trigger="click" overlayClassName={"hyd-tooltip hyd-tooltip-wide-2"}>
              <QuestionIcon className="hyd-mortgage-q-ico"/>
            </Tooltip>
          }
        </>
      )
    }

    const getFormLife = () => {
      // Жизнь и здоровье
      return (
        <Row style={{marginBottom: 20}}>
          {/* Дата рождения */}
          <Form.Item
            validateStatus={birthDateError ? "error" : ""}
            help={birthDateError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-mortgage-283"
              htmlFor={"mortgage-step-1_" + birthDate}
              labelText={"Дата рождения"}
              divId={"float-label-small"}
              child={getFieldDecorator(birthDate, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: isLifeRiskChecked,
                    message: " "
                  },
                  {
                    validator: isLifeRiskChecked && checkValueBirthDate,
                  },
                ],
              })(
                <Cleave
                  className="ant-input hyd-input-small"
                  key={birthDate}
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"],
                  }}
                />)}
            />
          </Form.Item>

          {/* Пол */}
          <Form.Item
            validateStatus={genderError ? "error" : ""}
            help={genderError || ""}
          >
            <div className="hyd-select-small hyd-mortgage-283 hyd-osago-select">
              {getFieldDecorator(gender, {initialValue: "M", rules: baseRules,})(
                <Select
                  placeholder={"Пол"}
                  className="hyd-input-select-small"
                  style={{width: "100%"}}
                  name={gender}
                  onChange={this.onFormChange}
                  suffixIcon={<ArrowDownIcon className="hyd-default-select-icon"/>}
                >
                  <Option value="M">
                    <div className={"hyd-select-tooltip"}>Пол</div>
                    <div className={"hyd-select-value"}>Мужской</div>
                  </Option>
                  <Option value="F">
                    <div className={"hyd-select-tooltip"}>Пол</div>
                    <div className={"hyd-select-value"}>Женский</div>
                  </Option>
                </Select>
              )}
            </div>
          </Form.Item>
        </Row>
      )
    }

    const getFormProperty = () => {
      return (
        <Row style={{marginBottom: 20}}>
          {/* Тип объекта */}
          <div className="hyd-custom-select">
            <Form.Item
              validateStatus={objectTypeError ? "error" : ""}
              help={objectTypeError || ""}
            >
              <div className="hyd-select-small hyd-mortgage-283 hyd-osago-select">
                {getFieldDecorator(objectType, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: baseRules,
                })(
                  <Select
                    className="hyd-input-select-small"
                    style={{width: "100%"}}
                    onSelect={() => {
                      clearObjectAddressData()
                      setFieldsValue({
                          room: undefined
                        }
                      )
                    }}
                    onChange={this.onFormChange}
                    placeholder="Тип объекта"
                    suffixIcon={<ArrowDownIcon className="hyd-default-select-icon-shifted"/>}
                  >
                    {mortgageObjectTypes.map(({value, text, disabled}) =>
                      <Option value={value} key={value} disabled={disabled}>
                        <div className={"hyd-select-tooltip"}>Тип объекта</div>
                        <div>{text}</div>
                      </Option>
                    )}
                  </Select>
                )}
              </div>
            </Form.Item>
            <Tooltip
              title={textTooltipPropertyType}
              trigger="click"
              placement="topRight"
              overlayClassName={"hyd-tooltip hyd-tooltip-wide-4"}
            >
              <QuestionIcon className="hyd-mortgage-q-ico hyd-custom-input-icon"/>
            </Tooltip>
          </div>

          {!isNeedCalcMortgage(banks, getFieldValue(mortgageBank)) &&
            <span className={"hyd-mg-app-prop-checkboxes-group"}>
              {getFieldDecorator(applicationIsWoodenFloors, {})(
                <Checkbox
                  checked={getFieldValue(applicationIsWoodenFloors)}
                  className={"hyd-norm-text"}
                >
                  Деревянные перекрытия
                </Checkbox>
              )}

              {getFieldDecorator(applicationIsGas, {})(
                <Checkbox
                  checked={getFieldValue(applicationIsGas)}
                  className={"hyd-norm-text"}
                >
                  Газовая колонка, газовая плита, АОГВ
                </Checkbox>
              )}
            </span>
          }

        </Row>
      )
    }

    const getMortgageRate = (isSmall=false) => {
      // Ставка
      return (
        <Form.Item
          validateStatus={mortgageRateError ? "error" : ""}
          help={mortgageRateError || ""}
        >
          <FloatingLabelInput
            divClassName={isSmall ? "hyd-mortgage-130" : "hyd-mortgage-283-no-margin"}
            htmlFor={"mortgage-step-1_" + mortgageRate}
            labelText={"Ставка, %"}
            divId={"float-label-small"}
            child={getFieldDecorator(mortgageRate, {
              validateTrigger: ["onBlur", "onChange"],
              rules: baseRules,
            })(
              <Cleave
                className="ant-input hyd-input-small hyd-mortgage-debt"
                key={`rate`}
                options={{
                  numeral: true,
                  numeralPositiveOnly: true,
                  numeralDecimalMark: ',',
                  delimiter: ' ',
                }}
                disabled={banks && banks.length && checkSelectedBankKey(banks, getFieldValue(mortgageBank), VTB_KEY)}
              />
            )}
          />
        </Form.Item>
      )
    }

    const redAsterisk = () => {
      return (
        <span style={{color: "#FF2943"}}> * </span>
      )
    }

    const precalculate = () => {
      return (
        <>
          <div style={{marginTop: 16}}>
            {redAsterisk()}
            — Расчёт предварительный. Страховая премия может измениться после уточнения всех параметров
          </div>
          <Row gutter={16} style={{marginTop: 16, marginBottom: 16}}>
            {
              precalculateResult.map((value, index) => (
                <PrecalculateForm
                  index={index}
                  value={value}
                  userInfo={userInfo}
                  key={index}
                  redAsterisk={redAsterisk}
                />
              ))
            }
          </Row>
        </>
      )
    }

    const onChangeRisksCheckboxes = (e) => {
      // special logic for VTB allowing to check two risks
      const {form, isLifeRiskChecked, isPropertyRiskChecked} = this.props;
      const {setFieldsValue} = form;

      if (!e.target.checked) {
        if (e.target.id.includes(isLifeRisk)) {
          if (!isPropertyRiskChecked) {
            setFieldsValue({is_property_risk: true})
          }
        }
        if (e.target.id.includes(isPropertyRisk)) {
          setFieldsValue({birth_date: ''})
          if (!isLifeRiskChecked) {
            setFieldsValue({is_life_risk: true})
          }
        }
      }
    }

    const onChangeRiskCheckbox = (e) => {
      // only one risk can be checked
      const {setFieldsValue} = this.props.form;

      if (e.target.checked) {
        if (e.target.id.includes(isLifeRisk)) {
          setFieldsValue({is_property_risk: false})
        }
        if (e.target.id.includes(isPropertyRisk)) {
          setFieldsValue({birth_date: '', is_life_risk: false})
        }
      }

      if (!e.target.checked) {
        if (e.target.id.includes(isLifeRisk)) {
          setFieldsValue({is_property_risk: true})
        }
        if (e.target.id.includes(isPropertyRisk)) {
          setFieldsValue({birth_date: '', is_life_risk: true})
        }
      }
    }

    const checkboxRiskKind = () => {
      // Риск чекбоксы
      return (
        <div style={{marginTop: 40, marginBottom: 20}}>
          {getFieldDecorator(isLifeRisk, {})(
            <Checkbox checked={getFieldValue(isLifeRisk)}
                      onChange={(e) => {
                        checkSelectedBankKey(banks, getFieldValue(mortgageBank), VTB_KEY) ?
                          onChangeRisksCheckboxes(e) : onChangeRiskCheckbox(e)
                        this.onFormChange()
                      }}
                      className={"hyd-norm-text mortgage-checkbox-disabled"}>
              {lifeRiskDataUI()}
            </Checkbox>
          )}
          {getFieldDecorator(isPropertyRisk, {})(
            <Checkbox checked={getFieldValue(isPropertyRisk)}
                      onChange={(e) => {
                        checkSelectedBankKey(banks, getFieldValue(mortgageBank), VTB_KEY) ?
                          onChangeRisksCheckboxes(e) : onChangeRiskCheckbox(e)
                        this.onFormChange()
                      }}
                      className={"hyd-norm-text"}>
              {propertyRiskDataUI()}
            </Checkbox>
          )}
        </div>
      )
    }

    return (
      <>
        <Preloader loading={isRequest}/>

        <div className="step-title-active" ref={this.headerRef}>
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">1</span>
          </div>
          <span className="step-title-active-header">Предварительный расчёт</span>
        </div>

        <Form
          layout="inline"
          onSubmit={this.handleSubmit}
          onChange={this.onFormChange}
          id="mortgage-step-1"
        >

          <Row>
            {/* Банк */}
            <Form.Item
              validateStatus={mortgageBankError ? "error" : ""}
              help={mortgageBankError || ""}
            >
              <div className="hyd-select-small hyd-mortgage-283 hyd-osago-select">
                {getFieldDecorator(mortgageBank, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: baseRules,
                })(
                  <Select
                    className="hyd-input-select-small"
                    style={{width: "100%"}}
                    onSelect={(bankKey) => {
                      setFieldsValue({
                        is_life_risk: true,
                        is_property_risk: false,
                      })
                      if (!checkSelectedBankKey(banks, bankKey, VTB_KEY)) {
                        setFieldsValue({rate: undefined})
                        coBorrowersKeys && coBorrowersKeys.length && coBorrowersKeys.map((key) => {
                          removeCoBorrower(key)
                        })
                        setDefaultPropertyShare()
                      } else {
                        setFieldsValue({rate: "10"})
                      }
                    }}
                    onChange={this.onFormChange}
                    suffixIcon={<ArrowDownIcon className="hyd-default-select-icon"/>}
                  >
                    {banks.map(({id, name}) =>
                      <Option value={id} key={id}>
                        <div className={"hyd-select-tooltip"}>Банк</div>
                        <div>{name}</div>
                      </Option>
                    )}
                  </Select>
                )}
              </div>
            </Form.Item>

            {/* Остаток задолженности */}
            <Form.Item
              validateStatus={debtRestError ? "error" : ""}
              help={debtRestError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-mortgage-283"
                htmlFor={"mortgage-step-1_" + debtRest}
                labelText={"Остаток задолженности, ₽"}
                divId={"float-label-small"}
                child={getFieldDecorator(debtRest, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: baseRules,
                })(
                  <Cleave
                    className="ant-input hyd-input-small hyd-mortgage-debt"
                    key={`loan_amount`}
                    options={{
                      numeral: true,
                      numeralPositiveOnly: true,
                      numeralDecimalMark: ',',
                      delimiter: ' ',
                    }}
                  />
                )}
              />
            </Form.Item>


            {isNeedCalcMortgage(banks, getFieldValue(mortgageBank)) ?
              <>
                {/* Город страхования */}
                <div className="hyd-custom-input">
                  <Form.Item
                    validateStatus={insuranceCityError ? "error" : ""}
                    help={insuranceCityError || ""}
                  >
                    <FloatingLabelInput
                      divClassName="hyd-mortgage-283-no-margin hyd-osago-low-margin"
                      htmlFor={"mortgage-step-1_" + insuranceCity}
                      labelText={"Город страхования"}
                      divId={"float-label-small"}
                      child={getFieldDecorator(insuranceCity, {
                        validateTrigger: ["onBlur", "onChange"],
                        rules: [
                          {
                            required: getFieldValue(isPropertyRisk),
                            message: " ",
                            whitespace: true
                          },
                        ],
                      })(
                        <Input
                          onChange={this.handleCityInputChange}
                          className="hyd-input-small hyd-mortgage-283-no-margin"
                          maxLength={30}
                        />
                      )}
                    />
                  </Form.Item>
                  <Tooltip
                    title={textTooltipCity}
                    trigger="click"
                    placement="topRight"
                    overlayClassName={"hyd-tooltip hyd-tooltip-wide-3"}
                  >
                    <QuestionIcon className="hyd-mortgage-q-ico hyd-custom-input-icon"/>
                  </Tooltip>
                </div>
              </>
              : <>
                {getMortgageRate(true)}

                {/* Дата выдачи КД */}
                <Form.Item
                  validateStatus={loanAgreementIssueDateError ? "error" : ""}
                  help={loanAgreementIssueDateError || ""}
                >
                  <FloatingLabelInput
                    divClassName={"hyd-mortgage-140 hyd-osago-no-margin"}
                    htmlFor={"mortgage-step-1_" + loanAgreementIssueDate}
                    labelText={"Дата выдачи КД"}
                    divId={"float-label-small"}
                    child={getFieldDecorator(loanAgreementIssueDate, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        {
                          validator: isValidDate,
                        },
                      ],
                    })(
                      <Cleave
                        className={
                          "ant-input hyd-input-small hyd-mortgage-136"
                        }
                        key={JSON.stringify({
                          date: true,
                          delimiter: ".",
                          datePattern: ["d", "m", "Y"],
                        })}
                        options={{
                          date: true,
                          delimiter: ".",
                          datePattern: ["d", "m", "Y"],
                        }}
                      />
                    )}
                  />
                </Form.Item>
              </>
            }
          </Row>

          {isNeedCalcMortgage(banks, getFieldValue(mortgageBank)) &&
            <>
              <Row style={{marginTop: 20}}>
                {/* Номер договора */}
                <Form.Item
                  validateStatus={mortgageContractNumberError ? "error" : ""}
                  help={mortgageContractNumberError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-mortgage-283 hyd-osago-low-margin"
                    htmlFor={"mortgage-step-1_" + mortgageContractNumber}
                    labelText={"Номер договора"}
                    divId={"float-label-small"}
                    child={getFieldDecorator(mortgageContractNumber, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        {
                          required: true,
                          message: " ",
                          whitespace: true
                        },
                      ],
                    })(
                      <Input
                        className="hyd-input-small hyd-mortgage-283"
                        maxLength={30}
                      />
                    )}
                  />
                </Form.Item>

                {/* Дата выдачи */}
                <Form.Item
                  validateStatus={mortgageDateStartError ? "error" : ""}
                  help={mortgageDateStartError || ""}
                >
                  <FloatingLabelInput
                    divClassName={"hyd-mortgage-118 hyd-osago-no-margin"}
                    htmlFor={"mortgage-step-1_" + mortgageDateStart}
                    labelText={"Дата выдачи"}
                    divId={"float-label-small"}
                    child={getFieldDecorator(mortgageDateStart, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        ...baseRules,
                        {
                          validator: isValidDate,
                        },
                      ],
                    })(
                      <Cleave
                        className={
                          "ant-input hyd-input-small hyd-mortgage-118"
                        }
                        key={JSON.stringify({
                          date: true,
                          delimiter: ".",
                          datePattern: ["d", "m", "Y"],
                        })}
                        options={{
                          date: true,
                          delimiter: ".",
                          datePattern: ["d", "m", "Y"],
                        }}
                      />
                    )}
                  />
                </Form.Item>

                {/* Срок кредита, мес. */}
                <Form.Item
                  validateStatus={loanTermInMonthError ? "error" : ""}
                  help={loanTermInMonthError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-mortgage-158"
                    htmlFor={"mortgage-step-1_" + loanTermInMonth}
                    labelText={"Срок кредита, мес."}
                    divId={"float-label-small"}
                    child={getFieldDecorator(loanTermInMonth, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        ...baseRules,
                        {
                          pattern: /^[0-9]{1,3}$/g,
                          message: " "
                        },
                      ],
                    })(
                      <Cleave
                        className="ant-input hyd-input-small hyd-mortgage-debt"
                        key={`rate`}
                        options={{
                          numeral: true,
                          numeralPositiveOnly: true,
                          numericOnly: true,
                        }}
                        maxLength={3}
                      />
                    )}
                  />
                </Form.Item>

                {getMortgageRate()}
              </Row>

              {checkboxRiskKind()}

              {getFieldValue(isLifeRisk) && getFormLife()}
              {getFieldValue(isPropertyRisk) && getFormProperty()}

              {
                precalculateResult && Array.isArray(precalculateResult) &&
                precalculateResult.length !== 0 && precalculate()
              }
              {calculationInProgress && <Spin size="large" style={{marginTop: 16}}/>}

              {/* Назад/Далее */}
              <Row>
                <Form.Item>
                  <Button
                    className="hyd-btn-small hyd-mortgage-btn-proceed"
                    type="primary"
                    htmlType="submit"
                    form="mortgage-step-1"
                    key="submit"
                    disabled={hasErrors(getFieldsError())}
                  >
                    Продолжить
                  </Button>
                </Form.Item>
              </Row>
            </>
          }

          {
            !isNeedCalcMortgage(banks, getFieldValue(mortgageBank)) &&
            <>

              <Row style={{marginTop: 42, marginBottom: 42}}>
                <span className="hyd-mg-app-txt" style={{marginRight: 20}}>Тип полиса:</span>

                {getFieldDecorator(applicationIsLifeAndHealthInsurance, {})(
                  <Checkbox
                    checked={getFieldValue(applicationIsLifeAndHealthInsurance)}
                    onChange={this.onChangePolicyType}
                    className={"hyd-norm-text"}
                  >
                    Страхование жизни и здоровья
                  </Checkbox>
                )}

                {getFieldDecorator(applicationIsPropertyInsurance, {})(
                  <Checkbox
                    checked={getFieldValue(applicationIsPropertyInsurance)}
                    onChange={this.onChangePolicyType}
                    className={"hyd-norm-text"}
                  >
                    Страхование имущества
                  </Checkbox>
                )}

                {getFieldDecorator(applicationIsTitlePlusProperty, {})(
                  <Checkbox
                    checked={getFieldValue(applicationIsTitlePlusProperty)}
                    onChange={this.onChangePolicyType}
                    className={"hyd-norm-text"}
                  >
                    Титул + Имущество
                  </Checkbox>
                )}

              </Row>

              {(getFieldValue(applicationIsPropertyInsurance) || getFieldValue(applicationIsTitlePlusProperty)) &&
                <>
                  <Row style={{marginBottom: 20}}>
                    {getFormProperty()}
                  </Row>
                </>
              }

              {getFieldValue(applicationIsLifeAndHealthInsurance) &&
                <>
                  {professionFormItem(professions, profession, professionError, getFieldDecorator)}
                </>
              }

              <Row>
                {/* Примечание */}
                <Form.Item
                  validateStatus={noteError ? "error" : ""}
                  help={noteError || ""}
                  style={{marginTop: 20, marginBottom: 30}}
                >
                  <FloatingLabelInput
                    divClassName="casco-wide-tall-input"
                    htmlFor={"casco_" + note}
                    labelText={"Примечание"}
                    divId={"float-label-small"}
                    child={getFieldDecorator(note, {
                      validateTrigger: baseTrigger,
                      rules: baseRules,
                    })(<TextArea maxLength={300} className={"ant-input hyd-input-small"}/>)}
                  />
                </Form.Item>
              </Row>


              <div className="step-title-active" ref={this.headerRef}>
                <div className="step-icon-block">
                  <NumberIcon className="hyd-large-num-ico"/>
                  <span className="hyd-large-num-ico-num">2</span>
                </div>
                <span className="step-title-active-header">Загрузить документы</span>
              </div>

              <Row style={{marginTop: 30, marginBottom: 30}}>
                {personDocs.map(item => uploadButton(
                  item.text, item.multiple, item.fileList, item.actionUpload, item.actionRemove,
                ))}
              </Row>

              <Row>
                <Checkbox
                  checked={true}
                  className={"hyd-norm-text mortgage-checkbox-disabled"}
                  disabled
                >
                  Согласен на обработку персональных данных
                </Checkbox>
              </Row>

              <Row>
                <Button
                  className="hyd-btn-small"
                  type="primary"
                  htmlType="submit"
                  style={{marginTop: 10}}
                  disabled={hasErrors(getFieldsError())}
                >
                  Отправить заявку
                </Button>
              </Row>

            </>
          }
        </Form>

        {isNeedCalcMortgage(banks, getFieldValue(mortgageBank)) && [
          {number: 2, text: "Данные о Заёмщике"},
          {number: 3, text: "Страхование"},
          {number: 4, text: "Финальные Условия"},
          {number: 5, text: "Оплата"},
        ].map(({number, text}) =>
          <div className="step-title-disabled-small" key={number}>
            <div className="step-icon-block">
              <NumberInactiveIcon className="hyd-large-num-ico"/>
              <span className="hyd-large-num-ico-num">{number}</span>
            </div>
            <span className="step-title-disabled-header">{text}</span>
          </div>
        )}

        <ModalError type={7}/>
        <ModalApplication/>
      </>
    )
  }

}

const WrappedFirstStepMortgageForm = Form.create({
    name: "mortgage-step-1",
    mapPropsToFields(props) {
      const {mortgageDataForm} = props;
      const transformed = transform({mortgageDataForm});

      return transformed.mortgageDataForm;
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields);
    },
  }
)(FirstStepMortgage);

const mapStateToProps = (state) => ({
  banks: banksSelector(state),
  fiasId: fiasIdSelector(state),
  userInfo: userInfoSelector(state),
  isRequest: isRequestSelector(state),
  errors: errorsMortgageSelector(state),
  currentStep: currentStepSelector(state),
  professions: professionsSelector(state),
  isLifeRiskChecked: isLifeRiskSelector(state),
  coBorrowersKeys: coBorrowersKeysSelector(state),
  passportFileList: passportFileListSelector(state),
  mortgageDataForm: mortgageDataFormSelector(state),
  isPropertyRiskChecked: isPropertyRiskSelector(state),
  precalculateResult: precalculateResultSelector(state),
  calculationInProgress: calculationInProgressSelector(state),
  isFormSuccessfullySent: isFormSuccessfullySentSelector(state),
  loanAgreementFileList: loanAgreementFileListSelector(state),
  evaluationReportFileList: evaluationReportFileListSelector(state),
  EGRNFileList: EGRNFileListSelector(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBanks,
      clearForm,
      getFiasId,
      setDataForm,
      goToNextStep,
      sendDataForm,
      getProfessions,
      removeCoBorrower,
      clearIsRequestState,
      uploadPassportFiles,
      removePassportFiles,
      uploadLoanAgreementFiles,
      removeLoanAgreementFiles,
      uploadEvaluationReportFiles,
      removeEvaluationReportFiles,
      uploadEGRNFiles,
      removeEGRNFiles,
      clearObjectAddressData,
      sendMortgageApplication,
      setDefaultPropertyShare,
      sendPrecalculateDataForm,
      toggleModalMortgageApplication,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WrappedFirstStepMortgageForm);

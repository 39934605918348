import initialState from '../store/initialState';
import injectReducer from '../store/injectReducer';
import * as types from '../store/actionTypes';
import {backDateFormat, frontDateFormat, validateNulls} from "../utils";
import * as moment from "moment";
import * as keys from "../routers/keys";

const SHOP_HOSTS = {
  "dev.sevains.ru": "devshop.sevains.ru",
  "lk.sevains.ru": "shop.sevains.ru",
  "test.sevains.ru": "testshop.sevains.ru",
}


function transformDataFromBackend(data, dataForm, regions) {
  validateNulls(data)
  for (let item in dataForm) {
    if (item === "supervisor_data") {
      dataForm[item] = data[item]
    } else if (item === "region") {
      dataForm[item] = {
        value: regions
          .filter(itm => itm.key === data[item])
          .map(itm => itm.text)[0],
        name: item,
        errors: false,
        validating: false,
        dirty: false
      }
    } else if (["passport_date", "birth_date"].includes(item)) {
      // date format conversion
      dataForm[item] = {
        value: data[item] && moment(data[item], backDateFormat).format(frontDateFormat),
        name: item,
        errors: false,
        validating: false,
        dirty: false
      }
    } else {
      dataForm[item] = {
        value: data[item],
        name: item,
        errors: false,
        validating: false,
        dirty: false
      }
    }
  }
  return dataForm
}

function transformDataForm(dataForm, dataBlock, regions) {
  let dataFormResult = {}
  let itemsToParse = []

  if (dataBlock === "profile-account") {
    itemsToParse = [
      "username",
      "account_type",
      "last_name",
      "first_name",
      "middle_name",
      "phone",
      "email",
      "region",
    ]
  } else if (dataBlock === "profile-personal-data") {
    itemsToParse = [
      "passport",
      "passport_issued_by",
      "passport_date",
      "passport_unite_code",
      "gender",
      "birth_date",
      "address",
      "inn",
      "snils",
    ]
  } else if (dataBlock === "profile-card-details") {
    itemsToParse = [
      "card_bank_num",
      "card_holder_name",
      "card_valid_date",
    ]
  } else if (dataBlock === "profile-bank-details") {
    itemsToParse = [
      "bank_recipient",
      "bank_account",
      "bank_name",
      "bank_bik",
      "bank_ks",
      "bank_division_code",
      "bank_division_address",
    ]
  }

  for (let item in dataForm) {
    if (itemsToParse.includes(item)) {
      if (item === "supervisor_data") {
        dataFormResult[item] = dataForm[item]
      } else if (item === "region") {
        dataFormResult[item] = regions
          .filter(itm => itm.text === dataForm[item]["value"])
          .map(itm => itm.key)[0]
      } else if (["phone", "passport", "snils", "passport_unite_code", "card_bank_num", "bank_account"].includes(item)) {
        // removing spaces
        dataFormResult[item] = dataForm[item]["value"].replace(/ /g, '')
      } else if (["passport_date", "birth_date"].includes(item)) {
        // date format conversion
        dataFormResult[item] = moment(dataForm[item]["value"], frontDateFormat).format(backDateFormat)
      } else if (["card_valid_date"].includes(item)) {
        dataFormResult[item] = moment(dataForm[item]["value"], "MM/YY").endOf('month').format(frontDateFormat)
      } else if (["card_holder_name"].includes(item)) {
        dataFormResult[item] = dataForm[item]["value"].toUpperCase()
      } else {
        dataFormResult[item] = dataForm[item]["value"]
      }
    }
  }
  return dataFormResult
}


export default injectReducer(initialState.profileReducer, {

  [types.SET_DATA_PROFILE_FORM]: (state, action) => ({
    ...state,
    dataForm: {...state.dataForm, ...action.payload.data}
  }),

  [types.TOGGLE_ERROR_MODAL]: (state, {payload}) => ({
    ...state,
    visibleErrorModal: payload,
  }),

  [types.GET_PROFILE_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.GET_PROFILE_SUCCESS]: (state, {payload}) => {
    const stateDataForm = initialState.profileReducer.dataForm
    const regions = state.regions
    let dataForm = transformDataFromBackend(payload, stateDataForm, regions)
    return {
      ...state,
      isRequest: false,
      errors: null,
      dataForm: {...dataForm},
    };
  },

  [types.GET_PROFILE_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    visibleErrorModal: true,
    errors: payload
  }),

  [types.GET_USER_KLADR_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_USER_KLADR_SUCCESS]: (state, action) => {
    let address = action.payload;

    action.payload.map((item, i) => {
      address[i].value = item.text
    })

    return {
      ...state,
      isRequest: false,
      userAddressArr: address,
      errors: null
    };
  },

  [types.GET_USER_KLADR_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.SAVE_USER_ADDRESS]: (state, action) => {
    let obj = {};
    let key1 = `user_fias_id`;
    let key2 = `user_address`;

    obj[key1] = action.payload.fias_id === null ? undefined : action.payload.fias_id;
    obj[key2] = action.payload.address === null ? undefined : action.payload.address;
    return {
      ...state,
      isRequest: false,
      dataForm: {...state.dataForm, ...obj},
      errors: null
    };
  },

  [types.SELECTED_USER_ADDRESS]: (state, {payload}) => {
    return {
      ...state,
      selectedUserAddress: payload
    }
  },

  [types.SAVE_CARD_PAYMENT_SYSTEM]: (state, {payload}) => {
    return {
      ...state,
      cardPaymentSystem: payload
    }
  },

  [types.EDIT_MODE]: (state, {payload}) => {
    return {
      ...state,
      isEditModeOn: payload
    }
  },

  [types.UPDATE_PROFILE]: (state, {payload}) => {
    const stateDataForm = state.dataForm
    const regions = state.regions
    let dataFormResult = transformDataForm(stateDataForm, payload, regions)

    return {
      ...state,
      dataFormResult: dataFormResult,
      updatingComplete: false,
    }
  },

  [types.UPDATE_PROFILE_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.UPDATE_PROFILE_SUCCESS]: state => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      updatingComplete: true,
      isEditModeOn: false,
    }
  },

  [types.UPDATE_PROFILE_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.RESET_PASSWORD_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    resetPasswordComplete: false,
  }),

  [types.RESET_PASSWORD_SUCCESS]: state => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      resetPasswordComplete: true,
    }
  },

  [types.RESET_PASSWORD_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.GET_REFERRAL_LINK_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    isGenerateRefLinkComplete: false,
  }),

  [types.GET_REFERRAL_LINK_SUCCESS]: (state, {payload}) => {
    let refLink = `${window.location.protocol}//${window.location.host}${keys.REGISTRATION}/?ref_id=${payload}`
    return {
      ...state,
      isRequest: false,
      errors: null,
      isGenerateRefLinkComplete: true,
      generatedReferralLink: refLink
    }
  },

  [types.GET_REFERRAL_LINK_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.GET_PRODUCT_REFERRAL_LINK_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    isGenerateRefLinkComplete: false,
  }),

  [types.GET_PRODUCT_REFERRAL_LINK_SUCCESS]: (state, {payload}) => {
    console.log(window.location.host)
    let host = SHOP_HOSTS[window.location.host]
    if (host === undefined){
      host = "shop.sevains.ru"
    }
    let refLink = `${window.location.protocol}//${host}/?ref_id=${payload}`
    return {
      ...state,
      isRequest: false,
      errors: null,
      isGenerateRefLinkComplete: true,
      generatedReferralLink: refLink
    }
  },

  [types.GET_PRODUCT_REFERRAL_LINK_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),


  [types.GET_PRODUCT_REFERRAL_QR_CODE_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    isGenerateRefLinkComplete: false,
    QRCodeImage: undefined,
  }),

  [types.GET_PRODUCT_REFERRAL_QR_CODE_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      QRCodeImage: payload,
      isRequest: false,
      errors: null,
    }
  },

  [types.GET_PRODUCT_REFERRAL_QR_CODE_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
    QRCodeImage: undefined,
  }),


  [types.GET_REGIONS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.GET_REGIONS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      regions: payload.map(_ => ({key: _.id, text: _.name, fias_id: _.fias_id})),
    };
  },

  [types.GET_REGIONS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    visibleErrorModal: true,
    errors: payload
  }),

});

import * as types from "../store/actionTypes";

export const goToNextStep = step => ({
  type: types.GO_TO_NEXT_STEP_MORTGAGE,
  payload: step
});

export const goToPrevStep = step => ({
  type: types.GO_TO_PREV_STEP_MORTGAGE,
  payload: step
});

export const setDataForm = (data, object) => ({
  type: types.SET_DATA_FORM_MORTGAGE,
  payload: {data, object}
});

export const sendPrecalculateDataForm = () => ({
  type: types.SEND_PRECALCULATE_DATA_FORM_MORTGAGE
});

export const sendDataForm = () => ({
  type: types.SEND_DATA_FORM_MORTGAGE
});

export const clearForm = (type) => ({
  type: types.CLEAR_FORM,
  payload: type,
});

export const clearIsRequestState = () => ({
  type: types.CLEAR_IS_REQUEST_STATE
});

export const getLastNameArray = (value, fio) => ({
  type: types.GET_LASTNAME_ARRAY_MORTGAGE,
  payload: value,
  fio: fio
});

export const defineGender = (type, owner_type, value) => ({
  type: types.DEFINE_GENDER_MORTGAGE,
  payload: {type, owner_type, value}
});

export const toggleSelectedRegAddress = value => ({
  type: types.MORTGAGE_SELECTED_REG_ADDRESS,
  payload: value
});

export const toggleSelectedObjectAddress = value => ({
  type: types.MORTGAGE_SELECTED_OBJECT_ADDRESS,
  payload: value
});

export const clearPolicyholderAddressData = () => ({
  type: types.CLEAR_POLICYHOLDER_ADDRESS_DATA_MORTGAGE
})

export const savePolicyholderAddressData = (
  address,
  country,
  region,
  zip,
  city_name,
  street,
  house,
  block,
  block_type,
  block_type_full,
  flat,
  fias_id,
  value_kladr,
  city_kladr,
  region_with_type,
  area_with_type,
  city_with_type,
  settlement_with_type,
  house_type,
  flat_type,
  region_kladr_id,
  area_kladr_id,
  city_kladr_id,
  settlement_kladr_id,
) => ({
  type: types.SAVE_POLICYHOLDER_ADDRESS_DATA_MORTGAGE,
  payload: {
    address,
    country,
    region,
    zip,
    city_name,
    street,
    house,
    block,
    block_type,
    block_type_full,
    flat,
    fias_id,
    value_kladr,
    city_kladr,
    region_with_type,
    area_with_type,
    city_with_type,
    settlement_with_type,
    house_type,
    flat_type,
    region_kladr_id,
    area_kladr_id,
    city_kladr_id,
    settlement_kladr_id,
  }
});

export const clearPolicyholderFactAddressData = () => ({
  type: types.CLEAR_POLICYHOLDER_FACT_ADDRESS_DATA_MORTGAGE
})

export const savePolicyholderFactAddressData = (
  address,
  country,
  region,
  zip,
  city_name,
  street,
  house,
  block,
  block_type,
  block_type_full,
  flat,
  fias_id,
  value_kladr,
  city_kladr,
  region_with_type,
  area_with_type,
  city_with_type,
  settlement_with_type,
  house_type,
  flat_type,
  region_kladr_id,
  area_kladr_id,
  city_kladr_id,
  settlement_kladr_id,
) => ({
  type: types.SAVE_POLICYHOLDER_FACT_ADDRESS_DATA_MORTGAGE,
  payload: {
    address,
    country,
    region,
    zip,
    city_name,
    street,
    house,
    block,
    block_type,
    block_type_full,
    flat,
    fias_id,
    value_kladr,
    city_kladr,
    region_with_type,
    area_with_type,
    city_with_type,
    settlement_with_type,
    house_type,
    flat_type,
    region_kladr_id,
    area_kladr_id,
    city_kladr_id,
    settlement_kladr_id,
  }
});

export const clearCoBorrowerAddressData = (k) => ({
  type: types.CLEAR_CO_BORROWER_ADDRESS_DATA_MORTGAGE,
  payload: k,
})

export const saveCoBorrowerAddressData = (k, value) => ({
  type: types.SAVE_CO_BORROWER_ADDRESS_DATA_MORTGAGE,
  payload: {k, value},
})

export const clearCoBorrowerFactAddressData = (k) => ({
  type: types.CLEAR_CO_BORROWER_FACT_ADDRESS_DATA_MORTGAGE,
  payload: k,
})

export const saveCoBorrowerFactAddressData = (k, value) => ({
  type: types.SAVE_CO_BORROWER_FACT_ADDRESS_DATA_MORTGAGE,
  payload: {k, value},
})

export const clearObjectAddressData = () => ({
  type: types.CLEAR_OBJECT_ADDRESS_DATA_MORTGAGE
})

export const saveObjectAddressData = (
  address,
  country,
  region,
  zip,
  city_name,
  street,
  house,
  block,
  block_type,
  block_type_full,
  flat,
  fias_id,
  value_kladr,
  city_kladr,
  region_with_type,
  area_with_type,
  city_with_type,
  settlement_with_type,
  house_type,
  flat_type,
  region_kladr_id,
  area_kladr_id,
  city_kladr_id,
  settlement_kladr_id,
) => ({
  type: types.SAVE_OBJECT_ADDRESS_DATA_MORTGAGE,
  payload: {
    address,
    country,
    region,
    zip,
    city_name,
    street,
    house,
    block,
    block_type_full,
    block_type,
    flat,
    fias_id,
    value_kladr,
    city_kladr,
    region_with_type,
    area_with_type,
    city_with_type,
    settlement_with_type,
    house_type,
    flat_type,
    region_kladr_id,
    area_kladr_id,
    city_kladr_id,
    settlement_kladr_id,
  }
});

export const getAddressData = (address_data, address_type) => {
  return ({
    type: types.GET_ADDRESS_DATA,
    address_data: address_data,
    address_type: address_type,
  })
}

export const clearFormStep3 = () => ({
  type: types.CLEAR_FORM_STEP_3
});

export const reConstructAddressStr = (value, name) => ({
  type: types.RE_CONSTRUCT_ADDRESS_STR,
  value: value,
  name: name,
});

export const createPolicy = value => ({
  type: types.CREATE_POLICY_MORTGAGE,
  payload: value
});

export const getPaymentStatus = value => ({
  type: types.GET_PAYMENT_STATUS_MORTGAGE,
  payload: value
});

export const getProfessions = () => ({
  type: types.GET_PROFESSIONS_MORTGAGE,
});

export const getEmploymentStatuses = () => ({
  type: types.GET_EMPLOYMENT_STATUSES,
});

export const getHealthAbnormalities = () => ({
  type: types.GET_HEALTH_ABNORMALITIES,
});

export const paymentLinkToEmail = value => ({
  type: types.PAYMENT_LINK_TO_EMAIL,
  payload: value
});

export const getBanks = () => ({
  type: types.GET_BANKS,
});

export const getFiasId = value => ({
  type: types.GET_FIAS_ID,
  payload: value,
});

export const addCoBorrower = () => ({
  type: types.ADD_CO_BORROWER,
});

export const removeCoBorrower = index => ({
  type: types.REMOVE_CO_BORROWER,
  payload: index,
});

export const setDefaultPropertyShare = () => ({
  type: types.SET_DEFAULT_PROPERTY_SHARE,
});

export const changeQuantityCoBorrowers = quantity => ({
  type: types.CHANGE_QUANTITY_CO_BORROWERS,
  payload: quantity
});

export const removeRiskAction = riskKey => ({
  type: types.REMOVE_RISK,
  payload: riskKey
});

export const calcPropertyShareSum = () => ({
  type: types.CALC_PROPERTY_SHARE_SUM,
});

export const uploadPassportFiles = (payload) => ({
    type: types.UPLOAD_PASSPORT_FILES,
    payload: payload,
})

export const removePassportFiles = (payload) => ({
    type: types.REMOVE_PASSPORT_FILES,
    payload: payload,
})

export const uploadLoanAgreementFiles = (payload) => ({
    type: types.UPLOAD_LOAN_AGREEMENT_FILE,
    payload: payload,
})

export const removeLoanAgreementFiles = (payload) => ({
    type: types.REMOVE_LOAN_AGREEMENT_FILE,
    payload: payload,
})

export const uploadEvaluationReportFiles = (payload) => ({
    type: types.UPLOAD_EVALUATION_REPORT_FILE,
    payload: payload,
})

export const removeEvaluationReportFiles = (payload) => ({
    type: types.REMOVE_EVALUATION_REPORT_FILE,
    payload: payload,
})

export const uploadEGRNFiles = (payload) => ({
    type: types.UPLOAD_EGRN_FILE,
    payload: payload,
})

export const removeEGRNFiles = (payload) => ({
    type: types.REMOVE_EGRN_FILE,
    payload: payload,
})

export const sendMortgageApplication = () => ({
  type: types.SEND_MORTGAGE_APPLICATION
})

export const toggleModalMortgageApplication = (value) => ({
  type: types.TOGGLE_MORTGAGE_APPLICATION_MODAL,
  payload: value,
})

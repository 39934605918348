import * as types from "../store/actionTypes";

export const getExportDocument = (start, end) => {
  let offset = new Date().getTimezoneOffset()
  return ({
    type: types.GET_EXPORT_DOCUMENT,
    payload: {start, end, offset},
  });
};

export const clearIsRequestState = () => ({
  type: types.CLEAR_IS_REQUEST_STATE
});

export const getContractsList = (start, end) => {
  let offset = new Date().getTimezoneOffset()
  return ({
    type: types.GET_CONTRACTS_LIST,
    payload: {start, end, offset},
  });
};

export const resumeAccidentsSport = (payload) => ({
  type: types.RESUME_POLICY_ACCIDENTS_SPORT,
  payload: payload,
});

export const copyPolicyAccidentsSport = (payload) => ({
  type: types.COPY_POLICY_ACCIDENTS_SPORT,
  payload: payload,
});

export const payPolicyAccidentsSport = (payload) => ({
  type: types.PAY_POLICY_ACCIDENTS_SPORT,
  payload: payload,
});

export const copyPolicyMortgage = (payload) => ({
  type: types.COPY_POLICY_MORTGAGE,
  payload: payload,
});

export const copyPolicyCross = (payload) => ({
  type: types.COPY_POLICY_CROSS,
  payload: payload,
});

export const resumeDraft = (payload) => ({
  type: types.RESUME_DRAFT,
  payload: payload,
});

export const resumeDraftMortgage = (payload) => ({
  type: types.RESUME_DRAFT_MORTGAGE,
  payload: payload,
});

export const resumeDraftCross = (payload) => ({
  type: types.RESUME_DRAFT_CROSS,
  payload: payload,
});

export const resumePolicy = (payload) => ({
  type: types.RESUME_POLICY,
  payload: payload,
});

export const resumePolicyProlongation = (payload) => ({
  type: types.RESUME_POLICY_PROLONGATION,
  payload: payload,
});

export const resumeDraftProlongation = (payload) => ({
  type: types.RESUME_DRAFT_PROLONGATION,
  payload: payload,
});

export const resumePolicyMortgage = (payload) => ({
  type: types.RESUME_POLICY_MORTGAGE,
  payload: payload,
});

export const resumePolicyCross = (payload) => ({
  type: types.RESUME_POLICY_CROSS,
  payload: payload,
});

export const copyPolicy = (payload) => ({
  type: types.COPY_POLICY,
  payload: payload,
});

export const selectPolicy = (id) => ({
  type: types.SELECT_POLICY,
  payload: id,
});

export const toggleViewDocsModal = value => ({
  type: types.TOGGLE_VIEW_DOCS_MODAL,
  payload: value
});

export const getPolicyDocs = ({policy_id, insurance_name, policy_number, obj_type, policy_obj_id}) => ({
  type: types.GET_POLICY_DOCS,
  payload: {policy_id, insurance_name, policy_number, obj_type, policy_obj_id}
});

export const checkPaymentStatusCommon = ({obj_id, obj_type}) => ({
  type: types.GET_PAYMENT_STATUS_COMMON,
  payload: {obj_id, obj_type}
});


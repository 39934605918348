export const HOME = '/';
export const OSAGO = '/osago';
export const ACCIDENTS_SPORT = '/accidents';
export const CONTRACTS = '/contracts';
export const AUTH = '/auth';
export const REGISTRATION = '/registration';
export const KBM = '/kbm';
export const CHECK_TO = '/check-to';
export const PROFILE = '/profile';
export const AGENTS = '/agents';
export const PROLONGATION = '/prolongation';
export const REFERRAL_LINK = '/referral_link';
export const MORTGAGE = '/mortgage';
export const CROSS = '/cross';
export const CASCO = '/casco';
export const ADDENDUM = '/addendum';
export const RENEWAL = '/renewal';

import * as types from "../store/actionTypes";


export const signIn = (value) => ({
    type: types.SIGN_IN,
    payload: value,
});

export const clearIsRequestState = () => ({
    type: types.CLEAR_IS_REQUEST_STATE
});

export const signOut = () => ({
    type: types.SIGN_OUT,
});

export const checkAuthentication = () => ({
    type: types.CHECK_AUTHENTICATION,
});

export const refreshToken = (value) => ({
    type: types.REFRESH_TOKEN,
    payload: value
});

export const refreshNewToken = (value, refresh) => ({
    type: types.REFRESH_NEW_TOKEN,
    payload: {value, refresh,}
});

export const getUserInfo = () => ({
    type: types.GET_USER_INFO
});

import React, {Component} from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  notification,
  Tabs,
  Slider,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
  goToNextStep,
  clearIsRequestState,
  setDataForm,
  sendDataForm,
  getRisksAndAttributes,
  toggleCrossKind,
} from "../../actions/crossActions";
import {
  isRequestSelector,
  currentStepSelector,
  crossDataFormSelector,
  errorsCrossSelector,
  limitSumsSelector,
  marksSelector,
  crossKindSelector,
} from "../../selectors/crossSelectors";
import ModalError from "../../components/ModalError";
import {
  transform,
  hasErrors,
  formItemLayout,
  transliterateSeries,
} from "../../utils";
import "./styles/index.scss";
import "../../fonts/open-sans.css"
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import {ReactComponent as NumberInactiveIcon} from "../../assets/Icons/NumberInactive.svg";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import Preloader from "../../components/Preloader";

const {TabPane} = Tabs;


class FirstStepCross extends Component {

  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  componentDidMount() {
    const {clearIsRequestState, getRisksAndAttributes} = this.props;
    getRisksAndAttributes()
    clearIsRequestState()
  }

  handleSubmit = (e) => {
    const {currentStep, goToNextStep, sendDataForm} = this.props;

    e.preventDefault()

    this.props.form.validateFields((err,) => {
      if (!err) {
        goToNextStep(currentStep + 1)
        sendDataForm()
      } else {
        notification["error"]({
          message: err
        })
      }
    })
  }

  changeTab = () => {
    this.props.form.setFieldsValue({
      "policy_series": "",
      "policy_number": "",
    })
  }

  render() {
    const {
      isRequest,
      limitSums,
      marks,
      crossKind,
      toggleCrossKind,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      getFieldValue,
    } = this.props.form;

    const insuredSum = "insured_sum";
    const insuredSumError = getFieldError(insuredSum);

    const policySeries = "policy_series";
    const policySeriesError = getFieldError(policySeries);

    const policyNumber = "policy_number";
    const policyNumberError = getFieldError(policyNumber);

    return (
      <>
        <div className={"cross-container"}>
          <Preloader loading={isRequest}/>

          <div className="step-title-active" ref={this.headerRef}>
            <div className="step-icon-block">
              <NumberIcon className="hyd-large-num-ico"/>
              <span className="hyd-large-num-ico-num">1</span>
            </div>
            <span className="step-title-active-header">Данные для расчёта КАСКО</span>
          </div>

          <Form {...formItemLayout}
                onSubmit={this.handleSubmit}
                id="cross-step-1"
          >

            <Tabs
              className="hyd-tab"
              defaultActiveKey={crossKind.toString()}
              onChange={(k) => {
                toggleCrossKind(parseInt(k))
                this.changeTab()
              }}
            >

              <TabPane tab={<span className="hyd-tab-pane">Демократичное</span>} key="1">
                <span className={"hyd-bold-text-small hyd-cross-sum"}>
                  Страховая сумма
                </span>
              </TabPane>

              <TabPane tab={<span className="hyd-tab-pane">Практичное</span>} key="2">
                <span className={"hyd-bold-text-small hyd-cross-sum"}>
                  Страховая сумма — {Number(getFieldValue("insured_sum")).toLocaleString("ru-RU")} ₽
                </span>
              </TabPane>

            </Tabs>

            {Array.isArray(limitSums) && crossKind === 1 &&
              <Form.Item
                validateStatus={insuredSumError ? "error" : ""}
                help={insuredSumError || ""}
                className={"slider"}
              >
                {getFieldDecorator(insuredSum, {
                  initialValue: getFieldValue(insuredSum),
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                  ]
                })(
                  <Slider
                    className={"hyd-cross-slider"}
                    trackStyle={{backgroundColor: "var(--color-four)"}}
                    handleStyle={{borderColor: "var(--color-seven)"}}
                    marks={marks}
                    step={100000}
                    min={Number(limitSums[0])}
                    max={Number(limitSums.at(-1))}
                    tooltipVisible={false}
                  />
                )}
              </Form.Item>}

            <div className={"hyd-cross-row"}>
              <Row gutter={8}>
                <Col span={4} className={"hyd-cross-span"}>
                  Полис СК Гелиос
                </Col>

                {/* Серия полиса */}
                <Col span={2} className={"hyd-cross-col"}>
                  <Form.Item
                    validateStatus={policySeriesError ? "error" : ""}
                    help={policySeriesError || ""}
                  >
                    <FloatingLabelInput
                      divClassName="hyd-input-cross"
                      htmlFor={"cross_" + policySeries}
                      labelText={"Серия"}
                      divId={"float-label-small"}
                      child={getFieldDecorator(policySeries, {
                        validateTrigger: ["onBlur", "onChange"],
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: " "
                          },
                          {
                            pattern: /((^[АВЕКМНОРСТУХавекмнорстух]{3})|(^[ABEKMHOPCTYXabekmhopctyx]{3}))$/g,
                            message: " "
                          }
                        ]
                      })(<Input
                        className="hyd-input-cross hyd-input-small"
                        maxLength={3}
                        onChange={(e) => {
                          e.target.value = transliterateSeries(e.target.value.toUpperCase())
                        }}
                      />)}
                    />
                  </Form.Item>
                </Col>

                {/* Номер полиса */}
                <Col span={1}>
                  <Form.Item
                    validateStatus={policyNumberError ? "error" : ""}
                    help={policyNumberError || ""}
                  >
                    <FloatingLabelInput
                      divClassName="hyd-input-cross-2"
                      htmlFor={"cross_" + policyNumber}
                      labelText={"Номер"}
                      divId={"float-label-small"}
                      child={getFieldDecorator(policyNumber, {
                        validateTrigger: ["onBlur", "onChange"],
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: " "
                          },
                          {
                            pattern: /^[0-9]{10}$/g,
                            message: " "
                          },
                        ],
                      })(<Input className="hyd-input-small hyd-input-cross-2" maxLength={10}/>)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            {/* Назад/Далее */}
            <Row gutter={8}>
              <Col span={10}>
                <Form.Item>
                  <Button
                    className="hyd-btn-small hyd-cross-btn-proceed"
                    type="primary"
                    htmlType="submit"
                    form="cross-step-1"
                    key="submit"
                    disabled={hasErrors(getFieldsError())}
                  >
                    Рассчитать
                  </Button>
                </Form.Item>
              </Col>
            </Row>

          </Form>
          {[
            {number: 2, text: "Расчёт"},
            {number: 3, text: "Оплата"},
          ].map(({number, text}) =>
            <div className="step-title-disabled-small" key={number}>
              <div className="step-icon-block">
                <NumberInactiveIcon className="hyd-large-num-ico"/>
                <span className="hyd-large-num-ico-num">{number}</span>
              </div>
              <span className="step-title-disabled-header">{text}</span>
            </div>
          )}

          <ModalError type={8}/>
        </div>
      </>
    )
  }

}

const WrappedFirstStepCrossForm = Form.create({
    name: "cross-step-1",
    mapPropsToFields(props) {
      const {crossDataForm} = props;
      const transformed = transform({crossDataForm});

      return transformed.crossDataForm;
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields);
    },
  }
)(FirstStepCross);

const mapStateToProps = (state) => ({
  isRequest: isRequestSelector(state),
  currentStep: currentStepSelector(state),
  errors: errorsCrossSelector(state),
  crossDataForm: crossDataFormSelector(state),
  limitSums: limitSumsSelector(state),
  marks: marksSelector(state),
  crossKind: crossKindSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToNextStep,
      clearIsRequestState,
      setDataForm,
      sendDataForm,
      getRisksAndAttributes,
      toggleCrossKind,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WrappedFirstStepCrossForm);

import initialState from "../store/initialState";
import injectReducer from "../store/injectReducer";
import * as types from "../store/actionTypes";
import dateFormat from "dateformat";
import {
  checkTOBtnByYear,
  identificationDocumentsOptions,
  transform,
  validateNulls,
  validateNullsDelete,
} from "../utils";

/* проверка не просрочен ли ТО */
function checkDateTO(date) {
  let currentDate = new Date();
  let result1 = dateFormat(currentDate, "isoDate"); //

  let result3 = dateFormat(date, "isoDate");

  return !(result1 < result3 || result1 === result3);
}

function clearKbm(dataForm) {
  return Object.keys(dataForm).reduce((object, key) => {
    //удаляем кбм
    if (key !== "kbm_coeff") {
      //если совпадения не найдены

      object[key] = dataForm[key];
    }
    return object;
  }, {});
}

function clearFormPolicyOwner(dataForm) {
  const arrayOfKeys = [
    "policy_owner_name",
    "policy_owner_gender",
    "policy_owner_middle_name",
    "policy_owner_birth_date",
    "policy_owner_identity_document",
    "policy_owner_serial",
    "policy_owner_number",
    "policy_owner_experience_date",
    "policy_owner_last_name",
    "policy_owner_issuer",
    "policy_owner_driving_experience_date",
    "policy_owner_address",
    // 'policy_owner_insured_accident_count',
    "policy_owner_document_type",
    "kbm_coeff",
    "policy_owner_cladr",
    "policy_owner_cladr_address",
    "policy_owner_cladr_okato",
    "policy_owner_cladr_country",
    "policy_owner_cladr_region",
    "policy_owner_cladr_zip",
    "policy_owner_cladr_city_name",
    "policy_owner_cladr_city_kladr",
    "policy_owner_cladr_street_kladr",
    "policy_owner_cladr_street_name",
    "policy_owner_cladr_house",
    "policy_owner_cladr_block",
    "policy_owner_cladr_flat",
    "policy_owner_fias_id",
    "policy_owner_house_fias_id",
    "document_series_number_policy_owner_serial_policy_owner_number",
  ];

  return Object.keys(dataForm).reduce((object, key) => {
    //удаляем данные с формы (шаг 2) - страхователь-физическое лицо
    const findData = arrayOfKeys.find(item => item === key);
    if (!findData) {
      //если совпадения не найдены
      object[key] = dataForm[key];
    } else if (initialState.osagoReducer.dataForm[key] !== undefined) {
      object[key] = initialState.osagoReducer.dataForm[key];
    }
    return object;
  }, {});
}

function clearFormPolicyOwnerJuristic(dataForm) {
  const arrayOfKeys = [
    "policy_owner_name_juristic",
    "policy_owner_series_juristic_document",
    "policy_owner_number_juristic_document",
    "policy_owner_date_of_juristic_issue_doc",
    "policy_owner_identify_number_juristic",
    "policy_owner_juristic_kpp",
    "policy_owner_juristic_ogrn",
    "policy_owner_cladr_juristic",
    "policy_owner_cladr",
    //'policy_owner_insured_accident_count',
    "kbm_coeff",
    "policy_owner_juristic_cladr_address",
    "policy_owner_juristic_address",
    "policy_owner_juristic_cladr_okato",
    "policy_owner_juristic_cladr_country",
    "policy_owner_juristic_cladr_region",
    "policy_owner_juristic_cladr_zip",
    "policy_owner_juristic_cladr_city_name",
    "policy_owner_juristic_cladr_city_kladr",
    "policy_owner_juristic_cladr_street_kladr",
    "policy_owner_juristic_cladr_street_name",
    "policy_owner_juristic_cladr_house",
    "policy_owner_juristic_cladr_block",
    "policy_owner_juristic_cladr_flat",
    "policy_owner_juristic_fias_id",
    "policy_owner_juristic_house_fias_id",
  ];

  return Object.keys(dataForm).reduce((object, key) => {
    //удаляем данные с формы (шаг 2) - страхователь-юридическое лицо

    const findData = arrayOfKeys.find(item => item === key);

    if (!findData) {
      //если совпадения не найдены
      object[key] = dataForm[key];
    } else if (initialState.osagoReducer.dataForm[key] !== undefined) {
      object[key] = initialState.osagoReducer.dataForm[key];
    }
    return object;
  }, {});
}

function clearFormCarOwner(dataForm) {
  const arrayOfData = [
    "car_owner_last_name",
    "car_owner_name",
    "car_owner_middle_name",
    "car_owner_gender",
    "car_owner_birth_date",
    "car_owner_phone",
    "car_owner_email",
    "identity_document",
    "car_owner_serial",
    "car_owner_number",
    "car_owner_experience_date",
    "car_owner_issuer",
    "car_owner_address",
    //'car_owner_insured_accident_count',
    "car_owner_cladr",
    "car_owner_cladr_address",
    "car_owner_cladr_okato",
    "car_owner_cladr_country",
    "car_owner_cladr_region",
    "car_owner_cladr_zip",
    "car_owner_cladr_city_name",
    "car_owner_cladr_city_kladr",
    "car_owner_cladr_street_kladr",
    "car_owner_cladr_street_name",
    "car_owner_cladr_house",
    "car_owner_cladr_block",
    "car_owner_cladr_flat",
    "car_owner_fias_id",
    "car_owner_house_fias_id",
    "document_series_number_car_owner_serial_car_owner_number",
    //"inshur",
  ];

  return Object.keys(dataForm).reduce((object, key) => {
    const findData = arrayOfData.find(item => item === key);

    //удаляем данные с формы (шаг 2) - собвственник-юридическое лицо
    if (!findData) {
      //если совпадения не найдены
      object[key] = dataForm[key];
    } else if (initialState.osagoReducer.dataForm[key] !== undefined) {
      object[key] = initialState.osagoReducer.dataForm[key];
    }
    return object;
  }, {});
}

function clearFormCarOwnerJuristic(dataForm) {
  const arrayOfData = [
    "car_owner_identify_number_juristic",
    "car_owner_series_juristic_document",
    "car_owner_number_juristic_document",
    "car_owner_date_of_juristic_issue_doc",
    "car_owner_name_juristic",
    "car_owner_kpp_juristic",
    "car_owner_cladr_juristic",
    "car_owner_juristic_cladr_address",
    "car_owner_juristic_address",
    "car_owner_juristic_cladr_okato",
    "car_owner_juristic_cladr_country",
    "car_owner_juristic_cladr_region",
    "car_owner_juristic_cladr_zip",
    "car_owner_juristic_cladr_city_name",
    "car_owner_juristic_cladr_city_kladr",
    "car_owner_juristic_cladr_street_kladr",
    "car_owner_juristic_cladr_street_name",
    "car_owner_juristic_cladr_house",
    "car_owner_juristic_cladr_block",
    "car_owner_juristic_cladr_flat",
    "car_owner_juristic_fias_id",
    "car_owner_juristic_house_fias_id",
    //"inshur",
  ];

  return Object.keys(dataForm).reduce((object, key) => {
    const findData = arrayOfData.find(item => item === key);
    //удаляем данные с формы (шаг 2) - собвственник-юридическое лицо
    if (!findData) {
      //если совпадения не найдены
      object[key] = dataForm[key];
    } else if (initialState.osagoReducer.dataForm[key] !== undefined) {
      object[key] = initialState.osagoReducer.dataForm[key];
    }
    return object;
  }, {});
}

function clearCarPlate(dataForm) {
  const arrayOfData = [
    "car_plate",
  ];

  return Object.keys(dataForm).reduce((object, key) => {
    const findData = arrayOfData.find(item => item === key);

    //удаляем данные о гос.номер авто в форме (шаг 1)
    if (!findData) {
      //если совпадения не найдены
      object[key] = dataForm[key];
    } else if (initialState.osagoReducer.dataForm[key] !== undefined) {
      object[key] = initialState.osagoReducer.dataForm[key];
    }
    return object;
  }, {});
}

function clearVIN(dataForm) {
  const arrayOfData = [
    "vin",
  ];

  return Object.keys(dataForm).reduce((object, key) => {
    const findData = arrayOfData.find(item => item === key);

    //удаляем данные о VIN авто в форме (шаг 1)
    if (!findData) {
      //если совпадения не найдены
      object[key] = dataForm[key];
    } else if (initialState.osagoReducer.dataForm[key] !== undefined) {
      object[key] = initialState.osagoReducer.dataForm[key];
    }
    return object;
  }, {});
}

function clearMarkModel(dataForm) {
  const arrayOfData = [
    "brand_car",
    "model_car",
  ];

  return Object.keys(dataForm).reduce((object, key) => {
    const findData = arrayOfData.find(item => item === key);

    //удаляем данные о Марке и Модели авто в форме (шаг 1)
    if (!findData) {
      //если совпадения не найдены
      object[key] = dataForm[key];
    } else if (initialState.osagoReducer.dataForm[key] !== undefined) {
      object[key] = initialState.osagoReducer.dataForm[key];
    }
    return object;
  }, {});
}

function clearDKdata(dataForm) {
  const arrayOfData = [
    "car_dk_date",
    "car_to_number",
  ];

  return Object.keys(dataForm).reduce((object, key) => {
    const findData = arrayOfData.find(item => item === key);

    //удаляем данные о ДК авто в форме (шаг 1)
    if (!findData) {
      //если совпадения не найдены
      object[key] = dataForm[key];
    } else if (initialState.osagoReducer.dataForm[key] !== undefined) {
      object[key] = initialState.osagoReducer.dataForm[key];
    }
    return object;
  }, {});
}

function clearBodyNumber(dataForm) {
  const arrayOfData = [
    "body_number",
  ];

  return Object.keys(dataForm).reduce((object, key) => {
    const findData = arrayOfData.find(item => item === key);

    //удаляем данные о номере кузова авто в форме (шаг 1)
    if (!findData) {
      //если совпадения не найдены
      object[key] = dataForm[key];
    } else if (initialState.osagoReducer.dataForm[key] !== undefined) {
      object[key] = initialState.osagoReducer.dataForm[key];
    }
    return object;
  }, {});
}

function clearChassisNumber(dataForm) {
  const arrayOfData = [
    "chassis_number",
  ];

  return Object.keys(dataForm).reduce((object, key) => {
    const findData = arrayOfData.find(item => item === key);

    //удаляем данные о номере шасси авто в форме (шаг 1)
    if (!findData) {
      //если совпадения не найдены
      object[key] = dataForm[key];
    } else if (initialState.osagoReducer.dataForm[key] !== undefined) {
      object[key] = initialState.osagoReducer.dataForm[key];
    }
    return object;
  }, {});
}

function clearListDrivers(dataForm) {
  return Object.keys(dataForm).reduce((object, key) => {
    //удаляем дополнительных водителей
    if (
      key.indexOf("additional_drivers_lastname_") === -1 &&
      key.indexOf("additional_drivers_name_") === -1 &&
      key.indexOf("additional_drivers_middlename_") === -1 &&
      key.indexOf("additional_drivers_birth_") === -1 &&
      key.indexOf("additional_drivers_country_") === -1 &&
      key.indexOf("additional_drivers_document_number_") === -1 &&
      key.indexOf("additional_drivers_document_serial_") === -1 &&
      key.indexOf("additional_drivers_kbm_") === -1 &&
      key.indexOf("additional_drivers_document_type_") === -1 &&
      key.indexOf("additional_drivers_document_date_") === -1 &&
      key.indexOf("additional_drivers_experience_date_") === -1 &&
      key.indexOf("additional_drivers_gender_") === -1 &&
      key.indexOf("additional_drivers_family_state_") === -1 &&
      key.indexOf("additional_drivers_old_document_number_") === -1 &&
      key.indexOf("additional_drivers_old_document_series_number_") === -1 &&
      key.indexOf("additional_drivers_old_document_date_") === -1 &&
      key.indexOf("additional_drivers_document_series_number_") === -1 &&
      key.indexOf("is_additional_drivers_old_") === -1 &&

      key.indexOf("additional_drivers_old_document_serial_") === -1
    ) {
      //если совпадения не найдены
      object[key] = dataForm[key];
    }
    return object;
  }, {});
}

function getDigits(str) {
  return parseInt(str.replace(/[^\d]/g, ""));
}

function transformDataFormWithListAdditionalDrivers(dataForm, dataFormResult) {
  let length = 0;
  let i = 0, j;
  let additional_drivers = [];
  let digits = [];

  Object.keys(dataForm).reduce((object, key) => {
    if (key.indexOf("additional_drivers_name_") !== -1) {
      //если совпадения найдены
      length++;
    }
  }, {});

  Object.keys(dataForm).reduce((object, key) => {
    //находим номера в названиях доп.водителей
    if (key.indexOf("additional_drivers_name_") !== -1) {
      let num = getDigits(key);
      if (!isNaN(getDigits(key))) digits.push(num);
    }
  }, {});


  for (i = 0, j = 0; i < length; i++, j++) {
    additional_drivers[j] = Object.keys(dataForm).reduce((object, key) => {
      //получаем массив с дополнительными водителями

      if (key.indexOf(`additional_drivers_lastname_${digits[i]}`) !== -1) {
        //если совпадения найдены
        object["last_name"] = dataForm[key];
      }
      if (key.indexOf(`additional_drivers_name_${digits[i]}`) !== -1) {
        //если совпадения найдены
        object["first_name"] = dataForm[key];
      }
      if (key.indexOf(`additional_drivers_middlename_${digits[i]}`) !== -1) {
        //если совпадения найдены
        object["middle_name"] = dataForm[key];
      }
      if (key.indexOf(`additional_drivers_birth_${digits[i]}`) !== -1) {
        //если совпадения найдены
        object["birth_date"] = dataForm[key];
      }
      if (
        key.indexOf(`additional_drivers_document_number_${digits[i]}`) !== -1
      ) {
        //если совпадения найдены
        object["number"] = dataForm[key];
      }
      if (
        key.indexOf(`additional_drivers_document_serial_${digits[i]}`) !== -1
      ) {
        //если совпадения найдены
        object["series"] = dataForm[key];
      }
      if (
        key.indexOf(`is_additional_drivers_old_${digits[i]}`) !== -1
      ) {
        //если совпадения найдены
        object["is_old"] = dataForm[key];
      }
      if (
        key.indexOf(`additional_drivers_old_document_number_${digits[i]}`) !== -1
      ) {
        //если совпадения найдены
        object["old_number"] = dataForm[key];
      }
      if (
        key.indexOf(`additional_drivers_old_document_serial_${digits[i]}`) !== -1
      ) {
        //если совпадения найдены
        object["old_series"] = dataForm[key];
      }
      if (key.indexOf(`additional_drivers_old_document_date_${digits[i]}`) !== -1) {
        //если совпадения найдены
        object["old_document_date"] = dataForm[key];
      }
      if (key.indexOf(`additional_drivers_document_type_${digits[i]}`) !== -1) {
        //если совпадения найдены
        object["document_type"] = dataForm[key];
      }
      if (key.indexOf(`additional_drivers_country_${digits[i]}`) !== -1) {
        object["country"] = dataForm[key]
      }
      if (key.indexOf(`additional_drivers_document_date_${digits[i]}`) !== -1) {
        //если совпадения найдены
        object["document_date"] = dataForm[key];
      }
      if (
        key.indexOf(`additional_drivers_experience_date_${digits[i]}`) !== -1
      ) {
        //если совпадения найдены
        object["experience_date"] = dataForm[key];
      }
      if (key.indexOf(`additional_drivers_gender_${digits[i]}`) !== -1) {
        //если совпадения найдены
        object["gender"] = dataForm[key];
      }
      // if (key.indexOf(`additional_drivers_insured_accident_count_${digits[i]}`) !== -1) {
      // 	//если совпадения найдены
      // 	object['insured_accident_count'] = dataForm[key];
      // }

      if (key.indexOf(`additional_drivers_kbm_${digits[i]}`) !== -1) {
        //если совпадения найдены
        object["driver_kbm"] = dataForm[key];
      }

      if (key.indexOf(`additional_drivers_family_state_${digits[i]}`) !== -1) {
        //если совпадения найдены
        object["family_state"] = dataForm[key];
      }

      return object;
    }, {});
  }

  dataFormResult = clearListDrivers(dataForm); //удаляем доп водителей как отдельные объекты
  if (additional_drivers) {
    additional_drivers = additional_drivers.map(driver => {
      Object.keys(driver).map(key => {
        driver[key] = driver[key] === undefined ? undefined : driver[key].value
      })
      return driver
    })
    dataFormResult["additional_drivers"] = additional_drivers;
  }
  //заносим доп.водителей в форме массива
  else {
    dataFormResult["additional_drivers"] = [];
  }

  return dataFormResult;
}

function construct_field(field_name, value, dirty = false) {
  return {
    value: value,
    name: field_name,
    errors: false,
    validating: false,
    dirty: dirty
  }
}

function clearProlongationData(data) {
  const arrayOfData = [
    "prolongation_policy_owner_birth_date",
    "prolongation_policy_owner_identify_number_juristic",
    "prolongation_previous_policy_serial",
    "prolongation_previous_policy_number",
    "prolongation_vin",
    "prolongation_chassis_number",
    "prolongation_body_number",
    "prolongation_policy_owner_last_name",
    "prolongation_policy_owner_name",
    "prolongation_policy_owner_middle_name",
    "is_all_companies_calculation",
    "is_prolongation_seva",
    "prolongation_policy_owner_inshur",
    "policy_owner_doc_series",
    "policy_owner_doc_number",
    "prolongation_previous_policy_insurance_company",
  ];

  const clearForm = Object.keys(data).reduce((object, key) => {
    const findData = arrayOfData.find(item => item === key);

    // удаляем данные о пролонгации полиса ОСАГО
    if (!findData) {
      // если совпадения не найдены
      object[key] = data[key];
    } else if (initialState.osagoReducer.dataForm[key] !== undefined) {
      object[key] = initialState.osagoReducer.dataForm[key];
    }
    return object;
  }, {});

  clearForm["prolongation_policy_owner_inshur"] = construct_field("prolongation_policy_owner_inshur", 1)

  return clearForm;
}

function construct_drivers_data(drivers_data) {
  let drivers_dataform = {}

  if (drivers_data.length > 0) {
    let keys = []
    for (let i = 0; i < drivers_data.length; i++) {
      keys = keys.concat(i)
      const current_driver = drivers_data[i]
      for (let item in current_driver) {
        if (item === "last_name") {
          drivers_dataform[`additional_drivers_lastname_${i}`] = construct_field(`additional_drivers_lastname_${i}`, current_driver[item])
        } else if (item === "first_name") {
          drivers_dataform[`additional_drivers_name_${i}`] = construct_field(`additional_drivers_name_${i}`, current_driver[item])
        } else if (item === "middle_name") {
          drivers_dataform[`additional_drivers_middlename_${i}`] = construct_field(`additional_drivers_middlename_${i}`, current_driver[item])
        } else if (item === "birth_date") {
          drivers_dataform[`additional_drivers_birth_${i}`] = construct_field(`additional_drivers_birth_${i}`, current_driver[item])
        } else if (item === "series") {
          drivers_dataform[`additional_drivers_document_serial_${i}`] = construct_field(`additional_drivers_document_serial_${i}`, current_driver[item])
        } else if (item === "number") {
          drivers_dataform[`additional_drivers_document_number_${i}`] = construct_field(`additional_drivers_document_number_${i}`, current_driver[item])
        } else if (item === "document_type") {
          drivers_dataform[`additional_drivers_document_type_${i}`] = construct_field(`additional_drivers_document_type_${i}`, current_driver[item])
        } else if (item === "is_old") {
          drivers_dataform[`is_additional_drivers_old_${i}`] = construct_field(`is_additional_drivers_old_${i}`, current_driver[item])
        } else if (item === "old_series") {
          drivers_dataform[`additional_drivers_old_document_serial_${i}`] = construct_field(`additional_drivers_old_document_serial_${i}`, current_driver[item])
        } else if (item === "old_number") {
          drivers_dataform[`additional_drivers_old_document_number_${i}`] = construct_field(`additional_drivers_old_document_number_${i}`, current_driver[item])
        } else if (item === "old_document_date") {
          drivers_dataform[`additional_drivers_old_document_date_${i}`] = construct_field(`additional_drivers_old_document_date_${i}`, current_driver[item])
        } else if (item === "gender") {
          drivers_dataform[`additional_drivers_gender_${i}`] = construct_field(`additional_drivers_gender_${i}`, current_driver[item])
        } else if (item === "document_date") {
          drivers_dataform[`additional_drivers_document_date_${i}`] = construct_field(`additional_drivers_document_date_${i}`, current_driver[item])
        } else if (item === "experience_date") {
          drivers_dataform[`additional_drivers_experience_date_${i}`] = construct_field(`additional_drivers_experience_date_${i}`, current_driver[item])
        } else if (item === "family_state") {
          drivers_dataform[`additional_drivers_family_state_${i}`] = construct_field(`additional_drivers_family_state_${i}`, current_driver[item])
        } else if (item === "country" && current_driver[item] !== undefined && current_driver[item] !== null) {
          drivers_dataform[`additional_drivers_country_${i}`] = construct_field(`additional_drivers_country_${i}`, current_driver[item].toString())
        }
      }
      if (current_driver["series"] && current_driver["number"]) {
        drivers_dataform[`additional_drivers_document_series_number_${i}`] = {
          value: `${current_driver["series"]} ${current_driver["number"]}`,
          name: `additional_drivers_document_series_number_${i}`,
          errors: false,
          validating: false,
          dirty: false
        }
      }
      if (current_driver["old_series"] && current_driver["old_number"]) {
        drivers_dataform[`additional_drivers_old_document_series_number_${i}`] = {
          value: `${current_driver["old_series"]} ${current_driver["old_number"]}`,
          name: `additional_drivers_old_document_series_number_${i}`,
          errors: false,
          validating: false,
          dirty: false
        }
      }
    }

    drivers_dataform["keys"] = construct_field("keys", keys)
    drivers_dataform["quantityAdditionalDrivers"] = keys.length

  }
  return drivers_dataform
}

const product_type_options = [
  {
    value: "OS",
    text: "bso"
  },
  {
    value: "EO",
    text: "eosago"
  },
  {
    value: "EB",
    text: "ebso"
  },
]

const addresses_list = [
  "policy_owner_cladr",
  "policy_owner_cladr_okato",
  "policy_owner_cladr_address",
  "policy_owner_cladr_country",
  "policy_owner_cladr_region",
  "policy_owner_cladr_zip",
  "policy_owner_cladr_city_name",
  "policy_owner_cladr_city_kladr",
  "policy_owner_cladr_street_kladr",
  "policy_owner_cladr_street_name",
  "policy_owner_cladr_house",
  "policy_owner_cladr_block",
  "policy_owner_cladr_flat",
  "policy_owner_fias_id",
  "policy_owner_house_fias_id",

  "policy_owner_juristic_cladr_zip",
  "policy_owner_juristic_address",
  "policy_owner_juristic_cladr_okato",
  "policy_owner_juristic_cladr_country",
  "policy_owner_juristic_cladr_region",
  "policy_owner_juristic_cladr_city_name",
  "policy_owner_juristic_cladr_city_kladr",
  "policy_owner_juristic_cladr_street_kladr",
  "policy_owner_juristic_cladr_street_name",
  "policy_owner_juristic_cladr_house",
  "policy_owner_juristic_cladr_block",
  "policy_owner_juristic_cladr_flat",
  "policy_owner_juristic_fias_id",
  "policy_owner_juristic_house_fias_id",

  "car_owner_cladr",
  "car_owner_cladr_address",
  "car_owner_cladr_okato",
  "car_owner_cladr_country",
  "car_owner_cladr_region",
  "car_owner_cladr_zip",
  "car_owner_cladr_city_name",
  "car_owner_cladr_city_kladr",
  "car_owner_cladr_street_kladr",
  "car_owner_cladr_street_name",
  "car_owner_cladr_house",
  "car_owner_cladr_block",
  "car_owner_cladr_flat",
  "car_owner_fias_id",
  "car_owner_house_fias_id",

  "car_owner_juristic_cladr_zip",
  "car_owner_juristic_address",
  "car_owner_juristic_cladr_okato",
  "car_owner_juristic_cladr_country",
  "car_owner_juristic_cladr_region",
  "car_owner_juristic_cladr_city_name",
  "car_owner_juristic_cladr_city_kladr",
  "car_owner_juristic_cladr_street_kladr",
  "car_owner_juristic_cladr_street_name",
  "car_owner_juristic_cladr_house",
  "car_owner_juristic_cladr_block",
  "car_owner_juristic_cladr_flat",
  "car_owner_juristic_fias_id",
  "car_owner_juristic_house_fias_id",
]

function construct_prolong_dataform(data, is_delete) {
  if (is_delete) {
    validateNullsDelete(data)
  } else {
    validateNulls(data)
  }
  return {
    ...initialState.osagoReducer.dataForm,
    prolongation_previous_policy_insurance_company: construct_field("prolongation_previous_policy_insurance_company", data["previous_policy_insurance_company"]),
    is_all_companies_calculation: construct_field("is_all_companies_calculation", data["is_all_companies_calculation"]),
    draft_id: construct_field("draft_id", data["draft_id"]),
    prolongation_previous_policy_serial: construct_field("prolongation_previous_policy_serial", data["previous_policy_serial"]),
    prolongation_previous_policy_number: construct_field("prolongation_previous_policy_number", data["previous_policy_number"]),
    prolongation_policy_owner_inshur: construct_field("prolongation_policy_owner_inshur", data["policy_owner_inshur"]),
    start_policy_date: construct_field("start_policy_date", data["start_policy_date"]),
    finish_policy_date: construct_field("finish_policy_date", data["finish_policy_date"]),
    prolongation_vin: construct_field("prolongation_vin", data["vin"]),
    prolongation_policy_owner_last_name: construct_field("prolongation_policy_owner_last_name", data["policy_owner_last_name"]),
    prolongation_policy_owner_name: construct_field("prolongation_policy_owner_name", data["policy_owner_name"]),
    prolongation_policy_owner_middle_name: construct_field("prolongation_policy_owner_middle_name", data["policy_owner_middle_name"]),
    policy_owner_doc_series: construct_field("policy_owner_doc_series", data["policy_owner_serial"]),
    policy_owner_doc_number: construct_field("policy_owner_doc_number", data["policy_owner_number"]),
    prolongation_policy_owner_identify_number_juristic: construct_field("prolongation_policy_owner_identify_number_juristic", data["policy_owner_identify_number_juristic"]),
    policy_owner_name_juristic: construct_field("policy_owner_name_juristic", data["policy_owner_name_juristic"]),
    prolongation_body_number: construct_field("prolongation_body_number", data["body_num"]),
    prolongation_chassis_number: construct_field("prolongation_chassis_number", data["chassis_num"]),
    prolongation_policy_owner_birth_date: construct_field("prolongation_policy_owner_birth_date", data["policy_owner_birth_date"]),
    transport_insurer: construct_field("transport_insurer", data["transport_insurer"]),
  }
}

function validateIdentityDocs(dataForm, identityDocKey, serialKey, numberKey) {
  if (!identificationDocumentsOptions.find(e => dataForm[identityDocKey].value === e.value)) {
    dataForm[serialKey] = construct_field(serialKey, undefined, true)
    dataForm[numberKey] = construct_field(numberKey, undefined, true)
  }
}

function construct_dataform(data, is_delete) {
  if (is_delete) {
    validateNullsDelete(data)
  } else {
    validateNulls(data)
  }
  let data_form = {...initialState.osagoReducer.dataForm}
  for (let item in data) {
    if (data[item] === "" && data_form[item] !== undefined) {
    } else if (item === "car_brand" && data[item]) {
      data_form[item] = construct_field(item, data[item])
      data_form["brand_car"] = construct_field("brand_car", data[item].toString())
    } else if (item === "car_model" && data[item]) {
      data_form[item] = construct_field(item, data[item])
      data_form["model_car"] = construct_field("model_car", data[item].toString())
    } else if (item === "car_modification" && data[item]) {
      data_form[item] = construct_field(item, data[item])
      data_form["modification_car"] = construct_field("modification_car", data[item].toString())
    } else if (item === "additional_drivers") {
      data_form = {...construct_drivers_data(data[item]), ...data_form}
    } else if (addresses_list.includes(item)) {
      data_form[item] = data[item]
    } else if (["purpose_use", "duration",].includes(item) && data[item]) {
      data_form[item] = construct_field(item, parseInt(data[item]))
    } else {
      data_form[item] = construct_field(item, data[item])
    }
  }
  data_form["policy_owner_address"] = construct_field("policy_owner_address", data_form["policy_owner_cladr_address"])
  data_form["policy_owner_juristic_cladr_address"] = construct_field("policy_owner_juristic_cladr_address", data_form["policy_owner_juristic_address"])
  data_form["car_owner_address"] = construct_field("car_owner_address", data_form["car_owner_cladr_address"])
  data_form["car_owner_juristic_cladr_address"] = construct_field("car_owner_juristic_cladr_address", data_form["car_owner_juristic_address"])
  data_form["is_prolongation"] = construct_field("is_prolongation", true)
  validateIdentityDocs(data_form, "policy_owner_identity_document", "policy_owner_serial", "policy_owner_number")
  if (data_form["policy_owner_serial"] && data_form["policy_owner_number"]) {
    data_form["document_series_number_policy_owner_serial_policy_owner_number"] = {
      value: `${data_form["policy_owner_serial"].value} ${data_form["policy_owner_number"].value}`,
      name: "document_series_number_policy_owner_serial_policy_owner_number",
      errors: false,
      validating: false,
      dirty: false
    }
  }
  validateIdentityDocs(data_form, "identity_document", "car_owner_serial", "car_owner_number")
  if (data_form["car_owner_serial"] && data_form["car_owner_number"] && data_form["car_owner_number"].value) {
    data_form["document_series_number_car_owner_serial_car_owner_number"] = {
      value: `${data_form["car_owner_serial"].value} ${data_form["car_owner_number"].value}`,
      name: "document_series_number_car_owner_serial_car_owner_number",
      errors: false,
      validating: false,
      dirty: false
    }
  }
  if (data_form["car_document_serial"] && data_form["car_document_number"] && data_form["car_document_serial"].value) {
    data_form["car_document_series_number"] = {
      value: `${data_form["car_document_serial"].value} ${data_form["car_document_number"].value}`,
      name: "car_document_series_number",
      errors: false,
      validating: false,
      dirty: false
    }
  } else if (data_form["car_document_number"]) {
    data_form["car_document_series_number"] = data_form["car_document_number"]
  }

  let pol_own_jur_doc_ser = "policy_owner_series_juristic_document"
  let pol_own_jur_doc_num = "policy_owner_number_juristic_document"
  let car_own_jur_doc_ser = "car_owner_series_juristic_document"
  let car_own_jur_doc_num = "car_owner_number_juristic_document"
  if (data_form["policy_owner_inshur"] && data_form["policy_owner_inshur"].value === 2) {
    data_form[pol_own_jur_doc_ser] = construct_field(pol_own_jur_doc_ser, data[pol_own_jur_doc_ser])
    data_form[pol_own_jur_doc_num] = construct_field(pol_own_jur_doc_num, data[pol_own_jur_doc_num])
    data_form[`united_${pol_own_jur_doc_ser}_${pol_own_jur_doc_num}`] = construct_field(
      `united_${pol_own_jur_doc_ser}_${pol_own_jur_doc_num}`, `${data[pol_own_jur_doc_ser]} ${data[pol_own_jur_doc_num]}`
    )
  }
  if (data_form["inshur"] && data_form["inshur"].value === 2) {
    data_form[car_own_jur_doc_ser] = construct_field(car_own_jur_doc_ser, data[car_own_jur_doc_ser])
    data_form[car_own_jur_doc_num] = construct_field(car_own_jur_doc_num, data[car_own_jur_doc_num])
    data_form[`united_${car_own_jur_doc_ser}_${car_own_jur_doc_num}`] = construct_field(
      `united_${car_own_jur_doc_ser}_${car_own_jur_doc_num}`, `${data[car_own_jur_doc_ser]} ${data[car_own_jur_doc_num]}`
    )
  }
  if (data_form["vehicle_type"].value !== 5 && data_form["number_of_seats"] !== undefined) {
    data_form["number_of_seats"].value = undefined
  }

  return data_form
}

function transformAddendumApplicationDataForm(dataForm, state) {
  let dataFormResult = {}

  dataFormResult["agent_email"] = dataForm.agent_email.value
  dataFormResult["insurance_company"] = dataForm.insurance_company.value
  dataFormResult["policy_series"] = dataForm.policy_series.value
  dataFormResult["policy_number"] = dataForm.policy_number.value
  dataFormResult["note"] = dataForm.note.value
  dataFormResult["add_driver"] = dataForm.add_driver.value
  dataFormResult["delete_driver"] = dataForm.delete_driver.value
  dataFormResult["change_number_sts"] = dataForm.change_number_sts.value
  dataFormResult["change_driver_license"] = dataForm.change_driver_license.value
  dataFormResult["add_or_change_reg_plate"] = dataForm.add_or_change_reg_plate.value
  dataFormResult["change_last_name"] = dataForm.change_last_name.value
  dataFormResult["prolongation_insurance_period"] = dataForm.prolongation_insurance_period.value
  dataFormResult["change_address"] = dataForm.change_address.value
  dataFormResult["addendum_documents"] = state.addendumFileList

  return dataFormResult
}

export default injectReducer(initialState.osagoReducer, {
  [types.PREV_STEP]: (state, action) => ({
    ...state,
    currentStep: action.payload
  }),

  [types.NEXT_STEP]: (state, action) => ({
    ...state,
    currentStep: action.payload
  }),

  [types.SET_DATA_FORM]: (state, action) => ({
    ...state,
    dataForm: {...state.dataForm, ...action.payload.data}
  }),

  [types.GET_INFO_BY_VIN]: state => ({
    ...state
  }),

  [types.GET_INFO_BY_VIN_REQUEST]: state => ({
    ...state,
    isRequest: true,
    reportuid: null,
    errors: undefined
  }),

  [types.GET_INFO_BY_VIN_SUCCESS]: (state, {payload}) => {
    let obj = null;

    let report = {
      car_plate: {
        value: state.withoutCarPlate ? undefined : payload.result.car_plate
      },
      vin: {
        value: state.withoutVIN ? undefined : payload.result.vin
      },
      car_year_issue: {
        value: payload.result.year
      },
      engine_power: {
        value: parseInt(payload.result.engine_power)
      },
      brand_avtocod_id: {
        value: payload.result.brand
      },
      model_avtocod_id: {
        value: payload.result.model
      },
      vehicle_category_avtocod: {
        value: payload.result.vehicle_category
      },
    };

    return {
      ...state,
      isRequest: false,
      dataForm: {
        ...state.dataForm,
        ...transform(report),
        ...obj
      },
      buttonTO: checkTOBtnByYear(payload.result.year),
      errors: null
    };
  },

  [types.GET_INFO_BY_VIN_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    reportuid: null,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.SEND_DATA_FORM]: state => {
    let dataFormResult = transformDataFormWithListAdditionalDrivers(
      state.dataForm,
      state.dataFormResult
    )

    if (state.dataForm.is_owner.value) {
      dataFormResult["car_owner_phone"] = state.dataForm.phone.value
      dataFormResult["car_owner_email"] = state.dataForm.email.value
      state.dataForm.insur = state.dataForm.policyOwnerInsur;
    }

    if (state.dataForm.is_foreign_vehicle && state.dataForm.is_foreign_vehicle.value) {
      dataFormResult["vehicle_country"] = "Армения"
    } else {
      dataFormResult["vehicle_country"] = "Россия"
    }

    if (state.dataForm.policy_owner_address && state.dataForm.policy_owner_address.value) {
      let valueAddr = state.dataForm.policy_owner_address.value;

      if (valueAddr.indexOf("_") !== -1) {
        valueAddr = valueAddr.split("_")[1];
      }

      dataFormResult.policy_owner_address.value = valueAddr;
    }

    if (state.dataForm.car_owner_address && state.dataForm.car_owner_address.value) {
      let valueAddr = state.dataForm.car_owner_address.value;

      if (valueAddr.indexOf("_") !== -1) {
        valueAddr = valueAddr.split("_")[1];
      }
      dataFormResult.car_owner_address.value = valueAddr;
    }

    return {
      ...state,
      dataFormResult: dataFormResult,
      calcResult: [],
      calculationComplete: false,
    }
  },

  [types.SEND_DATA_FORM_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.SEND_DATA_FORM_SUCCESS]: (state, {payload}) => {
    let result = payload.calc_results;
    let draft_id = payload.draft_id;
    let kbm_data_additional_drivers_tmp = undefined;
    let rosgosstrah_uuid = undefined;

    result.map(value => {
      // if(value.key === "reso") {
      // 	calc_id_tmp = value.calc_id;
      // }
      if (value.key === "vsk") {
        kbm_data_additional_drivers_tmp = value.kbm_data;
      }
      if (value.key === "rosgosstrah") {
        rosgosstrah_uuid = value.rosgosstrah_uuid;
      }
    });
    return {
      ...state,
      isRequest: false,
      calcResult: result,
      //calc_id: calc_id_tmp, //только для "РЕСО"
      kbm_data_additional_drivers: kbm_data_additional_drivers_tmp, //только для ВСК
      dataForm: {
        ...state.dataForm,
        draft_id,
      },
      dataFormResult: {
        ...state.dataFormResult, ...{
          rosgosstrah_uuid,
          draft_id,
        }
      },
      errors: null
    };
  },

  [types.SEND_DATA_FORM_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.ADD_CALC_RESULT]: (state, {payload}) => {
    return {
      ...state,
      calcResult: [...state.calcResult, payload.result],
    };
  },

  [types.ASSIGN_DRAFT_ID]: (state, {payload}) => {
    const draft_id = payload.draft_id
    return {
      ...state,
      dataForm: {
        ...state.dataForm,
        draft_id,
      },
      dataFormResult: {
        ...state.dataFormResult,
        draft_id,
      },
    };
  },

  [types.CALC_ERROR]: (state, {payload}) => ({
    ...state,
    errors: {errors: payload.errors},
    calculationInProgress: false,
    visibleErrorModal: true
  }),

  [types.GET_ONE_TIME_TOKEN_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.START_CALCULATION]: (state) => ({
    ...state,
    currentStep: 4,
    calculationInProgress: true
  }),

  [types.CALC_COMPLETE]: (state) => ({
    ...state,
    calculationInProgress: false,
    calculationComplete: true,
  }),

  [types.TOGGLE_TYPE_FORM_PERSON_STEP_TWO]: (state, {payload}) => ({
    ...state,
    typeFormPersonStepTwo: payload
  }),

  [types.TOGGLE_TYPE_FORM_POLICY_OWNER_STEP_TWO]: (state, {payload}) => ({
    ...state,
    typeFormPolicyOwnerStepTwo: payload
  }),

  [types.CLEAR_FORM_STEP_TWO]: (state, {payload}) => ({
    ...state,
    dataForm: payload
  }),

  [types.IS_POLICY_OWNER]: (state, {payload}) => ({
    ...state,
    policyOwner: payload
  }),

  [types.NUMBER_DRIVERS_STEP_THREE]: (state, {payload}) => ({
    ...state,
    numberDriversStepThree: payload
  }),

  [types.ADD_ADDITIONAL_DRIVERS_STEP_THREE]: (state, action) => ({
    ...state,
    additionalDriversStepThree: {
      ...state.additionalDriversStepThree,
      ...action.payload
    }
  }),

  [types.REMOVE_DRIVER_FROM_LIST_DRIVERS]: (state, action) => {
    //удаляем информацию про конкретного водителя
    let k = action.payload;
    let dataForm = state.dataForm;

    const newDataFormLastnameName = Object.keys(dataForm).reduce(
      (object, key) => {
        if (
          `additional_drivers_lastname_${k}` !== key &&
          `additional_drivers_name_${k}` !== key &&
          `additional_drivers_middlename_${k}` !== key &&
          `additional_drivers_birth_${k}` !== key &&
          `additional_drivers_document_number_${k}` !== key &&
          `additional_drivers_document_serial_${k}` !== key &&
          `additional_drivers_document_series_number_${k}` !== key &&
          `additional_drivers_kbm_${k}` !== key &&
          `additional_drivers_document_type_${k}` !== key &&
          `additional_drivers_document_date_${k}` !== key &&
          `additional_drivers_experience_date_${k}` !== key &&
          `additional_drivers_gender_${k}` !== key &&
          // `additional_drivers_insured_accident_count_${k}` !== key &&
          `additional_drivers_family_state_${k}` !== key &&
          `additional_drivers_old_document_date_${k}` !== key &&
          `additional_drivers_old_document_number_${k}` !== key &&
          `additional_drivers_old_document_serial_${k}` !== key &&
          `additional_drivers_old_document_series_number_${k}` !== key
        ) {
          object[key] = dataForm[key];
        }
        return object;
      },
      {}
    );

    return {
      ...state,
      dataForm: newDataFormLastnameName
    };
  },

  [types.CLEAR_LIST_DRIVERS]: (state, action) => {
    const additionalDriversClearExperience = clearListDrivers(state.dataForm);
    return {
      ...state,
      dataForm: additionalDriversClearExperience,
      dataFormResult: additionalDriversClearExperience
    };
  },

  [types.CHANGE_QUANTITY_ADDITIONAL_DRIVERS]: (state, {payload}) => ({
    ...state,
    quantityAdditionalDrivers: payload
  }),

  [types.GET_DRIVER_KBM]: (state, action) => ({
    ...state,
    driver_key: action.driver_key
  }),
  [types.GET_DRIVER_KBM_REQUEST]: state => ({
    ...state,
    isRequest: true,
    driver_kbm: "",
    errors: null
  }),

  [types.GET_DRIVER_KBM_SUCCESS]: (state, {payload}) => {
    let obj = {};
    let key = `additional_drivers_kbm_${state.driver_key}`;

    obj[key] = {name: key, value: payload.driver_kbm};

    return {
      ...state,
      isRequest: false,
      driver_kbm: payload.driver_kbm,
      dataForm: {...state.dataForm, ...obj},
      errors: null
    };
  },

  [types.GET_DRIVER_KBM_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    driver_kbm: "",
    errors: payload,
    visibleErrorModal: true
  }),

  [types.GET_OWNER_DRIVER_KBM_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_OWNER_DRIVER_KBM_SUCCESS]: (state, {payload}) => {
    let kbm_coeff = {
      kbm_coeff: {
        value: String(payload.driver_kbm),
        name: "kbm_coeff",
        errors: false,
        validating: false,
        dirty: false
      }
    };
    return {
      ...state,
      isRequest: false,

      dataForm: {...state.dataForm, ...kbm_coeff},
      errors: null
    };
  },

  [types.GET_OWNER_DRIVER_KBM_FAILURE]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: payload,
      visibleErrorModal: true
    };
  },

  [types.GET_CAR_OWNER_DRIVER_KBM_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_CAR_OWNER_DRIVER_KBM_SUCCESS]: (state, {payload}) => {
    let kbm_coeff = {
      car_owner_kbm_coeff: {
        value: String(payload.driver_kbm),
        name: "car_owner_kbm_coeff",
        errors: false,
        validating: false,
        dirty: false
      }
    };
    return {
      ...state,
      isRequest: false,
      dataForm: {...state.dataForm, ...kbm_coeff},
      errors: null
    };
  },

  [types.GET_CAR_OWNER_DRIVER_KBM_FAILURE]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: payload,
      //	dataForm: { ...state.dataForm, ...kbm_coeff },
      visibleErrorModal: true
    };
  },

  [types.GET_KBM_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_KBM_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      driverKbm: payload,
      visibleResultKbmModal: true,
      visibleKbmModal: false,
      errors: null
    };
  },

  [types.GET_KBM_FAILURE]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: payload,
      visibleResultKbmModal: false,
      visibleKbmModal: false,
      visibleErrorModal: true
    };
  },

  [types.TOGGLE_KBM_RESULT_MODAL]: (state, {payload}) => {
    return {
      ...state,
      visibleResultKbmModal: payload
    };
  },

  [types.GET_KLADR_POLICY_CAR_OWNER_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_KLADR_POLICY_CAR_OWNER_SUCCESS]: (state, action) => {
    //obj = { policy_owner_cladr: action.payload.kladr };
    //obj = { policy_owner_cladr: "77000000000" };
    let policyAdress = action.payload;

    action.payload.map((item, i) => {
      policyAdress[i].value_cladr = item.value;
      policyAdress[i].value = item.text;
    });

    return {
      ...state,
      isRequest: false,
      //dataForm: { ...state.dataForm, ...obj },
      policyAdressArr: policyAdress,
      errors: null
    };
  },

  [types.GET_KLADR_POLICY_CAR_OWNER_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    //	policyAdressArr: [{'text': "", 'value': "0",}],
    visibleErrorModal: true,
  }),

  [types.SAVE_POLICY_OWNER_CLADR]: (state, action) => {
    let obj = {};
    let key1 = `policy_owner_cladr`;
    let key2 = `policy_owner_cladr_address`;
    let key3 = `policy_owner_cladr_okato`;
    let key4 = `policy_owner_cladr_country`;
    let key5 = `policy_owner_cladr_region`;
    let key6 = `policy_owner_cladr_zip`;
    let key7 = `policy_owner_cladr_city_name`;
    let key8 = `policy_owner_cladr_city_kladr`;
    let key9 = `policy_owner_cladr_street_kladr`;
    let key10 = `policy_owner_cladr_street_name`;
    let key11 = `policy_owner_cladr_house`;
    let key12 = `policy_owner_cladr_block`;
    let key13 = `policy_owner_cladr_flat`;
    let key14 = `policy_owner_fias_id`;
    let key15 = `policy_owner_house_fias_id`;
    //	zip, city_kladr, street_kladr, street_name, house

    obj[key1] = action.payload.value === null ? undefined : action.payload.value;
    obj[key2] = action.payload.address === null ? undefined : action.payload.address;
    obj[key3] = action.payload.okato === null ? undefined : action.payload.okato;
    obj[key4] = action.payload.country === null ? undefined : action.payload.country;
    obj[key5] = action.payload.region === null ? undefined : action.payload.region;
    obj[key6] = action.payload.zip === null ? undefined : action.payload.zip;
    obj[key7] = action.payload.city_name === null ? undefined : action.payload.city_name;
    obj[key8] = action.payload.city_kladr === null ? undefined : action.payload.city_kladr;
    obj[key9] = action.payload.street_kladr === null ? undefined : action.payload.street_kladr;
    obj[key10] = action.payload.street_name === null ? undefined : action.payload.street_name;
    obj[key11] = action.payload.house === null ? undefined : action.payload.house;
    obj[key12] = action.payload.block === null ? undefined : action.payload.block;
    obj[key13] = action.payload.flat === null ? undefined : action.payload.flat;
    obj[key14] = action.payload.fias_id === null ? undefined : action.payload.fias_id;
    obj[key15] = action.payload.house_fias_id === null ? undefined : action.payload.house_fias_id;
    return {
      ...state,
      isRequest: false,
      dataForm: {...state.dataForm, ...obj},
      //	adressArr: action.payload,
      errors: null
    };
  },

  [types.GET_KLADR_CAR_OWNER_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_KLADR_CAR_OWNER_SUCCESS]: (state, action) => {

    let adressArr = action.payload;

    action.payload.map((item, i) => {
      adressArr[i].value_cladr = item.value;
      adressArr[i].value = item.text;
    });

    return {
      ...state,
      isRequest: false,
      //dataForm: { ...state.dataForm, ...obj },
      //adressArr: action.payload,
      adressArr: adressArr,
      errors: null
    };
  },

  [types.GET_KLADR_CAR_OWNER_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
  }),

  [types.SAVE_CLADR]: (state, action) => {
    let obj = {};
    let key1 = `car_owner_cladr`;
    let key2 = `car_owner_cladr_address`;
    let key3 = `car_owner_cladr_okato`;
    let key4 = `car_owner_cladr_country`;
    let key5 = `car_owner_cladr_region`;
    let key6 = `car_owner_cladr_zip`;
    let key7 = `car_owner_cladr_city_name`;
    let key8 = `car_owner_cladr_city_kladr`;
    let key9 = `car_owner_cladr_street_kladr`;
    let key10 = `car_owner_cladr_street_name`;
    let key11 = `car_owner_cladr_house`;
    let key12 = `car_owner_cladr_block`;
    let key13 = `car_owner_cladr_flat`;
    let key14 = `car_owner_fias_id`;
    let key15 = `car_owner_house_fias_id`;

    //obj = { car_owner_cladr: action.payload };
    obj[key1] = action.payload.value === null ? undefined : action.payload.value;
    obj[key2] = action.payload.address === null ? undefined : action.payload.address;
    obj[key3] = action.payload.okato === null ? undefined : action.payload.okato;
    obj[key4] = action.payload.country === null ? undefined : action.payload.country;
    obj[key5] = action.payload.region === null ? undefined : action.payload.region;
    obj[key6] = action.payload.zip === null ? undefined : action.payload.zip;
    obj[key7] = action.payload.city_name === null ? undefined : action.payload.city_name;
    obj[key8] = action.payload.city_kladr === null ? undefined : action.payload.city_kladr;
    obj[key9] = action.payload.street_kladr === null ? undefined : action.payload.street_kladr;
    obj[key10] = action.payload.street_name === null ? undefined : action.payload.street_name;
    obj[key11] = action.payload.house === null ? undefined : action.payload.house;
    obj[key12] = action.payload.block === null ? undefined : action.payload.block;
    obj[key13] = action.payload.flat === null ? undefined : action.payload.flat;
    obj[key14] = action.payload.fias_id === null ? undefined : action.payload.fias_id;
    obj[key15] = action.payload.house_fias_id === null ? undefined : action.payload.house_fias_id;
    return {
      ...state,
      isRequest: false,
      dataForm: {...state.dataForm, ...obj},
      //	adressArr: action.payload,
      errors: null
    };
  },

  [types.GET_TO_INFO_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_TO_INFO_SUCCESS]: (state, {payload}) => {
    let date = payload.date;
    let visibleModal;
    let checked = checkDateTO(date);
    //let checked = checkDateTO("2020-03-29");
    visibleModal = checkDateTO(date) || !date;
    let report = {
      car_dk_date: {
        value: checked === false ? dateFormat(payload.date, "dd-mm-yyyy") : ''
      },
      car_to_number: {
        value: checked === false ? payload.number : ''
      }
    }

    return {
      ...state,
      isRequest: false,
      infoTO: payload,
      dataForm: {
        ...state.dataForm,
        ...transform(report),
      },

      visibleChoiceTOModal: visibleModal,
      //visibleChoiceTOModal: true,
      errors: null
    };
  },

  [types.GET_TO_INFO_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.GET_ALFA_PROLONGATION_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    calcResult: undefined,
    dataFormResult: undefined,
    isProlongDisabledButtons: true,
    isProlongDisabledFields: true,
    isProlongCalcSuccess: false,
  }),

  [types.GET_ALFA_PROLONGATION_SUCCESS]: (state, {payload}) => {
    const dataForm = state.dataForm;
    return {
      ...state,
      isRequest: false,
      calcResult: payload["calc_results"],
      dataForm: {...dataForm, draft_id: payload.draft_id},
      dataFormResult: payload,
      isProlongDisabledButtons: false,
      isProlongCalcSuccess: true,
      isProlongDisabledFields: !payload["is_rejected"],
      errors: null
    };
  },

  [types.GET_ALFA_PROLONGATION_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
    calcResult: undefined,
    dataFormResult: undefined,
    isProlongDisabledButtons: false,
    isProlongDisabledFields: false,
    isProlongCalcSuccess: false,
  }),

  [types.GET_ALFA_AGREEMENT_DATA_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_ALFA_AGREEMENT_DATA_SUCCESS]: (state, {payload}) => {
    let dataForm = construct_dataform(payload, false)
    let selectedAdress = dataForm["policy_owner_inshur"].value === 1 && dataForm["policy_owner_cladr"] && true
    let selectedCarOwnerAdress = dataForm["inshur"] && dataForm["inshur"].value === 1 && dataForm["car_owner_cladr"] && true
    let selectedPolicyOwnerJuristicAdress = dataForm["policy_owner_inshur"] && dataForm["policy_owner_inshur"].value === 2 && dataForm["policy_owner_cladr_juristic"] && true
    let selectedCarOwnerJuristicAdress = dataForm["inshur"] && dataForm["inshur"].value === 2 && dataForm["car_owner_cladr_juristic"] && true
    let quantityAdditionalDrivers = 0
    if (dataForm["quantityAdditionalDrivers"] !== undefined) {
      quantityAdditionalDrivers = dataForm["quantityAdditionalDrivers"]
    }
    return {
      ...state,
      isRequest: false,
      dataForm: dataForm,
      selectedAdress: selectedAdress,
      selectedCarOwnerAdress: selectedCarOwnerAdress,
      selectedPolicyOwnerJuristicAdress: selectedPolicyOwnerJuristicAdress,
      selectedCarOwnerJuristicAdress: selectedCarOwnerJuristicAdress,
      quantityAdditionalDrivers: quantityAdditionalDrivers,
      withoutVIN: dataForm["vin"] === undefined,
      errors: null,
    };
  },

  [types.GET_ALFA_AGREEMENT_DATA_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.GET_VSK_PROLONGATION_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    calcResult: undefined,
    dataFormResult: undefined,
    isProlongDisabledButtons: true,
    isProlongDisabledFields: true,
    isProlongCalcSuccess: false,
  }),

  [types.GET_VSK_PROLONGATION_SUCCESS]: (state, {payload}) => {
    const dataForm = state.dataForm;
    return {
      ...state,
      isRequest: false,
      calcResult: payload["calc_results"],
      dataForm: {...dataForm, draft_id: payload.draft_id},
      dataFormResult: payload,
      isProlongDisabledButtons: false,
      isProlongCalcSuccess: true,
      isProlongDisabledFields: !payload["is_rejected"],
      errors: null
    };
  },

  [types.GET_VSK_PROLONGATION_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
    calcResult: undefined,
    dataFormResult: undefined,
    isProlongDisabledButtons: false,
    isProlongDisabledFields: false,
    isProlongCalcSuccess: false,
  }),

  [types.GET_VSK_AGREEMENT_DATA_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_VSK_AGREEMENT_DATA_SUCCESS]: (state, {payload}) => {
    let dataForm = construct_dataform(payload, false)
    let selectedAdress = dataForm["policy_owner_inshur"].value === 1 && dataForm["policy_owner_cladr"] && true
    let selectedCarOwnerAdress = dataForm["inshur"] && dataForm["inshur"].value === 1 && dataForm["car_owner_cladr"] && true
    let selectedPolicyOwnerJuristicAdress = dataForm["policy_owner_inshur"] && dataForm["policy_owner_inshur"].value === 2 && dataForm["policy_owner_cladr_juristic"] && true
    let selectedCarOwnerJuristicAdress = dataForm["inshur"] && dataForm["inshur"].value === 2 && dataForm["car_owner_cladr_juristic"] && true
    let quantityAdditionalDrivers = 0
    if (dataForm["quantityAdditionalDrivers"] !== undefined) {
      quantityAdditionalDrivers = dataForm["quantityAdditionalDrivers"]
    }
    return {
      ...state,
      isRequest: false,
      dataForm: dataForm,
      selectedAdress: selectedAdress,
      selectedCarOwnerAdress: selectedCarOwnerAdress,
      selectedPolicyOwnerJuristicAdress: selectedPolicyOwnerJuristicAdress,
      selectedCarOwnerJuristicAdress: selectedCarOwnerJuristicAdress,
      quantityAdditionalDrivers: quantityAdditionalDrivers,
      withoutVIN: dataForm["vin"] === undefined,
      errors: null,
    };
  },

  [types.GET_VSK_AGREEMENT_DATA_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),


  [types.GET_SEVA_PROLONGATION_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_SEVA_PROLONGATION_SUCCESS]: (state, {payload}) => {
    let dataForm = construct_dataform(payload.draft, true)
    let selectedAdress = dataForm["policy_owner_inshur"].value === 1 && dataForm["policy_owner_cladr"] && true
    let selectedCarOwnerAdress = dataForm["inshur"] && dataForm["inshur"].value === 1 && dataForm["car_owner_cladr"] && true
    let selectedPolicyOwnerJuristicAdress = dataForm["policy_owner_inshur"] && dataForm["policy_owner_inshur"].value === 2 && dataForm["policy_owner_cladr_juristic"] && true
    let selectedCarOwnerJuristicAdress = dataForm["inshur"] && dataForm["inshur"].value === 2 && dataForm["car_owner_cladr_juristic"] && true
    let quantityAdditionalDrivers = 0
    if (dataForm["quantityAdditionalDrivers"] !== undefined) {
      quantityAdditionalDrivers = dataForm["quantityAdditionalDrivers"]
    }
    dataForm["phone"].value = (dataForm["phone"].value !== undefined && dataForm["phone"].value.includes("7")) ? (dataForm["phone"].value.includes("+") ? dataForm["phone"].value : "+" + dataForm["phone"].value) : undefined
    dataForm["car_owner_phone"].value = (dataForm["car_owner_phone"].value !== undefined && dataForm["car_owner_phone"].value.includes("7")) ? (dataForm["car_owner_phone"].value.includes("+") ? dataForm["car_owner_phone"].value : "+" + dataForm["car_owner_phone"].value) : undefined

    return {
      ...state,
      isRequest: false,
      dataForm: {
        ...dataForm,
        draft_id: undefined
      },
      selectedAdress: selectedAdress,
      selectedCarOwnerAdress: selectedCarOwnerAdress,
      selectedPolicyOwnerJuristicAdress: selectedPolicyOwnerJuristicAdress,
      selectedCarOwnerJuristicAdress: selectedCarOwnerJuristicAdress,
      quantityAdditionalDrivers: quantityAdditionalDrivers,
      withoutVIN: dataForm["vin"] === undefined,
      errors: null,
      currentStep: 0,
    };
  },

  [types.GET_SEVA_PROLONGATION_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),


  [types.GET_RENESSANS_PROLONGATION_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    calcResult: undefined,
    dataFormResult: undefined,
    isProlongDisabledButtons: true,
    isProlongDisabledFields: true,
    isProlongCalcSuccess: false,
  }),

  [types.GET_RENESSANS_PROLONGATION_SUCCESS]: (state, {payload}) => {
    const dataForm = state.dataForm;
    return {
      ...state,
      isRequest: false,
      calcResult: payload["calc_results"],
      dataForm: {...dataForm, draft_id: payload.draft_id},
      dataFormResult: payload,
      isProlongDisabledButtons: false,
      isProlongCalcSuccess: true,
      isProlongDisabledFields: !payload["is_rejected"],
      errors: null
    };
  },

  [types.GET_RENESSANS_PROLONGATION_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
    calcResult: undefined,
    dataFormResult: undefined,
    isProlongDisabledButtons: false,
    isProlongDisabledFields: false,
    isProlongCalcSuccess: false,
  }),

  [types.GET_RENESSANS_AGREEMENT_DATA_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_RENESSANS_AGREEMENT_DATA_SUCCESS]: (state, {payload}) => {
    let dataForm = construct_dataform(payload, false)
    let selectedAdress = dataForm["policy_owner_inshur"].value === 1 && dataForm["policy_owner_cladr"] && true
    let selectedCarOwnerAdress = dataForm["inshur"] && dataForm["inshur"].value === 1 && dataForm["car_owner_cladr"] && true
    let selectedPolicyOwnerJuristicAdress = dataForm["policy_owner_inshur"] && dataForm["policy_owner_inshur"].value === 2 && dataForm["policy_owner_cladr_juristic"] && true
    let selectedCarOwnerJuristicAdress = dataForm["inshur"] && dataForm["inshur"].value === 2 && dataForm["car_owner_cladr_juristic"] && true
    let quantityAdditionalDrivers = 0
    if (dataForm["quantityAdditionalDrivers"] !== undefined) {
      quantityAdditionalDrivers = dataForm["quantityAdditionalDrivers"]
    }
    return {
      ...state,
      isRequest: false,
      dataForm: dataForm,
      selectedAdress: selectedAdress,
      selectedCarOwnerAdress: selectedCarOwnerAdress,
      selectedPolicyOwnerJuristicAdress: selectedPolicyOwnerJuristicAdress,
      selectedCarOwnerJuristicAdress: selectedCarOwnerJuristicAdress,
      quantityAdditionalDrivers: quantityAdditionalDrivers,
      withoutVIN: dataForm["vin"] === undefined,
      errors: null,
    };
  },

  [types.GET_RENESSANS_AGREEMENT_DATA_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.GET_INGOS_AGREEMENT_DATA_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_INGOS_AGREEMENT_DATA_SUCCESS]: (state, {payload}) => {
    let dataForm = construct_dataform(payload, false)
    let selectedAdress = dataForm["policy_owner_inshur"].value === 1 && dataForm["policy_owner_cladr"] && true
    let selectedCarOwnerAdress = dataForm["inshur"] && dataForm["inshur"].value === 1 && dataForm["car_owner_cladr"] && true
    let selectedPolicyOwnerJuristicAdress = dataForm["policy_owner_inshur"] && dataForm["policy_owner_inshur"].value === 2 && dataForm["policy_owner_cladr_juristic"] && true
    let selectedCarOwnerJuristicAdress = dataForm["inshur"] && dataForm["inshur"].value === 2 && dataForm["car_owner_cladr_juristic"] && true
    let quantityAdditionalDrivers = 0
    if (dataForm["quantityAdditionalDrivers"] !== undefined) {
      quantityAdditionalDrivers = dataForm["quantityAdditionalDrivers"]
    }

    return {
      ...state,
      isRequest: false,
      dataForm: dataForm,
      selectedAdress: selectedAdress,
      selectedCarOwnerAdress: selectedCarOwnerAdress,
      selectedPolicyOwnerJuristicAdress: selectedPolicyOwnerJuristicAdress,
      selectedCarOwnerJuristicAdress: selectedCarOwnerJuristicAdress,
      errors: null,
      visibleProlongationModal: false,
      quantityAdditionalDrivers: quantityAdditionalDrivers,
      withoutVIN: dataForm["vin"] === undefined,
    };
  },

  [types.GET_INGOS_AGREEMENT_DATA_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.GET_INGOS_PROLONGATION_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    calcResult: undefined,
    dataFormResult: undefined,
    isProlongDisabledButtons: true,
    isProlongCalcSuccess: false,
    isProlongDisabledFields: true,
  }),

  [types.GET_INGOS_PROLONGATION_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      calcResult: payload["calc_results"],
      dataFormResult: payload,
      isProlongDisabledButtons: false,
      isProlongCalcSuccess: true,
      isProlongDisabledFields: !payload["is_rejected"],
      errors: null,
    }
  },

  [types.GET_INGOS_PROLONGATION_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
    calcResult: undefined,
    dataFormResult: undefined,
    isProlongDisabledButtons: false,
    isProlongCalcSuccess: false,
    isProlongDisabledFields: false,
  }),

  [types.CHANGE_DATE_INFO_TO]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      infoTO: {
        date: state.dataForm.car_dk_date.value,
        number: state.dataForm.car_to_number.value,
      },
    }
  },

  [types.GET_CAR_BRANDS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_CAR_BRANDS_SUCCESS]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    car_brands: payload,
    errors: null
  }),

  [types.GET_CAR_BRANDS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.GET_INSURANCES_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_INSURANCES_SUCCESS]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    insurances: payload,
    errors: null
  }),

  [types.GET_INSURANCES_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.GET_COUNTRIES_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_COUNTRIES_SUCCESS]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    countries: payload.map((item) => ({text: item.name, value: item.id})),
    errors: null
  }),

  [types.GET_COUNTRIES_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.GET_CAR_MODELS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_CAR_MODELS_SUCCESS]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    car_models: payload,
    errors: null
  }),

  [types.GET_CAR_MODELS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.GET_MODEL_MODIFICATIONS_REQUEST]: state => ({
    ...state,
    isRequest: false,
    errors: null
  }),

  [types.GET_MODEL_MODIFICATIONS_SUCCESS]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    modelModifications: payload,
    errors: null
  }),

  [types.GET_MODEL_MODIFICATIONS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),


  [types.GET_INFO_POLICY_OWNER_BY_INN_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_INFO_POLICY_OWNER_BY_INN_SUCCESS]: (state, {payload}) => {
    let obj = {};
    let key = `policy_owner_name_juristic`;
    obj[key] = {name: key, value: payload.name};
    let key2 = `policy_owner_juristic_kpp`;
    obj[key2] = {name: key2, value: payload.kpp};
    let key3 = `policy_owner_cladr_juristic`;
    obj[key3] = {name: key3, value: payload.cladr};
    let key4 = `policy_owner_juristic_ogrn`;
    obj[key4] = {name: key4, value: payload.ogrn};
    return {
      ...state,
      isRequest: false,
      infoPolicyOwnerJuristic: payload,
      dataForm: {...state.dataForm, ...obj},
      errors: null
    };
  },

  [types.GET_INFO_POLICY_OWNER_BY_INN_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.GET_INFO_CAR_OWNER_BY_INN_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_INFO_CAR_OWNER_BY_INN_SUCCESS]: (state, {payload}) => {
    let obj = {};
    let key = `car_owner_name_juristic`;
    obj[key] = {name: key, value: payload.name};
    let key2 = `car_owner_kpp_juristic`;
    obj[key2] = {name: key2, value: payload.kpp};
    let key3 = `car_owner_cladr_juristic`;
    obj[key3] = {name: key3, value: payload.cladr};
    let key4 = `car_owner_juristic_ogrn`;
    obj[key4] = {name: key4, value: payload.ogrn};
    return {
      ...state,
      isRequest: false,
      dataForm: {...state.dataForm, ...obj},
      errors: null
    };
  },

  [types.GET_INFO_CAR_OWNER_BY_INN_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.CLEAR_DATA_POLICY_OWNER]: state => {
    let dataFormResult = clearFormPolicyOwner(state.dataForm);

    return {
      ...state,
      dataForm: dataFormResult
    };
  },

  [types.CLEAR_DATA_POLICY_OWNER_JURISTIC]: state => {
    let dataFormResult = clearFormPolicyOwnerJuristic(state.dataForm);

    return {
      ...state,
      dataForm: dataFormResult
    };
  },

  [types.CLEAR_DATA_CAR_OWNER]: state => {
    let dataFormResult;
    dataFormResult = clearFormCarOwner(state.dataForm);
    return {
      ...state,
      dataForm: dataFormResult
    };
  },

  [types.CLEAR_DATA_CAR_OWNER_JURISTIC]: state => {
    let dataFormResult;
    dataFormResult = clearFormCarOwnerJuristic(state.dataForm);

    return {
      ...state,
      dataForm: dataFormResult
    };
  },

  [types.CLEAR_DATA_CAR_PLATE]: state => {
    let dataFormResult = clearCarPlate(state.dataForm);
    return {
      ...state,
      dataForm: dataFormResult
    };
  },

  [types.CLEAR_DATA_TO]: state => {
    return {
      ...state,
      infoTO: undefined
    };
  },

  [types.WITHOUT_CAR_PLATE]: (state, {payload}) => ({
    ...state,
    withoutCarPlate: payload
  }),

  [types.CLEAR_DATA_VIN]: state => {
    let dataFormResult = clearVIN(state.dataForm);
    return {
      ...state,
      dataForm: dataFormResult
    };
  },

  [types.CLEAR_MARK_MODEL]: state => {
    let dataFormResult;
    dataFormResult = clearMarkModel(state.dataForm);
    return {
      ...state,
      dataForm: dataFormResult
    };
  },

  [types.CLEAR_PAYMENT_STATUS]: state => {
    return {
      ...state,
      payment_status: "Статус оплаты не определён..."
    };
  },

  [types.CLEAR_DATA_DK]: state => {
    let dataFormResult;
    dataFormResult = clearDKdata(state.dataForm);
    return {
      ...state,
      dataForm: dataFormResult
    };
  },

  [types.WITHOUT_VIN]: (state, {payload}) => ({
    ...state,
    withoutVIN: payload
  }),

  [types.INGOS_VIN]: (state, {payload}) => ({
    ...state,
    ingosWithoutVIN: payload
  }),

  [types.TOGGLE_TYPE_CAR_IDENTITY]: (state, {payload}) => ({
    ...state,
    typeCarIdentity: payload
  }),

  [types.TOGGLE_INGOS_TYPE_CAR_IDENTITY]: (state, {payload}) => ({
    ...state,
    typeCarIdentityIngos: payload
  }),

  [types.TOGGLE_TYPE_TRANSPORT_INSURER]: (state, {payload}) => ({
    ...state,
    typeTransportInsurer: payload
  }),

  [types.CLEAR_DATA_BODY_NUMBER]: state => {
    let dataFormResult = clearBodyNumber(state.dataForm);
    return {
      ...state,
      dataForm: dataFormResult
    };
  },

  [types.CLEAR_DATA_CHASSIS_NUMBER]: state => {
    let dataFormResult = clearChassisNumber(state.dataForm);
    return {
      ...state,
      dataForm: dataFormResult
    };
  },

  [types.GET_KBM_POLICY_CAR_OWNER_JURISTIC_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_KBM_POLICY_CAR_OWNER_JURISTIC_SUCCESS]: (state, {payload}) => {
    let kbm_coeff = {
      kbm_coeff: {
        value: String(payload.driver_kbm),
        name: "kbm_coeff",
        errors: false,
        validating: false,
        dirty: false
      }
    };
    //kbm_coeff = payload.driver_kbm;

    return {
      ...state,
      isRequest: false,
      dataForm: {...state.dataForm, ...kbm_coeff},
      errors: null
    };
  },

  [types.GET_KBM_POLICY_CAR_OWNER_JURISTIC_FAILURE]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: payload,
      visibleErrorModal: true
      //dataForm: { ...state.dataForm, ...kbm_coeff },
    };
  },

  [types.SHOW_TO_BUTTON]: (state, {payload}) => ({
    ...state,
    buttonTO: payload
  }),

  [types.TOGGLE_ERROR_MODAL]: (state, {payload}) => ({
    ...state,
    errors: payload ? state.errors : null,
    visibleErrorModal: payload
  }),

  [types.CREATE_POLICY]: state => {
    return {
      ...state,
      dataFormResult: state.dataFormResult,
      urlDownloadPdf: undefined,
      policyCreated: false,
    };
  },
  [types.CREATE_POLICY_REQUEST]: state => ({
    ...state,
    isRequest: true,
    creatingPolicy: true,
    errors: null,
  }),

  [types.CREATE_POLICY_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      policyCreated: true,
      isRequest: false,
      creatingPolicy: false,
      documentsPolicy: payload,
      documentsBSO: undefined,
      documentsNSS: undefined,
      calc_id: payload.calc_id,
      errors: null,
      bill_isn: payload.bill_isn,
      ingostrah_docs: undefined,
      alfa_docs: undefined,
      insuranceDocuments: undefined,
      policyObjId: payload.policyObjId,
      rosgosstrah_policy_id: payload.rosgosstrah_policy_id,
      vsk_policy_id: payload.vsk_policy_id,
      ingostrah_policy_id: payload.ingostrah_policy_id,
      renesans_policy_id: payload.renesans_policy_id,
      alfastrah_policy_id: payload.alfastrah_policy_id,
      gelios_policy_id: payload.gelios_policy_id,
    }
  },

  [types.CREATE_POLICY_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    policyCreated: false,
    creatingPolicy: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.CLEAR_KBM]: state => {
    let obj = clearKbm(state.dataForm);
    return {
      ...state,
      dataForm: obj
    };
  },
  [types.TOGGLE_TO_MODAL]: (state, {payload}) => ({
    ...state,
    visibleTOModal: payload
  }),
  [types.TOGGLE_CHOICE_TO_MODAL]: (state, {payload}) => ({
    ...state,
    visibleChoiceTOModal: payload
  }),

  [types.TOGGLE_EXIST_TO_MODAL]: (state, {payload}) => ({
    ...state,
    visibleExistTOModal: payload
  }),

  [types.TOGGLE_KBM_MODAL]: (state, {payload}) => ({
    ...state,
    visibleKbmModal: payload
  }),

  [types.SIGN_OUT]: state => ({
    ...state,
    ...initialState.osagoReducer
  }),

  [types.SAVE_CAR_BRAND_DATA]: (state, action) => {
    let obj = {car_brand_data: action.payload};

    return {
      ...state,
      isRequest: false,
      dataForm: {...state.dataForm, ...obj},
      errors: null
    };
  },

  [types.SAVE_CAR_BRAND]: (state, action) => {
    let obj = {brand_car: action.payload};

    return {
      ...state,
      dataForm: {...state.dataForm, ...obj},
    };
  },

  [types.SAVE_MODEL_MODIFICATIONS_DATA]: (state, action) => {
    let obj = {car_modification_data: action.payload};

    return {
      ...state,
      isRequest: false,
      dataForm: {...state.dataForm, ...obj},
      errors: null
    };
  },


  [types.SAVE_POLICY_OWNER_COUNTRY_DATA]: (state, action) => {
    let obj = {policy_owner_country_driver: action.payload};

    return {
      ...state,
      isRequest: false,
      dataForm: {...state.dataForm, ...obj},
      errors: null
    };
  },

  [types.SAVE_CAR_MODEL_DATA]: (state, action) => {
    let obj = {car_model_data: action.payload};

    return {
      ...state,
      isRequest: false,
      dataForm: {...state.dataForm, ...obj},
      errors: null
    };
  },

  [types.SAVE_CAR_MODEL]: (state, action) => {
    let obj = {model_car: action.payload};

    return {
      ...state,
      dataForm: {...state.dataForm, ...obj},
    };
  },

  [types.SELECTED_ADDRESS]: (state, {payload}) => {
    return {
      ...state,
      selectedAdress: payload
    };
  },

  [types.SELECTED_CAR_OWNER_ADDRESS]: (state, {payload}) => {
    return {
      ...state,
      selectedCarOwnerAdress: payload
    };
  },

  [types.CLEAR_MODAL_DRIVER_KBM]: (state, {payload}) => {
    return {
      ...state,
      driverKbm: undefined
    };
  },

  [types.TOOGLE_IS_SELECTED_BRAND]: (state, {payload}) => {
    return {
      ...state,
      isSelectedBrand: payload
    };
  },
  [types.TOOGLE_IS_SELECTED_MODEL]: (state, {payload}) => {
    return {
      ...state,
      isSelectedModel: payload
    };
  },

  [types.TOOGLE_CATEGORY]: (state, {payload}) => {
    let dataForm = state.dataForm
    dataForm["vehicle_type"] = construct_field("vehicle_type", payload)
    return {
      ...state,
      dataForm: {...dataForm},
    };
  },

  [types.CLEAR_FORM]: (state, {payload}) => {
    if (payload === 1) {
      return {
        ...state,
        ...initialState.osagoReducer,
      }
    } else if (payload === 2) {
      let dataFormCleared = clearProlongationData(state.dataForm);
      return {
        ...state,
        dataFormResult: undefined,
        documentsPolicy: undefined,
        calcResult: undefined,
        isProlongDisabledButtons: false,
        isProlongDisabledFields: false,
        isProlongCalcSuccess: false,
        isProlongationSEVA: false,
        policyCreated: false,
        dataForm: dataFormCleared
      }
    } else {
      return {
        ...state
      }
    }
  },

  [types.GET_KBM_DRIVER_NO_LIMIT_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_KBM_DRIVER_NO_LIMIT_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      driverKbm: payload,
      visibleResultKbmModal: true,
      visibleKbmModal: false,
      errors: null
    };
  },

  [types.GET_KBM_DRIVER_NO_LIMIT_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleResultKbmModal: false,
    visibleKbmModal: false,
    visibleErrorModal: true
  }),

  [types.GET_LASTNAME_ARRAY]: (state, action) => ({
    ...state,
    isRequest: false,
    currentOwner: action.currentOwner,
    fio: action.fio,
    errors: null
    //genGender: undefined,
  }),
  [types.GET_LASTNAME_ARRAY_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_LASTNAME_ARRAY_SUCCESS]: (state, action) => {
    let lastName = []; //action.payload;
    let gender = undefined;

    let j = 0;
    action.payload.map((item, i) => {
      //lastName[i].value = String(Math.random()+i);

      if (state.fio === "surname" && item.surname) {
        lastName[j] = {
          text: item.surname,
          value: item.surname,
          gender: item.gender
        };
        j++;
      } else if (state.fio === "name" && item.name) {
        // lastName[j].text = item.name;
        // lastName[j].value = item.name+i;
        lastName[j] = {
          text: item.name,
          value: item.name,
          gender: item.gender
        };
        j++;
      } else if (state.fio === "patronymic" && item.patronymic) {
        // lastName[j].text = item.patronymic;
        // lastName[j].value = item.patronymic+i;
        lastName[j] = {
          text: item.patronymic,
          value: item.patronymic,
          gender: item.gender
        };
        j++;
      }
    });

    if (state.fio === "surname") {
      return {
        ...state,
        isRequest: false,
        //dataForm: { ...state.dataForm, ...obj },
        lastNameArr: lastName,
        gen_gender: gender,
        errors: null
      };
    } else if (state.fio === "name") {
      return {
        ...state,
        isRequest: false,
        firstNameArr: lastName,
        gen_gender: gender,
        errors: null
      };
    } else if (state.fio === "patronymic") {
      return {
        ...state,
        isRequest: false,
        patronymicArr: lastName,
        gen_gender: gender,
        errors: null
      };
    } else {
      return {
        ...state,
        isRequest: false,
        errors: null
      };
    }
  },

  [types.GET_LASTNAME_ARRAY_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload
    //	policyAdressArr: [{'text': "", 'value': "0",}],
    //	visibleErrorModal: true,
  }),

  [types.GET_KBM_DRIVER_NO_LIMIT_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.DEFINE_GENDER]: (state, {payload}) => {
    let gen = undefined;
    let arr_name = undefined;
    if (payload.type === "lastName") {
      arr_name = state.lastNameArr;
    } else if (payload.type === "firstName") {
      arr_name = state.firstNameArr;
    }
    arr_name.map((item, i) => {
      if (payload.type === "lastName") {
        if (payload.value === item.text) {
          gen = item.gender;
        }
      } else if (payload.type === "firstName") {
        if (payload.value === item.text) {
          gen = item.gender;
        }
      } else if (payload.type === "patronymic") {
        if (payload.value === item.text) {
          gen = item.gender;
        }
      } else {
        gen = undefined;
      }
    });

    return {
      ...state,
      isRequest: false,
      genGender: gen,
      errors: null
    };
  },

  [types.GET_GONFIG_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.GET_GONFIG_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      configAdmin: payload,
      errors: null
    };
  },

  [types.GET_GONFIG_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload
  }),

  [types.TOOGLE_PROLONGATION_MODAL]: (state, {payload}) => ({
    ...state,
    visibleProlongationModal: payload
  }),


  [types.GET_KLADR_POLICY_CAR_OWNER_JURISTIC_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_KLADR_POLICY_CAR_OWNER_JURISTIC_SUCCESS]: (state, action) => {

    let adress = action.payload;

    action.payload.map((item, i) => {
      adress[i].value_cladr = item.value;
      adress[i].value = item.text;
    });

    // console.log("adress",adress);
    return {
      ...state,
      isRequest: false,
      //dataForm: { ...state.dataForm, ...obj },
      policyAdressJuristicArr: adress,
      errors: null
    };
  },

  [types.GET_KLADR_POLICY_CAR_OWNER_JURISTIC_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
  }),


  [types.SAVE_POLICY_OWNER_JURISTIC_CLADR]: (state, action) => {
    let obj = {};
    // let key1 = `policy_owner_cladr_juristic`;
    let key = `policy_owner_juristic_cladr_address`;
    let key2 = `policy_owner_juristic_address`;
    let key3 = `policy_owner_juristic_cladr_okato`;
    let key4 = `policy_owner_juristic_cladr_country`;
    let key5 = `policy_owner_juristic_cladr_region`;
    let key6 = `policy_owner_juristic_cladr_zip`;
    let key7 = `policy_owner_juristic_cladr_city_name`;
    let key8 = `policy_owner_juristic_cladr_city_kladr`;
    let key9 = `policy_owner_juristic_cladr_street_kladr`;
    let key10 = `policy_owner_juristic_cladr_street_name`;
    let key11 = `policy_owner_juristic_cladr_house`;
    let key12 = `policy_owner_juristic_cladr_block`;
    let key13 = `policy_owner_juristic_cladr_flat`;
    let key14 = `policy_owner_juristic_fias_id`;
    let key15 = `policy_owner_juristic_house_fias_id`;


    //  obj[key1] =   action.payload.value === null ? undefined : action.payload.value;
    obj[key2] = action.payload.address === null ? undefined : action.payload.address;
    obj[key3] = action.payload.okato === null ? undefined : action.payload.okato;
    obj[key4] = action.payload.country === null ? undefined : action.payload.country;
    obj[key5] = action.payload.region === null ? undefined : action.payload.region;
    obj[key6] = action.payload.zip === null ? undefined : action.payload.zip;
    obj[key7] = action.payload.city_name === null ? undefined : action.payload.city_name;
    obj[key8] = action.payload.city_kladr === null ? undefined : action.payload.city_kladr;
    obj[key9] = action.payload.street_kladr === null ? undefined : action.payload.street_kladr;
    obj[key10] = action.payload.street_name === null ? undefined : action.payload.street_name;
    obj[key11] = action.payload.house === null ? undefined : action.payload.house;
    obj[key12] = action.payload.block === null ? undefined : action.payload.block;
    obj[key13] = action.payload.flat === null ? undefined : action.payload.flat;
    obj[key14] = action.payload.fias_id === null ? undefined : action.payload.fias_id;
    obj[key15] = action.payload.house_fias_id === null ? undefined : action.payload.house_fias_id;
    return {
      ...state,
      isRequest: false,
      dataForm: {...state.dataForm, ...obj},
      //	adressArr: action.payload,
      errors: null
    };
  },


  [types.SELECTED_ADDRESS_POLICY_OWNER_JURISTIC]: (state, {payload}) => {
    return {
      ...state,
      selectedPolicyOwnerJuristicAdress: payload
    };
  },


  [types.GET_KLADR_CAR_OWNER_JURISTIC_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_KLADR_CAR_OWNER_JURISTIC_SUCCESS]: (state, action) => {

    let adress = action.payload;

    action.payload.map((item, i) => {
      adress[i].value_cladr = item.value;
      adress[i].value = item.text;
    });

    return {
      ...state,
      isRequest: false,
      carAdressJuristicArr: adress,
      errors: null
    };
  },

  [types.GET_KLADR_CAR_OWNER_JURISTIC_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
  }),


  [types.SAVE_CAR_OWNER_JURISTIC_CLADR]: (state, action) => {
    let obj = {};
    let key1 = `car_owner_juristic_cladr_address`;
    let key2 = `car_owner_juristic_adress`;
    let key3 = `car_owner_juristic_cladr_okato`;
    let key4 = `car_owner_juristic_cladr_country`;
    let key5 = `car_owner_juristic_cladr_region`;
    let key6 = `car_owner_juristic_cladr_zip`;
    let key7 = `car_owner_juristic_cladr_city_name`;
    let key8 = `car_owner_juristic_cladr_city_kladr`;
    let key9 = `car_owner_juristic_cladr_street_kladr`;
    let key10 = `car_owner_juristic_cladr_street_name`;
    let key11 = `car_owner_juristic_cladr_house`;
    let key12 = `car_owner_juristic_cladr_block`;
    let key13 = `car_owner_juristic_cladr_flat`;
    let key14 = `car_owner_juristic_fias_id`;
    let key15 = `car_owner_juristic_house_fias_id`;

    //  obj[key1] =   action.payload.value === null ? undefined : action.payload.value;
    obj[key2] =
      action.payload.address === null ? undefined : action.payload.address;
    obj[key3] =
      action.payload.okato === null ? undefined : action.payload.okato;
    obj[key4] =
      action.payload.country === null ? undefined : action.payload.country;
    obj[key5] =
      action.payload.region === null ? undefined : action.payload.region;
    obj[key6] = action.payload.zip === null ? undefined : action.payload.zip;
    obj[key7] =
      action.payload.city_name === null ? undefined : action.payload.city_name;
    obj[key8] =
      action.payload.city_kladr === null
        ? undefined
        : action.payload.city_kladr;
    obj[key9] =
      action.payload.street_kladr === null
        ? undefined
        : action.payload.street_kladr;
    obj[key10] =
      action.payload.street_name === null
        ? undefined
        : action.payload.street_name;
    obj[key11] =
      action.payload.house === null ? undefined : action.payload.house;
    obj[key12] =
      action.payload.block === null ? undefined : action.payload.block;
    obj[key13] =
      action.payload.flat === null ? undefined : action.payload.flat;
    obj[key14] =
      action.payload.fias_id === null ? undefined : action.payload.fias_id;
    obj[key15] =
      action.payload.house_fias_id === null ? undefined : action.payload.house_fias_id;
    return {
      ...state,
      isRequest: false,
      dataForm: {...state.dataForm, ...obj},
      //	adressArr: action.payload,
      errors: null
    };
  },


  [types.SELECTED_ADDRESS_CAR_OWNER_JURISTIC]: (state, {payload}) => {
    return {
      ...state,
      selectedCarOwnerJuristicAdress: payload
    };
  },

  [types.TOGGLE_OSAGO_MODAL]: (state, action) => ({
    ...state,
    visibleOsagoModal: action.payload.value,
    keyNameInsurance: action.payload.calc_item,
  }),

  [types.TOGGLE_BUTTON_MODAL_TO]: (state, {payload}) => ({
    ...state,
    buttonModalTO: payload
  }),

  [types.TOGGLE_REASON_TO]: (state, {payload}) => ({
    ...state,
    visibleReasonTO: payload
  }),


  [types.GET_KBM_JURISTIC_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_KBM_JURISTIC_SUCCESS]: (state, {payload}) => {

    return {
      ...state,
      isRequest: false,
      driverKbm: payload,
      visibleResultKbmModal: true,
      visibleKbmModal: false,
      errors: null
    };
  },

  [types.GET_KBM_JURISTIC_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleResultKbmModal: false,
    visibleKbmModal: false,
    visibleErrorModal: true
  }),


  [types.GET_INFO_KBM_MODAL_BY_INN_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_INFO_KBM_MODAL_BY_INN_SUCCESS]: (state, {payload}) => {

    return {
      ...state,
      isRequest: false,
      innInfoKbmModal: payload,
      errors: null
    };
  },

  [types.GET_INFO_KBM_MODAL_BY_INN_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),
  [types.TOGGLE_BSO_MODAL]: (state, {payload}) => ({
    ...state,
    visibleBSOModal: payload
  }),

  [types.TOGGLE_KP_MODAL]: (state, {payload}) => ({
    ...state,
    visibleKPModal: payload
  }),


  [types.DO_PAYMENT_BSO_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.DO_PAYMENT_BSO_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      documentsBSO: payload,
    };
  },

  [types.DO_PAYMENT_BSO_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.DO_PAYMENT_NSS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    documentsNSS: undefined,
    paymentDocs: undefined,
  }),

  [types.DO_PAYMENT_NSS_SUCCESS]: (state, {payload}) => {
    //  let documents = payload && payload.documents;
    return {
      ...state,
      isRequest: false,
      errors: null,
      documentsNSS: payload,
    };
  },

  [types.DO_PAYMENT_NSS_FAILURE]: (state, {payload, documentsPayload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
    documentsNSS: documentsPayload,
  }),


  [types.PRINT_KP_REQUEST]: state => ({
    ...state,
    isRequest: true,
    // errors: null,
    paymentDocs: undefined,
  }),

  [types.PRINT_KP_SUCCESS]: (state, {payload}) => {
    let fileURL = URL.createObjectURL(payload);
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute(
      'download',
      `FileName.pdf`,
    );
    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
    return {
      ...state,
      isRequest: false,
      // errors: null,
    };
  },

  [types.PRINT_KP_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
    paymentDocs: undefined,
  }),

  [types.GET_PAYMENT_STATUS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    payment_status: "",
    errors: null,
  }),

  [types.GET_PAYMENT_STATUS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      payment_status: payload.payment_status,
      insuranceDocuments: payload.documents,
      errors: null,
    };
  },

  [types.GET_PAYMENT_STATUS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    payment_status: "",
    errors: payload,
    visibleErrorModal: true,
  }),


  [types.PATCH_ALFA_PAYMENT_REQUEST]: state => ({
    ...state,
    isRequest: true,
    patch_result: "",
    errors: null,
  }),

  [types.PATCH_ALFA_PAYMENT_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      patch_result: payload.patch_result,
      alfa_docs: payload.documents,
      errors: null,
    };
  },

  [types.PATCH_ALFA_PAYMENT_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    patch_result: "",
    errors: payload,
    visibleErrorModal: true,
  }),

  [types.PATCH_INGOS_PAYMENT_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.PATCH_INGOS_PAYMENT_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      ingostrah_docs: payload.documents,
      errors: null,
    };
  },

  [types.PATCH_INGOS_PAYMENT_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
  }),

  [types.CLEAR_IS_REQUEST_STATE]: (state) => ({
    ...state,
    isRequest: false,
  }),

  [types.RESUME_DRAFT_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.RESUME_DRAFT_SUCCESS]: (state, {payload}) => {
    let dataForm = construct_dataform(payload, true)
    let selectedAdress = dataForm["policy_owner_inshur"].value === 1 && dataForm["policy_owner_cladr"] && true
    let selectedCarOwnerAdress = dataForm["inshur"] && dataForm["inshur"].value === 1 && dataForm["car_owner_cladr"] && true
    let selectedPolicyOwnerJuristicAdress = dataForm["policy_owner_inshur"] && dataForm["policy_owner_inshur"].value === 2 && dataForm["policy_owner_cladr_juristic"] && true
    let selectedCarOwnerJuristicAdress = dataForm["inshur"] && dataForm["inshur"].value === 2 && dataForm["car_owner_cladr_juristic"] && true
    let quantityAdditionalDrivers = 0
    if (dataForm["quantityAdditionalDrivers"] !== undefined) {
      quantityAdditionalDrivers = dataForm["quantityAdditionalDrivers"]
    }
    dataForm["phone"].value = (dataForm["phone"].value !== undefined && dataForm["phone"].value.includes("7")) ? (dataForm["phone"].value.includes("+") ? dataForm["phone"].value : "+" + dataForm["phone"].value) : undefined
    dataForm["car_owner_phone"].value = (dataForm["car_owner_phone"].value !== undefined && dataForm["car_owner_phone"].value.includes("7")) ? (dataForm["car_owner_phone"].value.includes("+") ? dataForm["car_owner_phone"].value : "+" + dataForm["car_owner_phone"].value) : undefined

    return {
      ...state,
      isRequest: false,
      dataForm: {
        ...dataForm,
        draft_id: payload.draft_id
      },
      selectedAdress: selectedAdress,
      selectedCarOwnerAdress: selectedCarOwnerAdress,
      selectedPolicyOwnerJuristicAdress: selectedPolicyOwnerJuristicAdress,
      selectedCarOwnerJuristicAdress: selectedCarOwnerJuristicAdress,
      quantityAdditionalDrivers: quantityAdditionalDrivers,
      withoutVIN: dataForm["vin"] === undefined,
      errors: null,
      currentStep: 3,
    };
  },

  [types.RESUME_DRAFT_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
  }),

  [types.RESUME_DRAFT_PROLONGATION_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.RESUME_DRAFT_PROLONGATION_SUCCESS]: (state, {payload}) => {
    let dataForm = construct_prolong_dataform(payload, true)

    return {
      ...state,
      isRequest: false,
      dataForm: {
        ...dataForm,
        draft_id: payload.draft_id
      },
      errors: null,
      isProlongDisabledButtons: false,
    };
  },

  [types.RESUME_DRAFT_PROLONGATION_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
  }),


  [types.RESUME_POLICY_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.RESUME_POLICY_SUCCESS]: (state, {payload}) => {
    let dataForm = construct_dataform(payload.draft, true)
    let documentsPolicy = {
      policyObjId: payload.id,
      key: payload.insurance.ins_key,
      product_type: product_type_options.find(e => payload.product_name === e.value)?.text,
      documents: payload.documents,
      price: payload.premium_sum,
      agreement_id: payload.policy_id,
      policy_owner_inshur: payload.draft.policy_owner_inshur,
      osago_uuid: payload.policy_id,
      payment_url: payload.payment_url,
      correlation_id: payload.order_id,
      order_id: payload.order_id,
      renesans_policy_id: payload.policy_id,
      policy_seria: payload.policy_series,
      policy_number: payload.policy_num,
    }
    let calcResult = [
      {
        key: payload.insurance.ins_key,
        type: product_type_options.find(e => payload.product_name === e.value)?.text,
        name: payload.insurance.short_name,
        price: payload.premium_sum,
        agent_commission: {
          value: payload.agent_commission
        },
      }
    ]
    let selectedAdress = dataForm["policy_owner_inshur"].value === 1 && dataForm["policy_owner_cladr"] && true
    let selectedCarOwnerAdress = dataForm["inshur"] && dataForm["inshur"].value === 1 && dataForm["car_owner_cladr"] && true
    let selectedPolicyOwnerJuristicAdress = dataForm["policy_owner_inshur"] && dataForm["policy_owner_inshur"].value === 2 && dataForm["policy_owner_cladr_juristic"] && true
    let selectedCarOwnerJuristicAdress = dataForm["inshur"] && dataForm["inshur"].value === 2 && dataForm["car_owner_cladr_juristic"] && true
    let quantityAdditionalDrivers = 0
    if (dataForm["quantityAdditionalDrivers"] !== undefined) {
      quantityAdditionalDrivers = dataForm["quantityAdditionalDrivers"]
    }
    dataForm["phone"].value = (dataForm["phone"].value !== undefined && dataForm["phone"].value.includes("7")) ? (dataForm["phone"].value.includes("+") ? dataForm["phone"].value : "+" + dataForm["phone"].value) : undefined
    dataForm["car_owner_phone"].value = (dataForm["car_owner_phone"].value !== undefined && dataForm["car_owner_phone"].value.includes("7")) ? (dataForm["car_owner_phone"].value.includes("+") ? dataForm["car_owner_phone"].value : "+" + dataForm["car_owner_phone"].value) : undefined

    let dataFormResult = transformDataFormWithListAdditionalDrivers(
      dataForm,
      {}
    )
    return {
      ...state,
      policyCreated: true,
      creatingPolicy: false,
      calcResult: calcResult,
      documentsPolicy: documentsPolicy,
      documentsBSO: undefined,
      documentsNSS: undefined,
      calc_id: payload.policy_id,
      bill_isn: payload.order_id,
      ingostrah_docs: undefined,
      alfa_docs: undefined,
      insuranceDocuments: undefined,
      policyObjId: payload.id,
      rosgosstrah_policy_id: payload.policy_id,
      vsk_policy_id: payload.policy_id,
      ingostrah_policy_id: payload.policy_id,
      renesans_policy_id: payload.policy_id,
      alfastrah_policy_id: payload.policy_id,
      gelios_policy_id: payload.policy_id,
      isRequest: false,
      dataForm: {
        ...dataForm,
        draft_id: payload.draft.id,
        number_policy: payload.number_policy,
        series_policy: payload.series_policy,
      },
      dataFormResult: {
        ...dataFormResult,
        draft_id: payload.draft.id,
        number_policy: payload.number_policy,
        series_policy: payload.series_policy,
      },
      selectedAdress: selectedAdress,
      selectedCarOwnerAdress: selectedCarOwnerAdress,
      selectedPolicyOwnerJuristicAdress: selectedPolicyOwnerJuristicAdress,
      selectedCarOwnerJuristicAdress: selectedCarOwnerJuristicAdress,
      quantityAdditionalDrivers: quantityAdditionalDrivers,
      withoutVIN: dataForm["vin"] === undefined,
      errors: null,
      currentStep: 5,
    };
  },

  [types.RESUME_POLICY_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
  }),

  [types.RESUME_POLICY_PROLONGATION_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.RESUME_POLICY_PROLONGATION_SUCCESS]: (state, {payload}) => {
    let documentsPolicy = {
      key: payload.insurance.ins_key,
      product_type: product_type_options.find(e => payload.product_name === e.value)?.text,
      documents: payload.documents,
      price: payload.premium_sum,
      agreement_id: payload.policy_id,
      policy_owner_inshur: payload.draft.policy_owner_inshur,
      osago_uuid: payload.policy_id,
      payment_url: payload.payment_url,
      correlation_id: payload.order_id,
      order_id: payload.order_id,
      renesans_policy_id: payload.policy_id,
    }
    let calcResult = [
      {
        key: payload.insurance.ins_key,
        type: product_type_options.find(e => payload.product_name === e.value)?.text,
        name: payload.insurance.short_name,
        price: payload.premium_sum,
        agent_commission: {
          value: payload.agent_commission
        },
      }
    ]

    return {
      ...state,
      policyCreated: true,
      creatingPolicy: false,
      calcResult: calcResult,
      documentsPolicy: documentsPolicy,
      documentsNSS: undefined,
      ingostrah_docs: undefined,
      alfa_docs: undefined,
      isRequest: false,
      isProlongCalcSuccess: true,
      dataFormResult: {
        draft_id: payload.draft.id,
        previous_policy_serial: payload.draft.previous_policy_serial,
        previous_policy_number: payload.draft.previous_policy_number,
        start_policy_date: payload.draft.start_policy_date,
        finish_policy_date: payload.draft.finish_policy_date,
        vin: payload.draft.vin,
        last_name: payload.draft.policy_owner_last_name,
        inn: payload.draft.inn,
        policy_owner_name_juristic: payload.draft.policy_owner_name_juristic,
        body_num: payload.draft.body_num,
        chassis_num: payload.draft.chassis_num,
      },
      errors: null,
    };
  },

  [types.RESUME_POLICY_PROLONGATION_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
  }),

  [types.COPY_POLICY_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.COPY_POLICY_SUCCESS]: (state, {payload}) => {
    let dataForm = construct_dataform(payload, true)
    let selectedAdress = dataForm["policy_owner_inshur"].value === 1 && dataForm["policy_owner_cladr"] && true
    let selectedCarOwnerAdress = dataForm["inshur"] && dataForm["inshur"].value === 1 && dataForm["car_owner_cladr"] && true
    let selectedPolicyOwnerJuristicAdress = dataForm["policy_owner_inshur"] && dataForm["policy_owner_inshur"].value === 2 && dataForm["policy_owner_cladr_juristic"] && true
    let selectedCarOwnerJuristicAdress = dataForm["inshur"] && dataForm["inshur"].value === 2 && dataForm["car_owner_cladr_juristic"] && true
    let quantityAdditionalDrivers = 0
    if (dataForm["quantityAdditionalDrivers"] !== undefined) {
      quantityAdditionalDrivers = dataForm["quantityAdditionalDrivers"]
    }
    dataForm["phone"].value = (dataForm["phone"].value !== undefined && dataForm["phone"].value.includes("7")) ? (dataForm["phone"].value.includes("+") ? dataForm["phone"].value : "+" + dataForm["phone"].value) : undefined
    dataForm["car_owner_phone"].value = (dataForm["car_owner_phone"].value !== undefined && dataForm["car_owner_phone"].value.includes("7")) ? (dataForm["car_owner_phone"].value.includes("+") ? dataForm["car_owner_phone"].value : "+" + dataForm["car_owner_phone"].value) : undefined

    if (state.isRenewal) {
      dataForm["previous_policy_insurance_company"] = construct_field("previous_policy_insurance_company", state.renewalInsuranceId)
      dataForm["previous_policy_serial"] = construct_field("previous_policy_serial", state.renewalPolicySerial)
      dataForm["previous_policy_number"] = construct_field("previous_policy_number", state.renewalPolicyNum)
    }

    return {
      ...state,
      isRequest: false,
      dataForm: {
        ...dataForm,
        draft_id: undefined
      },
      selectedAdress: selectedAdress,
      selectedCarOwnerAdress: selectedCarOwnerAdress,
      selectedPolicyOwnerJuristicAdress: selectedPolicyOwnerJuristicAdress,
      selectedCarOwnerJuristicAdress: selectedCarOwnerJuristicAdress,
      quantityAdditionalDrivers: quantityAdditionalDrivers,
      withoutVIN: dataForm["vin"] === undefined,
      errors: null,
      currentStep: 3,
    };
  },

  [types.COPY_POLICY_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
  }),


  [types.GET_SBER_QR_CODE_REQUEST]: state => ({
    ...state,
    isRequest: true,
    sberPaymentUrl: undefined,
    sberQRImage: undefined,
    errors: null,
  }),

  [types.GET_SBER_QR_CODE_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      sberPaymentUrl: payload.payment_url,
      sberQRImage: payload.image,
      visibleSberQRModal: true,
      errors: null,
    };
  },

  [types.GET_SBER_QR_CODE_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    sberPaymentUrl: undefined,
    sberQRImage: undefined,
    errors: payload,
    visibleErrorModal: true,
  }),


  [types.GET_SBER_QR_PAYMENT_STATUS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    payment_status: "",
    documentsNSS: undefined,
  }),

  [types.GET_SBER_QR_PAYMENT_STATUS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      payment_status: payload.payment_status,
      documentsNSS: payload,
    };
  },

  [types.GET_SBER_QR_PAYMENT_STATUS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    payment_status: "",
    visibleErrorModal: true,
  }),


  [types.TOGGLE_SBER_QR_MODAL]: (state, {payload}) => ({
    ...state,
    visibleSberQRModal: payload,
  }),

  [types.GET_RGS_PROLONGATION_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    calcResult: undefined,
    dataFormResult: undefined,
    isProlongDisabledButtons: true,
    isProlongDisabledFields: true,
    isProlongCalcSuccess: false,
  }),

  [types.GET_RGS_PROLONGATION_SUCCESS]: (state, {payload}) => {
    const dataForm = state.dataForm;
    return {
      ...state,
      isRequest: false,
      calcResult: payload["calc_results"],
      dataForm: {...dataForm, draft_id: payload.draft_id},
      dataFormResult: payload,
      isProlongDisabledButtons: false,
      isProlongCalcSuccess: true,
      isProlongDisabledFields: !payload["is_rejected"],
      errors: null
    };
  },

  [types.GET_RGS_PROLONGATION_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
    calcResult: undefined,
    dataFormResult: undefined,
    isProlongDisabledButtons: false,
    isProlongDisabledFields: false,
    isProlongCalcSuccess: false,
  }),

  [types.GET_RGS_AGREEMENT_DATA_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_RGS_AGREEMENT_DATA_SUCCESS]: (state, {payload}) => {
    let dataForm = construct_dataform(payload, false)
    let selectedAdress = dataForm["policy_owner_inshur"].value === 1 && dataForm["policy_owner_cladr"] && true
    let selectedCarOwnerAdress = dataForm["inshur"] && dataForm["inshur"].value === 1 && dataForm["car_owner_cladr"] && true
    let selectedPolicyOwnerJuristicAdress = dataForm["policy_owner_inshur"] && dataForm["policy_owner_inshur"].value === 2 && dataForm["policy_owner_cladr_juristic"] && true
    let selectedCarOwnerJuristicAdress = dataForm["inshur"] && dataForm["inshur"].value === 2 && dataForm["car_owner_cladr_juristic"] && true
    let quantityAdditionalDrivers = 0
    if (dataForm["quantityAdditionalDrivers"] !== undefined) {
      quantityAdditionalDrivers = dataForm["quantityAdditionalDrivers"]
    }
    return {
      ...state,
      isRequest: false,
      dataForm: dataForm,
      selectedAdress: selectedAdress,
      selectedCarOwnerAdress: selectedCarOwnerAdress,
      selectedPolicyOwnerJuristicAdress: selectedPolicyOwnerJuristicAdress,
      selectedCarOwnerJuristicAdress: selectedCarOwnerJuristicAdress,
      quantityAdditionalDrivers: quantityAdditionalDrivers,
      withoutVIN: dataForm["vin"] === undefined,
      errors: null,
    };
  },

  [types.GET_RGS_AGREEMENT_DATA_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.UPLOAD_ADDENDUM_FILES]: (state, {payload}) => ({
    ...state,
    addendumFileList: [...state.addendumFileList, payload],
  }),

  [types.REMOVE_ADDENDUM_FILES]: (state, {payload}) => ({
    ...state,
    addendumFileList: state.addendumFileList.filter(file => file !== payload),
  }),

  [types.SEND_ADDENDUM_APPLICATION]: state => {
    let stateDataForm = state.dataForm

    let dataFormResult = transformAddendumApplicationDataForm(stateDataForm, state)

    return {
      ...state,
      addendumApplicationDataFormResult: dataFormResult,
    }
  },

  [types.SEND_ADDENDUM_APPLICATION_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    isFormSuccessfullySent: false,
  }),

  [types.SEND_ADDENDUM_APPLICATION_SUCCESS]: (state) => ({
    ...state,
    isRequest: false,
    errors: null,
    isFormSuccessfullySent: true,
  }),

  [types.SEND_ADDENDUM_APPLICATION_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload},
    visibleErrorModal: true,
    isFormSuccessfullySent: false,
  }),

  [types.TOGGLE_ADDENDUM_MODAL]: (state, {payload}) => ({
    ...state,
    visibleModalAddendumApplication: payload
  }),

  [types.COPY_RENEWAL_POLICY_PARAMS]: (state, {payload}) => {
    return {
      ...state,
      isRenewal: true,
      renewalInsuranceId: payload.renewalInsuranceId,
      renewalPolicySerial: payload.renewalPolicySerial,
      renewalPolicyNum: payload.renewalPolicyNum,
    }
  },

});

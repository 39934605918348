import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";

import Preloader from "../../components/Preloader";
import {clearForm} from "../../actions/accidentsSportActions";
import {isRequestSelector} from "../../selectors/osagoSelectors";

import * as keys from "../../routers/keys";
import {signOut} from "../../actions/loginActions";
import {tokenSelector} from "../../selectors/loginSelectors";

import "./styles/index.scss";
import {ReactComponent as LogoHeadIcon} from '../../assets/Icons/logos/LogoHead.svg';
import {scrollToTop} from "../../utils";
import {getMoneyButton, mainDropdownMenu, showNotifications} from "../HeaderBase";
import {toggleNotificationsModal} from "../../actions/mainActions";
import {notificationsSelector} from "../../selectors/mainSelectors";

class HeaderLogOut extends Component {

  componentDidMount() {
  }

  render() {
    const {
      isRequest,
      history,
      notifications,
      toggleNotificationsModal,
      earnedMoney,
    } = this.props

    return (
      <>
        <Preloader loading={isRequest}/>
        <div className="hyd-header-container-sticky">

          <div className="hyd-header-left hyd-header-left-sticky">
          <span
            className="hyd-logo hyd-logo-header-sticky"
            onClick={() => {
              if (this.props.location.pathname === keys.HOME) {scrollToTop()}
              history.replace(keys.HOME)
            }}
          >
            <LogoHeadIcon/>
          </span>
            {mainDropdownMenu(history)}
          </div>

          <div className="hyd-header-right">
            {getMoneyButton(earnedMoney)}
            {showNotifications(notifications, toggleNotificationsModal)}
          </div>

        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  token: tokenSelector(state),
  isRequest: isRequestSelector(state),
  notifications: notificationsSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signOut,
      clearForm,
      toggleNotificationsModal,
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderLogOut)
)

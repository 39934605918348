import initialState from "../store/initialState";
import injectReducer from "../store/injectReducer";
import * as types from "../store/actionTypes";
import {replaceNullWithEmptyString} from "../utils";

export default injectReducer(initialState.contractsReducer, {

  [types.SIGN_OUT]: state => ({
    ...state,
    ...initialState.contractsReducer
  }),

  [types.TOGGLE_ERROR_MODAL]: (state, {payload}) => ({
    ...state,
    visibleErrorModal: payload
  }),


  [types.TOGGLE_CLEAR_FORM_MODAL]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    visibleClearFormModal: payload
  }),

  [types.GET_EXPORT_DOCUMENT_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    exportDocs: undefined,
  }),

  [types.GET_EXPORT_DOCUMENT_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      exportDocs: payload,
    };
  },

  [types.GET_EXPORT_DOCUMENT_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
    exportDocs: undefined,
  }),

  [types.CLEAR_IS_REQUEST_STATE]: (state) => ({
    ...state,
    isRequest: false,
  }),

  [types.GET_CONTRACTS_LIST_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_CONTRACTS_LIST_SUCCESS]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    contractsList: replaceNullWithEmptyString(payload),
    contractsCount: payload.length,
    errors: null
  }),

  [types.GET_CONTRACTS_LIST_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.SELECT_POLICY]: (state, {payload}) => {
    return {
      ...state,
      selectedObjId: payload
    };
  },

  [types.TOGGLE_VIEW_DOCS_MODAL]: (state, {payload}) => ({
    ...state,
    visibleViewDocsModal: payload
  }),

  [types.GET_POLICY_DOCS]: state => ({
    ...state
  }),

  [types.GET_POLICY_DOCS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    policyDocs: undefined
  }),

  [types.GET_POLICY_DOCS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      policyDocs: payload
    };
  },

  [types.GET_POLICY_DOCS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    policyDocs: undefined,
    visibleErrorModal: true
  }),

  [types.GET_PAYMENT_STATUS_COMMON_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    policyDocs: undefined
  }),

  [types.GET_PAYMENT_STATUS_COMMON_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      policyDocs: payload
    };
  },

  [types.GET_PAYMENT_STATUS_COMMON_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    policyDocs: undefined,
    visibleErrorModal: true
  }),

});

import React from "react";
import {Row} from "antd";

export default class InsurersDisabled extends React.Component {
  render() {
    return (
      <>
        <div className={"hyd-prev-step"}>
          <div className={"hyd-driver-number-osago-disabled"}>
            {`Застрахованный №${this.props.insurerNumber}`}
          </div>
        </div>
        <Row>
          {this.props.lastName &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Фамилия</div>
            <div className={"hyd-prev-step-value"}>{this.props.lastName}</div>
          </div>}

          {this.props.firstName &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Имя</div>
            <div className={"hyd-prev-step-value"}>{this.props.firstName}</div>
          </div>}

          {this.props.middleName &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Отчество</div>
            <div className={"hyd-prev-step-value"}>{this.props.middleName}</div>
          </div>}

          {this.props.birthDate &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата рождения</div>
            <div className={"hyd-prev-step-value"}>{this.props.birthDate}</div>
          </div>}
        </Row>
      </>
    )
  }
}
import React from "react";
import {ReactComponent as NumberInactiveIcon} from "../../../assets/Icons/NumberInactive.svg";
import {Button, Row} from "antd";
import {documents_options} from "../../../pages/Osago/step-1";
import {connect} from "react-redux";
import {
  dataFormSelector,
  inputCarPlateSelector,
  inputVinSelector,
  isRequestSelector,
} from "../../../selectors/osagoSelectors";
import {vehicleCategories} from "../../../utils";

class FirstStepDisabled extends React.Component {
  render() {
    const {
      dataForm,
      goToPrevStep,
      isDisabled
    } = this.props

    const getCarPlate = () =>
      <>
        {dataForm.car_plate &&
        <div className={"hyd-prev-step"}>
          <div className={"hyd-prev-step-header"}>Гос. номер авто</div>
          <div className={"hyd-prev-step-value"}>{dataForm.car_plate.value}</div>
        </div>}
      </>

    const getVIN = () =>
      <>
        {dataForm.vin &&
        <div className={"hyd-prev-step"}>
          <div className={"hyd-prev-step-header"}>VIN</div>
          <div className={"hyd-prev-step-value"}>{dataForm.vin.value}</div>
        </div>}
      </>

    const getIdentity = () =>
      <>
        {dataForm.body_number &&
        <div className={"hyd-prev-step"}>
          <div className={"hyd-prev-step-header"}>Кузов</div>
          <div className={"hyd-prev-step-value"}>{dataForm.body_number.value}</div>
        </div>}
        {dataForm.chassis_number &&
        <div className={"hyd-prev-step"}>
          <div className={"hyd-prev-step-header"}>Шасси</div>
          <div className={"hyd-prev-step-value"}>{dataForm.chassis_number.value}</div>
        </div>}
      </>
    return (
      <>
        <div className="step-title-disabled">
          <div className="step-icon-block">
            <NumberInactiveIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">1</span>
          </div>
          <span className="step-title-disabled-header">Транспортное средство</span>
          {goToPrevStep && !isDisabled &&
          <Button
            className="hyd-change-button-small"
            onClick={goToPrevStep}
          >
            Изменить
          </Button>}

        </div>
        <Row>
          {!this.props.withoutCarPlate && getCarPlate()}

          {!this.props.withoutVIN && getVIN()}
          {this.props.withoutVIN && getIdentity()}
        </Row>
        <Row>

          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Тип транспортного средства</div>
            <div className={"hyd-prev-step-value"}>
              {vehicleCategories.find(e => dataForm.vehicle_type.value === e.value).text}
            </div>
          </div>

          {dataForm.car_brand_data &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Марка</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_brand_data.text}</div>
          </div>}

          {dataForm.car_year_issue &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Год выпуска</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_year_issue.value}</div>
          </div>}

          {dataForm.car_model_data &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Модель</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_model_data.text}</div>
          </div>}

          {dataForm.car_modification_data &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Модификация</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_modification_data.text}</div>
          </div>}

          {dataForm.engine_power &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Мощность ТС в ЛС</div>
            <div className={"hyd-prev-step-value"}>{dataForm.engine_power.value}</div>
          </div>}
        </Row>
        <Row>
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Документы на ТС</div>
            <div className={"hyd-prev-step-value"}>
              {documents_options.find(e => dataForm.documents.value === e.value).text}
            </div>
          </div>

          {!dataForm.is_foreign_vehicle.value && dataForm.car_document_series_number &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Серия или номер документа</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_document_series_number.value}</div>
          </div>}

          {dataForm.is_foreign_vehicle.value &&
          <>
            {dataForm.car_document_serial &&
              <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Серия документа</div>
              <div className={"hyd-prev-step-value"}>{dataForm.car_document_serial.value}</div>
            </div>}
            {dataForm.car_document_number &&
              <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Номер документа</div>
              <div className={"hyd-prev-step-value"}>{dataForm.car_document_number.value}</div>
            </div>}
          </>}
          {dataForm.car_document_date &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата выдачи</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_document_date.value}</div>
          </div>}
        </Row>

      </>
    )
  }
}

const mapStateToProps = (state) => (
  {
    dataForm: dataFormSelector(state),
    isRequest: isRequestSelector(state),
    withoutCarPlate: inputCarPlateSelector(state),
    withoutVIN: inputVinSelector(state),
  });

export default connect(mapStateToProps)(FirstStepDisabled);
import {takeLatest, select} from 'redux-saga/effects';
import * as api from './api';
import * as types from '../store/actionTypes';

export function* sendDataFormCascoSaga() {
  const stateForm = yield select(state => state.cascoReducer.dataFormResult);
  yield api.post('casco_calc_request/', null, stateForm, types.SEND_DATA_FORM_CASCO);
}

export default function* () {
  yield takeLatest(types.SEND_DATA_FORM_CASCO, sendDataFormCascoSaga);
}

import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {formItemLayout} from "../../../utils";
import {toggleViewDocsModal,} from "../../../actions/contractsActions";
import {
  visibleViewDocsModalSelector,
  policyDocsSelector,
  isRequestSelector,
  policyDocumentSelector,
  policyDocTypeSelector,
} from "../../../selectors/contractsSelectors";
import {Button, Col, Form, Modal, Row, Spin} from "antd";


class ModalViewDocs extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {visibleViewDocsModal, isRequest, policyDocument, policyDocType} = this.props;

    if (!prevProps.visibleViewDocsModal && visibleViewDocsModal) {
      this.props.form.resetFields();
    }

    if (policyDocument !== prevProps.policyDocument && !isRequest && policyDocument) { //открытие Документов
      let docType
      if (policyDocType) {
        docType = policyDocType
      } else {
        docType = 'application/pdf'
      }
      let file = new Blob([policyDocument], {type: docType});
      let fileURL = URL.createObjectURL(file);
      fileURL && window.open(fileURL);
    }
  }

  showModal = () => {
    this.props.toggleViewDocsModal(true);
  };

  handleOk = () => {
    this.props.toggleViewDocsModal(false);
  };

  handleCancel = () => {
    this.props.toggleViewDocsModal(false);
  };

  render() {
    const {
      visibleViewDocsModal,
      policyDocs,
      isRequest,
    } = this.props;

    return (
      <Modal
        title={
          policyDocs && Array.isArray(policyDocs.docs) &&
          policyDocs.docs.length ? "Документы" : "Нет документов для просмотра"
        }
        visible={visibleViewDocsModal}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        cancelButtonProps={{style: {display: 'none'}}}
        width='500px'
        closable={false}
      >
        {isRequest && <Spin/>}
        {policyDocs && Array.isArray(policyDocs.docs) && policyDocs.docs.length &&
          <Form {...formItemLayout}>
            <Form.Item>
              <Row gutter={8}>
                <Col span={24}>
                  {policyDocs && policyDocs.insurance_name && (`СК ${policyDocs.insurance_name}`)}
                  {policyDocs && policyDocs.policy_number && (` Полис ${policyDocs.policy_number}`)}
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row gutter={8}>
                <Col span={24}>
                  {policyDocs.docs.map((item, index) => {
                    return (
                      item.name && (
                        <Button
                          key={item + index}
                          target="_blank"
                          style={{marginRight: "15px"}}
                          icon="download"
                          onClick={() => {
                            window.open(item.file, "_blank", "noreferrer");
                          }}
                        >
                          {item.name}
                        </Button>
                      )
                    )
                  })}
                </Col>
              </Row>
            </Form.Item>
          </Form>}
      </Modal>
    );
  }

}


const mapStateToProps = state => ({
  visibleViewDocsModal: visibleViewDocsModalSelector(state),
  policyDocs: policyDocsSelector(state),
  isRequest: isRequestSelector(state),
  policyDocument: policyDocumentSelector(state),
  policyDocType: policyDocTypeSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
      toggleViewDocsModal,
    },
    dispatch
  );

const WrappedModalViewDocsForm = Form.create({name: 'view_docs'})(ModalViewDocs);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedModalViewDocsForm);

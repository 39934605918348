import React, {Component} from "react";
import {
  Layout,
  Form,
  Input,
  Button,
  Checkbox,
  Popover,
  notification,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {hasErrors, nameRulesCyrillic} from "../../utils";
import * as keys from '../../routers/keys';
import {signUp, clearIsRequestState, saveRefId} from "../../actions/registrationActions";
import {isRequestSelector, refIdSelector, signUpCompleteSelector} from "../../selectors/registrationSelectors";

import "./styles/index.scss";
import "../../fonts/open-sans.css"
import Preloader from "../../components/Preloader";
import ModalError from "../../components/ModalError";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import {ReactComponent as LogoIcon} from "../../assets/Icons/logos/LogoAuth.svg";
import Background from '../../images/pexelsFauxels.jpg';
import Cleave from "cleave.js/react";
import qs from 'qs';

const {Content} = Layout;

class Registration extends Component {
  state = {
    oferta: "Подробное описание оферты ...",
    confidentiality: "Политика в отношении обработки персональных данных ...",
  }

  componentDidMount() {
    document.title = "SEVA"
    // To disabled submit button at the beginning.
    this.props.clearIsRequestState()
    let ref_id = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).ref_id

    ref_id && this.props.saveRefId(ref_id)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {isRequest, signUpComplete} = this.props;
    if (signUpComplete !== prevProps.signUpComplete && !isRequest && signUpComplete) {
      notification["success"]({
        message: "Вам отправлен email и SMS с параметрами учетной записи.",
        duration: 30,
      })
    }
  }

  handleSubmit = e => {
    const {signUp, refId} = this.props;
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      values.ref_id = refId
      if (!err) {
        signUp(values)
      }
    })
  }

  render() {
    const {
      isRequest,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldValue,
      getFieldsError,
      getFieldError,
    } = this.props.form;


    const email = "email";
    const emailError = getFieldError(email);
    const phone = "phone";
    const phoneError = getFieldError(phone);
    const firstName = "first_name";
    const firstNameError = getFieldError(firstName);
    const lastName = "last_name";
    const lastNameError = getFieldError(lastName);
    const middleName = "middle_name";
    const middleNameError = getFieldError(middleName);
    const isOfertaChecked = "is_oferta_checked";
    const isConfidentialityChecked = "is_confidentiality_checked";

    return (
      <>
        <Preloader loading={isRequest}/>
        <Content
          className="hyd-content-wrapper"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100vw',
            height: '100vh'
          }}
        >
          <div className="hyd-registration-container">

            <div className="hyd-logo-auth">
              <LogoIcon/>
            </div>

            <Form className="hyd-form hyd-form-auth" onSubmit={this.handleSubmit}>

              {/* Email */}
              <Form.Item
                validateStatus={emailError ? "error" : ""}
                help={emailError || ""}
                style={{marginBottom: 8}}
              >
                <FloatingLabelInput
                  divClassName="hyd-input-auth"
                  htmlFor={"registration_" + email}
                  labelText={"Email"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(email, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [
                      {required: true, message: " "},
                      {
                        pattern: /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?\s*)+$/g,
                        message: " "
                      }
                    ]
                  })(<Input className="hyd-input-small hyd-input-auth"/>)}
                />
              </Form.Item>

              {/* Телефон */}
              <Form.Item
                validateStatus={phoneError ? "error" : ""}
                help={phoneError || ""}
                style={{marginBottom: 8}}
              >
                <FloatingLabelInput
                  divClassName="hyd-input-auth"
                  htmlFor={"registration_" + phone}
                  labelText={"Телефон"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(phone, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [
                      {
                        required: true,
                        message: " "
                      },
                      {
                        pattern: /^\+?([0-9]{1})\)?[ ]?([0-9]{3})[- ]?([0-9]{3})[- ]?([0-9]{2})[- ]?([0-9]{2})$/g,
                        message: " "
                      },
                    ]
                  })(
                    <Cleave
                      className="ant-input hyd-input-small hyd-input-auth"
                      key={phone}
                      options={{
                        phone: true,
                        prefix: "+7 ",
                        phoneRegionCode: 'RU',
                        noImmediatePrefix: true,
                      }}
                    />
                  )}
                />
              </Form.Item>

              {/* Фамилия */}
              <Form.Item
                validateStatus={lastNameError ? "error" : ""}
                help={lastNameError || ""}
                style={{marginBottom: 8}}
              >
                <FloatingLabelInput
                  divClassName="hyd-input-auth"
                  htmlFor={"registration_" + lastName}
                  labelText={"Фамилия"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(lastName, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: nameRulesCyrillic,
                  })(
                    <Input
                      className={"ant-input hyd-input-small hyd-input-auth"}
                    />
                  )}
                />
              </Form.Item>

              {/* Имя */}
              <Form.Item
                validateStatus={firstNameError ? "error" : ""}
                help={firstNameError || ""}
                style={{marginBottom: 8}}
              >
                <FloatingLabelInput
                  divClassName="hyd-input-auth"
                  htmlFor={"registration_" + firstName}
                  labelText={"Имя"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(firstName, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: nameRulesCyrillic,
                  })(
                    <Input
                      className={"ant-input hyd-input-small hyd-input-auth"}
                    />
                  )}
                />
              </Form.Item>

              {/* Отчество */}
              <Form.Item
                validateStatus={middleNameError ? "error" : ""}
                help={middleNameError || ""}
                style={{marginBottom: 8}}
              >
                <FloatingLabelInput
                  divClassName="hyd-input-auth"
                  htmlFor={"registration_" + middleName}
                  labelText={"Отчество"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(middleName, {
                    rules: [
                      {
                        // только лат или только кириллица (допускается двойное имя с пробелом по центру или тире)
                        pattern: /^[А-Яа-яЁё() -]+$/g,
                        message: " ",
                      },
                    ],
                  })(
                    <Input
                      className={"ant-input hyd-input-small hyd-input-auth"}
                    />
                  )}
                />
              </Form.Item>

              <Button
                className="hyd-btn-small-2 hyd-btn-auth"
                type="primary"
                htmlType="submit"
                disabled={
                  hasErrors(getFieldsError()) ||
                  !getFieldValue(isOfertaChecked) ||
                  !getFieldValue(isConfidentialityChecked)
                }
                style={{marginBottom: 12}}
              >
                Зарегистрироваться
              </Button>

              <div className="hyd-registration-checkbox">
                {getFieldDecorator(isOfertaChecked, {initialValue: false})(
                  <Checkbox>
                  <span className={"hyd-checkbox-registration-txt"}>
                  Принимаю условия
                    <Popover
                      title="Согласие с офертой"
                      content={this.state.oferta}
                      trigger="click"
                    >
                      <a>{" публичной оферты"}</a>
                    </Popover>
                  </span>
                  </Checkbox>
                )}
              </div>

              <div className="hyd-registration-checkbox">
                {getFieldDecorator(isConfidentialityChecked, {initialValue: false})(
                  <Checkbox>
                  <span className={"hyd-checkbox-registration-txt"}>
                  Согласен
                    <Popover
                      title="Политика конфиденциальности"
                      content={this.state.confidentiality}
                      trigger="click"
                    >
                      <a>{" с политикой конфиденциальности"}</a>
                    </Popover>
                  </span>
                  </Checkbox>
                )}
              </div>

            </Form>
          </div>

          <Button
            className="hyd-btn-small-2-white hyd-btn-auth-2"
            onClick={() => {
              const {history} = this.props
              history.replace(keys.AUTH)
            }}
          >
            Войти
          </Button>

        </Content>
        <ModalError type={6}/>
      </>
    );
  }
}

const mapStateToProps = state => ({
  refId: refIdSelector(state),
  isRequest: isRequestSelector(state),
  signUpComplete: signUpCompleteSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signUp,
      saveRefId,
      clearIsRequestState,
    },
    dispatch
  );


const WrappedRegistrationForm = Form.create({name: 'registration'})(Registration);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);

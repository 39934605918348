import * as types from "../store/actionTypes";

export const setDataForm = (data, object) => ({
  type: types.SET_DATA_PROFILE_FORM,
  payload: {data, object}
});

export const clearIsRequestState = () => ({
  type: types.CLEAR_IS_REQUEST_STATE
});

export const getProfile = () => ({
  type: types.GET_PROFILE
});

export const getUserKladr = value => ({
  type: types.GET_USER_KLADR,
  payload: value
});

export const saveUserAddress = (
  value,
  address,
  okato,
  country,
  region,
  zip,
  city_name,
  city_kladr,
  street_kladr,
  street_name,
  house,
  block,
  flat,
  fias_id,
  house_fias_id,
) => ({
  type: types.SAVE_USER_ADDRESS,
  payload: {
    value,
    address,
    okato,
    country,
    region,
    zip,
    city_name,
    city_kladr,
    street_kladr,
    street_name,
    house,
    block,
    flat,
    fias_id,
    house_fias_id,
  }
});

export const toggleUserSelectedAddress = value => ({
  type: types.SELECTED_USER_ADDRESS,
  payload: value
});

export const toggleEditMode = value => ({
  type: types.EDIT_MODE,
  payload: value
});

export const toggleInnSnilsEditMode = value => ({
  type: types.EDIT_MODE_INN_SNILS,
  payload: value
});

export const updateProfile = value => ({
  type: types.UPDATE_PROFILE,
  payload: value
});

export const resetPassword = () => ({
  type: types.RESET_PASSWORD,
});

export const saveCardPaymentSystem = system => ({
  type: types.SAVE_CARD_PAYMENT_SYSTEM,
  payload: system
});

export const getRegions = () => ({
  type: types.GET_REGIONS
});

export const getReferralLink = () => ({
  type: types.GET_REFERRAL_LINK
});

export const getProductReferralLink = () => ({
  type: types.GET_PRODUCT_REFERRAL_LINK
});

export const getProductReferralQRCode = () => ({
  type: types.GET_PRODUCT_REFERRAL_QR_CODE
});

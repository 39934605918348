import osagoReducer from './osagoReducer';
import cascoReducer from './cascoReducer';
import loginReducer from './loginReducer';
import agentsReducer from './agentsReducer';
import registrationReducer from './registrationReducer';
import accidentsSportReducer from './accidentsSportReducer';
import contractsReducer from './contractsReducer';
import mainReducer from './mainReducer';
import profileReducer from './profileReducer';
import mortgageReducer from './mortgageReducer';
import crossReducer from './crossReducer';
import renewalReducer from './renewalReducer';

export default {
  osagoReducer,
  cascoReducer,
  loginReducer,
  agentsReducer,
  registrationReducer,
  accidentsSportReducer,
  contractsReducer,
  mainReducer,
  profileReducer,
  mortgageReducer,
  crossReducer,
  renewalReducer,
};

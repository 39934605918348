export const agentsSelector = state => state.agentsReducer.agents;
export const pageSizeSelector = state => state.agentsReducer.pageSize;
export const isRequestSelector = state => state.agentsReducer.isRequest;
export const errorsAgentsSelector = state => state.agentsReducer.errors;
export const agentsTypesSelector = state => state.agentsReducer.agentsTypes;
export const currentPageSelector = state => state.agentsReducer.currentPage;
export const kvCategoriesSelector = state => state.agentsReducer.kvCategories;
export const filterShowKVSelector = state => state.agentsReducer.filterShowKV;
export const filterIsActiveSelector = state => state.agentsReducer.filterIsActive;
export const filterIsOnlineSelector = state => state.agentsReducer.filterIsOnline;
export const selectedAgentsListSelector = state => state.agentsReducer.selectedAgentsList;
export const selectedSupervisorsListSelector = state => state.agentsReducer.selectedSupervisorsList;
export const isAllAgentsReceivedSelector = state => state.agentsReducer.isAllAgentsReceived;
export const visibleErrorModalAgentsSelector = state => state.agentsReducer.visibleErrorModal;
export const agentsTypesSelectedFiltersSelector = state => state.agentsReducer.agentsTypesSelectedFilters;
export const kvCategoriesSelectedFiltersSelector = state => state.agentsReducer.kvCategoriesSelectedFilters;

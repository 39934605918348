import React, {Component} from "react";
import {Row, Form, Input, Button, Select, Checkbox, Radio, Tooltip, AutoComplete,} from "antd";
import Cleave from "cleave.js/react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {setDataForm, getDriverKbm,} from "../../../actions/osagoActions";
import {countriesSelector, dataFormSelector,} from "../../../selectors/osagoSelectors";
import {nameRules, transform,} from "../../../utils";
import FloatingLabelInput from "../FloatingLabeledInputs/FloatingLabelInput";
import {ReactComponent as DeleteIcon} from '../../../assets/Icons/Delete.svg';

const {Option} = Select;

class AdditionalDrivers extends Component {
  state = {
    CleaveKey: 0,
    oldCleaveKey: 0
  }

  onDriverCustomChange = (event) => {
    let resultValueDate = event.target.value.split(" ")
    if (resultValueDate.length === 1) {
      resultValueDate = event.target.value.split(" ")
    }
    if (resultValueDate.length === 2) {
      let series = resultValueDate[0]
      let number = resultValueDate[1]
      this.props.form.setFieldsValue({
        [`additional_drivers_document_number_${this.props.k}`]: number,
        [`additional_drivers_document_serial_${this.props.k}`]: series
      });
    } else {
      this.props.form.setFieldsValue({
        [`additional_drivers_document_number_${this.props.k}`]: event.target.rawValue,
        [`additional_drivers_document_serial_${this.props.k}`]: ''
      });
    }
  }
  onOldCustomChange = (event) => {
    let resultValueDate = event.target.value.split(" ")
    if (resultValueDate.length === 1) {
      resultValueDate = event.target.value.split("/")
    }
    if (resultValueDate.length === 2) {
      let series = resultValueDate[0]
      let number = resultValueDate[1]
      this.props.form.setFieldsValue({
        [`additional_drivers_old_document_number_${this.props.k}`]: number,
        [`additional_drivers_old_document_serial_${this.props.k}`]: series
      });
    } else {
      this.props.form.setFieldsValue({
        [`additional_drivers_old_document_number_${this.props.k}`]: event.target.rawValue,
        [`additional_drivers_old_document_serial_${this.props.k}`]: ''
      });
    }
  }

  onChangeOldAddDriversCheckBox = e => {
    if (e.target.checked === false) {
      this.props.form.setFieldsValue({
        [`additional_drivers_old_document_serial_${this.props.k}`]: undefined,
        [`additional_drivers_old_document_number_${this.props.k}`]: undefined,
        [`additional_drivers_old_document_series_number_${this.props.k}`]: undefined,
        [`additional_drivers_old_document_date_${this.props.k}`]: undefined,
      })
      this.props.form.validateFields([`additional_drivers_experience_date_${this.props.k}`], {force: true})
    }
  };

  render() {
    const {
      k,
      key,
      index,
      getDriverKbm,
      remove,
      isAlone,
      add,
      defineGender,
      checkValueDate,
      checkValueExperienceDate,
      checkValueOldExperienceDate,
      checkValueStartExperienceDate,
      isLast,
      countries,
    } = this.props;
    const {
      getFieldDecorator,
      getFieldValue,
      getFieldError,
      setFieldsValue
    } = this.props.form;

    return (
      <>
        <div className={"hyd-driver-number-osago"}>
          <span>Водитель №{index}</span>
          {!isAlone && <span className={"hyd-osago-driver-delete"} onClick={() => remove(k)}>
      <Tooltip title="Удалить водителя">
        <DeleteIcon className={"hyd-osago-driver-delete-ico"}/>
      </Tooltip>
    </span>}
        </div>
        <Row key={key} data-key={key}>

          <Form.Item
            required={false}
            validateStatus={getFieldError(`additional_drivers_lastname_${k}`) ? "error" : ""}
            help={getFieldError(`additional_drivers_lastname_${k}`) || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"step-3_" + `additional_drivers_lastname_${k}`}
              labelText={"Фамилия"}
              divId={"float-label-small"}
              child={getFieldDecorator(`additional_drivers_lastname_${k}`, {
                validateTrigger: ["onBlur", "onChange"],
                rules: nameRules,
              })(<Input className={"hyd-input-small hyd-osago-8-input"}/>)}
            />
          </Form.Item>

          <Form.Item
            required={false}
            validateStatus={getFieldError(`additional_drivers_name_${k}`) ? "error" : ""}
            help={getFieldError(`additional_drivers_name_${k}`) || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"step-3_" + `additional_drivers_name_${k}`}
              labelText={"Имя"}
              divId={"float-label-small"}
              child={getFieldDecorator(`additional_drivers_name_${k}`, {
                validateTrigger: ["onBlur", "onChange"],
                rules: nameRules,
              })(<Input className={"hyd-input-small hyd-osago-8-input"}/>)}
            />
          </Form.Item>

          <Form.Item
            required={false}
            validateStatus={getFieldError(`additional_drivers_middlename_${k}`) ? "error" : ""}
            help={getFieldError(`additional_drivers_middlename_${k}`) || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input hyd-osago-no-margin"
              htmlFor={"step-3_" + `additional_drivers_middlename_${k}`}
              labelText={"Отчество"}
              divId={"float-label-small"}
              child={getFieldDecorator(`additional_drivers_middlename_${k}`, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: false,
                    message: " "
                  },
                  {
                    // только лат или только кириллица (допускается двойное имя с пробелом по центру или тире)
                    pattern: /^[А-Яа-яЁёa-zA-Z() -]+$/g,
                    message: " ",
                  },
                  {
                    validator: defineGender,
                  },
                ],
              })(<Input className={"hyd-input-small hyd-osago-8-input"}/>)}
            />
          </Form.Item>


          {/*Пол */}
          <Form.Item
            required={false}
          >
            <div className="hyd-osago-8-input hyd-select-small">
              {getFieldDecorator(`additional_drivers_gender_${k}`, {
                initialValue: "M",
                rules: [{required: true, message: " "}]
              })(
                <Select
                  placeholder="Пол"
                  className="hyd-input-select-small"
                >
                  <Option value="M">
                    <div className={"hyd-select-tooltip"}>Пол</div>
                    <div className={"hyd-select-value"}>Мужской</div>
                  </Option>
                  <Option value="F">
                    <div className={"hyd-select-tooltip"}>Пол</div>
                    <div className={"hyd-select-value"}>Женский</div>
                  </Option>
                </Select>
              )}
            </div>
          </Form.Item>

          <Form.Item
            required={false}
            validateStatus={getFieldError(`additional_drivers_birth_${k}`) ? "error" : ""}
            help={getFieldError(`additional_drivers_birth_${k}`) || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"step-3_" + `additional_drivers_birth_${k}`}
              labelText={"Дата рождения"}
              divId={"float-label-small"}
              child={getFieldDecorator(`additional_drivers_birth_${k}`, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: " ",
                  },
                  {
                    validator: checkValueDate,

                  },
                ],
              })(
                <Cleave
                  className="ant-input hyd-input-small hyd-osago-8-input"
                  key={JSON.stringify({
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  })}
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  }}

                />
              )}
            />
          </Form.Item>

          {/*Семейное положение */}
          <Form.Item
            required={false}
          >
            <div className="hyd-osago-8-input hyd-select-small hyd-osago-no-margin">
              {getFieldDecorator(`additional_drivers_family_state_${k}`, {
                initialValue: 1,
                rules: [{required: true, message: " "}]
              })(
                <Select
                  className="hyd-input-select-small"
                  style={{width: "100%"}}
                  placeholder="Семейное положение"
                >
                  <Option value={1}>
                    <div className={"hyd-select-tooltip"}>Семейное положение</div>
                    <div className={"hyd-select-value"}>Не женат/ Не замужем</div>
                  </Option>
                  <Option value={2}>
                    <div className={"hyd-select-tooltip"}>Семейное положение</div>
                    <div className={"hyd-select-value"}>Женат/ Замужем</div>
                  </Option>
                </Select>
              )}
            </div>

          </Form.Item>
          <Row>
            {/* Тип документа водителя */}
            <Form.Item
              required={true}
            >
              {getFieldDecorator(`additional_drivers_document_type_${k}`, {
                initialValue: 1,
                rules: [{required: true, message: " "}]
              })(
                <Radio.Group
                  className={"hyd-radio-group"}
                  onChange={() => {
                    this.setState({CleaveKey: ++this.state.CleaveKey});
                    setFieldsValue({
                      [`additional_drivers_document_number_${k}`]: undefined,
                      [`additional_drivers_document_serial_${k}`]: undefined,
                      [`additional_drivers_document_series_number_${k}`]: undefined,
                      [`additional_drivers_document_date_${k}`]: undefined,
                      [`additional_drivers_document_country_${k}`]: undefined,
                      [`additional_drivers_old_document_serial_${k}`]: undefined,
                      [`additional_drivers_old_document_number_${k}`]: undefined,
                      [`additional_drivers_old_document_series_number_${k}`]: undefined,
                      [`additional_drivers_old_document_date_${k}`]: undefined
                    })
                  }}
                >
                  <Radio value={1}>ВУ РФ</Radio>
                  <Radio value={2}>Иностранное ВУ</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Row>

          <Form.Item
            required={false}
            style={([1].includes(getFieldValue(`additional_drivers_document_type_${k}`))) ? {display: 'none'} : {}}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"step-3_" + `additional_drivers_document_serial_${k}`}
              labelText={"Серия документа"}
              divId={"float-label-small"}
              child={getFieldDecorator(`additional_drivers_document_serial_${k}`, {
                rules: [
                  {
                    required: true,
                    message: " ",
                  }
                ],
              })(<Input
                className={"hyd-input-small hyd-osago-8-input"}
                maxLength={10}
              />)}
            />
          </Form.Item>

          <Form.Item
            required={false}
            style={([1].includes(getFieldValue(`additional_drivers_document_type_${k}`))) ? {display: 'none'} : {}}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"step-3_" + `additional_drivers_document_number_${k}`}
              labelText={"Номер документа"}
              divId={"float-label-small"}
              child={getFieldDecorator(`additional_drivers_document_number_${k}`, {
                rules: [
                  {
                    required: true,
                    message: " ",
                  }
                ],
              })(<Input
                className={"hyd-input-small hyd-osago-8-input"}
                maxLength={10}/>)}
            />
          </Form.Item>


          <Form.Item
              required={false}
              style={([1].includes(getFieldValue(`additional_drivers_document_type_${k}`))) ? {display: 'none'} : {}}
          >
            <FloatingLabelInput
                divClassName="hyd-osago-8-input hyd-select-small hyd-osago-no-margin"
                htmlFor={"step-3_" + `additional_drivers_country_${k}`}
                labelText={"страна выдачи в/у"}
                divId={"float-label-small"}
                child={getFieldDecorator(`additional_drivers_country_${k}`, {
                  rules: [{required: false, message: " "}]
                })(
                    <AutoComplete
                        className="hyd-input-autocomplete-small"
                        dataSource={countries}
                        filterOption={(inputValue, option) =>
                            option.props.children
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                )}
            />
          </Form.Item>




          {[1].includes(getFieldValue(`additional_drivers_document_type_${k}`)) &&
          <Form.Item
            validateStatus={getFieldError(`additional_drivers_document_series_number_${k}`) ? "error" : ""}
            help={getFieldError(`additional_drivers_document_series_number_${k}`) || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"step-3_" + `additional_drivers_document_series_number_${k}`}
              labelText={"Серия и номер документа"}
              divId={"float-label-small"}
              child={getFieldDecorator(`additional_drivers_document_series_number_${k}`, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " ",
                  },
                  {
                    pattern: /((^[0-9]{2}[АВЕКМНОРСТУХавекмнорстух]{2})|(^[0-9]{2}[ABEKMHOPCTYXabekmhopctyx]{2})|(^[0-9]{4})) [0-9]{6}$/g,
                    message: " ",
                  },
                ],
              })(
                <Cleave
                  key={this.state.CleaveKey}
                  className="ant-input hyd-input-small hyd-osago-8-input"
                  style={{width: "100%"}}
                  options={{
                    blocks: [4, 6],
                    delimiter: ' ',
                    numericOnly: false
                  }}
                  onChange={this.onDriverCustomChange}
                />
              )}
            />
          </Form.Item>}


          {/* Дата выдачи вод. удостоверения  */}
          <Form.Item
            required={false}
            validateStatus={getFieldError(`additional_drivers_document_date_${k}`) ? "error" : ""}
            help={getFieldError(`additional_drivers_document_date_${k}`) || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"step-3_" + `additional_drivers_document_date_${k}`}
              labelText={"Дата выдачи"}
              divId={"float-label-small"}
              child={getFieldDecorator(`additional_drivers_document_date_${k}`, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: " ",
                  },
                  {
                    validator: checkValueExperienceDate,
                  },
                ],
              })(
                <Cleave
                  className="ant-input hyd-input-small hyd-osago-8-input"
                  key={JSON.stringify({
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  })}
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  }}

                />
              )}
            />
          </Form.Item>

          {/*Дата начала стажа вождения*/}
          <Form.Item
            required={false}
            validateStatus={getFieldError(`additional_drivers_experience_date_${k}`) ? "error" : ""}
            help={getFieldError(`additional_drivers_experience_date_${k}`) || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input hyd-osago-no-margin"
              htmlFor={"step-3_" + `additional_drivers_experience_date_${k}`}
              labelText={"Дата начала стажа"}
              divId={"float-label-small"}
              child={getFieldDecorator(`additional_drivers_experience_date_${k}`, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: " ",
                  },
                  {
                    validator: checkValueStartExperienceDate,
                  },
                ],
              })(
                <Cleave
                  className="ant-input hyd-input-small hyd-osago-8-input"
                  key={JSON.stringify({
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  })}
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"]
                  }}
                />
              )}
            />
          </Form.Item>
        </Row>
        <Row>
          {getFieldValue(`additional_drivers_document_type_${k}`) === 1 && (
            <Form.Item>
              {getFieldDecorator(`is_additional_drivers_old_${k}`, {
                initialValue: false,
                valuePropName: "checked",
              })(
                <Checkbox
                  className="hyd-checkbox-osago"
                  onChange={this.onChangeOldAddDriversCheckBox}
                >
                  <span className="hyd-checkbox-label-osago">Старое ВУ</span>
                </Checkbox>
              )}
            </Form.Item>
          )
          }
        </Row>
        {getFieldValue(`is_additional_drivers_old_${k}`)
        && getFieldValue(`additional_drivers_document_type_${k}`) === 1 && (
          <Row>
            {/* Серия старого водительского удостоверения */}
            <Form.Item
              required={false}
              style={{display: 'none'}}
            >
              {getFieldDecorator(`additional_drivers_old_document_serial_${k}`, {})(<Input
                placeholder="Серия старого водительского удостоверения"/>)}
            </Form.Item>

            {/* Номер старого водительского удостоверения */}
            <Form.Item
              required={false}
              style={{display: 'none'}}
            >
              {getFieldDecorator(`additional_drivers_old_document_number_${k}`, {})(<Input
                placeholder="Номер старого водительского удостоверения"/>)}
            </Form.Item>

            <Form.Item
              validateStatus={getFieldError(`additional_drivers_old_document_series_number_${k}`) ? "error" : ""}
              help={getFieldError(`additional_drivers_old_document_series_number_${k}`) || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input"
                htmlFor={"step-3_" + `additional_drivers_old_document_series_number_${k}`}
                labelText={"Серия и номер документа"}
                divId={"float-label-small"}
                child={getFieldDecorator(`additional_drivers_old_document_series_number_${k}`, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " ",
                    },
                    {
                      pattern: /((^[0-9]{2}[АВЕКМНОРСТУХавекмнорстух]{2})|(^[0-9]{2}[ABEKMHOPCTYXabekmhopctyx]{2})|(^[0-9]{4})) [0-9]{6}$/g,
                      message: " ",
                    },
                  ],
                })(
                  <Cleave
                    key={this.state.oldCleaveKey}
                    className="ant-input hyd-input-small hyd-osago-8-input"
                    style={{width: "100%"}}
                    options={{
                      blocks: [4, 6],
                      delimiter: ' ',
                      numericOnly: false
                    }}
                    onChange={this.onOldCustomChange}
                  />
                )}
              />
            </Form.Item>

            {/* Дата выдачи старого вод. удостоверения  */}
            <Form.Item
              required={false}
              validateStatus={getFieldError(`additional_drivers_old_document_date_${k}`) ? "error" : ""}
              help={getFieldError(`additional_drivers_old_document_date_${k}`) || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input"
                htmlFor={"step-3_" + `additional_drivers_old_document_date_${k}`}
                labelText={"Дата выдачи"}
                divId={"float-label-small"}
                child={getFieldDecorator(`additional_drivers_old_document_date_${k}`, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: " ",
                    },
                    {
                      validator: checkValueOldExperienceDate,
                    },
                  ],
                })(
                  <Cleave
                    className="ant-input hyd-input-small hyd-osago-8-input"
                    key={JSON.stringify({
                      date: true,
                      delimiter: ".",
                      datePattern: ["d", "m", "Y"]
                    })}
                    options={{
                      date: true,
                      delimiter: ".",
                      datePattern: ["d", "m", "Y"]
                    }}

                  />
                )}
              />
            </Form.Item>
          </Row>
        )
        }
        <Row>
          <div id={"hyd-osago-kbm-block"} className={"hyd-emphasise-block-small"}>
            <Form.Item>
              <Button
                type="primary"
                className="hyd-btn-small hyd-osago-btn"
                disabled={!getFieldValue(`additional_drivers_lastname_${k}`) || getFieldError(`additional_drivers_lastname_${k}`) ||
                !getFieldValue(`additional_drivers_name_${k}`) || getFieldError(`additional_drivers_name_${k}`) ||
                !getFieldValue(`additional_drivers_birth_${k}`) || getFieldError(`additional_drivers_birth_${k}`) ||
                !getFieldValue(`additional_drivers_document_number_${k}`) || getFieldError(`additional_drivers_document_number_${k}`) ||
                (!getFieldValue(`additional_drivers_document_serial_${k}`) &&
                  getFieldValue(`additional_drivers_document_type_${k}`) === 1) || getFieldError(`additional_drivers_document_serial_${k}`)
                }
                onClick={() => {
                  getDriverKbm({
                    document_type: getFieldValue(`additional_drivers_document_type_${k}`),
                    last_name: getFieldValue(`additional_drivers_lastname_${k}`),
                    first_name: getFieldValue(`additional_drivers_name_${k}`),
                    middle_name: getFieldValue(`additional_drivers_middlename_${k}`),
                    birth_date: getFieldValue(`additional_drivers_birth_${k}`),
                    number: getFieldValue(`additional_drivers_document_number_${k}`),
                    series: getFieldValue(`additional_drivers_document_serial_${k}`),
                    is_old: getFieldValue(`is_additional_drivers_old_${k}`) ? getFieldValue(`is_additional_drivers_old_${k}`) : false,
                    old_number: getFieldValue(`additional_drivers_old_document_number_${k}`) ? getFieldValue(`additional_drivers_old_document_number_${k}`) : undefined,
                    old_series: getFieldValue(`additional_drivers_old_document_serial_${k}`) ? getFieldValue(`additional_drivers_old_document_serial_${k}`) : undefined
                  }, k);
                }}
                style={{width: "100%", marginRight: "auto"}}
              >
                Получить КБМ
              </Button>


            </Form.Item>

            <Form.Item>
              <div className="hyd-osago-kbm-value">
                {getFieldDecorator(`additional_drivers_kbm_${k}`, {initialValue: ''})(
                  <Input
                    placeholder="КБМ"
                    className="hyd-input-small-emphasise"
                    disabled={true}
                  />)}
              </div>
            </Form.Item>
          </div>
          {isLast && (
            <div className={"hyd-emphasise-block-small"} id={"hyd-add-driver"}>
              <Form.Item>
                <Button
                  className={"hyd-btn-small hyd-osago-btn"}
                  onClick={add}
                  style={{width: "100%", marginRight: "auto"}}
                >
                  Добавить водителя
                </Button>
              </Form.Item>
            </div>
          )}
        </Row>
      </>
    )
  }
}

const WrappedAdditionalDriversForm = Form.create({
  name: "step-3",
  mapPropsToFields(props) {
    const {dataForm} = props;
    const transformed = transform({dataForm});

    return transformed.dataForm;
  },
  onFieldsChange(props, changedFields) {
    props.setDataForm(changedFields);
  }
})(AdditionalDrivers);

const mapStateToProps = state => ({
  dataForm: dataFormSelector(state),
  countries: countriesSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setDataForm,
      getDriverKbm,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedAdditionalDriversForm);

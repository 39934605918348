let localhost = "localhost"
let isLocal = window.location.hostname === localhost
let host = isLocal ? `${localhost}:8000` : window.location.host
let protocol = window.location.protocol
let isHttps = protocol === "https:"

export default {
  socketProtocol: isHttps ? "wss:" : "ws:",
  apiUrl: `${protocol}//${host}/api/v1/`,
  url: `${protocol}//${host}`,
  host: host,
}

import React from "react";
import {ReactComponent as NumberInactiveIcon} from "../../../assets/Icons/NumberInactive.svg";
import {Button, Row} from "antd";
import {identificationDocumentsOptions, juristicDocumentsTypes} from "../../../utils";

export default class SecondStepDisabled extends React.Component {
  render() {
    const {
      dataForm,
      goToPrevStep,
      isDisabled
    } = this.props

    const gender_options = [
      {
        value: "M",
        text: "Мужской"
      },
      {
        value: "F",
        text: "Женский"
      },
    ]

    let policyOwnerJuristicDocType = dataForm.policy_owner_juristic_document_type.value
    let carOwnerJuristicDocType = dataForm.car_owner_juristic_document_type.value

    const getInfoPolicyOwner = () =>
      <>
        {!dataForm.is_owner.value && <Row>
          <span>Страхователь</span>
        </Row>}
        <Row>
          {dataForm.policy_owner_last_name &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Фамилия</div>
            <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_last_name.value}</div>
          </div>}
          {dataForm.policy_owner_name &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Имя</div>
            <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_name.value}</div>
          </div>}
          {dataForm.policy_owner_middle_name &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Отчество</div>
            <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_middle_name.value}</div>
          </div>}
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Пол</div>
            <div className={"hyd-prev-step-value"}>
              {gender_options.find(e => dataForm.policy_owner_gender.value === e.value) && gender_options.find(e => dataForm.policy_owner_gender.value === e.value)?.text}
            </div>
          </div>
          {dataForm.policy_owner_birth_date &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата рождения</div>
            <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_birth_date.value}</div>
          </div>}
        </Row>
        <Row>
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Документ</div>
            <div className={"hyd-prev-step-value"}>
              {identificationDocumentsOptions.find(e => dataForm.policy_owner_identity_document.value === e.value)?.text}
            </div>
          </div>
          {dataForm.policy_owner_identity_document.value === 1 &&
          dataForm.document_series_number_policy_owner_serial_policy_owner_number &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Серия и номер паспорта</div>
            <div className={"hyd-prev-step-value"}>
              {dataForm.document_series_number_policy_owner_serial_policy_owner_number.value}
            </div>
          </div>}
          {dataForm.policy_owner_identity_document.value !== 1 && dataForm.policy_owner_serial &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Серия паспорта</div>
            <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_serial.value}</div>
          </div>}
          {dataForm.policy_owner_identity_document.value !== 1 && dataForm.policy_owner_number &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Номер паспорта</div>
            <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_number.value}</div>
          </div>}
          {dataForm.policy_owner_experience_date &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата выдачи</div>
            <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_experience_date.value}</div>
          </div>}
          {dataForm.policy_owner_cladr_address &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Адрес регистрации</div>
            <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_cladr_address}</div>
          </div>}
        </Row>
        <Row>
          {dataForm.policy_owner_issuer &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Кем выдан</div>
            <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_issuer.value}</div>
          </div>}
        </Row>
      </>

    const getFormJuristicPolicyOwner = () =>
      <>
        {!dataForm.is_owner.value && <Row>
          <span>Страхователь</span>
        </Row>}
        <Row>
          {dataForm.policy_owner_identify_number_juristic &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>ИНН</div>
            <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_identify_number_juristic.value}</div>
          </div>}
          {dataForm.policy_owner_name_juristic &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Название страхователя как ЮЛ</div>
            <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_name_juristic.value}</div>
          </div>}
          {dataForm.policy_owner_juristic_kpp &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>КПП организации</div>
            <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_juristic_kpp.value}</div>
          </div>}
          {dataForm.policy_owner_cladr_juristic &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>КЛАДР</div>
            <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_cladr_juristic.value}</div>
          </div>}
        </Row>
        <Row>
          <Row>
            {policyOwnerJuristicDocType && juristicDocumentsTypes.find(e => policyOwnerJuristicDocType === e.key)?.text}
          </Row>
          {[1].includes(policyOwnerJuristicDocType) && dataForm.united_policy_owner_series_juristic_document_policy_owner_number_juristic_document &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Серия и номер</div>
            <div className={"hyd-prev-step-value"}>{dataForm.united_policy_owner_series_juristic_document_policy_owner_number_juristic_document.value}</div>
          </div>}
          {[2].includes(policyOwnerJuristicDocType) && dataForm.policy_owner_number_juristic_document &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Номер</div>
            <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_number_juristic_document.value}</div>
          </div>}
          {dataForm.policy_owner_date_of_juristic_issue_doc &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата выдачи</div>
            <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_date_of_juristic_issue_doc.value}</div>
          </div>}
          {dataForm.policy_owner_juristic_cladr_address &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Адрес регистрации</div>
            <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_juristic_cladr_address.value}</div>
          </div>}
        </Row>
      </>

    const getFormPhysicalPerson = () =>
      <>
        <Row>
          <span>Собственник</span>
        </Row>
        <Row>
          {dataForm.car_owner_last_name &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Фамилия</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_owner_last_name.value}</div>
          </div>}
          {dataForm.car_owner_name &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Имя</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_owner_name.value}</div>
          </div>}
          {dataForm.car_owner_middle_name &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Отчество</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_owner_middle_name.value}</div>
          </div>}
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Пол</div>
            <div className={"hyd-prev-step-value"}>
              {gender_options.find(e => dataForm.car_owner_gender.value === e.value)?.text}
            </div>
          </div>
          {dataForm.car_owner_birth_date &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата рождения</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_owner_birth_date.value}</div>
          </div>}
        </Row>
        <Row>
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Документ</div>
            <div className={"hyd-prev-step-value"}>
              {identificationDocumentsOptions.find(e => dataForm.identity_document.value === e.value)?.text}
            </div>
          </div>
          {dataForm.identity_document.value === 1 &&
          dataForm.document_series_number_car_owner_serial_car_owner_number &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Серия и номер паспорта</div>
            <div className={"hyd-prev-step-value"}>
              {dataForm.document_series_number_car_owner_serial_car_owner_number.value}
            </div>
          </div>}
          {dataForm.identity_document.value !== 1 && dataForm.car_owner_serial &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Серия паспорта</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_owner_serial.value}</div>
          </div>}
          {dataForm.identity_document.value !== 1 && dataForm.car_owner_number &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Номер паспорта</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_owner_number.value}</div>
          </div>}
          {dataForm.car_owner_experience_date &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата выдачи</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_owner_experience_date.value}</div>
          </div>}
          {dataForm.car_owner_cladr_address &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Адрес регистрации</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_owner_cladr_address}</div>
          </div>}
        </Row>
        <Row>
          {dataForm.car_owner_issuer &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Кем выдан</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_owner_issuer.value}</div>
          </div>}
        </Row>
      </>
    const getFormJuristicPerson = () =>
      <>
        <Row>
          <span>Собственник</span>
        </Row>
        <Row>
          {dataForm.car_owner_identify_number_juristic &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>ИНН</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_owner_identify_number_juristic.value}</div>
          </div>}
          {dataForm.car_owner_name_juristic &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Название страхователя как ЮЛ</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_owner_name_juristic.value}</div>
          </div>}
          {dataForm.car_owner_kpp_juristic &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>КПП организации</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_owner_kpp_juristic.value}</div>
          </div>}
          {dataForm.car_owner_cladr_juristic &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>КЛАДР</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_owner_cladr_juristic.value}</div>
          </div>}
        </Row>
        <Row>
          <Row>
            {carOwnerJuristicDocType && juristicDocumentsTypes.find(e => carOwnerJuristicDocType === e.key)?.text}
          </Row>
          {[1].includes(carOwnerJuristicDocType) && dataForm.united_car_owner_series_juristic_document_car_owner_number_juristic_document &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Серия и номер</div>
            <div className={"hyd-prev-step-value"}>{dataForm.united_car_owner_series_juristic_document_car_owner_number_juristic_document.value}</div>
          </div>}
          {[2].includes(carOwnerJuristicDocType) && dataForm.car_owner_number_juristic_document &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Номер</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_owner_number_juristic_document.value}</div>
          </div>}
          {dataForm.car_owner_date_of_juristic_issue_doc &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата выдачи</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_owner_date_of_juristic_issue_doc.value}</div>
          </div>}
          {dataForm.car_owner_juristic_cladr_address &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Адрес регистрации</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_owner_juristic_cladr_address.value}</div>
          </div>}
        </Row>
      </>

    return (
      <>
        <div className="step-title-disabled">
          <div className="step-icon-block">
            <NumberInactiveIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">2</span>
          </div>
          <span className="step-title-disabled-header">Страхователь</span>
          {goToPrevStep && !isDisabled &&
          <Button
            className="hyd-change-button-small"
            onClick={goToPrevStep}
          >
            Изменить
          </Button>}
        </div>

        {dataForm.policy_owner_inshur.value === 1 && getInfoPolicyOwner()}
        {dataForm.policy_owner_inshur.value === 2 && getFormJuristicPolicyOwner()}

        {!dataForm.is_owner.value && dataForm.inshur.value === 1 && getFormPhysicalPerson()}
        {!dataForm.is_owner.value && dataForm.inshur.value === 2 && getFormJuristicPerson()}
      </>
    )
  }
}
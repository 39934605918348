import initialState from '../store/initialState';
import injectReducer from '../store/injectReducer';
import * as types from '../store/actionTypes';


export default injectReducer(initialState.registrationReducer, {

  [types.SIGN_UP]: (state) => {
    return {
      ...state,
    };
  },

  [types.SIGN_UP_REQUEST]: (state) => ({
    ...state,
    isRequest: true,
    errors: null,
    signUpComplete: false,
  }),

  [types.SIGN_UP_SUCCESS]: (state) => ({
    ...state,
    isRequest: false,
    errors: null,
    signUpComplete: true,
  }),

  [types.SIGN_UP_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.TOGGLE_ERROR_MODAL]: (state, {payload}) => ({
    ...state,
    visibleErrorModal: payload,
  }),

  [types.CLEAR_IS_REQUEST_STATE]: (state) => ({
    ...state,
    isRequest: false,
  }),

  [types.SAVE_REF_ID]: (state, {payload}) => ({
    ...state,
    refId: payload,
  }),

});

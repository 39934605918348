import React, {Component} from "react";
import {Form, Spin} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
  goToPrevStep,
  goToNextStep,
  createPolicyAccidentSport,
  sendDataAccidentSportForm,
  setDataAccidentsSportForm,
} from "../../actions/accidentsSportActions";
import {
  isRequestSelector,
  calcResultSelector,
  currentStepSelector,
  errorsAccidentsSportSelector,
  calculationInProgressSelector,
  accidentsSportDataFormSelector,
} from "../../selectors/accidentsSportSelectors";
import {userInfoSelector} from "../../selectors/loginSelectors";
import {transform} from "../../utils";

import ModalError from "../../components/ModalError";
import CustomCollapse from "../../components/Form/CustomCollapse";
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import {ReactComponent as NumberInactiveIcon} from "../../assets/Icons/NumberInactive.svg";
import FirstStepDisabled from "../../components/AccidentsSportDisabledSteps/FirstStepDisabled";

const ins_days_variants = {
  absolut: 5,
}

const getPlusDays = (startDate, insuranceKey) => {
  let today = new Date();
  let cur_date = startDate.split(".");
  cur_date = new Date(+cur_date[2], cur_date[1] - 1, +cur_date[0]);
  let date_difference = Math.floor((cur_date - today) / (1000 * 60 * 60 * 24))
  if (date_difference < ins_days_variants[insuranceKey]) {
    return ins_days_variants[insuranceKey]
  } else return undefined
}

class SecondStepAccidentsSport extends Component {
  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  componentDidMount() {
    this.headerRef.current.scrollIntoView()
  }

  componentDidUpdate(prevProps) {
    const {
      errors,
      isRequest,
      currentStep,
      goToNextStep,
      // creatingPolicy,
    } = this.props;

    if (
      isRequest !== prevProps.isRequest &&
      // creatingPolicy !== prevProps.creatingPolicy &&
      !isRequest &&
      !errors
    ) {
      goToNextStep(currentStep + 1);
    }

    if (errors !== prevProps.errors && errors) {
      //alert(errors)
    }
  }

  handleSubmit = (e) => {
    const {createPolicyAccidentSport} = this.props;

    this.props.form.validateFields((err) => {
      if (!err) {
        createPolicyAccidentSport(e);
      }
    });
  };

  render() {
    const {
      userInfo,
      calcResult,
      currentStep,
      goToPrevStep,
      calculationInProgress,
      accidentsSportDataForm,
      setDataAccidentsSportForm,
    } = this.props;

    return (
      <div style={{width: 911}}>
        <FirstStepDisabled
          goToPrevStep={() => goToPrevStep(currentStep - 1)}
        />

        <div className="step-title-active-small" ref={this.headerRef}>
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">2</span>
          </div>

          <span className="step-title-active-header">Расчет</span>
        </div>

        {/* Верстка результатов по страховым: */}
        <Form
          onSubmit={this.handleSubmit}
          className="calc-table"
          style={{paddingRight: "35px"}}
        >
          {calcResult &&
          Array.isArray(calcResult) &&
          calcResult.length !== 0 &&
          calcResult.map((value, index) => (
            <CustomCollapse
              plusDays={getPlusDays(accidentsSportDataForm.start_policy_date.value, value.key)}
              index={index}
              value={value}
              setDataForm={setDataAccidentsSportForm}
              toggleOsagoModal={null}
              userInfo={userInfo}
              handleSubmit={this.handleSubmit}
              key={index}
              smallUI={true}
            />

          ))
          }
          {calculationInProgress && <Spin size="large"/>}
        </Form>
        {[
          {number: 3, text: "Оплата"},
        ].map(({number, text}) =>
          <div className="step-title-disabled-small" key={number}>
            <div className="step-icon-block">
              <NumberInactiveIcon className="hyd-large-num-ico"/>
              <span className="hyd-large-num-ico-num">{number}</span>
            </div>
            <span className="step-title-disabled-header">{text}</span>
          </div>
        )}

        <ModalError type={4}/>
      </div>
    );
  }
}

const WrappedSecondStepAccidentsSportForm = Form.create({
  name: "accidents-sport-step-2",
  mapPropsToFields(props) {
    const {accidentsSportDataForm} = props;
    const transformed = transform({accidentsSportDataForm});

    return transformed.accidentsSportDataForm;
  },
  onFieldsChange(props, changedFields) {
    props.setDataAccidentsSportForm(changedFields);
  },
})(SecondStepAccidentsSport);

const mapStateToProps = (state) => ({
  userInfo: userInfoSelector(state),
  isRequest: isRequestSelector(state),
  calcResult: calcResultSelector(state),
  currentStep: currentStepSelector(state),
  errors: errorsAccidentsSportSelector(state),
  calculationInProgress: calculationInProgressSelector(state),
  accidentsSportDataForm: accidentsSportDataFormSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPrevStep,
      goToNextStep,
      setDataAccidentsSportForm,
      sendDataAccidentSportForm,
      createPolicyAccidentSport,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedSecondStepAccidentsSportForm);
